import React from 'react'
import '../../../styles/Forms.scss'
import './UploadMonitoredBundleMonitoringStep.scss'
import {
    Autocomplete, Box, FormControl, TextField
} from '@mui/material'
import {useGetAllMonitoredBundlesQuery} from '../../../../../monitoredBundle/rtkMonitoredBundleApi'
import {KeyboardArrowDown} from '@mui/icons-material'
import {useFormikContext} from 'formik'
import {Label} from '../../../../../genericComponents/typographie/Label'
import {RequestMonitoredBundleMonitoringInput} from '../../MonitorBundleForm'
import {SelectItemList} from '../../../genericComponents/SelectItemList'

export const UploadMonitoredBundleMonitoringForm = () => {
    const formik = useFormikContext<RequestMonitoredBundleMonitoringInput>()
    const {data: monitoredBundles = [] } = useGetAllMonitoredBundlesQuery()

    return (
        <>
                <FormControl className="form-select">
                    <SelectItemList
                        selectedList={formik.values.itemList}
                        handleChange={formik.handleChange}
                        touched={formik.touched.itemList}
                        label="Item List"
                        errors={formik.errors.itemList}
                        required
                    />
                </FormControl>
                <FormControl className="form-select">
                    <Label
                        className="select-item-list-label"
                        htmlFor="informationPackage"
                        required={true}
                    >
                        Monitored Bundle
                    </Label>
                    <Autocomplete
                        componentsProps={{
                            popupIndicator: {
                                disableRipple : true
                            }
                        }}
                        popupIcon={<KeyboardArrowDown/>}
                        disableClearable={true}
                        data-testid="informationpackage-dropdown-selector"
                        id="information-package-helper"
                        options={monitoredBundles}
                        autoHighlight
                        size="small"
                        getOptionLabel={(option) => option.label}
                        renderOption={(props, option) => (
                            <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                {option.label}
                            </Box>
                        )}
                        value={formik.values.monitoredBundle}
                        onChange={(event, value) => formik.setFieldValue('monitoredBundle', value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Select Monitored Bundle"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'off'
                                }}
                            />
                        )}
                    />
                </FormControl>
            </>
    )
}
