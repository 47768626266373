import { AttributeModel } from '../../model/AttributeModel'
import './ConformityLinkWidget.scss'
import LinkedItemsGroupByType from './LinkedItemsGroupByType'
import {getLinkedItemsByType, groupByDefinitionId} from '../../utils/sortedDefinitions'
import {ItemTypeEnum} from '../../../item/model/ItemType'
import {DisplaySet} from '../../model/DisplaySet'
import {SimpleItem} from '../../../item/model/SimpleItem'

const BACKWARD_LINK_DEFINITION_ID = '0fb85e6e-5385-5a74-9b72-0944486d4be3'
const FORWARD_LINK_DEFINITION_ID = 'd9a16476-e494-53cd-9454-41aa0489ae9f'

const AbstractItem = ({attributes}: {attributes: AttributeModel[]}) => {
    const definitionMap = groupByDefinitionId(attributes)
    const linkedConformItemsBackward = definitionMap.get(BACKWARD_LINK_DEFINITION_ID)?.attributes ?? []
    const linkedConformItemsForward = definitionMap.get(FORWARD_LINK_DEFINITION_ID)?.attributes ?? []

    const conformingManufacturerItems = getLinkedItemsByType(linkedConformItemsBackward, ItemTypeEnum.MANUFACTURER)
    const conformingCompanyItems = getLinkedItemsByType(linkedConformItemsBackward, ItemTypeEnum.COMPANY)
    const isConformedToManufacturerItems = getLinkedItemsByType(linkedConformItemsForward, ItemTypeEnum.MANUFACTURER)
    const isConformedToCompanyItems = getLinkedItemsByType(linkedConformItemsForward, ItemTypeEnum.COMPANY)

    return (
        <>
            <LinkedItemsGroupByType
                title="Conforming to this item"
                linkedItems={[...isConformedToManufacturerItems, ...isConformedToCompanyItems]}
            />
            <LinkedItemsGroupByType
                title="Conforms to these items"
                linkedItems={[...conformingCompanyItems, ...conformingManufacturerItems]}
            />
        </>
    )
}

const CompanyItem = ({attributes}: {attributes: AttributeModel[]}) => {
    const definitionMap = groupByDefinitionId(attributes)
    const linkedConformItemsBackward = definitionMap.get(BACKWARD_LINK_DEFINITION_ID)?.attributes ?? []
    const linkedConformItemsForward = definitionMap.get(FORWARD_LINK_DEFINITION_ID)?.attributes ?? []

    const conformingManufacturerItems = getLinkedItemsByType(linkedConformItemsBackward, ItemTypeEnum.MANUFACTURER)
    const conformingAbstractItems = getLinkedItemsByType(linkedConformItemsBackward, ItemTypeEnum.ABSTRACT)
    const isConformedToManufacturerItems = getLinkedItemsByType(linkedConformItemsForward, ItemTypeEnum.MANUFACTURER)
    const isConformedToAbstractItems = getLinkedItemsByType(linkedConformItemsForward, ItemTypeEnum.ABSTRACT)

    return (
        <>
            <LinkedItemsGroupByType
                title="Conforming to this item"
                linkedItems={[...conformingManufacturerItems, ...conformingAbstractItems]}
            />
            <LinkedItemsGroupByType title="Conforms to these specifications" linkedItems={isConformedToAbstractItems} />
            <LinkedItemsGroupByType title="Conforms to these items" linkedItems={isConformedToManufacturerItems} />
        </>
    )
}

const ManufacturerItem = ({attributes}: {attributes: AttributeModel[]}) => {
    const definitionMap = groupByDefinitionId(attributes)
    const linkedConformItemsBackward = definitionMap.get(BACKWARD_LINK_DEFINITION_ID)?.attributes ?? []
    const linkedConformItemsForward = definitionMap.get(FORWARD_LINK_DEFINITION_ID)?.attributes ?? []

    const conformingCompanyItems = getLinkedItemsByType(linkedConformItemsBackward, ItemTypeEnum.COMPANY)
    const conformingAbstractItems = getLinkedItemsByType(linkedConformItemsBackward, ItemTypeEnum.ABSTRACT)
    const isConformedToCompanyItems = getLinkedItemsByType(linkedConformItemsForward, ItemTypeEnum.COMPANY)
    const isConformedToAbstractItems = getLinkedItemsByType(linkedConformItemsForward, ItemTypeEnum.ABSTRACT)

    return (
        <>
            <LinkedItemsGroupByType
                title="Conforming to this item"
                linkedItems={[...conformingCompanyItems, ...conformingAbstractItems]}
            />
            <LinkedItemsGroupByType title="Conforms to these items" linkedItems={isConformedToCompanyItems} />
            <LinkedItemsGroupByType title="Conforms to these specifications" linkedItems={isConformedToAbstractItems} />
        </>
    )
}

const ConformityLinkWidget = ({displaySet, parentItem}: {displaySet: DisplaySet; parentItem?: SimpleItem}) => {
    const getWidgetByItemType = (type: string | undefined) => {
        switch (type) {
            case ItemTypeEnum.COMPANY:
                return <CompanyItem attributes={displaySet.attributes} />
            case ItemTypeEnum.MANUFACTURER:
                return <ManufacturerItem attributes={displaySet.attributes} />
            case ItemTypeEnum.ABSTRACT:
                return <AbstractItem attributes={displaySet.attributes} />
            default:
                return <></>
        }
    }

    return <div className="conforming-container">{getWidgetByItemType(parentItem?.type?.label)}</div>
}

export default ConformityLinkWidget
