import { Notification ,getGuildId} from '../../../models/Notification'
import React from 'react'
import {Button} from '@mui/material'
import {ROUTES} from '../../../../constants/routing'
import {generatePath, useNavigate} from 'react-router-dom'
import NotificationDisplay from './GenericNotificationDisplay'


const ServiceRequestCreatedNotification = ({notification}: { notification: Notification }) => {
    const navigate = useNavigate()

    const guildId = getGuildId(notification)

    return (

        <NotificationDisplay
            notification={notification}
            title="Service Request Created"
            canClose={true}
        >
            <Button
                id="notification-action-button-accept"
                type="button"
                variant="contained"
                onClick={() => navigate(generatePath(ROUTES.serviceRequests.path || '', {currentGuildId: guildId}))}
            >
                View

            </Button>
        </NotificationDisplay>

    )


}



export default ServiceRequestCreatedNotification