import {Link} from 'react-router-dom'
import {ROUTES} from '../../constants/routing'
import {useDatxPathGenerator} from '../../util/routing'
import './CookiesAlert.scss'
import {Button} from '@mui/material'
import {useCookiesControl} from '../useCookiesControl'
import {CookiesSettingsDialog} from '../CookiesDialog/CookiesSettingsDialog'

export const CookiesAlert = () => {

    const generatePath = useDatxPathGenerator()

    const {
        openCookieAlert,
        allowAllCookies,
        declineAllCookies,
        openSettingsDialog,
        handleOpenDialog,
        handleCloseDialog,
        acceptCustomCookies
    } = useCookiesControl()


    return openCookieAlert ?
        <div className="cookie-wrapper">
            <div className="decline-cookie-section">
                <Button onClick={declineAllCookies} className="decline-cookie-button" id="reject-all-cookies-button">Continue
                    without accepting</Button>
            </div>
            <div className="cookie-content-section">
                <div className="cookie-information">
                    <p>By clicking "Allow all cookies", you agree that DATX and its partners will deposit cookies on
                        your device to analyze site usage and improve your experience.</p>
                    <p>If you continue without accepting, some technical cookies necessary for the proper functioning of
                        the site will still be used.</p>
                    <br/>
                    <p>For more information, you may consult our <Link to={generatePath(ROUTES.cookiePolicy.path)}
                                                                       className="cookie-link">Cookie policy and list of
                        cookies</Link></p>
                </div>
                <div className="cookie-buttons">
                    <Button onClick={handleOpenDialog} id="cookies-settings-button">Cookies Settings</Button>
                    <Button onClick={allowAllCookies} variant="contained" id="accept-all-cookies-button"
                            data-testid="accept-all-cookies-button">Allow all cookies</Button>
                </div>

            </div>
        </div>
        : <CookiesSettingsDialog openDialog={openSettingsDialog}
                                 handleClose={handleCloseDialog}
                                 allowAllCookies={allowAllCookies}
                                 declineAllCookies={declineAllCookies}
                                 acceptCustomCookies={acceptCustomCookies}/>
}