import {AppBar, Checkbox, Divider, IconButton, Toolbar, Tooltip} from '@mui/material'
import GridViewIcon from '@mui/icons-material/GridView'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import {SelectionDownload} from '../../../../util/component/SelectionDownload'
import './SearchToolBar.scss'
import {SearchItem} from '../../../model/SearchItem'
import {SelectPageSizeWithMenu} from '../PageSelection/SelectPageSizeWithMenu'
import AddToListButton from '../../../../genericComponents/AddToListButton'

const SearchToolBarForClassificationAndPhraseSearch = ({
                           handleSelectAll,
                           handleUnselectAll,
                           handleCreateItemList,
                           isAnItemOnPageSelected,
                           allItemsSelected,
                           selectedItemsList,
                           startIndex,
                           endIndex,
                           totalResultCount,
                           legacyView,
                           toggleLegacyView,
                       }: {
    handleSelectAll: () => void,
    handleUnselectAll: () => void,
    handleCreateItemList: () => void,
    isAnItemOnPageSelected: boolean,
    allItemsSelected: boolean,
    selectedItemsList: SearchItem[],
    startIndex: number,
    endIndex: number,
    totalResultCount: number,
    toggleLegacyView: () => void,
    legacyView: boolean,
    hasSwitchCardViewIconButton?: boolean
    hasCreateItemListIconButton?: boolean,
    hasCheckboxIconButton?: boolean,
    hasDeleteIconButton?: boolean,
    stopSharingSpecificItems?: () => void,
}) => {
    const noItemSelected = selectedItemsList.length === 0

    return (<AppBar position="static" className="item-search-toolbar-container">
        <Toolbar variant="dense" className="item-search-toolbar">
            <Checkbox
                data-testid="check-all-items"
                checked={allItemsSelected}
                indeterminate={isAnItemOnPageSelected && !allItemsSelected}
                onClick={() => {
                    if (!allItemsSelected) {
                        handleSelectAll()
                    } else {
                        handleUnselectAll()
                    }
                }}
            />

            <Divider orientation="vertical" variant="middle" flexItem/>

            <AddToListButton onClick={handleCreateItemList} tooltip="Create new list from selection"/>

            <SelectionDownload selectedItems={selectedItemsList} isDisabled={noItemSelected}/>


            <Divider orientation="vertical" variant="middle" flexItem/>
            <IconButton onClick={toggleLegacyView}>
                {!legacyView && <Tooltip title="Switch to card view"><GridViewIcon/></Tooltip>}
                {legacyView && <Tooltip title="Switch to table view"><FormatListBulletedIcon/></Tooltip>}
            </IconButton>

            <SelectPageSizeWithMenu startIndex={startIndex} endIndex={endIndex} totalResultCount={totalResultCount}/>
        </Toolbar>
    </AppBar>)
}

export default SearchToolBarForClassificationAndPhraseSearch