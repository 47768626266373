import React from 'react'
import {useCurrentGuildContext} from '../../util/routing'
import {useGetGuildServiceRequestsForApproverQuery} from '../../service/serviceRequests/rtkServiceRequestApi'
import ApprovalDashboardTable from './ApprovalDashboardTable'
import {PageTitle} from '../../genericComponents/typographie/PageTitle'

const ApprovalDashboardPage = () => {
    const [currentGuildContext] = useCurrentGuildContext()
    const {data: serviceRequests = []} = useGetGuildServiceRequestsForApproverQuery(
        currentGuildContext?.guildId,
        {skip: !currentGuildContext?.guildId}
    )

    return (
        <>
            <div className="guildServiceOrdersMain">
                <PageTitle id="approve-dashboard-title" title="Approval Dashboard" />
                <div className="guild-service-request-table-container"
                     data-testid="guild-service-request-table-container">
                    <ApprovalDashboardTable userServiceRequests={serviceRequests} />
                </div>
            </div>
        </>
    )
}

export default ApprovalDashboardPage