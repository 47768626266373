import React, {useImperativeHandle, useRef, useState} from 'react'
import {Menu, MenuItemProps, MenuProps} from '@mui/material'
import {ChevronRight} from '@mui/icons-material'
import IconMenuItem from './IconMenuItem'

export interface NestedMenuItemProps extends Omit<MenuItemProps, 'button'> {
    parentMenuOpen: boolean
    component?: React.ElementType
    label?: string
    rightIcon?: React.ReactNode
    leftIcon?: React.ReactNode
    children?: React.ReactNode
    className?: string
    tabIndex?: number
    disabled?: boolean
    containerProps?: React.HTMLAttributes<HTMLElement> &
        React.RefAttributes<HTMLElement | null>
    menuProps?: Omit<MenuProps, 'children'>
    button?: true | undefined
}

// imported from opensource project https://github.com/steviebaa/mui-nested-menu/blob/main/src/mui-nested-menu/components/NestedMenuItem.tsx
const CustomNestedMenuItem = React.forwardRef<HTMLLIElement | null, NestedMenuItemProps> (function NestedMenuItem(props, ref) {
    const {
        parentMenuOpen,
        label,
        rightIcon = <ChevronRight />,
        leftIcon = null,
        children,
        className,
        tabIndex: tabIndexProp,
        containerProps: ContainerPropsProp = {},
        ...MenuItemProps
    } = props


    const {ref: containerRefProp, ...containerProps} = ContainerPropsProp

    const menuItemRef = useRef<any>(null)
    useImperativeHandle(ref, () => menuItemRef.current)

    const containerRef = useRef<any>(null)
    useImperativeHandle(containerRefProp, () => containerRef.current)

    const menuContainerRef = useRef<any>(null)

    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false)

    const handleMouseEnter = (e: React.MouseEvent<HTMLElement>) => {
        setIsSubMenuOpen(true)
        containerProps?.onMouseEnter?.(e)
    }
    const handleMouseLeave = (e: React.MouseEvent<HTMLElement>) => {
        setIsSubMenuOpen(false)
        containerProps?.onMouseLeave?.(e)
    }

    // Check if any immediate children are active
    const isSubmenuFocused = () => {
        const active = containerRef?.current?.ownerDocument?.activeElement ?? false
        for (const child of menuContainerRef?.current?.children ?? {}) {
            if (child === active) {
                return true
            }
        }
        return false
    }

    const handleFocus = (e: React.FocusEvent<HTMLElement>) => {
        if (e.target === containerRef.current) {
            setIsSubMenuOpen(true)
        }

        containerProps?.onFocus?.(e)
    }

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Escape') {
            return
        }

        if (isSubmenuFocused()) {
            e.stopPropagation()
        }

        const active = containerRef.current.ownerDocument.activeElement

        if (e.key === 'ArrowRight' && isSubmenuFocused()) {
            containerRef.current.focus()
        }

        if (
            e.key === 'ArrowLeft' &&
            e.target === containerRef.current &&
            e.target === active
        ) {
            const firstChild = menuContainerRef.current.children[0]
            firstChild.focus()
        }
    }

    const open = isSubMenuOpen && parentMenuOpen

    // Root element must have a `tabIndex` attribute for keyboard navigation
    let tabIndex
    if (!props.disabled) {
        tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1
    }

    return (
        <div
            {...containerProps}
            ref={containerRef}
            onFocus={handleFocus}
            tabIndex={tabIndex}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onKeyDown={handleKeyDown}
            className={className}
        >
            <IconMenuItem
                MenuItemProps={MenuItemProps}
                className={className}
                ref={menuItemRef}
                leftIcon={leftIcon}
                rightIcon={rightIcon}
                label={label}
            />

            <Menu
                // Set pointer events to 'none' to prevent the invisible Popover div
                // from capturing events for clicks and hovers
                style={{pointerEvents: 'none'}}
                anchorEl={menuItemRef.current}
                anchorOrigin={{vertical: 'top', horizontal: 'left'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                open={open}
                autoFocus={false}
                disableAutoFocus
                disableEnforceFocus
                onClose={() => {
                    setIsSubMenuOpen(false)
                }}
            >
                <div ref={menuContainerRef} style={{pointerEvents: 'auto'}}>
                    {children}
                </div>
            </Menu>
        </div>
    )
})

CustomNestedMenuItem.displayName = 'CustomNestedMenuItem'
export {CustomNestedMenuItem}