import React from 'react'
import {ROUTES} from '../../constants/routing'
import ItemNavigationMenu from '../headerGenericComponents/ItemNavigationMenu'
import {useDatxPathGenerator} from '../../util/routing'

const ItemListMenu = () => {
    const generatePath = useDatxPathGenerator()

    return (
        <ItemNavigationMenu
            label="Lists"
            subRoute="/lists"
            url={generatePath(ROUTES.manageItemLists.path)}
        />
    )
}

export default ItemListMenu
