import React, {ChangeEvent} from 'react'
import {Pagination} from '@mui/lab'

export const ES_MAX_TOTAL_NUMBER_OBJECTS_FOR_PAGES = 10000

export const SearchPagination = ({totalElements, pageSize, page, handlePageChange}: {
    totalElements: number,
    pageSize: number,
    page: number,
    handlePageChange: (e: ChangeEvent<unknown>, pageNumber: number) => void
}) => {
    let totalNumberOfPages = Math.ceil(totalElements / pageSize)
    if(totalElements >= ES_MAX_TOTAL_NUMBER_OBJECTS_FOR_PAGES)
        totalNumberOfPages = Math.ceil(ES_MAX_TOTAL_NUMBER_OBJECTS_FOR_PAGES / pageSize)

    return <Pagination className="pagination-bar"
                       boundaryCount={0}
                       size="large"
                       count={totalNumberOfPages}
                       page={page}
                       onChange={handlePageChange}
                       shape="rounded"/>
}
