import {IdType} from '../../../util/models/IdType'
import CustomDialog from '../../../genericComponents/customDialog/CustomDialog'
import {Button, Divider} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import {usePromoteGuildUserToGuildAdminMutation} from '../../rtkGuildApi'
import './GuildMemberPromoteAdminDialog.scss'


const GuildMemberPromoteAdminDialog = ({ isOpen, closeDialog, params }:
                                         {
                                             isOpen: boolean,
                                             closeDialog: () => void,
                                             params: {
                                                 guildId: IdType | undefined,
                                                 memberId: IdType | undefined,
                                                 memberEmail: string | undefined
                                             }
                                         }
) => {
    return <PromoteToAdminDialog
        open={isOpen}
        closeDialog={closeDialog}
        title="Confirm Promotion"
        confirmationMsg={`${params.memberEmail} will be promoted to Guild Admin?`}
        actionButtonTitle="Confirm"
        params={params}
    />
}

const PromoteToAdminDialog = ({ open, closeDialog, title, confirmationMsg, actionButtonTitle, params}: {
    open: boolean,
    closeDialog: () => void,
    title: string,
    confirmationMsg: string,
    actionButtonTitle: string,
    params: { guildId: IdType | undefined, memberId: IdType | undefined }
}) => {
    const [promoteUser, promoteUserResult] = usePromoteGuildUserToGuildAdminMutation()
    const onSubmit = () => {
        promoteUser({ guildId: params.guildId, memberId: params.memberId })
            .then((data) => {
                if (data) closeDialog()
            })
    }

    return (
        <CustomDialog
            className="promote-admin-dialog"
            title={title}
            open={open}
            onClose={closeDialog}
        >
            <div>
                <p className="confirmation-dialog-text">
                    {confirmationMsg}
                </p>
                <Divider sx={{ mt: 5, mb: 2 }} />

                <div className="confirmation-dialog-buttons">
                    <Button
                        className="delete-item-list-back-button"
                        onClick={() => closeDialog()}
                    >
                        Cancel
                    </Button>

                    <LoadingButton
                        className="delete-item-list-submit"
                        color="primary"
                        variant="contained"
                        loading={promoteUserResult.isLoading}
                        onClick={() => onSubmit()}
                    >
                        {actionButtonTitle}
                    </LoadingButton>
                </div>
            </div>
        </CustomDialog>
    )
}

export default GuildMemberPromoteAdminDialog