import {Typography} from '@mui/material'
import './GuildServiceRequests.scss'
import {useTypedSelector} from '../../util/store'
import GuildServiceRequestsTable from './GuildServiceRequestsTable'
import {useGetGuildQuery} from '../rtkGuildApi'
import {useGetGuildServiceRequestsQuery} from '../../service/serviceRequests/rtkServiceRequestApi'
import {NumericFormat} from 'react-number-format'
import {CREDITS} from '../../constants/credits'
import { useCurrentGuildContext } from '../../util/routing'

const GuildServiceRequests = () => {
    const [currentGuildContext] = useCurrentGuildContext()
    const {data: guild} = useGetGuildQuery(currentGuildContext?.guildId, {skip: !currentGuildContext?.guildId || !currentGuildContext?.guildAdmin})
    const {data: serviceRequests = []} = useGetGuildServiceRequestsQuery(currentGuildContext?.guildId, {skip: !currentGuildContext?.guildId || !currentGuildContext?.guildAdmin})

    return (
        <>
            {guild && <div className="guildServiceOrdersMain">

                <Typography variant="h5" id="serviceOrdersTitle" className="serviceOrdersTitle">
                    Services Requests
                </Typography>

                {currentGuildContext?.guildAdmin &&
                    <>

                        <div className="guild-service-request-table-container"
                             data-testid="guild-service-request-table-container">


                            <GuildServiceRequestsTable userServiceRequests={serviceRequests}
                                                       guildBudget = {guild.budget}/>
                        </div>
                    </>
                }
            </div>
            }
        </>
    )
}

export default GuildServiceRequests
