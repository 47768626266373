import React, {useState} from 'react'
import GenericNotificationDisplay from './GenericNotificationDisplay'
import './GuildInvitationNotification.scss'
import {Notification} from '../../../models/Notification'
import {NotificationParameter} from '../../../models/NotificationAction'
import {useAcceptGuildInvitationMutation, useRejectGuildInvitationMutation} from '../../../../guild/rtkGuildApi'
import {useDeleteNotificationMutation} from '../rtkNotificationApi'
import {LoadingButton} from '@mui/lab'

const GuildInvitationNotification = ({notification}: { notification: Notification }) => {
    const GUILD_INVITATION_ACTION_CATEGORY_ACCEPT = 'GUILD_INVITATION_APPROVE'
    const GUILD_INVITATION_ACTION_CATEGORY_REJECT = 'GUILD_INVITATION_REJECT'

    const [acceptGuildInvitation, acceptGuildInvitationResult] = useAcceptGuildInvitationMutation()
    const [rejectGuildInvitation, rejectGuildInvitationResult] = useRejectGuildInvitationMutation()
    const [deleteNotification, deleteNotificationResult] = useDeleteNotificationMutation()
    const [notificationIds, setNotificationIds] = useState<string[]>([])


    const isDisabled = acceptGuildInvitationResult.isLoading || rejectGuildInvitationResult.isLoading
        || deleteNotificationResult.isLoading
    
    const {notificationActions} = notification

    const rejectInvitationAction = notificationActions.find(
        (a) => a.category === GUILD_INVITATION_ACTION_CATEGORY_REJECT
    )

    const acceptInvitationAction = notificationActions.find(
        (a) => a.category === GUILD_INVITATION_ACTION_CATEGORY_ACCEPT
    )

    const guildId: NotificationParameter | undefined = rejectInvitationAction?.parameters?.find(param => param.label === 'guildId')
    const invitedUser: NotificationParameter | undefined = rejectInvitationAction?.parameters?.find(param => param.label === 'invitedUser')

    const getDeclineInvitationButton = () => {
        return (
            <>
                    <LoadingButton
                        className="notification-action-button reject"
                        id="notification-action-button-reject"
                        type="button"
                        variant="outlined"
                        onClick={() => rejectGuildInvitation({'notificationId': notification.id, 'guildId': guildId?.value, 'invitedUserId': invitedUser?.value})
                            .then(() => deleteNotification(notification.id)).then(() => setNotificationIds([...notificationIds, notification.id]))
                        }
                        disabled={isDisabled || notificationIds.includes(notification.id)}
                        loading={rejectGuildInvitationResult.isLoading}
                    >
                        Reject
                    </LoadingButton>
            </>
        )
    }

    const getAcceptInvitationButton = () => {
        return (
            <>
                {
                    <LoadingButton
                        className="notification-action-button accept"
                        id="notification-action-button-accept"
                        type="button"
                        variant="contained"
                        onClick={() => acceptGuildInvitation({ 'notificationId': notification.id, 'guildId': guildId?.value, 'invitedUserId': invitedUser?.value })
                            .then(() => deleteNotification(notification.id)).then(() => setNotificationIds([...notificationIds, notification.id]))
                        }
                        disabled={isDisabled || notificationIds.includes(notification.id)}
                        loading={acceptGuildInvitationResult.isLoading}
                    >
                        Accept
                    </LoadingButton>
                }
            </>
        )
    }

    return (
        <GenericNotificationDisplay
            notification={notification}
            canClose={false}
            title={'Guild invitation'}
        >
            {notificationActions?.length > 0 && (
                <div className="datx-notification-actions">
                    {rejectInvitationAction && getDeclineInvitationButton()}
                    {acceptInvitationAction && getAcceptInvitationButton()}
                </div>
            )}
        </GenericNotificationDisplay>
    )
}

export default GuildInvitationNotification