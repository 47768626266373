import {createContext, ReactNode, useEffect, useState} from 'react'
import {IdType} from '../util/models/IdType'
import {DisplaySetOrdered} from '../user/model/DisplaySetOrdered'

export interface DetailsContext {
    selectedDisplaySet?: DisplaySetOrdered,
    setSelectedDisplaySet: (displaySetId: IdType) => void
  }

 export const DisplaySetContext = createContext<DetailsContext>({selectedDisplaySet : undefined, setSelectedDisplaySet: (displaySetId: IdType) => {/**/}})

 export const DisplaySetProvider = ({displaySets, children} :  {displaySets? : DisplaySetOrdered[], children? : ReactNode}) => {
   const [selectedDisplaySet, setSelectedDisplaySet] = useState<DisplaySetOrdered>()

   useEffect(() => {
      // if I have at least one display set
      if(displaySets && displaySets.length > 0) {
          const initialDisplaySet = displaySets.reduce((prev, curr) => prev.displayOrder < curr.displayOrder ? prev : curr)
          setSelectedDisplaySet(initialDisplaySet)
      }
   }, [displaySets])

   return  (
      <DisplaySetContext.Provider value={{selectedDisplaySet: selectedDisplaySet, setSelectedDisplaySet : (id: IdType) => {
         setSelectedDisplaySet(displaySets?.find(ds => ds.id === id))
      }}}>
         {children}
      </DisplaySetContext.Provider>
   )
 }
