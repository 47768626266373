import {Button, Checkbox, Dialog as MuiDialog, Divider, FormControlLabel, IconButton} from '@mui/material'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import React, {useState} from 'react'
import './ManagePrivateAttributeSharing.scss'
import {ROUTES} from '../../constants/routing'
import {useCurrentGuildContext, useDatxPathGenerator} from '../../util/routing'
import {LoadingButton} from '@mui/lab'
import {useGetPrivateDefinitionsManagedByGuildQuery} from '../../search/rtkSearchApi'
import {useGetSharingByIdQuery, useUpdateDefinitionsSharingMutation} from '../rtkGuildApi'
import {useFormik} from 'formik'
import {array, object} from 'yup'
import {Close} from '@mui/icons-material'

const ManagePrivateAttributeSharing = () => {
    const generatePath = useDatxPathGenerator()
    const navigate = useNavigate()
    const location = useLocation()
    const targetGuildName = location?.state?.targetGuildName ?? 'Target Guild'
    const {sharingId} = useParams()
    const [openDialog, setOpenDialog] = useState(false)

    const [currentGuildContext] = useCurrentGuildContext()

    const {data:definitions = [] , isLoading: isDefinitionsLoading} = useGetPrivateDefinitionsManagedByGuildQuery({guildId :currentGuildContext?.guildId}, {skip: !currentGuildContext?.guildId })

    const [selectAllDefinitionsChecked, setSelectAllDefinitionsChecked] = useState<boolean>(false)

    const handleClose = () => {
        setOpenDialog(false)
        navigate(generatePath(ROUTES.itemsShareToOtherGuild.path, {sharingId: sharingId}))
    }

    const [updateDefinitionsSharing,
        updateSharingResult] = useUpdateDefinitionsSharingMutation()

    const {
        data: sharingDetail,
        isFetching: isSharingLoading
    } = useGetSharingByIdQuery(sharingId!, {skip: !sharingId})


    const handleSubmit = async () => {
        const result = await updateDefinitionsSharing({sharingId: sharingId ?? '', definitions: formik.values.updatedDefinitionIds ?? []})
        if ('data' in result) setOpenDialog(true)
    }

    const validationSchema = object({
        updatedDefinitionIds:  array().min(0)
    })

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            updatedDefinitionIds: sharingDetail?.definitionsSharing ?? []
        },
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
    })

    const handleSelectAll = () => {
        const newSelectAll = !selectAllDefinitionsChecked
        if(newSelectAll) {
            formik.setValues({...formik.values, updatedDefinitionIds: definitions.map(({id}) => id)})
        } else {
            formik.setValues({...formik.values, updatedDefinitionIds: []})
        }
        setSelectAllDefinitionsChecked(newSelectAll)
    }

    const confirmationAttributesShared = () => {
        return (
            <MuiDialog open={openDialog} onClose={handleClose}>
                <div className="custom-dialog">
                    <div className="dialog-header">
                        <div className="dialog-title" data-testid="title-dialog">Private Attributes Shared</div>
                        <IconButton className="dialog-close" onClick={handleClose} data-testid="close-btn">
                            <Close/>
                        </IconButton>
                    </div>
                    <div className="dialog-body add-sharing-dialog-body" data-testid="add-sharing-message">
                        You have successfully shared the following Private Attributes with Guild {sharingDetail?.targetGuildName}
                        <p>
                            {sharingDetail?.definitionsSharing?.map((sharedDefinitionId) => (
                                <div>{definitions.find(def => def.id === sharedDefinitionId)?.name}</div>
                            ))}
                        </p>
                    </div>
                </div>
            </MuiDialog>)
    }

    return <div className="page-container">
        <div className="guild-form-title-container">
            <h5 id="shareToOtherGuildTitle" className="page-title">
                Manage Sharing with <span>{targetGuildName}</span>
            </h5>
            <Divider className="divider-title"/>
        </div>
        <div>By sharing Attribute Definitions, the guild will have access to the Private Attributes all the time.
        </div>

        <div className="management-section">
            <div className="management-header">
                <h3 className="management-header-title">Update Private Attributes Shared <span className="mandatory-character">*</span>
                </h3>
            </div>

        </div>

        <div className="definition-selection">
            {!isDefinitionsLoading && !isSharingLoading &&
                <div className="definition-selection-list" data-testid="definition-selection-list">
                    <FormControlLabel
                        control={
                            <Checkbox
                                data-testid="select-all-attributes"
                                name="selectAllDefinitions"
                                onChange={handleSelectAll}
                                checked={selectAllDefinitionsChecked || definitions.length === formik.values.updatedDefinitionIds.length}
                            />
                        }
                        label="(Select All)"
                    />
                    {!(isDefinitionsLoading && definitions.length > 0) &&
                        definitions.map((definition) => (
                            <FormControlLabel
                                key={definition.id}
                                data-testid={definition.id + '-definition-selection'}
                                id={definition.id + '-definition-selection'}
                                control={
                                    <Checkbox
                                        onChange={formik.handleChange}
                                        checked={!isSharingLoading && formik.values.updatedDefinitionIds.includes(definition.id)}
                                        name="updatedDefinitionIds"
                                        value={definition.id}
                                    />
                                }
                                label={definition.name}
                            />
                        ))
                    }
                </div>
            }
        </div>

        <Divider className="divider"/>
        <div className="step-footer">
            <Button onClick={() => navigate(-1)} className="back-btn">Back</Button>
            <LoadingButton
                data-testid="shareToGuildButton"
                variant="contained"
                color="primary"
                disabled={!sharingId}
                onClick={handleSubmit}
                loading={updateSharingResult.isLoading}
            >
                Share to Guild
            </LoadingButton>
        </div>
        {confirmationAttributesShared()}
    </div>
}

export default ManagePrivateAttributeSharing
