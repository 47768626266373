import React from 'react'
import {ServiceSubscriptionSummary} from '../../model/subscription/ServiceSubscription'
import SubscriptionRow from './SubscriptionRow'

const SubscriptionList = ({subscriptions}: { subscriptions: ServiceSubscriptionSummary[] }) => {
    return <>
        {subscriptions?.length ? subscriptions?.map((subscription: ServiceSubscriptionSummary) => <SubscriptionRow
            key={subscription.id}
            subscription={subscription}/>) : <p>No Subscription was found</p>}
    </>
}

export default SubscriptionList
