import React, {useEffect, useRef, useState} from 'react'
import ServiceSubscriptionStatus from '../../model/subscription/ServiceSubscriptionStatus'
import {Button, Divider, Link} from '@mui/material'
import './ManageServiceSubscription.scss'
import {setSnackbarMessage} from '../../../genericComponents/commonSlice'
import {useTypedDispatch} from '../../../util/store'
import {useMatch, useNavigate, useParams} from 'react-router-dom'
import {useCancelServiceSubscriptionMutation, useGetServiceSubscriptionQuery} from '../rtkServiceSubscriptionApi'
import {getServiceName, isRecurringExtraction, ServiceEnum} from '../../model/ServiceEnum'
import {getServiceSubscriptionLogo} from '../../model/Service'
import {DownloadServiceFileDetail} from '../../serviceRequests/DownloadServiceFile/DownloadServiceFileDetail'
import {useGetAllInformationPackagesQuery} from '../../../informationPackage/rtkInformationPackageApi'
import {BillingTypeEnum} from '../../../item/model/InformationPackage'
import {ExtractionFrequencyEnum} from '../../model/ExtractionFrequencyEnum'
import ConfirmServiceSubscriptionActionDialog from './ConfirmServiceSubscriptionActionDialog'
import SubscriptionDate from './SubscriptionDate'
import LoadingSpinner from '../../../genericComponents/LoadingSpinner'
import {ROUTES} from '../../../constants/routing'
import {useCurrentGuildContext, useDatxPathGenerator, useMatchedRoute} from '../../../util/routing'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import {NoData} from '../../../genericComponents/NoData'
import {CopyButton} from '../../../genericComponents/CopyButton'
import RestartAltRoundedIcon from '@mui/icons-material/RestartAltRounded'
import {ResetRecurringSubscriptionDescription} from '../recurringSubscription/ResetRecurringSubscriptionDescription'
import '../recurringSubscription/ResetRecurringExtractionDialog.scss'
import {useResetRecurringExtractionMutation} from '../../serviceRequests/rtkServiceRequestApi'

const serviceDetailLabelMap = new Map<string, string>([
    [ServiceEnum.RECURRING_EXTRACTION, 'Extraction Format'],
    [ServiceEnum.SUBSCRIBE_INFORMATION_PACKAGE, 'Information Package'],
    [ServiceEnum.ITEM_MONITORING, 'Item Monitoring']
])

const extractionFrequencyMap = new Map<string, string>([
    [ExtractionFrequencyEnum.WEEKLY, 'Weekly'],
    [ExtractionFrequencyEnum.MONTHLY, 'Monthly'],
    [ExtractionFrequencyEnum.DAILY, 'Daily']
])

const ManageServiceSubscription = () => {
    const generatePath = useDatxPathGenerator()
    const dispatch = useTypedDispatch()
    const params = useParams()
    const navigate = useNavigate()
    const serviceSubscriptionId = params?.serviceSubscriptionId
    const [openDialog, setOpenDialog] = useState(false)
    const [openResetRecurringExtractionModal, setOpenResetRecurringExtractionModalOpen] = useState(false)
    const [currentGuildContext] = useCurrentGuildContext()
    const previousGuildContext = useRef(currentGuildContext?.guildId)
    const relatedToGuildServiceSubscriptions = useMatch({path: ROUTES.guildServiceSubscriptions.path ?? '', end: false})
    const [cancelServiceSubscription, cancelServiceSubscriptionResult] = useCancelServiceSubscriptionMutation()
    const [resetRecurringExtraction, resetRecurringExtractionResult] = useResetRecurringExtractionMutation()
    const route = useMatchedRoute()?.[0].route

    const {data: serviceSubscription, isLoading: getServiceSubscriptionLoading} = useGetServiceSubscriptionQuery({
        serviceSubscriptionId: serviceSubscriptionId
    })

    const {data: informationPackages, isLoading: informationPackagesLoading} = useGetAllInformationPackagesQuery(
        BillingTypeEnum.TO_BUY
    )

    useEffect(() => {
        // If user changes guild context, redirect to subscription page
        if (currentGuildContext?.guildId != previousGuildContext.current && relatedToGuildServiceSubscriptions) {
            navigate(generatePath(ROUTES.guildServiceSubscriptions.path), {replace: true})
        }
    }, [currentGuildContext])

    if (!serviceSubscription) return <NoData />

    const renderServiceSubscriptionDetail = () => {
        const {
            status,
            cost,
            requesterEmail,
            startDate,
            endDate,
            serviceName,
            serviceRequestId,
            extractionFrequency,
            fileDTO,
            fileExtractionFormat,
            informationPackageId,
            extractionPath
        } = serviceSubscription
        const getInactiveClass = () =>
            (status === ServiceSubscriptionStatus.EXPIRED || status === ServiceSubscriptionStatus.CANCELLED) &&
            'inactive'
        const getInformationPackageValue = () => {
            if (serviceName !== ServiceEnum.RECURRING_EXTRACTION) {
                const informationPackage = informationPackages?.find(
                    (informationPackage) => informationPackage.id === informationPackageId
                )
                return informationPackage?.label
            }
            return fileExtractionFormat
        }

        const renderInformationPackage = () => {
            if (informationPackagesLoading || !informationPackages) return
            return <>{getInformationPackageValue()}</>
        }
        const handleServiceSubscriptionCancellation = () => {
            if (!currentGuildContext) {
                return
            }

            cancelServiceSubscription(serviceRequestId).then(() => {
                const routeAfterCancellation =
                    route === ROUTES.manageServiceSubscription
                        ? ROUTES.guildInactiveServiceSubscriptions.path
                        : ROUTES.userInactiveServiceSubscriptions.path
                setOpenDialog(false)
                navigate(generatePath(routeAfterCancellation))
            })
        }

        const handleResetRecurringExtraction = () => {
            if (!currentGuildContext) {
                return
            }
            resetRecurringExtraction(serviceRequestId).then(() => {
                setOpenResetRecurringExtractionModalOpen(false)
                dispatch(setSnackbarMessage('Reset Incremental Extraction Triggered'))
            })
        }

        return (
            <>
                <div className="subscription-details">
                    <img
                        src={getServiceSubscriptionLogo(serviceName)}
                        alt={getServiceName(serviceName)}
                        className={`service-card-logo ${getInactiveClass()}`}
                    />
                    <div className="subscription-title-column">
                        <span className="subscription-title">{getServiceName(serviceName)}</span>
                        <div className="id-container" data-testid="id-container">
                            <span className="id-value">{serviceSubscriptionId}</span>
                            <CopyButton
                                toCopy={serviceSubscriptionId}
                                onCopySuccess={() =>
                                    dispatch(setSnackbarMessage('Id was successfully copied to your clipboard'))
                                }
                            />
                        </div>
                    </div>
                    {serviceName === ServiceEnum.RECURRING_EXTRACTION &&
                        status === ServiceSubscriptionStatus.ACTIVE && (
                            <Button
                                className="reset-recurring-extraction"
                                data-testid="reset-recurring-extraction"
                                onClick={() => setOpenResetRecurringExtractionModalOpen(true)}
                            >
                                <RestartAltRoundedIcon className="icon" />
                                <span className="reset-recurring-extraction-text">Reset Incremental Extraction</span>
                            </Button>
                        )}
                </div>
                <Divider />
                <div className="subscription-details-container">
                    <div className="subscription-details-table">
                        <div>
                            <label className="details-title-main">Subscription Details</label>
                        </div>
                        <div className={`subscription-details-column ${getInactiveClass()}`}>
                            <span className="details-content">
                                {extractionFrequencyMap.get(extractionFrequency)} {getServiceName(serviceName)}
                            </span>
                            <div className="details-content">{cost}</div>
                            <span className="details-content">{requesterEmail}</span>
                            <SubscriptionDate
                                date={startDate}
                                prefix="Start Date"
                                hasWarningExpiration={false}
                                isActiveSubscription={status === ServiceSubscriptionStatus.ACTIVE}
                            />
                        </div>
                        <div>
                            <SubscriptionDate
                                date={endDate}
                                prefix="Valid Till"
                                hasWarningExpiration={true}
                                isActiveSubscription={status === ServiceSubscriptionStatus.ACTIVE}
                            />
                        </div>
                    </div>
                    <div className="subscription-details-table">
                        <div>
                            <label className="details-title-main">Service Details</label>
                        </div>
                        <div>
                            <span className="details-title">{serviceDetailLabelMap.get(serviceName)}</span>
                            <div>
                                <span className={`details-content ${getInactiveClass()}`}>
                                    {renderInformationPackage()}
                                </span>
                            </div>
                            <br />

                            {isRecurringExtraction(serviceName) && (
                                <>
                                    <span className="details-title">Extraction Path</span>
                                    <div>
                                        <span className={`details-content ${getInactiveClass()}`}>
                                            {extractionPath != undefined ? extractionPath : '/'}
                                        </span>
                                    </div>
                                </>
                            )}
                        </div>
                        <div>
                            <div className="details-title">Item List</div>
                            <DownloadServiceFileDetail serviceRequestId={serviceRequestId} file={fileDTO} />
                        </div>
                    </div>
                    <ConfirmServiceSubscriptionActionDialog
                        className="reset-recurring-extraction-dialog"
                        open={openResetRecurringExtractionModal}
                        children={<ResetRecurringSubscriptionDescription />}
                        closeDialog={() => setOpenResetRecurringExtractionModalOpen(false)}
                        title="Reset Confirmation"
                        onSubmit={handleResetRecurringExtraction}
                        isLoading={resetRecurringExtractionResult.isLoading}
                    />
                    {status === ServiceSubscriptionStatus.ACTIVE && (
                        <div className="cancel-subscription">
                            <Button
                                type="button"
                                variant="outlined"
                                onClick={() => setOpenDialog(true)}
                                disabled={serviceName !== ServiceEnum.RECURRING_EXTRACTION}
                                data-testid="cancel-or-extension-action-subscription"
                            >
                                {serviceName === ServiceEnum.RECURRING_EXTRACTION
                                    ? 'Cancel Subscription'
                                    : 'Request Extension'}
                            </Button>
                        </div>
                    )}
                    <ConfirmServiceSubscriptionActionDialog
                        open={openDialog}
                        closeDialog={() => setOpenDialog(false)}
                        children={`Confirm Cancellation of ${getServiceName(serviceName)} subscription?`}
                        title={
                            serviceName === ServiceEnum.RECURRING_EXTRACTION
                                ? 'Cancel Subscription'
                                : 'Request Extension'
                        }
                        onSubmit={handleServiceSubscriptionCancellation}
                        isLoading={cancelServiceSubscriptionResult.isLoading}
                    />
                </div>
            </>
        )
    }

    if (getServiceSubscriptionLoading) return <LoadingSpinner />

    if (!serviceSubscription) return <></>

    return (
        <div key={serviceSubscriptionId} className="subscription-details-container">
            <Button className="back-btn" type="button" onClick={() => navigate(-1)} startIcon={<ChevronLeftIcon />}>
                Back
            </Button>
            {renderServiceSubscriptionDetail()}
        </div>
    )
}

export default ManageServiceSubscription
