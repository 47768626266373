import {useFormikContext} from 'formik'
import {ServiceRequestStep} from '../../../genericComponents/formTemplate/steps/ServiceRequestStep'
import {Description} from '../../../../../genericComponents/typographie/Description'
import {Label} from '../../../../../genericComponents/typographie/Label'
import React from 'react'
import {RequestMonitoredBundleMonitoringInput} from '../../MonitorBundleForm'
import {WorkflowSelector} from '../../../model/WorkflowSelector'
import {ServiceEnum} from '../../../../model/ServiceEnum'

export const ReviewMonitoredBundleMonitoringForm = () => {
    const formik = useFormikContext<RequestMonitoredBundleMonitoringInput>()

    return (
        <ServiceRequestStep>
            <Description>Review the perimeters of your selection and submit.</Description>

            <div data-testid="cad-model-creation-summary" className="service-request-form-summary">
                <Label className="form-summary-label">Item List</Label>
                <span className="form-summary-value">{formik?.values?.itemList?.name ?? '-'}</span>
                <Label className="form-summary-label">Monitored Bundle</Label>
                <span className="form-summary-value" data-testid="monitored-bundle-creation-summary">{formik?.values?.monitoredBundle?.label ?? '-'}</span>
            </div>
            <WorkflowSelector service={ServiceEnum.ITEM_MONITORING} />
        </ServiceRequestStep>
    )
}
