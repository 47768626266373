import ItemCardClickableProperty from '../generic/ItemRowCardClickableProperty'
import {ROUTES} from '../../../../../constants/routing'
import * as React from 'react'
import {useDatxPathGenerator} from '../../../../../util/routing'
import {SearchItem} from '../../../../model/SearchItem'

export const ItemDefinedByComponent = ({item}: {item: SearchItem}) => {
    const generatePath = useDatxPathGenerator()

    return <ItemCardClickableProperty value={item?.definedByOrganization?.label}
                               linkTo={generatePath(ROUTES.organizationDetails.path, {organizationId : item.definedByOrganization.id})}
                               testId={`link-${item.definedByOrganization.id}-organization`}
                               opensInNewTab={true}
    />
}