import NotificationDisplay from './GenericNotificationDisplay'
import {getGuildId, Notification} from '../../../models/Notification'
import {Button} from '@mui/material'
import React from 'react'
import {ROUTES} from '../../../../constants/routing'
import {generatePath, useNavigate} from 'react-router-dom'
import {selectGuildWhereAdmin} from '../../../../guild/rtkGuildApi'
import {useSelector} from 'react-redux'



const ServiceRequestDeliveryNotification = ({ notification }: { notification: Notification }) => {
    const navigate = useNavigate()
    const guildId = getGuildId(notification)

    const isAdminOfTheGuild = !!useSelector(selectGuildWhereAdmin)?.find(guild => guild.guildId === guildId)

    const navigateToRequestPage= (guildId: string | undefined) =>{
         isAdminOfTheGuild  ?
            navigate(generatePath(ROUTES.serviceRequests.path || '', {currentGuildId: guildId})) :
            navigate(generatePath(ROUTES.userServiceRequests.path || '', {currentGuildId: guildId}))
    }

    return (
        <NotificationDisplay
            notification={notification}
            title="Delivery Confirmation"
            canClose={true}
        >
            <Button
                id="notification-action-button-accept"
                type="button"
                variant="contained"
                onClick={() => navigateToRequestPage(guildId)
                }
            >
                View
            </Button>
        </NotificationDisplay>

    )
}



export default ServiceRequestDeliveryNotification