// delete a specific row
import {WorkSheet} from 'xlsx'
import * as XLSX from 'xlsx'

const encode = (row: number, column: number) => {
    return XLSX.utils.encode_cell({r:row,c:column})
}
export const deleteRow = (worksheet: WorkSheet, rowIndex: number) =>{
    const variable = XLSX.utils.decode_range(worksheet['!ref'] ?? '')
    for(let R = rowIndex; R < variable.e.r; ++R){
        for(let C = variable.s.c; C <= variable.e.c; ++C){
            worksheet[encode(R,C)] = worksheet[encode(R+1,C)]
        }
    }
    variable.e.r--
    worksheet['!ref'] = XLSX.utils.encode_range(variable.s, variable.e)
}

// A function to compare two arrays in javascript
export const equalsCheck = (a: string[], b: string[]) =>
    a.length === b.length &&
    a.every((v: string) => b.includes(v))