import * as XLSX from 'xlsx'
import {ItemMatchingInput, ItemMatchingInputDTO, NewItemInput, NewItemInputDTO} from '../model/NewItemInput'
import {deleteRow} from '../../util/excel-util'

export const excelColumnHeader = {
    partNumber: 'PART NUMBER (mandatory)',
    orgName: 'MANUFACTURER NAME (mandatory)',
    itemName: 'ITEM NAME (optional)',
    itemDescription: 'ITEM DESCRIPTION (optional)',
    manufacturerId: 'DATX ID (mandatory)'
}

export const excelColumnHeaderForPartMatching = {
    partNumber: 'PARTNUMBER_IN',
    orgName: 'MANUFACTURER_IN'
}

const isNotEmptyCell = (value?: string): boolean => (value ? value.toString().trim().length > 0 : false)

const validatedCell = (value?: string) => {
    if (isNotEmptyCell(value)) return value
    return null
}

export const getItemsFromFile = async (tempFile: File) => {
    const buffer = await tempFile.arrayBuffer()
    const wb = XLSX.read(buffer)
    const content = wb.Sheets[wb.SheetNames[0]]
    // delete the first header row
    deleteRow(content, 0)
    const items: NewItemInput[] = XLSX.utils.sheet_to_json<any>(content).filter((row) => {
        return validatedCell(row[excelColumnHeader.orgName]) && validatedCell(row[excelColumnHeader.partNumber])
    })
    return items.map((item) => new NewItemInputDTO(item))
}

export const getItemsFromPartMatchingFile = async (tempFile: File) => {
    const buffer = await tempFile.arrayBuffer()
    const wb = XLSX.read(buffer)
    const content = wb.Sheets[wb.SheetNames[0]]
    const items: ItemMatchingInput[] = XLSX.utils.sheet_to_json<any>(content).filter((row) => {
        return (
            validatedCell(row[excelColumnHeaderForPartMatching.orgName]) &&
            validatedCell(row[excelColumnHeaderForPartMatching.partNumber])
        )
    })
    return items.map((item) => new ItemMatchingInputDTO(item))
}

export const getCompanyItemsFromFile = async (tempFile: File) => {
    const buffer = await tempFile.arrayBuffer()
    const wb = XLSX.read(buffer)
    const content = wb.Sheets[wb.SheetNames[0]]
    // delete the first header row
    deleteRow(content, 0)
    const items: NewItemInput[] = XLSX.utils
        .sheet_to_json<any>(content)
        .filter((row) => {
            return (
                validatedCell(row[excelColumnHeader.partNumber]) && validatedCell(row[excelColumnHeader.manufacturerId])
            )
        })
        .map((row) => {
            const {LINE_ID, ...rest} = row // Use destructuring to isolate firstColumnName and get the rest of the object.
            return rest
        })
    return items.map((item) => new NewItemInputDTO(item))
}
