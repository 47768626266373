import {msalConfig, signinRequest, signupRequest} from './authConfig'
import {useEffect} from 'react'
import {useMsal} from '@azure/msal-react'
import {IPublicClientApplication, PublicClientApplication} from '@azure/msal-browser'
import {NavigateFunction} from 'react-router-dom'
import {setAppInsightUser} from '../util/tracking/appInsight'
import {useTypedDispatch} from '../util/store'
import {setUserIsAuthenticated} from '../header/headerSlice'

export const useTryToGetToken = (
    canGetTokenAction: () => void,
    cannotGetTokenAction: () => void,
    toWatch: any[] = []
) => {

    const {instance, inProgress} = useMsal()
    const dispatch = useTypedDispatch()

    useEffect(() => {

        if (inProgress !== 'none') {
            return
        }

        setDefaultAccount(instance)

        if (!instance.getActiveAccount()) {
            dispatch(setUserIsAuthenticated(false))
            cannotGetTokenAction()
            return
        }
        setAppInsightUser(getEmailFromMsal(instance))
        instance
            .acquireTokenSilent(signinRequest)
            .then((tokenResponse) => {
                dispatch(setUserIsAuthenticated(true))
                canGetTokenAction()
            })
            .catch((e) => {
                dispatch(setUserIsAuthenticated(false))
                cannotGetTokenAction()
            })
    }, [instance, inProgress, ...toWatch])
}

export const setDefaultAccount = (instance: IPublicClientApplication) => {
    // Default to using the first account if no account is active on page load
    if (!instance.getActiveAccount() && instance.getAllAccounts().length > 0) {
        // Account selection logic is app dependent. Adjust as needed for different use cases.
        instance.setActiveAccount(instance.getAllAccounts()[0])
    }
}

export const redirectToLogin = (url: string, navigate: NavigateFunction) => {
    signinRequest.redirectStartPage = url
    navigate('/login')
}

export const resetRedirectToLogin = () => {
    signinRequest.redirectStartPage = '/'
}

export const getEmailFromMsal = (msalInstance: IPublicClientApplication) => {
    return msalInstance?.getActiveAccount()?.username
}


