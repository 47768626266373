import React, {useState} from 'react'
import IconButton from '@mui/material/IconButton'
import {MoreHoriz} from '@mui/icons-material'
import {Menu, MenuItem} from '@mui/material'
import {DialogStopSharing} from '../common/DialogStopSharing'
import {IdType} from '../../../util/models/IdType'
import {OrganizationItemSharing} from '../model/OrganizationItemSharing'

const SharingActions = ({itemSharing, sharingId, targetGuildName}: {
    itemSharing: OrganizationItemSharing,
    sharingId: IdType,
    targetGuildName: string
}) => {
    const [anchorEl, setAnchorEl] = useState<Element | null>(null)
    const [isModalOpen, setModalOpen] = useState(false)

    const open = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const closeMenu = () => {
        setAnchorEl(null)
    }

    const stopItemSharing = () => {
        setModalOpen(true)
        closeMenu()
    }

    return <>
        <IconButton onClick={handleClick}>
            <MoreHoriz/>
        </IconButton>
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={closeMenu}
        >
            <MenuItem
                data-testid="stop-sharing"
                onClick={stopItemSharing}
            >
                Stop sharing
            </MenuItem>
        </Menu>
        <DialogStopSharing
            itemSharing={itemSharing}
            sharingId={sharingId}
            targetGuildName={targetGuildName}
            open={isModalOpen}
            title="Confirm Stop Sharing"
            className="share-item-list-dialog"
            onClose={() => setModalOpen(false)}/>
    </>
}

export default SharingActions
