import {guildAPI} from '../../guild/rtkGuildApi'
import {IdType} from '../../util/models/IdType'
import {ServiceSubscriptionSummary} from '../model/subscription/ServiceSubscription'

const SERVICE_SUBSCRIPTIONS_TAG = 'ServiceSubscriptions'

export const serviceSubscriptionApi = guildAPI
    .enhanceEndpoints({
        addTagTypes: [SERVICE_SUBSCRIPTIONS_TAG]
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getGuildServiceSubscriptions: builder.query<
                ServiceSubscriptionSummary[],
                {guildId: IdType | undefined; isActiveSubscription: boolean}
            >({
                query: ({guildId, isActiveSubscription}) => ({
                    url: `serviceSubscription/guild/${guildId}`,
                    method: 'GET',
                    params: {
                        isActiveSubscription
                    }
                }),
                transformResponse: (response: any) => response.data,
                providesTags: [SERVICE_SUBSCRIPTIONS_TAG]
            }),
            getUserServiceSubscriptions: builder.query<
                ServiceSubscriptionSummary[],
                {guildId: IdType | undefined; isActiveSubscription: boolean}
            >({
                query: ({guildId, isActiveSubscription}) => ({
                    url: `serviceSubscription/guild/${guildId}/me`,
                    method: 'GET',
                    params: {
                        isActiveSubscription
                    }
                }),
                transformResponse: (response: any) => response.data,
                providesTags: [SERVICE_SUBSCRIPTIONS_TAG]
            }),
            getServiceSubscription: builder.query<
                ServiceSubscriptionSummary,
                {serviceSubscriptionId: IdType | undefined}
            >({
                query: ({serviceSubscriptionId}) => ({
                    url: `serviceSubscription/${serviceSubscriptionId}`,
                    method: 'GET'
                }),
                transformResponse: (response: any) => response.data,
                transformErrorResponse: (response: any) =>
                    response?.data?.errorMessage || 'Something went wrong during getting service subscription',
                providesTags: [SERVICE_SUBSCRIPTIONS_TAG]
            }),
            cancelServiceSubscription: builder.mutation<IdType, IdType>({
                query: (serviceRequestId) => ({
                    url: `serviceSubscription/serviceRequest/${serviceRequestId}/cancel`,
                    method: 'PATCH'
                }),
                transformResponse: (response: any) => response.data,
                invalidatesTags: [SERVICE_SUBSCRIPTIONS_TAG]
            })
        })
    })

export const {
    useGetGuildServiceSubscriptionsQuery,
    useGetUserServiceSubscriptionsQuery,
    useGetServiceSubscriptionQuery,
    useCancelServiceSubscriptionMutation
} = serviceSubscriptionApi
