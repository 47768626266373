import {Box, Tooltip} from '@mui/material'
import React from 'react'
import Highlighter from 'react-highlight-words'

const ItemCardProperty = ({
                              label,
                              value,
                              highlightPhrase,
                              dataTestId
                          }: { label?: string, value: string, highlightPhrase?: string, dataTestId?: string }) => {
    return (<div className="item-card-property">
        {label && <div className="property-label">{label.toUpperCase()}</div>}
        <Tooltip title={value}>
            <div className="property-tooltip" data-testid={dataTestId}>
                <Highlighter
                    searchWords={[highlightPhrase || '']}
                    autoEscape={true}
                    textToHighlight={value}
                    highlightClassName	={'match-element'}
                />
            </div>
        </Tooltip>
    </div>)
}

export default ItemCardProperty