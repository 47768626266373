import React from 'react'
import {MultiplyPricePerQuantityRule} from '../QuotationRule'

export const MultiplyPricePerQuantityRuleComponent = ({rule, quantity,optionName}: {rule: MultiplyPricePerQuantityRule, quantity: number,optionName?:string}) => {

    const defaultDescriptioin = 'No. Of Items'
    const description = optionName ?? defaultDescriptioin

    return <div className="quotation-detail-rule-structure">
        <p className="quotation-detail-description">{description}</p>
        <p className="quotation-detail-unit-price">{rule?.price}</p>
        <p className="quotation-detail-nb-object">{quantity}</p>
        <p className="quotation-detail-total">{rule.calculate(0, quantity)}</p>
    </div>
}
