import {FormControl, MenuItem, Select, SelectChangeEvent} from '@mui/material'
import {ItemList} from '../../../itemList/model/ItemList'
import {KeyboardArrowDown} from '@mui/icons-material'
import LoadingSpinner from '../../../genericComponents/LoadingSpinner'
import React, {ReactNode} from 'react'
import {useCurrentGuildContext} from '../../../util/routing'
import {useGetItemListsQuery} from '../../../itemList/rtkItemListApi'
import {skipToken} from '@reduxjs/toolkit/dist/query/react'
import {Label} from '../../../genericComponents/typographie/Label'

export const SelectItemList = ({
    selectedList,
    handleChange,
    touched,
    errors,
    label = 'Item List',
    required = false,
    className
}: {
    selectedList: ItemList | undefined
    handleChange: (event: SelectChangeEvent<ItemList>, child: ReactNode) => void
    touched: boolean | undefined
    errors: string | undefined
    label?: string
    required?: boolean
    className?: string
}) => {
    const [currentGuildContext] = useCurrentGuildContext()
    const {data: itemLists = [], isLoading} = useGetItemListsQuery(currentGuildContext?.guildId ?? skipToken)

    return (
        <FormControl className={className}>
            <Label
                id="select-item-list-label"
                className="select-item-list-label"
                htmlFor="itemList"
                required={required}
            >
                {label}
            </Label>
            <Select
                size="small"
                displayEmpty
                variant="outlined"
                data-testid="itemlist-dropdown-selector"
                required
                id="itemList"
                name="itemList"
                value={selectedList ?? ''}
                onChange={handleChange}
                className="select-item-list"
                placeholder="Select item list"
                renderValue={(itemList: ItemList) =>
                    itemList?.name ?? <span className="placeholder">Select Item List</span>
                }
                aria-invalid={touched && Boolean(errors)}
                IconComponent={KeyboardArrowDown}
            >
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    itemLists?.length > 0 &&
                    itemLists.map((list) => (
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        <MenuItem
                            value={list}
                            data-testid="menuitem-dropdown-selector"
                            key={list.id}
                            autoFocus={false}
                            data-cy={`select-option-${list.name}`}
                            sx={{fontSize: '14px'}}
                        >
                            {list.name}
                        </MenuItem>
                    ))
                )}
            </Select>
        </FormControl>
    )
}
