export type ItemType = 'Company' | 'Manufacturer' | 'Abstract';


export const availableItemTypes: ItemType[] = ['Company', 'Manufacturer', 'Abstract']

export const convertItemTypeLabel = (input: ItemType): string | null => {
    const map = new Map<string, string>([
        ['Company', 'Company Item'],
        ['Manufacturer', 'Manufacturer Item'],
        ['Abstract', 'Abstract Item']
    ])

    return map.get(input) || null
}
