import {IconButton, Tooltip} from '@mui/material'
import {FileDownload} from '@mui/icons-material'
import React from 'react'
import {ExcelItem} from '../../search/model/ExcelItem'
import * as XLSX from 'xlsx'
import * as FileSaver from 'file-saver'
import {SearchItem} from '../../search/model/SearchItem'

export const SelectionDownload = ({selectedItems, isDisabled}: {
    selectedItems: SearchItem[],
    isDisabled: boolean
}) => {
    const constructSelected = () => {
        const selectedExcelItems = new Set<ExcelItem>()
        selectedItems.forEach((item: SearchItem) => {
            selectedExcelItems.add({
                'partNumber': item?.partNumber ?? '',
                'definedBy': item?.definedByOrganization?.label ?? '',
                'datxId': item?.id.toString() ?? '',
                'name': item?.label ?? ''
            })
        })

        return Array.from(selectedExcelItems)
    }

    const downloadExcel = () => {
        const currDate = new Date()
        const fileName = `Datx-${currDate.toLocaleString('en-GB').replace(' ', '_').replace(',', '')}`
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        const fileExtension = '.xlsx'
        // Init worksheet
        const contructedData = constructSelected()
        const ws = XLSX.utils.json_to_sheet(contructedData)

        // Set custom headers
        const headers = [['Part Number', 'Defined by', 'Datx ID', 'Name']]
        XLSX.utils.sheet_add_aoa(ws, headers)

        // Write to workbook
        const wb = {Sheets: {'data': ws}, SheetNames: ['data']}
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'})
        const data = new Blob([excelBuffer], {type: fileType})
        FileSaver.saveAs(data, fileName + fileExtension)
    }

    return (
        <Tooltip title="Download selected items">
            <IconButton
                data-testid="download-button"
                aria-label="download-item"
                disabled={isDisabled}
                onClick={() => downloadExcel()}

            >
                <FileDownload/>
            </IconButton>
        </Tooltip>
    )
}