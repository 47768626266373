import React, {ChangeEvent, SyntheticEvent} from 'react'
import '../styles/Forms.scss'
import {LoadingButton} from '@mui/lab'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined'

const shortenFileName = (selectedFileName : string) => {
    const FILE_NAME_SIZE = 30
    if (selectedFileName.length <= FILE_NAME_SIZE) {
        return selectedFileName
    } else {
        return selectedFileName.substring(0, FILE_NAME_SIZE) + '...'
    }
}

const UploadCommonLoadingButton = ({
                                       selectedFileName,
                                       unselectFile,
                                       parseFile,
                                       className = '',
                                       name = 'upload-file',
                                       id = 'upload-file',
                                       variant = 'contained',
                                       acceptedFileType = '.xlsx'
                                   }:
                                       {
                                           selectedFileName: string,
                                           name?: string, id?: string, className?: string,
                                           variant?: 'outlined' | 'contained',
                                           acceptedFileType?: string,
                                           unselectFile: (event: SyntheticEvent) => void,
                                           parseFile: (event: ChangeEvent<HTMLInputElement>) => void
                                       }) => {
    return <LoadingButton data-testid="file-upload-btn" component="label" variant={variant} color="primary" className={className}
                          startIcon={!selectedFileName && <UploadOutlinedIcon/>}
                          endIcon={selectedFileName && <DeleteForeverOutlinedIcon onClick={unselectFile}/>}>
        {shortenFileName(selectedFileName) || 'Choose File from Computer'}
        <input name={name} id={id} onChange={parseFile} hidden type="file"
               accept={acceptedFileType}/>
    </LoadingButton>


}

export default UploadCommonLoadingButton


