enum UserRequestStatusEnum {
    AWAITING_APPROVAL= 'AWAITING_APPROVAL',
    CREATION_PENDING= 'CREATION_PENDING',
    CREATION_ERROR= 'CREATION_ERROR',
    QUOTATION_APPROVED = 'QUOTATION_APPROVED',
    QUOTATION_REJECTED = 'QUOTATION_REJECTED',
    REQUEST_REJECTED = 'REQUEST_REJECTED',
    AWAITING_QUOTATION = 'AWAITING_QUOTATION',
    DELIVERED = 'DELIVERED',
    AWAITING_QUOTATION_APPROVAL = 'AWAITING_QUOTATION_APPROVAL'
}

export default UserRequestStatusEnum

