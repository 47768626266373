export enum CADModelTypeEnum {
    'FOOTPRINT_AND_SYMBOL_CADENCE' = 'FOOTPRINT_AND_SYMBOL_CADENCE',
    'MODEL_CATIA' = 'MODEL_CATIA',
    'MODEL_PTC' = 'MODEL_PTC'
}

export const getCadModelDisplayName: { [key in CADModelTypeEnum]: string } = {
    [CADModelTypeEnum.FOOTPRINT_AND_SYMBOL_CADENCE]: 'Footprint & Symbol (CADENCE)',
    [CADModelTypeEnum.MODEL_CATIA]: '3D Model (CATIA)',
    [CADModelTypeEnum.MODEL_PTC]: '3D Model (PTC)'
}
