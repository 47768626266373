import React from 'react'
import { RegulationStatusAttribute } from '../attributeTemplates/RegulationStatusAttribute'
import {sortAttributesByDisplayOrder} from '../utils/sortedDefinitions'
import {AttributeDefinitionModel} from '../model/AttributeModel'


export const RegulationStatusDefinition = ({definition}: {definition: AttributeDefinitionModel}) => {
    return <div className="display-set-definition definition-attribute-regulation" data-testid="test-details-regulation-status-definition"
                id={definition.attributeDefinitionId}>
        <span className="definition-name">Status</span>
        {sortAttributesByDisplayOrder(definition.attributes).map(attribute => <RegulationStatusAttribute attribute={attribute} key={attribute.id}/>)}
    </div>
}