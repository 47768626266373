import {useState} from 'react'
import {GridCellParams, GridColDef, GridRenderCellParams, GridValueGetterParams} from '@mui/x-data-grid'
import {ServiceRequest} from '../../service/model/ServiceRequest'
import {Button} from '@mui/material'
import {getSortedDate, getValue} from '../../util/component/table'
import UserRequestStatusEnum from '../../service/model/UserRequestStatusEnum'
import {
    formatIdLink,
    getDisplayedValueForQuotationFile,
    getModifiedDate,
    getStatus,
    getValidatedBy,
    getWorkflowWithServcie
} from '../../genericComponents/serviceRequest/ServiceRequestFormatter'
import ServiceRequestDialog from '../../genericComponents/serviceRequest/ServiceRequestDialog'
import {DownloadServiceFile} from '../../service/serviceRequests/DownloadServiceFile/DownloadServiceFile'
import './ApprovalDashboardTable.scss'
import {CREDITS} from '../../constants/credits'

import {getTotalFromRequest, isQuotationAvailable} from '../../service/model/Quotation'
import DaTXDataGrid from '../../genericComponents/grid/DaTXDataGrid'
import {equalsOperator, matchString} from '../../genericComponents/grid/gridFilterOperators'
import {getServiceName} from '../../service/model/ServiceEnum'
import getUserServiceStatus from '../../service/model/UserServiceRequestType'
import ApproveRequest from '../guildServiceRequests/menu/ApproveRequest'
import ApproveQuotation from '../guildServiceRequests/menu/ApproveQuotation'
import QuotationDialog from '../guildServiceRequests/modals/model/Quotation/QuotationDialog'
import GuildServiceRequestContent from '../guildServiceRequests/GuildServiceRequestContent'


export const getServiceLabel = (params: GridValueGetterParams) => {
    return  getServiceName(params.row.service ?? '')
}


export const getStatusValue = (params :GridValueGetterParams) => {
    return retrieveStatus(params.row).label
}

export const retrieveStatus =  (row: ServiceRequest ) => {
    const status = row?.status === undefined ? '' : row?.status?.charAt(0)?.toUpperCase() + row?.status?.slice(1)
    return  getUserServiceStatus(status)
}


export const getQuotationValue = (params: GridValueGetterParams) => {
    return getTotalFromRequest(params.row) ?? '-'
}



const ApprovalDashboardTable = ({userServiceRequests}: { userServiceRequests: ServiceRequest[]}) => {
    const [isQuotationDialog, setQuotationDialog] = useState(false)
    const [currentServiceRequest, setCurrentServiceRequest] = useState<ServiceRequest | null>(null)
    const [open, setOpen] = useState(false)
    const [selectedServiceRequest, setSelectedServiceRequest] = useState<GridCellParams>()

    const getFileNameforServiceRequestTable = ({row}: { row: ServiceRequest }) => {
        return <DownloadServiceFile userServiceRequest={row}/>
    }

    const onServiceRequestClick = (params: GridCellParams) => {
        setSelectedServiceRequest(params)
        setOpen(true)
    }

    const openQuotationModal = ({row}: { row: ServiceRequest }) => {
        const totalQuotation = getTotalFromRequest(row) ?? '-'
        const isAvailable = isQuotationAvailable(totalQuotation,row.status)

        return (
            <>
                <Button className="quotation-cell"
                        data-testid="quotation"
                        disabled={!isQuotationAvailable}
                        onClick={() => {
                            setCurrentServiceRequest(row)
                            setQuotationDialog(true)
                        }}>
                    {isAvailable ? totalQuotation : '-'}
                </Button>
            </>
        )
    }

    const getCreditHeader = (headerName: string) => (<>
        <strong>{headerName}</strong>
        <div className="caption">({CREDITS})</div>
    </>)

    const columns: GridColDef[] = [
        {
            field: 'id', headerName: 'Request ID',
            flex: 3, minWidth: 100,
            renderCell: (params: GridRenderCellParams) => formatIdLink(params, onServiceRequestClick),
            filterOperators: [matchString]
        },
        {
            field: 'service', headerName: 'Workflow',
            flex: 1, minWidth: 120,
            renderCell: getWorkflowWithServcie,
            valueGetter: getServiceLabel,
            filterOperators: [equalsOperator]
        },
        {
            field: 'requesterEmail',
            headerName: 'Requestor',
            flex: 3,
            minWidth: 230,
            sortable: true,
            renderCell: getValue,
            filterOperators: [matchString]
        },
        {
            field: 'validatedByEmail',
            headerName: 'Request Approved By',
            flex: 3,
            minWidth: 230,
            sortable: true,
            renderCell: getValidatedBy,
            filterOperators: [matchString]
        },
        {
            field: 'quotation',
            renderHeader: () => getCreditHeader('Quotation'),
            filterOperators: [matchString],
            sortable:true,
            flex: 1,
            minWidth: 90,
            valueGetter: getQuotationValue,
            renderCell: openQuotationModal,
        },
        {
            field: 'fileName', headerName: 'Details',
            flex: 5, minWidth: 150,
            filterOperators: [matchString],
            renderCell: getFileNameforServiceRequestTable,
            valueGetter: (row) => getDisplayedValueForQuotationFile(row),
            sortComparator: (v1, v2) => v1.localeCompare(v2)
        },

        {
            field: 'status', headerName: 'Status',
            flex: 1, minWidth: 150,
            filterOperators: [equalsOperator],
            renderCell: getStatus,
            valueGetter: getStatusValue
        },
        {
            field: 'createdDate', headerName: 'Creation Date',
            flex: 1, minWidth: 130,
            sortable: true,
            filterable: false,
            filterOperators: [],
            renderCell: getModifiedDate,
            sortComparator: (v1, v2) => getSortedDate(v1, v2)
        },
        {
            field: 'updatedDate', headerName: 'Last Modified',
            flex: 1, minWidth: 130,
            sortable: true,
            filterable: false,
            filterOperators: [],
            renderCell: getModifiedDate,
            sortComparator: (v1, v2) => getSortedDate(v1, v2)
        },
        {
            field: 'action', headerName: 'Actions',
            flex: 1, align: 'center',
            sortable: false, disableColumnMenu: true,
            headerClassName: 'more-option-menu-header',
            renderCell: (params: GridCellParams) => {
                return <>
                    {params.row.status === UserRequestStatusEnum.AWAITING_APPROVAL ?
                        <ApproveRequest row={params.row}/>
                        :
                        <ApproveQuotation row={params.row}/>
                    }
                </>
            }
        }
    ]

    return (



        <div className="guild-member-grid-container">
            <DaTXDataGrid
                columns={columns}
                rows={userServiceRequests}
                getRowId={(serviceRequest: ServiceRequest) => serviceRequest?.id}
            >
                {/*<div className="remainingBudgetLabel data-grid-action-holder-left">*/}
                {/*    Remaining budget:*/}

                {/*    <NumericFormat*/}
                {/*        value={guildBudget}*/}
                {/*        displayType={'text'}*/}
                {/*        thousandSeparator={' '}*/}
                {/*        decimalSeparator="."*/}
                {/*        suffix={` ${CREDITS}`}*/}
                {/*        className="remainingBudgetValue"*/}

                {/*    />*/}
                {/*</div>*/}

            </DaTXDataGrid>


            {currentServiceRequest && (
                <QuotationDialog className="quotation-dialog"
                                 title="Quotation"
                                 onClose={() => setQuotationDialog(false)}
                                 open={isQuotationDialog}
                                 selectedServiceRequest={currentServiceRequest}/>
            )
            }

            <ServiceRequestDialog
                open={open}
                onClose={() => setOpen(false)}
                serviceRequestId={selectedServiceRequest?.row.id}>
            {selectedServiceRequest && (
                    <GuildServiceRequestContent selectedServiceRequest={selectedServiceRequest}/>
                )}
            </ServiceRequestDialog>
        </div>
    )
}
export default ApprovalDashboardTable

