import '../../../styles/Forms.scss'
import {
    Autocomplete,
    Box,
    FormControl,
    TextField
} from '@mui/material'
import { BillingTypeEnum } from '../../../../../item/model/InformationPackage'
import {useGetAllInformationPackagesQuery} from '../../../../../informationPackage/rtkInformationPackageApi'
import { SelectItemList } from '../../../genericComponents/SelectItemList'
import { useFormikContext } from 'formik'
import { AccessToInformationPackageInput } from '../../AccessToInformationPackage'
import { Label } from '../../../../../genericComponents/typographie/Label'
import { KeyboardArrowDown } from '@mui/icons-material'

export const UploadInformationPackageAccessForm = () => {
    const formik = useFormikContext<AccessToInformationPackageInput>()
    const {data: informationPackages = []} = useGetAllInformationPackagesQuery(BillingTypeEnum.TO_BUY)
    return (
        <>
            <SelectItemList 
                selectedList={formik.values.itemList}
                handleChange={formik.handleChange}
                touched={formik.touched.itemList}
                label="Item List"
                errors={formik.errors.itemList}
                required
            />
            <FormControl className="form-select">
                <Label
                    className="select-item-list-label"
                    htmlFor="informationPackage"
                    required={true}
                >
                    Information Package
                </Label>
                <Autocomplete
                    componentsProps={{
                        popupIndicator: {
                            disableRipple : true
                        }
                    }}
                    popupIcon={<KeyboardArrowDown/>}
                    disableClearable={true}
                    data-testid="informationpackage-dropdown-selector"
                    id="information-package"
                    options={informationPackages}
                    autoHighlight
                    size="small"
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                            {option.label}
                        </Box>
                    )}
                    onChange={(e, value) => formik.setFieldValue('informationPackage', value)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Select Information Package"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off'
                            }}
                        />
                    )}
                />
            </FormControl>
        </>
    )
}
