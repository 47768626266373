import {getServiceName, ServiceEnum} from '../../model/ServiceEnum'
import {useCurrentGuildContext, useDatxPathGenerator} from '../../../util/routing'
import React from 'react'
import {useParams} from 'react-router-dom'
import {ROUTES} from '../../../constants/routing'
import {ServiceRequestFormTemplate} from '../genericComponents/formTemplate/ServiceRequestFormTemplate'
import {ServiceRequestStep} from '../model/ServiceRequestStep'
import {useCreateItemUpdateServiceRequestMutation} from '../rtkServiceRequestApi'
import {ServiceRequestWorkflowInput} from '../model/ServiceRequestWorkflowInput'
import {DataDomainUpload} from './DataDomainUpload'
import {ReviewAndSubmitItemUpdate} from './ReviewAndSubmitItemUpdate'
import {DisplaySet} from '../../../displaySet/model/DisplaySet'
import {mixed, object, string} from 'yup'
import NotFoundPage from '../../../notFound/NotFoundPage'


export interface ItemUpdateRequestForm extends ServiceRequestWorkflowInput {
    file: File | undefined
    displaySet: DisplaySet | undefined,
    description: string | undefined,
    selectedWorkflowId: string
}

export const ItemUpdateRequest = () => {
    const [currentGuildContext] = useCurrentGuildContext()
    const generatePath = useDatxPathGenerator()
    const {itemId} = useParams()

    const defineDisplaySet = {
        displaySet: object().required(),
        file: mixed().required(),
        description: string().required()
    }
    const defineWorkflow = {
        ...defineDisplaySet,
        selectedWorkflowId: string().required()
    }
    const [createRequest] = useCreateItemUpdateServiceRequestMutation()
    const steps: ServiceRequestStep<ItemUpdateRequestForm>[] = [
        {
            name: getServiceName(ServiceEnum.ITEM_UPDATE),
            component: <DataDomainUpload/>,
            description: 'By filling this form you will be requesting data update for the data domain of your chosen item.',
            pageValidation: object<ItemUpdateRequestForm>({...defineDisplaySet})
        },
        {
            name: getServiceName(ServiceEnum.ITEM_UPDATE),
            component: <ReviewAndSubmitItemUpdate/>,
            description: 'By filling this form you will be requesting data update for the data domain of your chosen item.',
            pageValidation: object<ItemUpdateRequestForm>({...defineWorkflow})
        },
    ]

    function handleCompleteUploadForm({
                                          file,
                                          selectedWorkflowId,
                                          description,
                                          displaySet
                                      }: ItemUpdateRequestForm): Promise<any> | undefined {
        if (!itemId || !selectedWorkflowId || !displaySet) return

        return createRequest({
            itemId: itemId,
            guildId: currentGuildContext?.guildId ?? '',
            file: file,
            workflowConfigurationId: selectedWorkflowId,
            displaySetId: displaySet.id,
            description: description
        })
    }

    if (!currentGuildContext?.guildId) return <NotFoundPage/>

    return <div className="item-update-request">
        <ServiceRequestFormTemplate
            steps={steps}
            handleFormSubmit={handleCompleteUploadForm}
            initialValues={{
                file: undefined,
                displaySet: undefined,
                description: undefined,
                selectedWorkflowId: '',
            }}
            title={getServiceName(ServiceEnum.ITEM_UPDATE)}
            previousUrl={generatePath(ROUTES.detailItem.path, {itemId: itemId})}
        >

        </ServiceRequestFormTemplate></div>
}
