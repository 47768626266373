import React from 'react'
import {Link} from 'react-router-dom'
import { ROUTES } from '../../constants/routing'
import { useDatxPathGenerator } from '../../util/routing'

const Logo = ({logoTitle = 'DATX'}) => {
    const generatePath = useDatxPathGenerator()
    return (
        <Link to={generatePath(ROUTES.welcome.path)}>
            <img
                src="/images/DATX_Logo_Gradient.svg"
                alt={logoTitle}
                style={{height: '32px', paddingTop: '8px', margin: 'auto'}}
            />
        </Link>
    )
}

export default Logo
