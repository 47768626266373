import { DateAttribute } from '../attributeTemplates/DateAttribute'
import {sortAttributesByDisplayOrder} from '../utils/sortedDefinitions'
import {AttributeDefinitionModel} from '../model/AttributeModel'


export const RegulationDetailsDefinition = ({ definition }: { definition: AttributeDefinitionModel }) => {
    return <div className="display-set-definition definition-attribute-regulation"
                data-testid="test-details-regulation-details-definition" id={definition.attributeDefinitionId}>
        {sortAttributesByDisplayOrder(definition.attributes).map(attribute => attribute.value.composedByAttributes
            .map(statusDetailsDefinition =>
                <>
                    <span className="definition-name regulation-detail-definition">{statusDetailsDefinition.name}</span>
                    <DateAttribute attribute={statusDetailsDefinition} />
                </>
            )
        )}
    </div>
}