import {CookieConsentType, CookieDetail, CookiesCategory, CookieVendor} from './CookiesTypes'

//When modifying AllCookiesDetails, add additional types for CookiesCategory and CookieVendor if needed.
export const AllCookiesDetails: CookieDetail[] = [
    {
        category: CookiesCategory.RequiredCookies,
        description: 'These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not store any personally identifiable information.',
        vendorDetails: [
            {
                type: CookieVendor.MicrosoftAzureB2C,
                moreInfoUrl: 'https://learn.microsoft.com/en-us/azure/active-directory-b2c/cookie-definitions#cookies',
                vendorList: [
                    {
                        name: 'x-ms-cpim-admin',
                        description: 'Holds user membership data across tenants. The tenants a user is a member of and level of membership (Admin or User).'
                    },
                    {
                        name: 'x-ms-cpim-slice',
                        description: 'Used to route requests to the appropriate production instance.'
                    },
                    {
                        name: 'x-ms-cpim-trans',
                        description: 'Used for tracking the transactions (number of authentication requests to Azure AD B2C) and the current transaction.'
                    },
                    {
                        name: 'x-ms-cpim-sso:{Id}',
                        description: 'Used for maintaining the SSO session. This cookie is set as persistent, when Keep Me Signed In is enabled.'
                    },
                    {
                        name: 'x-ms-cpim-cache:{id}_n',
                        description: 'Used for maintaining the request state.'
                    },
                    {
                        name: 'x-ms-cpim-csrf',
                        description: 'Cross-Site Request Forgery token used for CRSF protection. For more information, read the Cross-Site request forgery token section.'
                    },
                    {
                        name: 'x-ms-cpim-dc',
                        description: 'Used for Azure AD B2C network routing.'
                    },
                    {
                        name: 'x-ms-cpim-ctx',
                        description: 'Context'
                    },
                    {
                        name: 'x-ms-cpim-rp',
                        description: 'Used for storing membership data for the resource provider tenant.'
                    },
                    {
                        name: 'x-ms-cpim-rc',
                        description: 'Used for storing the relay cookie.'
                    },
                    {
                        name: 'x-ms-cpim-geo',
                        description: 'Used as a hint to determine the resource tenants home geographic location.'
                    }
                ]
            },
            {
                type: CookieVendor.AzureFrontDoor,
                moreInfoUrl: 'https://learn.microsoft.com/en-us/azure/frontdoor/routing-methods#session-affinity',
                vendorList: [
                    {
                        name: 'ASLBSA',
                        description: 'Azure Software Load Balancer Session Affinity: Cookie-based session affinity allows Front Door to identify different users even if behind the same IP address, which in turn allows a more even distribution of traffic between your different origins.'
                    },
                    {
                        name: 'ASLBSACORS',
                        description: 'Azure Software Load Balancer Session Affinity Cross Origin Resource Sharing: Cookie-based session affinity allows Front Door to identify different users even if behind the same IP address, which in turn allows a more even distribution of traffic between your different origins.'
                    }
                ]
            },
            {
                type: CookieVendor.ApplicationGateway,
                moreInfoUrl: 'https://learn.microsoft.com/en-us/azure/application-gateway/configuration-http-settings#cookie-based-affinity',
                vendorList: [
                    {
                        name: 'ApplicationGatewayAffinity',
                        description: 'Cookie-based session affinity allows Application Gateway to identify different users even if behind the same IP address, which in turn allows a more even distribution of traffic between your different origins.'
                    },
                    {
                        name: 'ApplicationGatewayAffinityCORS',
                        description: 'Cookie-based session affinity allows Application Gateway to identify different users even if behind the same IP address, which in turn allows a more even distribution of traffic between your different origins.'
                    }
                ]
            },
            {
                type: CookieVendor.Datx,
                moreInfoUrl: process.env.REACT_APP_USER_PORTAL_URL + '/guild/none/legal/cookiePolicy',
                vendorList: [
                    {
                        name: 'CookiesConsentV1',
                        description: 'The state of your cookie consent on Datx. When the cookie expire, we will ask you again'
                    }
                ]
            }
        ]
    },
    {
        category: CookiesCategory.PerformanceCookies,
        description: 'These cookies enable the website to provide measure the performances of our website and to help improve functionalities. In addition, they can help use to debug if you have errors. They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies then some or all of these services may not function properly.',
        vendorDetails: [
            {
                type: CookieVendor.MicrosoftApplicationInsights,
                moreInfoUrl: 'https://learn.microsoft.com/en-us/azure/azure-monitor/app/configuration-with-applicationinsights-config#telemetry-initializers-aspnet',
                vendorList: [
                    {
                        name: 'ai_session',
                        description: 'This cookie name is associated with the Microsoft Application Insights software, which collects statistical usage and telemetry information for apps built on the Azure cloud platform. This is a unique Anonymous session identifier cookie. ai_session - This is a unique anonymous session identifier cookie',
                    },
                    {
                        name: 'ai_user',
                        description: 'Those cookie names are associated with the Microsoft Application Insights software, which collects statistical usage and telemetry information for apps built on the Azure cloud platform. ai_user - This is a unique user identifier cookie enabling counting of the number of users accessing the application over time',
                    }
                ]
            },
            {
                type: CookieVendor.Hotjar,
                moreInfoUrl: 'https://help.hotjar.com/hc/en-us/articles/6952777582999#list-of-cookies-set-by-the-hotjar-tracking-code',
                vendorList: [
                    {
                        name: '_hjSessionUser_{site_id}',
                        description: 'Persists the Hotjar User ID which is unique to that site. Hotjar does not track users across different sites. Ensures data from subsequent visits to the same site are attributed to the same user ID.',
                    },
                    {
                        name: '_hjFirstSeen',
                        description: 'Identifies a new user’s first session. Used by Recording filters to identify new user sessions.',
                    },
                    {
                        name: '_hjHasCachedUserAttributes',
                        description: 'Enables us to know whether the data set in _hjUserAttributes Local Storage item is up to date or not.',
                    },
                    {
                        name: '_hjUserAttributesHash',
                        description: 'Enables us to know when any User Attribute has changed and needs to be updated.',
                    },
                    {
                        name: '_hjUserAttributes',
                        description: 'Stores User Attributes sent through the Hotjar Identify API.',
                    },
                    {
                        name: 'hjViewportId',
                        description: 'Stores user viewport details such as size and dimensions.',
                    },
                    {
                        name: 'hjActiveViewportIds',
                        description: 'Stores user active viewports IDs. Stores an expirationTimestamp that is used to validate active viewports on script initialization.',
                    },
                    {
                        name: '_hjSession_{site_id}',
                        description: 'Holds current session data. Ensures subsequent requests in the session window are attributed to the same session.',
                    },
                    {
                        name: '_hjSessionTooLarge',
                        description: 'Causes Hotjar to stop collecting data if a session becomes too large or if the site has been rate limited temporarily. Determined automatically by a signal from the server if the session size exceeds the limit.',
                    },
                    {
                        name: '_hjSessionResumed',
                        description: 'Set when a session/recording is reconnected to Hotjar servers after a break in connection. ',
                    },
                    {
                        name: '_hjCookieTest',
                        description: 'Checks to see if the Hotjar Tracking Code can use cookies. If it can, a value of 1 is set. ',
                    },
                    {
                        name: '_hjLocalStorageTest',
                        description: 'Checks if the Hotjar Tracking Code can use Local Storage. If it can, a value of 1 is set. Data stored in _hjLocalStorageTest has no expiration time, but it is deleted almost immediately after it is created.',
                    },
                    {
                        name: '_hjSessionStorageTest',
                        description: 'Checks if the Hotjar Tracking Code can use Session Storage. If it can, a value of 1 is set. Data stored in _hjSessionStorageTest has no expiration time, but it is deleted almost immediately after it is created.',
                    },
                    {
                        name: '_hjIncludedInSessionSample_{site_id}',
                        description: 'Set to determine if a user is included in the data sampling defined by your site\'s daily session limit.',
                    },
                    {
                        name: '_hjAbsoluteSessionInProgress',
                        description: 'Used to detect the first pageview session of a user.',
                    },
                    {
                        name: '_hjTLDTest',
                        description: 'We try to store the _hjTLDTest cookie for different URL substring alternatives until it fails. Enables us to try to determine the most generic cookie path to use, instead of page hostname. It means that cookies can be shared across subpackages (where applicable).',
                    },
                    {
                        name: '_hjClosedSurveyInvites',
                        description: 'Set when a user interacts with a Link Survey invitation modal. Ensures the same invite does not reappear if it has already been shown.',
                    },
                    {
                        name: '_hjDonePolls',
                        description: 'Set when a user completes an on-site Survey. Ensures the same Survey does not reappear if it has already been filled in.',
                    },
                    {
                        name: '_hjMinimizedPolls',
                        description: 'Set when a user minimizes an on-site Survey. Ensures that the Survey stays minimized when the user navigates through your site.',
                    },
                    {
                        name: '_hjShownFeedbackMessage',
                        description: 'Set when a user minimizes or completes a Feedback widget. Ensures the Feedback widget will load as minimized if the user navigates to another page where it is set to show.',
                    }
                ]
            },
        ]
    },
    {
        category: CookiesCategory.FunctionalCookies,
        description: 'These cookies enable the website to provide enhanced functionality and personalisation. They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies then some or all of these services may not function properly.',
        vendorDetails: []
    }
]

export const InitialCookiesUserConsent = (cookieConsent: boolean): CookieConsentType => {
    const cookiesConsent: CookieConsentType = {
        timestamp: '',
        cookieCategories: {
            [CookiesCategory.RequiredCookies]: {
                userConsent: true,
                cookies: {}
            },
            [CookiesCategory.FunctionalCookies]: {
                userConsent: cookieConsent,
                cookies: {}
            },
            [CookiesCategory.PerformanceCookies]: {
                userConsent: cookieConsent,
                cookies: {}
            },
        }
    }
    cookiesConsent.timestamp = new Date().toString()

    AllCookiesDetails.forEach(({category, vendorDetails}) => {
        vendorDetails.reduce((acc, vendor) => {
            acc[vendor.type] = {userConsent: category === CookiesCategory.RequiredCookies ? true : cookieConsent}
            return acc
        }, cookiesConsent.cookieCategories[category].cookies)
    })
    return cookiesConsent
}