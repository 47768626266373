import {useGetEnabledBannersQuery} from './rtkBannerApi'
import {Banner} from './model/Banner'
import {useTypedSelector} from '../util/store'
import './BannersComponent.scss'
import {Alert, Collapse} from '@mui/material'
import React from 'react'
import {Info, SvgIconComponent, Warning} from '@mui/icons-material'
import {palette} from '../styles/theme'
import {LocalStorage} from '../util/localStorage'
import {IdType} from '../util/models/IdType'

interface BannerTypeParameters {
    icon: SvgIconComponent,
    sx: {
        backgroundColor: string
    }
}

function updateSeenBanners(bannerIdList : IdType[], banners : Banner[]) {
    return bannerIdList.filter((bannerId) =>
        banners.map((banner) => banner.id)
            .includes(bannerId))
}

const BannerComponent = ({banner}:{banner:Banner}) => {
    const [isDisplayed, setIsDisplayed] = React.useState(true)

    const handleClose = () => {
        setIsDisplayed(false)
        const seenBanners = LocalStorage.getSeenBanners()
        seenBanners.push(banner.id)
        LocalStorage.setSeenBanners(seenBanners)
    }

    const bannerTypeMap = new Map<string, BannerTypeParameters>([
        ['INFO', {icon: Info, sx: {backgroundColor: palette.extraLightBlueColor}}],
        ['SUCCESS', {icon: Info, sx: {backgroundColor: palette.successGreen}}],
        ['WARNING', {icon: Info, sx: {backgroundColor: palette.orangeColor}}],
        ['ALERT', {icon: Warning, sx: {backgroundColor: palette.errorRed}}]
    ])
    const typeParameter = bannerTypeMap.get(banner.type) ?? {icon: Info, sx: {backgroundColor : palette.lightGreyColor}}

    return (
            <Collapse in={isDisplayed}>
            <Alert
                icon={<typeParameter.icon fontSize="small" htmlColor="black" sx = {{mr : 2.5 }}/>}
                onClose={handleClose}
                sx={{...typeParameter.sx, color : palette.black, fontWeight : 'medium', padding: '6px 48px'}}
                square={true}
            >
                    {banner.content}
            </Alert>
            </Collapse>
    )
}

const BannersComponent = () => {
    const {userIsAuthenticated} = useTypedSelector(state => state.header)

    const seenBanners = LocalStorage.getSeenBanners()
    const {data:banners = []} = useGetEnabledBannersQuery(undefined ,{skip: !userIsAuthenticated})
    let updatedSeenBanners: IdType[] = []
    if (banners.length !== 0) { //This If condition accounts for the latency of getting banners due to user authentication
        updatedSeenBanners = updateSeenBanners(seenBanners, banners)// cleaning localStorage of outdated banners
        LocalStorage.setSeenBanners(updatedSeenBanners)
    }
    const unseenBanners = banners.filter((banner) => ! updatedSeenBanners.includes(banner.id))

    return (
        <div className = "app-banner">
            {userIsAuthenticated && <>
            {unseenBanners?.map((banner: Banner) => (
                <BannerComponent key={banner.id} banner={banner}/>
            ))}
            </>
            }
        </div>
    )
}

export default BannersComponent