import {createApi} from '@reduxjs/toolkit/dist/query/react'
import {SEARCH_API_ENDPOINT} from '../constants/backendUrls'
import {axiosBaseQuery} from '../rtkQueryUtils'
import {PrivateValuesForItem} from './model/PrivateValuesForItem'
import {AttributeDefinition} from './model/AttributeDefinition'
import {FREE_TEXT_VALUE_DEFINITION_ID} from '../constants/ids'
import {DisplayFiltersDTO} from './model/DisplayFiltersDTO'
import {SearchResultDTO} from './model/SearchResultDTO'
import {Search} from './model/SearchFilter'
import {IdType} from '../util/models/IdType'

import {createSelector} from '@reduxjs/toolkit'
import {SidebarClassificationNode} from '../header/models/SidebarClassificationNode'
import {PartNumberSearchDTO} from './model/PartNumberSearchDTO'
import {MatchingItemsResult} from './model/ItemsByPartNumber'
import {SearchClassificationsResultDTO} from './model/SearchClassificationsResultDTO'
import {setSnackbarMessage} from '../genericComponents/commonSlice'
import {chunck} from '../util/array'

export const SEARCH_ITEM_TAG = 'SearchItems'
const SEARCH_FILTERS_TAG = 'SearchFilter'

export const searchAPI = createApi({
    reducerPath: 'searchAPI',
    tagTypes: [SEARCH_ITEM_TAG, SEARCH_FILTERS_TAG],
    baseQuery: axiosBaseQuery({baseUrl: `${SEARCH_API_ENDPOINT}/api/v1/datx/`}),
    keepUnusedDataFor: 0,
    endpoints: (builder) => ({

        // =========== Private Attributes

        getPrivateDefinitionsForGuild: builder.query<AttributeDefinition[], {
            itemUuids: IdType[] | undefined,
            guildId: IdType | undefined
        }>({
            query: ({itemUuids, guildId}) => ({
                url: `guild/${guildId}/attribute-definition/${FREE_TEXT_VALUE_DEFINITION_ID}/derived`,
                method: 'GET',
                data: itemUuids,
            }),
            keepUnusedDataFor: 5
        }),
        getDefinitionsByIdsForGuild: builder.query<AttributeDefinition[], {
            definitionIds: IdType[] | undefined,
            guildId: IdType | undefined
        }>({
            query: ({definitionIds, guildId}) => ({
                url: `definitions/guild/${guildId}`,
                method: 'POST',
                data: definitionIds,
            }),
            keepUnusedDataFor: 5
        }),
        //ToDo: To change naming for derived and private attributes in rtk api, both names seem similar
        getPrivateDefinitionsManagedByGuild: builder.query<AttributeDefinition[], {
            guildId: IdType | undefined
        }>({
            query: ({guildId}) => ({
                url: `definitions/guild/${guildId}/private`,
                method: 'GET',
            }),
            keepUnusedDataFor: 5
        }),
        getPrivateValuesForItem: builder.query<PrivateValuesForItem[], {
            itemUuids: IdType[] | undefined,
            guildId: IdType | undefined,
            attributeDefinition: IdType | undefined,
            pageSize?: number
        }>({
            queryFn: async ({itemUuids, guildId, attributeDefinition, pageSize = 100},
                            {dispatch}, extraOptions, baseQuery,) => {
                try {
                    if (!itemUuids) return {data: []}
                    const allItemsPromise = chunck(itemUuids, pageSize)
                        .flatMap(async sliceIds => {
                                return baseQuery({
                                    url: `guild/${guildId}/attribute-definition/${attributeDefinition}/values`,
                                    method: 'POST',
                                    data: sliceIds,
                                })
                            }
                        )

                    const allItems = await Promise.all(allItemsPromise)

                    return {data: (allItems.flatMap(response => response.data as PrivateValuesForItem[]) )}

                } catch (error: any) {
                    dispatch(setSnackbarMessage('Error getting all the existing value of your items'))
                    return {error: error?.response}
                }
            },
            keepUnusedDataFor:
                5
        }),
        getDatxClassificationTree: builder.query<SidebarClassificationNode, void>({
            query: () => ({
                url: 'classification/datxtree',
                method: 'GET'
            }),
            keepUnusedDataFor: 300
        }),


        // =========== Search

        getDisplayFilters:
            builder.query<DisplayFiltersDTO, Search>({
                query: (search) => ({
                    url: 'search/filters',
                    method: 'POST',
                    data: search,
                }),
                keepUnusedDataFor: 60, // cache 30 seconds
                providesTags: [SEARCH_FILTERS_TAG]
            }),
        searchItems:
            builder.query<SearchResultDTO, { pageNumber: number, pageSize: number, search: Search }>({
                query: ({pageNumber = 0, pageSize = 24, search}) => ({
                    url: `search/full?page=${pageNumber}&size=${pageSize}`,
                    method: 'POST',
                    data: search,
                }),
                keepUnusedDataFor: 30, // cache 30 seconds
                providesTags: [SEARCH_ITEM_TAG],
            }),
        searchItemsByName:
            builder.query<SearchResultDTO, { pageNumber: number, pageSize: number, search: Search }>({
                query: ({pageNumber = 0, pageSize = 24, search}) => ({
                    url: `search?page=${pageNumber}&size=${pageSize}`,
                    method: 'POST',
                    data: search,
                }),
                keepUnusedDataFor: 30, // cache 30 seconds
                providesTags: [SEARCH_ITEM_TAG]
            }),
        searchClassificationsRelatedToSearchingItems:
            builder.query<SearchClassificationsResultDTO[], {
                search: Search
            }>({
                query: ({search}) => ({
                    url: 'search/classification-result',
                    method: 'POST',
                    data: search,
                }),
                keepUnusedDataFor: 30, // cache 30 seconds
            }),
        getItemMatching:
            builder.query<MatchingItemsResult[], PartNumberSearchDTO>({
                queryFn: async (searchDTO, api, extraOptions, baseQuery) => {
                    const simultaneousSearchPhrases = 20
                    const nbSamples = Math.ceil(searchDTO.matchingSearch.length / simultaneousSearchPhrases)
                    const samples = Array.from({length: nbSamples}, (_, i) => searchDTO.matchingSearch.slice(i * simultaneousSearchPhrases, (i + 1) * simultaneousSearchPhrases))
                    const {guildId} = searchDTO

                    const requests = samples
                        .map(async sample => {
                            const lastResult = await baseQuery({
                                url: 'search/item-matching',
                                method: 'POST',
                                data: {guildId, matchingSearch: sample}
                            })
                            if (lastResult.error) {
                                return {error: lastResult.error}
                            }
                            return lastResult.data
                        })

                    const result = await Promise.all(requests)

                    const error = result.find((r: any) => r.error)
                    if (error) {
                        return error as any
                    }
                    return {data: result.flat() as MatchingItemsResult[]}
                },
            })
    })
})

const selectGetDatxClassificationTree = searchAPI.endpoints.getDatxClassificationTree.select()
export const selectClassificationTree = createSelector(selectGetDatxClassificationTree, getDatxClassificationTreeResult => getDatxClassificationTreeResult.data ?? {
    label: '',
    id: '',
    children: null
})


export const {
    useGetPrivateDefinitionsForGuildQuery,
    useGetPrivateValuesForItemQuery,
    useGetPrivateDefinitionsManagedByGuildQuery,
    useGetDefinitionsByIdsForGuildQuery,
    useGetDatxClassificationTreeQuery,
    useGetDisplayFiltersQuery,
    useSearchItemsQuery,
    useSearchClassificationsRelatedToSearchingItemsQuery,
    useSearchItemsByNameQuery,
    useLazyGetItemMatchingQuery
} = searchAPI



