import { IconButton } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import './datxIcon.scss'

export const CopyButton = ({toCopy, onCopySuccess, onCopyFailure, color}: {toCopy?: string, onCopySuccess? : () => void, onCopyFailure?: () => void, color?: string}) => {
    
    const sendToClipboard = async () => {
        if (toCopy) {
            await navigator.clipboard.writeText(toCopy).then(
                () => onCopySuccess?.(),
                () => onCopyFailure?.()
            )
        }
    }

    return (
        <IconButton className="datxIcon" onClick={() => sendToClipboard()}>
            <ContentCopyIcon />
        </IconButton>
    )
}