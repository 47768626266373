import './UpdateApprovalDialog.scss'
import CustomDialog from '../../../genericComponents/customDialog/CustomDialog'
import {Button, Divider} from '@mui/material'
import {IdType} from '../../../util/models/IdType'
import LoadingButton from '@mui/lab/LoadingButton'
import {UpdateDialogContent} from './model/UpdateDialogContent'

const UpdateApprovalDialog = ({ open, closeDialog, params, approved, updateDialogContent, approvedRequest, rejectedRequest, isLoading}:
    {
        open: boolean,
        closeDialog: () => void,
        params: { serviceRequestId: IdType | undefined, guildId: IdType | undefined },
        approved : boolean | null,
        updateDialogContent : UpdateDialogContent,
        approvedRequest: () => void,
        rejectedRequest: () => void,
        isLoading: boolean
    }
) => {

    const onSubmitApproval = () => {
        approvedRequest()
        closeDialog()
    }

    const onSubmitReject = () => {
        rejectedRequest()
        closeDialog()
    }

    const approvalDialog = <SubmitStatusDialog 
        open={open}
        closeDialog={closeDialog}
        confirmationMsg={updateDialogContent.approvedContent.confirmationMsg}
        params={params}
        isLoading={isLoading}
        titleMsg={updateDialogContent.approvedContent.titleMsg}
        approveButtonLabel={updateDialogContent.approvedContent.approveButtonLabel}
        onSubmit={onSubmitApproval}
    />

    const rejectDialog = <SubmitStatusDialog 
        open={open}
        closeDialog={closeDialog}
        confirmationMsg={updateDialogContent.rejectedContent.confirmationMsg}
        params={params}
        isLoading={isLoading}
        titleMsg={updateDialogContent.rejectedContent.titleMsg}
        approveButtonLabel={updateDialogContent.rejectedContent.approveButtonLabel}
        onSubmit={onSubmitReject}
    />

    return approved ? approvalDialog : rejectDialog
}

const SubmitStatusDialog  = ({ open, closeDialog, confirmationMsg, params, isLoading ,titleMsg, approveButtonLabel, onSubmit}:
    {
        open: boolean,
        closeDialog: () => void,
        confirmationMsg: string,
        params: { serviceRequestId: IdType | undefined, guildId: IdType | undefined },
        isLoading: boolean,
        titleMsg: string,
        approveButtonLabel:string,
        onSubmit: () => void
    }) => {


    return (<CustomDialog
        className="confirmation-dialog"
        open={open}
        title={titleMsg}
        onClose={closeDialog}
        
    >
        <div>
            <p className="confirmation-dialog-text">
                {confirmationMsg}
            </p>
            <Divider sx={{ mt: 5, mb: 2 }} />

            <div className="confirmation-dialog-buttons">
                <Button
                    className="delete-item-list-back-button"
                    onClick={() => closeDialog()}
                >
                    Cancel
                </Button>

                <LoadingButton
                    className="delete-item-list-submit"
                    color="primary"
                    variant="contained"
                    loading={isLoading}
                    disabled={isLoading}
                    onClick={() => onSubmit()}
                    data-testid="approve-button"
                >
                   {approveButtonLabel}
                </LoadingButton>
            </div>
        </div>
    </CustomDialog>
    )
}

export default UpdateApprovalDialog
