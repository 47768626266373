import {Organization} from '../../model/Organization'
import DisplaySetTitle from '../../../displaySet/DisplaySetTitle'
import React from 'react'
import {OrganizationFakeDefinition} from './OrganizationFakeDefinition'

export const OrganizationAddressFakeDisplaySet = ({organization}: { organization: Organization }) => {
    const address = organization.address
    if (!address) return <></>

    return <div className="display-set" data-testid="test-first-level-display-set">
        <DisplaySetTitle title={'Address'}/>
        <div className="display-set-content">
            {address?.postalCode &&
                <OrganizationFakeDefinition label={'Zip Code'} value={address.postalCode}></OrganizationFakeDefinition>}
            {address?.city &&
                <OrganizationFakeDefinition label={'City'} value={address.city}></OrganizationFakeDefinition>}
            {address?.region &&
                <OrganizationFakeDefinition label={'Region'} value={address.region}></OrganizationFakeDefinition>}
            {address?.country &&
                <OrganizationFakeDefinition label={'Country'} value={address.country}></OrganizationFakeDefinition>}
            {address?.countryCode &&
                <OrganizationFakeDefinition label={'Country Code'}
                                            value={address.countryCode}></OrganizationFakeDefinition>}
            {address?.address &&
                <OrganizationFakeDefinition label={'Address'} value={address.address}></OrganizationFakeDefinition>}
        </div>
    </div>
}