import './Breadcrumbs.scss'
import {Breadcrumbs} from '@mui/material'
import {Link, To} from 'react-router-dom'
import React from 'react'
import {NavigateNext} from '@mui/icons-material'

export type BreadcrumbType = {
    link: To
    label: string
    id: string
}

export const BreadCrumbs = ({
    breadcrumbList,
    totalResults,
    isFetching,
}: {
    breadcrumbList: BreadcrumbType[]
    totalResults?: number
    isFetching?: boolean
}) => {
    const getUnit = (number: number) => (number <= 1 ? 'result' : 'results')

    return (
        <div className="breadcrumb-container generic-content-top-action">
            <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNext fontSize="small" />} className="breadcrumbs">
                {breadcrumbList.map((breadcrumb) => (
                    <Link to={breadcrumb.link} id={breadcrumb.id} key={breadcrumb.id} className="breadcrumb-label">
                        {breadcrumb.label}
                    </Link>
                ))}
            </Breadcrumbs>

            {!isFetching && totalResults && totalResults !== 0 && (
                <div className="breadcrumb-label showed-results" data-testid="breadcrumb-label">
                    (<span data-testid="total-results">{totalResults}</span> &nbsp; {getUnit(totalResults)})
                </div>
            )}
        </div>
    )
}
