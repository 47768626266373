import {SearchResultDTO} from '../model/SearchResultDTO'
import {useState} from 'react'
import {SearchItem} from '../model/SearchItem'
import {useSearchParams} from 'react-router-dom'
import {pageSizeURLSearchParamsName} from '../SearchParams'
import {toPageSize} from '../../util/component/search'

export const useSearchResultControl = ({searchResult, page}: {
    searchResult: SearchResultDTO | undefined,
    page: number,
}) => {
    const [itemsSelectedList, updateItemSelectedList] = useState<SearchItem[]>([])

    const [legacyView, setLegacyView] = useState<boolean>(false)
    const [addItemsToListModalVisible, setAddItemsToListModalVisible] = useState<boolean>(false)
    const [finalItemsToBeAddedToList, setFinalItemsToBeAddedToList] = useState<SearchItem[]>([])
    const [searchParams] = useSearchParams()
    const pageSize = toPageSize(searchParams.get(pageSizeURLSearchParamsName))

    const startIndex = ((page * pageSize) - pageSize) + 1
    const endIndex = startIndex + (searchResult?.elements?.length ?? 0) - 1

    const noFoundItems = !searchResult || searchResult?.elements?.length === 0

    const addToMultiSelect = (itemsToAdd: SearchItem[]) => {
        const selectedItems = [...itemsSelectedList, ...itemsToAdd]
        updateItemSelectedList(selectedItems)
    }

    const removeFromMultiSelect = (itemToRemove: SearchItem) => {
        updateItemSelectedList(itemsSelectedList.filter(item => item.id !== itemToRemove.id))
    }

    const isSelected = (item: SearchItem) => {
        return itemsSelectedList.map(item => item.id).includes(item.id)
    }


    const clearPageSelection = () => {
        updateItemSelectedList(itemsSelectedList.filter(item => !searchResult?.elements.find(result => result.id === item.id)))
    }


    const areAllItemsInPageSelected = () => {
        return !!searchResult?.elements.every(returnedItem => itemsSelectedList.find(item => item.id === returnedItem.id))
    }

    const isAnItemInPageSelected = () => {
        return !!searchResult?.elements.some(returnedItem => itemsSelectedList.find(item => item.id === returnedItem.id))
    }

    const addPageResultToSelection = () => {
        addToMultiSelect(searchResult?.elements || [])
    }
    const handleAddMultipleItemsToList = () => {
        setAddItemsToListModalVisible(true)
        setFinalItemsToBeAddedToList(itemsSelectedList)
    }
    const handleAddSingleItemToList = (item: SearchItem) => {
        setAddItemsToListModalVisible(true)
        setFinalItemsToBeAddedToList([item])
    }

    return {
        noFoundItems,
        addPageResultToSelection,
        clearPageSelection,
        handleAddMultipleItemsToList,
        setLegacyView,
        legacyView,
        isAnItemInPageSelected,
        areAllItemsInPageSelected,
        itemsSelectedList,
        startIndex,
        endIndex,
        addToMultiSelect,
        removeFromMultiSelect,
        handleAddSingleItemToList,
        isSelected,
        pageSize,
        addItemsToListModalVisible,
        setAddItemsToListModalVisible,
        finalItemsToBeAddedToList,
    }
}