import {IdType} from './models/IdType'
import {ItemDetailView} from '../item/itemDetails/itemDetailsPage/ItemDetailToggleButton'

export class LocalStorage {

    static keys = {
        userLanguage: 'userLanguage',
        userEmail: 'email',
        lastGuildSelected: 'lastGuildSelected',
        itemDetailView: 'itemDetailViewSelected',

    }

    static get(key: string) {
        const data = localStorage.getItem(key)

        if (data) {
            return JSON.parse(data)
        }

        return null
    }

    static set(key: string, data: any) {
        localStorage.setItem(key, JSON.stringify(data))
    }

    static remove(key: string) {
        localStorage.removeItem(key)
    }


    static setGuildContext(guildContext: any) {
        this.set(this.keys.lastGuildSelected, guildContext)
    }

    static getGuildContext() {
        return this.get(this.keys.lastGuildSelected)
    }

    static removeGuildContext() {
        this.remove(this.keys.lastGuildSelected)
    }

    static getUserLanguage() {
        return this.get(this.keys.userLanguage) || {}
    }

    static setUserLanguage(userLanguage: string) {
        this.set(this.keys.userLanguage, userLanguage)
    }

    static getUserEmail() {
        return this.get(this.keys.userEmail) || ''
    }

    static setUserEmail(email: string) {
        this.set(this.keys.userEmail, email)
    }

    static getSeenBanners(): IdType[] {
        return JSON.parse(localStorage.getItem('seenBanners') || '[]')
    }

    static setSeenBanners(bannerIds: IdType[]) {
        localStorage.setItem('seenBanners', JSON.stringify(bannerIds))

    }

    static getItemDetailView() {
        return this.get(this.keys.itemDetailView) || ''
    }
    static setItemDetailView(itemDetailView: ItemDetailView) {
        this.set(this.keys.itemDetailView, itemDetailView)
    }
}
