import {SearchResultDTO} from '../../../model/SearchResultDTO'
import {Search} from '../../../model/SearchFilter'
import React, {ChangeEvent} from 'react'
import {SearchToolbarForSpecificItemsSharing} from '../SearchToolBar/SearchToolbarForSpecificItemsSharing'
import SearchResultDataTable from '../SearchResultDataTable/SearchResultDataTable'
import ItemSearchModal from '../AddItemToListModal/AddItemToListModal'
import {useSearchResultControl} from '../../useSearchResultControl'
import {SearchPagination} from '../../../searchPagination/SearchPagination'

export const SearchResultTableForSpecificItemSharing = ({searchResult, page, search, handlePageChange}: {
    searchResult: SearchResultDTO | undefined,
    page: number,
    search: Search,
    handlePageChange: (e: ChangeEvent<unknown>, pageNumber: number) => void
}) => {
    const {
        addPageResultToSelection,
        clearPageSelection,
        isAnItemInPageSelected,
        areAllItemsInPageSelected,
        itemsSelectedList,
        startIndex,
        endIndex,
        addToMultiSelect,
        removeFromMultiSelect,
        handleAddSingleItemToList,
        isSelected,
        pageSize,
        addItemsToListModalVisible,
        setAddItemsToListModalVisible,
        finalItemsToBeAddedToList
    } = useSearchResultControl({searchResult, page})

    return <div>
        <div className="search-results-table">
            <SearchToolbarForSpecificItemsSharing handleSelectAll={addPageResultToSelection}
                                                  handleUnselectAll={clearPageSelection}
                                                  isAnItemOnPageSelected={isAnItemInPageSelected()}
                                                  allItemsSelected={areAllItemsInPageSelected()}
                                                  selectedItemsList={itemsSelectedList}
                                                  startIndex={startIndex}
                                                  endIndex={endIndex}
                                                  totalResultCount={searchResult?.totalElements ?? 0}/>
            <SearchResultDataTable
                definitionsToDisplay={searchResult?.definitionsToDisplay ?? []}
                list={searchResult?.elements ?? []}
                addToMultiSelect={(item) => addToMultiSelect([item])}
                removeFromMultiSelect={removeFromMultiSelect}
                handleAddSingleItemToList={handleAddSingleItemToList}
                isSelected={isSelected}
                highlightPhrase={search?.phrase?.phraseDetail ?? ''}
                noItemMessages="There are no items shared with this guild"
            />

        </div>
        <SearchPagination totalElements={searchResult?.totalElements || 0}
                          pageSize={pageSize}
                          page={page}
                          handlePageChange={handlePageChange}/>
        {addItemsToListModalVisible &&
            <ItemSearchModal closeModal={() => setAddItemsToListModalVisible(false)}
                             itemList={finalItemsToBeAddedToList}/>}
    </div>
}