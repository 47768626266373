import {Box, Button, Divider} from '@mui/material'
import {Link, useParams} from 'react-router-dom'
import {ROUTES} from '../../../constants/routing'
import React from 'react'
import {useDatxPathGenerator} from '../../../util/routing'
import {ItemOrganization} from '../../../item/model/ItemOrganization'

const SharingCreatedSuccessfully = ({targetGuildName, organization, updatedTargetGuildName}: {
    targetGuildName: string,
    organization: ItemOrganization | null,
    updatedTargetGuildName: string
}) => {
    const generatePath = useDatxPathGenerator()
    const {sharingId} = useParams()

    return (
        <Box className="page-container">
            <div className="guild-form-title-container">
                <h5 id="shareSuccessToOtherGuildTitle" className="page-title">
                    Items shared with <span>{targetGuildName}</span>
                </h5>
                <Divider className="divider-title"/>
            </div>
            <div className="add-sharing-body" data-testid="add-sharing-message">
                <b>Items defined by {organization?.label}</b> have been shared with the <b>Guild <span className="guild-name-uppercase">{updatedTargetGuildName}</span></b>.<br/>
                Guild members of <span className="guild-name-uppercase">{updatedTargetGuildName}</span> will now be able to search and view these items.<br/>

                <p>Please note that <b>new items</b> that are later created and defined by the Organisation ({organization?.label}) will be <b>shared automatically.</b><br/><br/></p>
                <p>Data will be updated asynchronously, thank you for your patience.</p>
            </div>
            <Button data-testid="back-btn"
                    className="back-to-org-item-button"
                    variant="contained"
                    color="primary"
                    size="small"
                    component={Link}
                    to={generatePath(ROUTES.manageOrganisationSharedItems.path, {sharingId: sharingId})}>
                Back to Item Sharing
            </Button>
        </Box>
    )
}

export default SharingCreatedSuccessfully
