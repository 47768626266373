import React, {ReactNode} from 'react'

import {DefinitionForDisplaySet} from '../definitionForDisplaySet/DefinitionForDisplaySet'
import {AttributeModel} from '../model/AttributeModel'
import {groupByDefinitionId, sortByDisplayOrder} from '../utils/sortedDefinitions'


export const ComplexAttribute = ({attribute, children}: { attribute?: AttributeModel, children?: ReactNode }) => {
    if(!attribute) return <></>
    const definitionsMap = groupByDefinitionId(attribute.value?.composedByAttributes ?? [])
    const sortedDefinitions = sortByDisplayOrder([...definitionsMap.values()])

    return <div className="complex-attribute">
        <>{children}</>
        <>{sortedDefinitions?.map(child => {
                if (!child) return <></>
                return <DefinitionForDisplaySet key={child?.attributeDefinitionId} definition={child}/>
            }
        )}</>
    </div>
}
