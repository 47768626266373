import {activateAppInsightCookie, disableAppInsightCookie} from '../util/tracking/appInsight'
import {useEffect, useState} from 'react'
import {
    COOKIE_EXPIRE_TIME,
    CookieConsentType,
    COOKIES_CONSENT_NAME,
    CookieVendor,
    getCookiesListFromConsentObject,
} from './CookiesTypes'
import {InitialCookiesUserConsent} from './CookiesData'
import {startHotjar, stopHotjar} from '../util/tracking/hotjar'
import {getCookie, setCookie} from './cookiesUtils'
import {equalsCheck} from '../util/excel-util'

export const useCookiesControl = () => {

    const [openCookieAlert, setOpenCookieAlert] = useState(false)
    const [openSettingsDialog, setOpenSettingsDialog] = useState(false)

    useEffect(() => {
        openCookieAlertIfNeeded()
    }, [])

    const openCookieAlertIfNeeded = () => {
        const previousCookieConsent = getCookie(COOKIES_CONSENT_NAME)
        if (!previousCookieConsent) {
            setOpenCookieAlert(true)
            return
        }

        const previousCookies = getCookiesListFromConsentObject(previousCookieConsent as CookieConsentType)
        const actualCookies = getCookiesListFromConsentObject(InitialCookiesUserConsent(true))
        const allCookiesAlreadySet = equalsCheck(actualCookies, previousCookies)
        setOpenCookieAlert(!allCookiesAlreadySet)
    }



    const setCookieConsent = (cookieValue: string) => {
        setCookie(COOKIES_CONSENT_NAME, cookieValue, COOKIE_EXPIRE_TIME)
    }

    const activateCookie = (cookie: CookieVendor, consent: boolean) => {
        switch (cookie) {
            case CookieVendor.MicrosoftApplicationInsights:
                consent ? activateAppInsightCookie() : disableAppInsightCookie()
                break
            case CookieVendor.Hotjar:
                consent ? startHotjar() : stopHotjar()
                break
            default:
                break
        }
    }

    const activateAllCookies = (consentObject: CookieConsentType) => {
        Object.values(consentObject.cookieCategories).forEach(category => {
            Object.entries(category.cookies).forEach(([cookieName, consent]) => {
                activateCookie(cookieName as CookieVendor, consent.userConsent)
            })
        })
    }

    const allowAllCookies = () => {
        const cookieConsentAccepted = InitialCookiesUserConsent(true)
        setCookieConsent(JSON.stringify(cookieConsentAccepted))
        activateAllCookies(cookieConsentAccepted)
        setOpenCookieAlert(false)
        setOpenSettingsDialog(false)
    }

    const declineAllCookies = () => {
        const cookieConsentRejected = InitialCookiesUserConsent(false)
        setCookieConsent(JSON.stringify(cookieConsentRejected))
        activateAllCookies(cookieConsentRejected)
        setOpenCookieAlert(false)
        setOpenSettingsDialog(false)
    }

    const acceptCustomCookies = (cookiesConsentObject: CookieConsentType) => {
        setCookieConsent(JSON.stringify(cookiesConsentObject))
        activateAllCookies(cookiesConsentObject)
        setOpenCookieAlert(false)
        setOpenSettingsDialog(false)
    }

    const handleOpenDialog = () => {
        setOpenCookieAlert(false)
        setOpenSettingsDialog(true)
    }

    const handleCloseDialog = () => {
        setOpenSettingsDialog(false)
        setOpenCookieAlert(true)
    }


    return {
        openCookieAlert,
        allowAllCookies,
        declineAllCookies,
        acceptCustomCookies,
        setOpenCookieAlert,
        openSettingsDialog,
        handleOpenDialog,
        handleCloseDialog,
    }
}