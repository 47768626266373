import React from 'react'
import {resetRedirectToLogin, useTryToGetToken} from './AuthHooksAndFunctions'
import {signinRequest, signupRequest} from './authConfig'
import './LoginPage.scss'
import {useMsal} from '@azure/msal-react'
import {useNavigate} from 'react-router-dom'
import LoadingSpinner from '../genericComponents/LoadingSpinner'


const AutoSignUpPage = () => {
    const {instance, inProgress} = useMsal()
    const navigate = useNavigate()
    useTryToGetToken(
        () => navigate('/'),
        () => null
    )

    const startSignUp = () => {
        signinRequest.redirectStartPage = '/'
        instance
            .loginRedirect(signupRequest)
            .then((_) => {
                resetRedirectToLogin()
            })
    }
    if (inProgress !== 'none') {
        return <LoadingSpinner/>
    }
    startSignUp()
    return <LoadingSpinner />
}

export default AutoSignUpPage
