import React from 'react'
import {Link} from 'react-router-dom'
import {AttributeModel} from '../model/AttributeModel'
import {useDatxPathGenerator} from '../../util/routing'
import {ROUTES} from '../../constants/routing'
import {ComplexAttribute} from './ComplexAttribute'


export const LinkAttribute = ({attribute}: { attribute: AttributeModel }) => {
    const generatePath = useDatxPathGenerator()

    const targetItem = attribute?.value?.linkedItem
    return <ComplexAttribute attribute={attribute}>
        <div className="link-attribute">
            {targetItem &&
                <Link to={generatePath(ROUTES.detailItem.path, {itemId: targetItem.id})} data-testid="test-relatedItem">
                    {targetItem.partNumber}
                    <span className="link-attribute-organization"> by {targetItem.definedByOrganization.label}</span>
                </Link>}

        </div>
    </ComplexAttribute>
}
