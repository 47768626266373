import React from 'react'
import {useFormikContext} from 'formik'
import {Link} from '@mui/material'
import * as FileSaver from 'file-saver'
import {ServiceRequestStep} from '../../../genericComponents/formTemplate/steps/ServiceRequestStep'
import {Description} from '../../../../../genericComponents/typographie/Description'
import {Label} from '../../../../../genericComponents/typographie/Label'
import {PartMatchingItemForm} from '../UploadForm/PartMatchingSelfServiceForm'
import {WorkflowSelector} from '../../../model/WorkflowSelector'
import {ServiceEnum} from '../../../../model/ServiceEnum'

export const ReviewAndSubmitPartMatchingPaid = ({ isPaidForm }: { isPaidForm: boolean }) => {
    const formik = useFormikContext<PartMatchingItemForm>()

    const service = ServiceEnum.PART_MATCHING_CONSULT_DATX_EXPERT

    const getFile = () => {
        const file = formik?.values?.file
        if (!file) return
        FileSaver.saveAs(file, file.name)
    }

    return (
        <ServiceRequestStep>
            <Description>Review the parameters of your selection and submit.</Description>
            <div className="service-request-form-summary">
                <Label className="form-summary-label">Uploaded Document</Label>
                <Link onClick={getFile} className="form-summary-value link" data-testid="download-template">
                    {formik?.values?.file?.name ?? '-'}
                </Link>
            </div>
            {isPaidForm && (
                <WorkflowSelector service={ServiceEnum.PART_MATCHING}/>
            )}
        </ServiceRequestStep>
    )
}
