import React, {SyntheticEvent, useState} from 'react'
import {Tab, Tabs} from '@mui/material'
import '../../../guild/guildManagement/members/GuildMembers.scss'
import {useGetUserServiceSubscriptionsQuery} from '../rtkServiceSubscriptionApi'
import {NavLink} from 'react-router-dom'
import {ROUTES} from '../../../constants/routing'

import '../common/UserServiceSubscriptions.scss'
import SubscriptionList from '../common/SubscriptionList'
import LoadingSpinner from '../../../genericComponents/LoadingSpinner'
import { useCurrentGuildContext, useDatxPathGenerator } from '../../../util/routing'

const UserServiceSubscriptions = ({activeSubscription}: { activeSubscription: boolean }) => {
    const generatePath = useDatxPathGenerator()
    const [currentGuildContext] = useCurrentGuildContext()
    const [tabValue, setTabValue] = useState(activeSubscription ? 0 : 1)

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setTabValue(newValue)
    }


    const {
        data: serviceSubscriptions = [],
        isFetching,
        isLoading
    } = useGetUserServiceSubscriptionsQuery({
        guildId: currentGuildContext?.guildId,
        isActiveSubscription: activeSubscription
    }, {skip: !currentGuildContext?.guildId})

    if (!currentGuildContext) {
        return <div className="service-subscriptions-page" data-testid="service-subscriptions-list-container">
            <p>Please select a Guild to display your Subscriptions</p>
        </div>
    }

    return (<div className="service-subscriptions-page" data-testid="service-subscriptions-list-container">
        <div className="header-title">
            My Service Subscriptions
        </div>
        <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Active subscriptions" component={NavLink}
                 to={generatePath(ROUTES.userActiveServiceSubscriptions.path)}/>
            <Tab label="Inactive subscriptions" component={NavLink}
                 to={generatePath(ROUTES.userInactiveServiceSubscriptions.path)}/>
        </Tabs>
        {(isLoading || isFetching) ? <LoadingSpinner/> : <SubscriptionList subscriptions={serviceSubscriptions}/>}
    </div>)
}

export default UserServiceSubscriptions
