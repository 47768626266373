import React, {SyntheticEvent, useState} from 'react'
import {Tab, Tabs} from '@mui/material'
import '../../../guild/guildManagement/members/GuildMembers.scss'
import {useGetGuildServiceSubscriptionsQuery} from '../rtkServiceSubscriptionApi'
import {NavLink} from 'react-router-dom'
import {ROUTES} from '../../../constants/routing'

import '../common/UserServiceSubscriptions.scss'
import SubscriptionList from '../common/SubscriptionList'
import LoadingSpinner from '../../../genericComponents/LoadingSpinner'
import {ServiceSubscriptionSummary} from '../../model/subscription/ServiceSubscription'
import { useCurrentGuildContext, useDatxPathGenerator } from '../../../util/routing'

const GuildServiceSubscriptions = ({activeSubscription}: { activeSubscription: boolean }) => {
    const generatePath = useDatxPathGenerator()
    const [currentGuildContext] = useCurrentGuildContext()

    const [tabValue, setTabValue] = useState(activeSubscription ? 0 : 1)
    const [selectedServiceSubscriptionSummary, setSelectedServiceSubscriptionSummary] = useState<ServiceSubscriptionSummary>()
    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setTabValue(newValue)
    }

    const {
        data: serviceSubscriptions = [],
        isFetching,
        isLoading
    } = useGetGuildServiceSubscriptionsQuery({
        guildId: currentGuildContext?.guildId,
        isActiveSubscription: activeSubscription
    }, {skip: !currentGuildContext?.guildId})

    if (!currentGuildContext) {
        return <div className="service-subscriptions-page" data-testid="service-subscriptions-list-container">
            <p>Please select a Guild to display your Subscriptions</p>
        </div>
    }

    return <div className="service-subscriptions-page" data-testid="service-subscriptions-list-container">
        <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Active Guild Subscriptions" component={NavLink} className = "guild-subscription-tab"
                 to={generatePath(ROUTES.guildActiveServiceSubscriptions.path)}/>
            <Tab label="Inactive Guild Subscriptions" component={NavLink} className = "guild-subscription-tab"
                 to={generatePath(ROUTES.guildInactiveServiceSubscriptions.path)}/>
        </Tabs>
        {(isLoading || isFetching) ? <LoadingSpinner/> : <SubscriptionList subscriptions={serviceSubscriptions}/>}
    </div>
}

export default GuildServiceSubscriptions
