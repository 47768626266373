import React from 'react'

import {SimpleAttribute} from '../attributeTemplates/SimpleAttribute'
import {TemplateDefinitionForDisplaySet} from './TemplateDefinitionForDisplaySet'
import {AttributeDefinitionModel} from '../model/AttributeModel'


export const FreeTextDefinitionTemplate = ({definition}: { definition: AttributeDefinitionModel }) => {
    const simpleAttribute = definition.attributes[0]
    return <TemplateDefinitionForDisplaySet definition={definition}>
        <SimpleAttribute attribute={simpleAttribute}></SimpleAttribute>
    </TemplateDefinitionForDisplaySet>
}
