import React from 'react'
import './homeSpinner.scss'

const LoadingSpinner = () => (
    <div className="home-spinner" data-testid="loading-spinner">
        <div className="loader">
            <div className="loader-block-container">
                <div style={{left: '19px', top: '19px', animationDelay: '0.0s'}}></div>
                <div style={{left: '40px', top: '19px', animationDelay: '0.0625s'}}></div>
                <div style={{left: '61px', top: '19px', animationDelay: '0.125s'}}></div>
                <div style={{left: '19px', top: '40px', animationDelay: '0.4375s'}}></div>
                <div style={{left: '61px', top: '40px', animationDelay: '0.1875s'}}></div>
                <div style={{left: '19px', top: '61px', animationDelay: '0.375s'}}></div>
                <div style={{left: '40px', top: '61px', animationDelay: '0.3125s'}}></div>
                <div style={{left: '61px', top: '61px', animationDelay: '0.25s'}}></div>
            </div>
        </div>
    </div>
)

export default LoadingSpinner