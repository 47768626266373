import {ReactElement, ReactNode} from 'react'
import {Dialog as MuiDialog, IconButton,} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import './CustomDialog.scss'
import {Close} from '@mui/icons-material'

function CustomDialog({
                          className,
                          title,
                          caption = '',
                          children,
                          open,
                          onClose,
                          headerIcon = true,
                      }: {
    className: string,
    title: string,
    caption?: string | ReactElement,
    children: ReactNode,
    open: boolean,
    onClose: () => void,
    headerIcon?: boolean
}) {
    return (
        <MuiDialog
            open={open}
            onClose={onClose}
            classes={{
                root: 'custom-dialog-root',
                paper: 'custom-dialog-container',
            }}
            className={className}
            onClick={(event) =>
                event.stopPropagation()
            }
        >
            <div className="custom-dialog">
                <div className="dialog-header">
                    {headerIcon && <InfoIcon className="dialog-info-icon"/>}
                    <div>
                        <div className="dialog-title">{title}</div>
                        <div className="dialog-caption">{caption}</div>
                    </div>

                    <IconButton className="dialog-close" onClick={onClose} data-testid="close-btn">
                        <Close/>
                    </IconButton>
                </div>
                <div className="dialog-body">{children}</div>
            </div>
        </MuiDialog>
    )
}

export default CustomDialog
