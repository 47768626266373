import {createApi} from '@reduxjs/toolkit/dist/query/react'
import {ITEMLIST_API_ENDPOINT} from '../constants/backendUrls'
import {axiosBaseQuery} from '../rtkQueryUtils'
import {IdType} from '../util/models/IdType'
import {ItemList} from './model/ItemList'
import {ItemListWithItems} from './model/ItemListWithItems'
import {UpdatedItemList} from './model/UpdatedItemList'
import {setSnackbarMessage} from '../genericComponents/commonSlice'
import {GenericRequestResponse} from '../service/serviceRequests/rtkServiceRequestApi'

export const ITEM_LISTS = 'ItemLists'
export const ITEM_LIST = 'ItemList'

export const itemListAPI = createApi({
    reducerPath: 'itemListAPI',
    keepUnusedDataFor: 0,
    tagTypes: [ITEM_LISTS, ITEM_LIST],
    baseQuery: axiosBaseQuery({baseUrl: `${ITEMLIST_API_ENDPOINT}/api/v1/datx/`}),
    endpoints: (builder) => ({
        getItemLists: builder.query<ItemList[], IdType | undefined>({
            query: (guildId) => ({
                url: `guilds/${guildId}/itemlists/me`,
                method: 'GET'
            }),
            providesTags: [ITEM_LISTS, ITEM_LIST]
        }),
        updateItemList: builder.mutation<any, { listId: IdType, data: UpdatedItemList }>({
            query: ({listId, data}) => ({
                url: `itemlists/${listId}`,
                method: 'PATCH',
                data
            }),
            transformErrorResponse: () => 'Something went wrong during your list update',
            invalidatesTags: [ITEM_LISTS, ITEM_LIST]
        }),

        updateItemsOfItemList: builder.mutation<any, { listId: IdType, data: UpdatedItemList }>({
            query: ({listId, data}) => ({
                url: `itemlists/${listId}/items`,
                method: 'PATCH',
                data
            }),
            transformErrorResponse: () => 'Something went wrong during your list update',
            invalidatesTags: [ITEM_LISTS, ITEM_LIST]
        }),

        deleteItemsFromItemList: builder.mutation<any, { listId: IdType, data: UpdatedItemList }>({
            query: ({listId, data}) => ({
                url: `itemlists/${listId}/items`,
                method: 'DELETE',
                data
            }),
            transformErrorResponse: () => 'Something went wrong during your list update',
            invalidatesTags: [ITEM_LISTS, ITEM_LIST]
        }),
        duplicateItemList: builder.mutation<any, { guildId: IdType, itemListId: IdType }>({
            query: ({guildId, itemListId}) => ({
                url: `guilds/${guildId}/itemlists/${itemListId}/duplicate`,
                method: 'POST'
            }),
            transformErrorResponse: () => 'Something went wrong during your list duplication',
            invalidatesTags: [ITEM_LISTS, ITEM_LIST]
        }),
        deleteItemLists: builder.mutation<any, IdType[]>({
            query: (itemListIds) => ({
                url: 'itemlists',
                method: 'DELETE',
                data: JSON.stringify(itemListIds)
            }),
            transformErrorResponse: () => 'Something went wrong during your list deletion',
            invalidatesTags: [ITEM_LISTS, ITEM_LIST]
        }),
        getPersonalItemLists: builder.query<ItemList[], IdType | undefined>({
            query: (guildId) => ({
                url: `guilds/${guildId}/itemlists/me/personal`,
                method: 'GET'
            })
        }),
        mergeItemLists: builder.mutation<any, { guildId: IdType, itemListIds: IdType[] }>({
            query: ({guildId, itemListIds}) => ({
                url: `guilds/${guildId}/itemlists/merge`,
                method: 'POST',
                data: JSON.stringify(itemListIds)
            }),
            transformErrorResponse: () => 'There is an error merging the list. Please try again.',
            invalidatesTags: [ITEM_LISTS, ITEM_LIST]
        }),
        createItemList: builder.mutation<any, { guildId: IdType, name: string, items?: IdType[] }>({
            query: ({guildId, name, items}) => ({
                url: 'itemlists',
                method: 'POST',
                data: {
                    guildId: guildId,
                    name: name,
                    items: items
                }
            }),
            transformErrorResponse: () => 'There is an error creating the list. Please try again.',
            invalidatesTags: [ITEM_LISTS, ITEM_LIST]
        }),
        shareItemList: builder.mutation<any, IdType>({
            query: (itemListId) => ({
                url: `itemlists/${itemListId}/share`,
                method: 'PATCH'
            }),
            transformErrorResponse: () => 'There is an error updating the list. Please try again.',
            invalidatesTags: [ITEM_LISTS, ITEM_LIST]
        }),
        unshareItemList: builder.mutation<any, IdType>({
            query: (itemListId) => ({
                url: `itemlists/${itemListId}/unshare`,
                method: 'PATCH'
            }),
            transformErrorResponse: () => 'There is an error updating the list. Please try again.',
            invalidatesTags: [ITEM_LISTS, ITEM_LIST]
        }),
        getItemList: builder.query<
            ItemListWithItems,
            { pageNumber?: number, pageSize?: number, listId: IdType | undefined }
        >({
            query: ({pageNumber = 0, pageSize = 100, listId}) => ({
                url: `itemlists/${listId}?page=${pageNumber}&size=${pageSize}`,
                method: 'GET'
            }),
            providesTags: [ITEM_LISTS, ITEM_LIST]
        }),
        getItemListAllPages: builder.query<ItemListWithItems, { listId: IdType | undefined, size?: number }>({
            queryFn: async ({listId, size = 100}, {dispatch}, extraOptions, baseQuery,) => {
                try {
                    const initCallResult = await baseQuery({
                        url: `itemlists/${listId}?page=0&size=${size}`,
                        method: 'GET'
                    })

                    const itemListResult = (initCallResult as GenericRequestResponse<ItemListWithItems>).data
                    const promises = []
                    for(let page = 1; page < itemListResult.totalPages; page++) {
                        const result = baseQuery({
                            url: `itemlists/${listId}?page=${page}&size=${size}`,
                            method: 'GET'
                        })

                        promises.push(result)
                    }

                    const allResults = await Promise.all(promises)
                    allResults.forEach(result => {
                        const itemListPageResult = (result as GenericRequestResponse<ItemListWithItems>)
                        itemListResult.elements.push(...(itemListPageResult?.data?.elements ?? []))
                    })

                    return {data: itemListResult}

                } catch (error: any) {
                    dispatch(setSnackbarMessage(`Error getting all pages of itemsList ${listId}`))
                    return {error: error?.response}
                }
            },
        }),
    })
})

export const {
    useGetItemListsQuery,
    useMergeItemListsMutation,
    useGetPersonalItemListsQuery,
    useDuplicateItemListMutation,
    useDeleteItemListsMutation,
    useUpdateItemListMutation,
    useUpdateItemsOfItemListMutation,
    useDeleteItemsFromItemListMutation,
    useCreateItemListMutation,
    useShareItemListMutation,
    useUnshareItemListMutation,
    useGetItemListQuery,
    useGetItemListAllPagesQuery
} = itemListAPI
