import {IdType} from '../../util/models/IdType'

export interface SearchScope {
    objId: IdType,
    entryPointId: string,
    label: string,
}

export const searchScopesMock: SearchScope[] = [
    {
        objId: '245170226',
        entryPointId: 'SearchByAll',
        label: 'Search by all',
    },
]