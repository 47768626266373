import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import '../ServiceRequestFormTemplate.scss'
import {CardTitle} from '../../../../../genericComponents/typographie/CardTitle'
import {AnyObject} from 'yup'
import {ServiceRequestStep} from '../../../model/ServiceRequestStep'
import {SuccessChip} from '../../../../../genericComponents/customChip/SuccessChip'

export const ServiceRequestOneStep = <T extends AnyObject>({step, currentStepIndex, currentStep, index}: {
    step: ServiceRequestStep<T>,
    currentStep?: ServiceRequestStep<T>
    currentStepIndex: number,
    index: number
}) => {
    const disabled = currentStepIndex < index
    const error = currentStep?.error ?? false
    const success = currentStepIndex > index && !error

    return <div
        className={'service-request-one-step ' + (success ? 'success' : '') + (error ? 'error' : '') + (disabled ? 'disabled' : '')}>
        {!error && !success && <span className="step-header-icon step-header-number">{index + 1}</span>}
        {success && <span className="step-header-icon step-header-success"><CheckRoundedIcon/></span>}
        {error && <span className="step-header-icon step-header-error"><CloseRoundedIcon/></span>}
        <CardTitle title={step.name}>{step.name}</CardTitle>
        {success && <SuccessChip label="Completed"/>}
    </div>
}

export const ServiceRequestStepsHeader = <T extends AnyObject>({currentStepIndex, steps, currentStep}: {
    steps: ServiceRequestStep<T>[],
    currentStep?: ServiceRequestStep<T>
    currentStepIndex?: number,
}) => {
    return <div className="service-request-step-header">
        {steps.map((step, index) => <ServiceRequestOneStep step={step}
                                                           currentStep={currentStep}
                                                           currentStepIndex={currentStepIndex ?? 0}
                                                           index={index}/>)}
    </div>
}