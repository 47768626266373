import {CategoryConsentType, CookiesCategory, CookieVendor} from '../CookiesTypes'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import {AllCookiesDetails} from '../CookiesData'
import {VendorCookieAccordion} from './Accordion/VendorCookieAccordion'
import {CookiesButtonsSection} from './CookiesButtonsSection'
import {useFormik} from 'formik'
import {AllowAllVendorsSwitch} from './AllowAllVendorsSwitch'
import {ChangeEvent} from 'react'

export const CookiesVendorsDialog = ({
                                         openDialogType,
                                         categoryVisited,
                                         handleSelectedVendors,
                                         initialCookieCategory,
                                     }: {
    categoryVisited: CookiesCategory,
    openDialogType: (category: undefined) => void,
    handleSelectedVendors: (categoryConsent: CategoryConsentType) => void
    initialCookieCategory: CategoryConsentType
}) => {
    const formik = useFormik<{
        categoryConsent: CategoryConsentType
    }>({
        initialValues: {
            categoryConsent: initialCookieCategory
        },
        onSubmit: values => {/**/
        }
    })

    const handleCategoryConsent = () => {
        //If no vendor in a category is selected, its corresponding switch in the category dialog will be unchecked.
        //conversely, if there is at least a selected vendor, its corresponding switch in the category dialog will be checked.
        const userConsent = Object.values(formik.values.categoryConsent.cookies).some(vendor => vendor.userConsent)
        updateCategoryConsent(userConsent)
    }

    const handleUnselectCategory = () => {
        const categoryConsent = formik.values.categoryConsent
        updateAllVendorsConsent(false)
        updateCategoryConsent(false)
    }

    const updateCategoryConsent = (userConsent: boolean) => {
        formik.values.categoryConsent.userConsent = userConsent
        //formik.setFieldValue('categoryConsent', cookiesCategoryConsent)
        handleSelectedVendors(formik.values.categoryConsent)
    }

    const updateAllVendorsConsent = (isChecked: boolean) => {
        const cookies = formik.values.categoryConsent.cookies
        Object.keys(cookies).forEach(cookie => {
            cookies[cookie as CookieVendor] = {userConsent: isChecked}
        })
    }

    const handleAllowAllVendorsSwitch = (e: ChangeEvent<HTMLInputElement>) => {
        formik.handleChange(e)
        updateAllVendorsConsent(e.target.checked)
    }

    const checkedAllowAllVendor = () => Object.values(formik.values.categoryConsent.cookies).every(vendor => vendor.userConsent)

    return <div className="cookies-settings-dialog-content">
        <div className="title-section">
            <IconButton className="icon-button" data-testid="back-to-category-dialog"
                        onClick={() => openDialogType(undefined)}>
                <ChevronLeftIcon/>
            </IconButton>
            <span>Vendor List</span>
        </div>


        {/*Include Vendor Search if needed.*/}
        {/*<VendorsSearch/>*/}

        <AllowAllVendorsSwitch category={categoryVisited} isChecked={checkedAllowAllVendor()}
                               handeSwitch={handleAllowAllVendorsSwitch}/>

        <div className="manage-consent-preferences">
            <div className="management-section">
                {categoryVisited && AllCookiesDetails
                    .find(category => category.category === categoryVisited)?.vendorDetails
                    .map(({type, vendorList}) =>
                        <VendorCookieAccordion key={`vendor-${type}`}
                                               category={categoryVisited}
                                               type={type}
                                               vendorDetails={vendorList}
                                               handleSwitch={formik.handleChange}
                                               checkedSwitch={formik.values.categoryConsent.cookies[type]?.userConsent || false}
                                               inCategoryDialog={false}
                        />
                    )}
            </div>
        </div>
        <CookiesButtonsSection inCategoryDialog={false}
                               category={categoryVisited}
                               rejectSelectedVendors={handleUnselectCategory}
                               handleSelectedVendors={handleCategoryConsent}
        />
    </div>
}