import React from 'react'
import {AddShoppingCart, KeyboardArrowRight} from '@mui/icons-material'
import './ServiceCard.scss'
import {Button, CardActionArea} from '@mui/material'
import {Service} from '../model/Service'

export const ServiceCard = ({service, handleButtonClick}: {
    service: Service,
    handleButtonClick: () => void,
}) => {
    const {title, summary, description, available, logoPath, buttonName} = service
    return (
            <div className={`catalogue-service-card ${available ? '' : 'unavailable'}`} key={service.title}
                 data-testid={service.title}>
                <div className="card-content">
                    <img src={logoPath} alt={title} className="service-card-logo"/>
                    <div className="card-title">
                        {title}
                    </div>
                    <div className="card-summary">
                        {summary}
                    </div>
                    <div className="card-description">
                        {description}
                    </div>
                </div>
                {available && <Button className="card-button-available" onClick={handleButtonClick}
                                      data-testid={`addShoppingCart ${service.title}`}>
                    <p className = "card-button-name-available">{buttonName}</p>
                </Button>
                }
                {!available &&
                    <Button className="card-button-unavailable">
                        <p  className = "card-button-name-unavailable">Coming Soon</p>
                    </Button>
                }
            </div>
    )
}