import * as React from 'react'
import {ReactElement, useEffect, useRef, useState} from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import './SearchResultDataTable.scss'
import {useTypedSelector} from '../../../../util/store'
import {SearchItem} from '../../../model/SearchItem'
import {IdType} from '../../../../util/models/IdType'
import {TextWithTitle} from '../../../../genericComponents/typographie/TextWithTitle'
import {SearchResultDataTableHeader} from './SearchResultDataTableHeader'
import {ItemRowHeaderCard} from '../ItemRowCard/ItemRowHeaderCard'
import {ItemTypeIcon} from '../ItemRowCard/ItemMainProperties/ItemTypeIcon'
import {ItemDefinedByComponent} from '../ItemRowCard/ItemMainProperties/ItemDefinedByComponent'
import ItemCardProperty from '../ItemRowCard/generic/ItemRowCardProperty'
import {
    ItemTechnicalClassificationComponent
} from '../ItemRowCard/ItemMainProperties/ItemTechnicalClassificationComponent'


export type SearchResultDataTableRowModel = {
    PartNumber: ReactElement;
    DatxId: ReactElement;
    NameTechnicalNode: ReactElement;
    ItemType: ReactElement;
    NameDefinedBy: ReactElement;
    id: IdType;
    [key: string]: string | ReactElement;
}


export interface SearchResultDataTableColumnModel {
    dataKey: string;
    label: string;
    numeric?: boolean;
    width: number;
    sortable?: boolean;
}

export default function SearchResultDataTable({
                                                  definitionsToDisplay,
                                                  list,
                                                  addToMultiSelect,
                                                  removeFromMultiSelect,
                                                  isSelected,
                                                  handleAddSingleItemToList,
                                                  highlightPhrase,
                                                  noItemMessages = ''
                                              }: {
    definitionsToDisplay: { id: IdType, name: string }[],
    list: SearchItem[],
    isSelected: (item: SearchItem) => boolean,
    addToMultiSelect: (item: SearchItem) => void,
    removeFromMultiSelect: (item: SearchItem) => void
    handleAddSingleItemToList: (item: SearchItem) => void
    highlightPhrase?: string | null,
    noItemMessages?: string
}) {
    const headerRef = useRef<any>(null)
    const {drawerOpen} = useTypedSelector((state) => state.header)
    const [stickyHeaderWidth, setStickyHeaderWidth] = useState<number>(0)
    const [stickyHeaderLeftOffSet, setStickyHeaderLeftOffSet] = useState<number>(0)
    useEffect(() => {
        if (drawerOpen) {
            setStickyHeaderLeftOffSet(600) // drawer width 600px
        } else {
            setStickyHeaderLeftOffSet(0)
        }
        setStickyHeaderWidth(document.getElementById('item-table-header')?.offsetWidth ?? 0)
    }, [drawerOpen])

    useEffect(() => {
        const scrollableHeader = document.getElementById('item-table-header')
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                const hiddenHeader = document.querySelector('.sticky-header')
                if (!entry.isIntersecting) {
                    setStickyHeaderWidth(document.getElementById('item-table-header')?.offsetWidth ?? 0)
                    hiddenHeader?.classList.add('sticky-header-visible')
                } else {
                    hiddenHeader?.classList.remove('sticky-header-visible')
                }
            })
        })

        if (scrollableHeader) {
            observer.observe(scrollableHeader)
        }
    })

    const columns: SearchResultDataTableColumnModel[] = [
        {
            width: 360,
            label: 'Part Number',
            dataKey: 'PartNumber',
            sortable: true
        },
        {
            width: 200,
            label: 'Defined By',
            dataKey: 'NameDefinedBy',
            sortable: true
        },
        {
            width: 160,
            label: 'DATX ID',
            dataKey: 'DatxId',
        },
        {
            width: 216,
            label: 'Technical Classification',
            dataKey: 'NameTechnicalNode',
            sortable: true
        },
        {
            width: 96,
            label: 'Item Type',
            dataKey: 'ItemType',
        }
    ]

    if (definitionsToDisplay) {
        columns.push(...definitionsToDisplay.map(definition => ({
            width: 160,
            label: definition.name,
            dataKey: definition.id,
            sortable: true
        })))
    }




    const rows: SearchResultDataTableRowModel[] = list?.map((item) => {
        const row: SearchResultDataTableRowModel = {
            PartNumber: <ItemRowHeaderCard item={item} key={item.id}
                                           addToMultiSelect={addToMultiSelect}
                                           removeFromMultiSelect={removeFromMultiSelect}
                                           isSelected={isSelected}
                                           handleAddSingleItemToList={handleAddSingleItemToList}
                                           highlightPhrase={highlightPhrase}
            />,
            DatxId: <ItemCardProperty value={item.id} dataTestId={'datx-id-property'}/>,
            NameTechnicalNode: <ItemTechnicalClassificationComponent item={item}/>,
            ItemType: <ItemTypeIcon item={item}/>,
            NameDefinedBy: <ItemDefinedByComponent item={item}/>,
            id: item.id
        }

        item?.attributes?.forEach(attribute => {
            const unit = attribute?.unit ?? ''
            if (attribute.attributeDefinitionId && attribute?.value?.value) {
                row[attribute.attributeDefinitionId] = <TextWithTitle text={attribute?.value?.value + ' ' + unit} />
            }
        })
        return row
    })

    return (
        <>
            <Table size="small" ref={headerRef} style={{
                width: stickyHeaderWidth,
                left: stickyHeaderLeftOffSet
            }} className="sticky-header">
                <TableHead>
                    <SearchResultDataTableHeader  columns={columns}/>
                </TableHead>
            </Table>
            <div
                className="scrollable-item-table-container"
                onScroll={event => {
                    if (drawerOpen) {
                        headerRef.current.style.left = `${stickyHeaderLeftOffSet - event.currentTarget.scrollLeft}px`
                    } else {
                        headerRef.current.style.left = `-${event.currentTarget.scrollLeft}px`
                    }
                }}>
                <Table size="small" className="item-search-data-table">
                    <TableHead id="item-table-header">
                        <SearchResultDataTableHeader  columns={columns}/>
                    </TableHead>
                    <TableBody>
                        {list.length === 0 && <TableRow>
                            <div className="item-search-data-table-no-data-message">{noItemMessages}</div>
                        </TableRow>}
                        {rows?.map((row) =>
                            <TableRow className="item-search-data-table-row" tabIndex={-1} key={row.id}>
                                {columns.map((column) => {
                                    const value = row[column.dataKey]
                                    return <>
                                        <TableCell className="item-search-data-table-cell" style={{
                                            width: column.width,
                                        }} key={column.dataKey}>
                                            {value ?? ''}
                                        </TableCell>
                                    </>
                                })}
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
        </>
    )
}
