import './legalsPage.scss'

function LegalNotices() {

  return (
    <div className="content-body">
        <p><strong><u>Legal notices</u></strong></p><p><strong><u></u></strong></p><p></p><p><strong>THALES GLOBAL SERVICES SAS</strong>, registered in the Versailles Trade and Companies Register under number 424 704963 and having its registered office located at 19-21, avenue Morane Saulnier, 78140 Vélizy-Villacoublay,</p><p></p><p><strong>Name of the Platform: </strong></p><ul><li>DATX</li></ul><p></p><p><strong>Website is published by: </strong></p><ul><li>Thales Global Services France SAS</li></ul><p></p><p><strong>Le Directeur of publication is: </strong></p><ul><li>Yves le Nevez. <u></u></li></ul><p><u></u></p><p><strong>Host of the Platform: </strong></p><ul><li>Thales Digital Factory, SAS, registered in the Paris Trade and Companies Register under number 518 928973 and having its registered office located at 54-56 Avenue Hoche, 75008 Paris</li></ul><p></p><p><strong>Phone number: </strong></p><ul><li>+33 (1) 57 77 80 00</li></ul>
        <hr/>
        <p><strong><u>Mentions légales</u></strong></p><p><strong><u></u></strong></p><p></p><p><strong>THALES GLOBAL SERVICES SAS</strong>, société par actions simplifiée, immatriculée au Registre du Commerce et des Sociétés de Versailles sous le numéro 424 704963, dont le siège social est sis 19-21, avenue Morane Saulnier, 78140 Vélizy-Villacoublay, France, </p><p></p><p><strong>Nom de la Plateforme : </strong></p><ul><li>DATX</li></ul><p></p><p><strong>Le site est publié par: </strong></p><ul><li>Thales Global Services France SAS</li></ul><p></p><p><strong>Le Directeur de la publication est : </strong></p><ul><li>Monsieur Yves le Nevez. <u></u></li></ul><p><strong><u></u></strong></p><p><strong>Hébergement de la Plateforme: </strong></p><ul><li>Au sein de Thales Digital Factory, SAS, ayant son siège social situé au 54/56 Avenue Hoche 75008 PARIS, France et immatriculée au Registre du Commerce et des Sociétés de Paris sous le numéro 518 928973.</li></ul><p></p><p><strong>Numéro de téléphone: </strong></p><ul><li>+33 (1) 57 77 80 00</li></ul>
    </div>
  )
}

export default LegalNotices