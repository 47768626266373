import React from 'react'
import {GroupByPackRule} from '../QuotationRule'
import {ServiceRequest} from '../../ServiceRequest'

export const GroupByPackRuleComponent = ({rule, quantity}: {rule: GroupByPackRule, quantity: number}) => {

    const packNumber = rule.getNumberOfPack(quantity)

    return <div className="quotation-detail-rule-structure">
        <p className="quotation-detail-description">Extraction of {rule.packSize} Items</p>
        <p className="quotation-detail-unit-price">{rule?.subRule?.subRule?.price} </p>
        <p className="quotation-detail-nb-object">{packNumber}</p>
        <p className="quotation-detail-min-quantity">{rule?.subRule?.minQuantity}</p>
        <p className="quotation-detail-total">{rule.calculate(0, quantity)}</p>
    </div>
}