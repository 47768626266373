import React from 'react'
import './ConfirmationInformationPackageAccessPopup.scss'
import {Button} from '@mui/material'
import {Link} from 'react-router-dom'
import {ROUTES} from '../../../../../constants/routing'
import { useDatxPathGenerator } from '../../../../../util/routing'

export const ConfirmationInformationPackageAccessPopup = (
    {requestId, close}: { requestId?: string , close: () => void}) => {

    const generatePath = useDatxPathGenerator()

    return (
        <div className="service-request-popup">
            <div className="step-description">

                Your request has been been sent for validation.<br/><br/>

                <span>Request ID : </span>
                <b>
                    <span data-testid= "requestId">{requestId}</span>
                </b><br/><br/>

                This service is a commercial process requiring interaction with the DATX commercial team.<br/>
                After your request is approved a DATX representative will contact you soon on your request.<br/><br/>

                Please note that requests for Access to Information Package are typically valid for 12 Months (unless specified otherwise).<br/><br/>
                You may track your request on the <Link data-testid= "myRequestLink" to={generatePath(ROUTES.userServiceRequests.path)}>My Service Requests</Link> page
            </div>


            <div className="popup-footer">
                <Button variant="contained" onClick={close}>CLOSE</Button>
            </div>
        </div>
    )
}