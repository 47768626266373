import {
    Autocomplete,
    Button,
    Divider,
    FormControl,
    TextField,
    Typography
} from '@mui/material'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import React, {useState} from 'react'
import '../common/ItemSharing.scss'
import {useGetOrganizationsByGuildIdQuery} from '../../../organization/rtkOrganizationApi'
import {ItemOrganization} from '../../../item/model/ItemOrganization'
import {LoadingButton} from '@mui/lab'
import {useUpdateItemsSharingMutation} from '../../rtkGuildApi'
import {useCurrentGuildContext} from '../../../util/routing'
import SharingCreatedSuccessfully from './SharingCreatedSuccessfully'

const AddSharingItems = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const targetGuildName = location?.state?.targetGuildName ?? 'Target Guild'
    const {sharingId} = useParams()

    const [currentGuildContext] = useCurrentGuildContext()
    const [organization, setOrganization] = useState<ItemOrganization | null>(null)

    const {
        data: organizations = [],
        isFetching: isOrganizationsLoading
    } = useGetOrganizationsByGuildIdQuery(currentGuildContext!.guildId, {skip: !currentGuildContext || !currentGuildContext!.guildId})

    const [updateItemsSharing,
        updateSharingResult] = useUpdateItemsSharingMutation()


    const handleSubmit = () => {
        updateItemsSharing({sharingId: sharingId ?? '', organizationId: organization?.id ?? ''})
    }

    const shareFormPage = () => {
        return (
            <div className="page-container">
                <div className="guild-form-title-container">
                    <h5 id="shareToOtherGuildTitle" className="page-title">
                        Add Sharing Items with <span>{targetGuildName}</span>
                    </h5>
                    <Divider className="divider-title"/>
                </div>
                <div>By sharing selected organisation’s items to the guild, the guild will receive view access to the
                    selected
                    organisation’s items.
                </div>

                <div className="management-section">
                    <div className="management-header">
                        <h3 className="management-header-title">Items Defined By <span
                            className="mandatory-character">*</span>
                        </h3>
                    </div>

                    <FormControl className="form-select">
                        <Autocomplete
                            disablePortal
                            id="attachOrganizationAutoComplete"
                            className="auto-complete-organization"
                            options={organizations}
                            value={organization}
                            onChange={(event, value: ItemOrganization | null) => {
                                setOrganization(value)
                            }}
                            renderInput={(params) =>
                                <TextField {...params}
                                           placeholder={isOrganizationsLoading ? 'Loading...' : 'Select Organisation'}
                                />
                            }
                            disabled={!organizations.length || !sharingId}
                            renderOption={(props, item) =>
                                <li {...props} data-testid={item.id} key={item.id}> {item.label}</li>}
                        />
                    </FormControl>
                </div>

                <Divider className="divider"/>
                <div className="step-footer">
                    <Button onClick={() => navigate(-1)} className="back-btn">Back</Button>
                    <LoadingButton
                        data-testid="shareToGuildButton"
                        variant="contained"
                        color="primary"
                        disabled={!organization || !sharingId}
                        onClick={handleSubmit}
                        loading={updateSharingResult.isLoading}
                    >
                        Share
                    </LoadingButton>
                </div>
            </div>
        )
    }

    const isUpdateSharingSuccessful = !updateSharingResult.isUninitialized && updateSharingResult.isSuccess && updateSharingResult.data

    if (!isUpdateSharingSuccessful)
        return <>{ shareFormPage() }</>

    return (
        <>
            <SharingCreatedSuccessfully targetGuildName={targetGuildName}
                                        organization={organization}
                                        updatedTargetGuildName={updateSharingResult.data.targetGuildName}/>
        </>
    )
}
export default AddSharingItems