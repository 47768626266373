import {IdType} from '../models/IdType'
import {SidebarClassificationNode} from '../../header/models/SidebarClassificationNode'

export const getPathToClassification = (leafId: IdType, node: SidebarClassificationNode): SidebarClassificationNode[] => {
    if(node.id === leafId) { // stop condition
        return [node]
    }

    // search in each child, the leafId
    const childNodesList = node?.children?.map((subNode) => {
        return getPathToClassification(leafId, subNode)
    }).filter(subNode => subNode && subNode.length > 0) // remove the leaf where we didn't find the leaf

    if(!childNodesList || childNodesList.length === 0) return []

    // if one child have the leaf, take the first one result and add the current node at the beginning
    const pathToLeaf = childNodesList[0]
    pathToLeaf?.unshift(node)

    return pathToLeaf
}