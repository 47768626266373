import './structure/ServiceRequest.scss'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import {Link} from 'react-router-dom'

export const ServiceRequestFormTitle = ({title, previousUrl}: { title: string, previousUrl: string }) => {
    return <div className="service-request-form-title">
        <Link to={previousUrl} className="service-request-form-title-back"
              data-testid="service-request-form-title-back">
            <ChevronLeftRoundedIcon></ChevronLeftRoundedIcon>
        </Link>
        <h2 className="page-title-service-request" title={title}>{title}</h2>
    </div>
}