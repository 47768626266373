import {IdType} from '../../util/models/IdType'
import {GuildContext} from '../../header/models/GuildContext'
import {DisplaySetConfiguration} from './DisplaySetConfiguration'

export interface User {
    id: IdType
    oauthProviderId: IdType
    email: string,
    guildList: GuildContext[],
    displaySetConfigurations?: DisplaySetConfiguration[]
}

// for any new field that can be updated, add it here
export const buildUserProfileUpdate = ({user, displaySetConfigurations}: {
    user: User, displaySetConfigurations?: DisplaySetConfiguration[]
}): UserProfileUpdate => {
    return {
        oauthProviderId: user.oauthProviderId,
        email: user.email,
        displaySetConfigurations: displaySetConfigurations ?? user.displaySetConfigurations
    }
}

export interface UserProfileUpdate {
    oauthProviderId: IdType,
    email: string,
    displaySetConfigurations?: DisplaySetConfiguration[]
}

