import React, {ChangeEvent, SyntheticEvent, useState} from 'react'
import './UploadListStep.scss'
import {Button, Divider, IconButton, InputLabel, TextField} from '@mui/material'
import {useNavigate} from 'react-router-dom'
import {setSnackbarMessage} from '../../../genericComponents/commonSlice'
import {LoadingButton} from '@mui/lab'
import {useDispatch} from 'react-redux'
import * as XLSX from 'xlsx'
import {ItemListExcelFileModel} from '../../model/ItemListExcelFileModel'
import {IdType} from '../../../util/models/IdType'
import {FileUpload, HighlightOff} from '@mui/icons-material'
import {ItemList} from '../../model/ItemList'


export const UploadListForm = ({handleImportAction, existingLists, isLoading}:
                                   {
                                       handleImportAction: (listName: string, itemIds: IdType[] | null) => void,
                                       existingLists: ItemList[],
                                       isLoading: boolean
                                   }) => {
    const [file, setFile] = useState<File | null>(null)
    const [itemIds, setItemIds] = useState<IdType[] | null>(null)
    const [listNameValue, setListNameValue] = useState('')
    const [listNameErrorMessageValue, setListNameErrorMessageValue] = useState('')
    const navigate = useNavigate()

    const listNameErrorMessage = (newListName: string) => {
        if (!newListName) return 'List name should not be empty'
        if (existingLists.some((list) => list.name === newListName)) return 'List name already exists'
        return ''
    }
    const onInput = (e: ChangeEvent<HTMLInputElement>) => {
        setListNameValue(e.target.value.trim())
        setListNameErrorMessageValue(listNameErrorMessage(e.target.value))
    }

    return (
        <div className="service-request-form upload-form">
            <div className="step-header">
                <p>Import List</p>
                <Divider/>
            </div>
            <div className="step-description">
                <p>
                    With this functionality, you can quickly create a list by importing the identifier of the component.
                    This will save your time instead of selecting and adding item one by one to a list. You can import
                    using the DATX ID of the component.<br/><br/>
                    All you need is to upload a spreadsheet containing the required list of items.<br/>
                    You may use this <a href="/files/itemlist/Import-list.xlsx">import list data template</a> for an
                    example of how you can import your list.
                </p>
            </div>
            <InputLabel
                className="import-list-label"
                htmlFor="import-list-name-input"
                required
            >
                Name of new List
            </InputLabel>
            <TextField
                className="import-list-name-input-container"
                id="import-list-name-input"
                variant="outlined"
                onChange={onInput}
                error={!!listNameErrorMessageValue}
                helperText={listNameErrorMessageValue}
                fullWidth
                autoFocus
                required
            />
            <div className="step-import-upload-title">
                Upload Import List Data Template
            </div>
            <UploadListTemplate listName={listNameValue}
                                onFileSelection={setFile}
                                selectedFileName={file?.name ?? ''}
                                setItemIds={setItemIds}
                                data-testid="import-list-file-upload"/>

            <Divider/>

            <div className="step-footer">
                <Button onClick={() => navigate(-1)}>Back</Button>
                <LoadingButton loading={isLoading} variant="contained"
                               color="primary" disabled={!file || !itemIds}
                               data-testid="import-list-btn"
                               onClick={() => {
                                   const listNameErrorMsg = listNameErrorMessage(listNameValue)

                                   if (listNameErrorMsg) {
                                       setListNameErrorMessageValue(listNameErrorMsg)
                                   } else {
                                       handleImportAction(listNameValue, itemIds)
                                   }
                               }}>
                    Import List
                </LoadingButton>
            </div>
        </div>
    )

}

const UploadListTemplate = ({
                                selectedFileName,
                                onFileSelection,
                                setItemIds,
                            }: {
    listName: string, selectedFileName: string, onFileSelection: (file: File | null) => void,
    setItemIds: (itemIdsForListCreation: IdType[] | null) => void
}) => {
    const dispatch = useDispatch()
    const parseFile = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target?.files?.length) return

        const reader = new FileReader()
        reader.onerror = () => dispatch(setSnackbarMessage('Error on the file format. We are not able to parse it.'))
        reader.onabort = () => dispatch(setSnackbarMessage('Error downloading the file. Please try again'))
        reader.onload = (event) => {
            try {
                const buffer = event?.target?.result
                const workbook = XLSX.read(buffer, {type: 'array'})
                const sheetName = workbook.SheetNames[0]
                const sheet = workbook.Sheets[sheetName]
                const data = XLSX.utils.sheet_to_json<ItemListExcelFileModel>(sheet)
                const dataForService: IdType[] = data.slice(1).filter((record, i) => {
                    if (!record.DATX_ID) {
                        dispatch(setSnackbarMessage(`A DATX Id is missing on line ${i + 3}. The line will be be ignored.`))
                        return false
                    }
                    return true
                }).map((record) => { return record.DATX_ID })
                setItemIds(dataForService)
            } catch (e: any) {
                dispatch(setSnackbarMessage('Error on the file format. We are not able to parse it.'))
            }
        }

        const tempFile = event.target.files[0]
        reader.readAsArrayBuffer(tempFile)
        onFileSelection(tempFile)
    }

    const unselectFile = (event: SyntheticEvent) => {
        onFileSelection(null)
        setItemIds(null)
        event.preventDefault()
    }

    return (
        <div>
            <LoadingButton component="label" variant="outlined" color="primary"
                           endIcon={!selectedFileName ? <FileUpload/> :
                               <IconButton onClick={unselectFile}><HighlightOff/></IconButton>}>
                {selectedFileName || 'Upload file...'}
                <input name="upload-file" id="upload-file" onChange={parseFile} hidden type="file"
                       accept=".xlsx"/>
            </LoadingButton>
        </div>
    )
}