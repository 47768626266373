import React from 'react'
import { Box, MenuItem } from '@mui/material'
import LoadingSpinner from '../../../genericComponents/LoadingSpinner'
import {NoData} from '../../../genericComponents/NoData'
import NotificationItem from './NotificationItem/NotificationItem'
import { Notification } from '../../models/Notification'


const NotificationsPanel = ({notifications, isLoading} : {notifications: Notification[], isLoading: boolean}) => {

    return (
        <Box p={1} className="datx-notification-panel">
            {isLoading ? (
                <LoadingSpinner />
            ) : notifications?.length ? (
                notifications.map((notification:Notification) => (
                    <MenuItem
                        className="notification-panel-single-item"
                        key={notification.id}
                    >
                        {NotificationItem(notification)}
                    </MenuItem>
                ))
            ) : (
                <NoData message="No notifications available!" />
            )}
        </Box>
    )
}

export default NotificationsPanel
