import {ReactNode} from 'react'
import './CardContainer.scss'

const CardContainer = ({children}: {children: ReactNode}) => {
    return (
        <div className="card-container">
            <div className="card-content">{children}</div>
        </div>
    )
}

export default CardContainer
