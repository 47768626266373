import {Tab, Tabs} from '@mui/material'
import {NavLink} from 'react-router-dom'
import {ROUTES} from '../../../constants/routing'
import React, {SyntheticEvent, useMemo, useState} from 'react'
import LoadingSpinner from '../../../genericComponents/LoadingSpinner'
import SharingList from '../common/SharingList'
import {
    useCreateNewSharingMutation, useGetGuildSummaryQuery,
    useGetSharingFromSourceGuildQuery,
    useGetSharingToTargetGuildQuery
} from '../../rtkGuildApi'
import {GuildSharingSummary} from '../model/GuildSharingSummary'
import { useCurrentGuildContext, useDatxPathGenerator } from '../../../util/routing'
import './GuildSharing.scss'
import {validate as uuidValidate} from 'uuid'
import {AddGuildSharing} from '../addGuildSharing/AddGuildSharing'

interface Shares {
    toMyGuild: GuildSharingSummary[]
    toOtherGuilds: GuildSharingSummary[]
}

const GuildSharing = ({toMyGuild}: { toMyGuild: boolean }) => {
    const generatePath = useDatxPathGenerator()
    const [tabValue, setTabValue] = useState(toMyGuild ? 1 : 0)
    const [shares, setShares] = useState<Shares | null>(null)
    const [targetGuildId, setTargetGuildId] = useState('')

    const {currentData: guild} = useGetGuildSummaryQuery(targetGuildId, {skip: !uuidValidate(targetGuildId)})

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setTabValue(newValue)
    }

    const [currentGuildContext] = useCurrentGuildContext()
    const {data: sharedToMyGuild} = useGetSharingToTargetGuildQuery(currentGuildContext?.guildId)
    const {
        data: sharedToOtherGuild,
        isFetching,
        isLoading
    } = useGetSharingFromSourceGuildQuery(currentGuildContext?.guildId)

    const updateShares = () => {
        if (!sharedToMyGuild || !sharedToOtherGuild) return

        const updatedShares = {
            toMyGuild: sharedToMyGuild,
            toOtherGuilds: sharedToOtherGuild
        }
        setShares(updatedShares)
    }

    useMemo(() => {
        if (sharedToMyGuild && sharedToOtherGuild) updateShares()
    }, [sharedToMyGuild, sharedToOtherGuild])

    const displayedShares = toMyGuild ? shares?.toMyGuild : shares?.toOtherGuilds

    return <div className="sharing-page" data-testid="service-subscriptions-list-container">
        <div className="shared-to-guild-tab-container">
            <Tabs value={tabValue} onChange={handleChange}>
                <Tab data-testid="SharedToOtherGuildsTab" label="Shared to Other Guilds" component={NavLink}
                     className="sharing-tab"
                     to={generatePath(ROUTES.itemsSharedToOtherGuilds.path)}/>
                <Tab data-testid="SharedToMyGuildTab" label="Shared to My Guild" component={NavLink}
                     className="sharing-tab"
                     to={generatePath(ROUTES.itemsSharedToMyGuild.path)}/>
            </Tabs>

            {/*<TextField*/}
            {/*    className="search-shared-filter-input"*/}
            {/*    placeholder="Search by Guild"*/}
            {/*    value={search}*/}
            {/*    onChange={handleSearch}*/}
            {/*    variant="outlined"*/}
            {/*     InputProps={{*/}
            {/*        endAdornment:*/}
            {/*            <InputAdornment position="end">*/}
            {/*                <SearchIcon className="search-icon"/>*/}
            {/*            </InputAdornment>*/}
            {/*    }}*/}
            {/*/>*/}
        </div>
        {!toMyGuild && <AddGuildSharing guild={guild}
                                        targetGuildId={targetGuildId} setTargetGuildId={setTargetGuildId}
                                        currentGuildContext={currentGuildContext} />}
        {(isLoading || isFetching) ? <LoadingSpinner/> :
            <SharingList itemShares={displayedShares} toMyGuild={toMyGuild}/>}
    </div>
}

export default GuildSharing
