import React, {useEffect, useRef} from 'react'
import {SidebarClassificationNode} from '../models/SidebarClassificationNode'
import CustomTreeItem from './CustomTreeViewComponent'
import {TreeView} from '@mui/lab'
import {useTypedDispatch, useTypedSelector} from '../../util/store'
import {Drawer, IconButton} from '@mui/material'
import './ClassificationTreeDrawer.scss'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import {selectCurrentUser} from '../../user/rtkUserApi'
import {useSelector} from 'react-redux'
import {useGetDatxClassificationTreeQuery} from '../../search/rtkSearchApi'
import {Close} from '@mui/icons-material'
import {setDrawerOpen} from '../headerSlice'

const DrawerClassificationTree = () => {

    const user = useSelector(selectCurrentUser)

    const {drawerOpen} = useTypedSelector((state) => state.header)
    const dispatch = useTypedDispatch()
    const {data:classificationTree} = useGetDatxClassificationTreeQuery(undefined, {skip : !user})
    const [expanded, setExpanded] = React.useState<string[]>([])
    const containerRef = useRef<HTMLDivElement>(null)
    const previousScrollTopRef = useRef<number | null>(null)

    const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
        setExpanded((nodeIds))
    }

    useEffect(() => {
        const container = containerRef.current
        if (container && previousScrollTopRef.current !== null) {
            container.scrollTop = previousScrollTopRef.current
            previousScrollTopRef.current = null
        }
    }, [setExpanded])

    const handleScroll = () => {
        const container = containerRef.current
        if (container) {
            previousScrollTopRef.current = container.scrollTop
        }
    }

    const RichObjectTreeView = ({
                                    expanded,
                                }: { expanded: string[]}) => {

        const renderTree = (node: SidebarClassificationNode) => (
            <CustomTreeItem key={node.id} nodeId={node.id} label={node.label}
                            className="custom-tree-view-component-container-children-main"
                            data-testid={node.id}
            >
                {node?.children?.map((node: SidebarClassificationNode) => renderTree(node)) ?? null}
            </CustomTreeItem>
        )

        return (
            <div className="side-menu-container" ref={containerRef} onScroll={handleScroll}>
                <div className="side-menu-header">
                    <span className="side-menu-title">Browse by Item Classifications</span>
                    <IconButton
                        className="drawer-close"
                        size="small"
                        onClick={() => dispatch(setDrawerOpen(!drawerOpen))}
                    >
                        <Close/>
                    </IconButton>
                </div>
                <TreeView
                    expanded={expanded}
                    onNodeToggle={handleToggle}
                    className="side-menu-tree"
                    aria-label="rich object"
                    defaultCollapseIcon={<ArrowDropDownIcon/>}
                    defaultExpanded={['root']}
                    defaultExpandIcon={<ArrowRightIcon/>}
                >
                    {classificationTree?.children?.map((node) => renderTree(node))}
                </TreeView>
            </div>
        )
    }

    return (
        <Drawer
            className={'side-menu-drawer ' + (drawerOpen ? 'open' : '')}
            id="side-menu-drawer"
            variant="persistent"
            anchor="left"
            open={drawerOpen}
        >
            <RichObjectTreeView expanded={expanded}/>
        </Drawer>

    )
}

export default DrawerClassificationTree