import {ServiceRequestFormTitle} from '../../genericComponents/ServiceRequestFormTitle'
import {Button, FormControl, MenuItem, Select} from '@mui/material'
import React, {useState} from 'react'
import {ROUTES} from '../../../../constants/routing'
import {useDatxPathGenerator} from '../../../../util/routing'
import {Link} from 'react-router-dom'
import {ServiceRequestTemplate} from '../../genericComponents/structure/ServiceRequestTemplate'
import {ServiceRequestHeader} from '../../genericComponents/structure/ServiceRequestHeader'
import {ServiceRequestBody} from '../../genericComponents/structure/ServiceRequestBody'
import {ServiceRequestFooter} from '../../genericComponents/structure/ServiceRequestFooter'
import {ServiceRequestFormTitleDescription} from '../../genericComponents/ServiceRequestFormTitleDescription'
import {Label} from '../../../../genericComponents/typographie/Label'
import {Description} from '../../../../genericComponents/typographie/Description'
import {KeyboardArrowDown} from '@mui/icons-material'

export const SelectPrivateAttributeCreationType = () => {
    const generatePath = useDatxPathGenerator()

    const [attributeType, setAttributeType] = useState<string | undefined>(undefined)

    const attributeTypeMap = new Map([
        ['String', ROUTES.createStringAttribute.path],
        ['Document', ROUTES.createDocumentAttribute.path]
    ])

    return <ServiceRequestTemplate>
        <ServiceRequestHeader>
            <ServiceRequestFormTitle title="Create New Private Attribute"
                                     previousUrl={generatePath(ROUTES.managePrivateAttribute.path)}/>
            <ServiceRequestFormTitleDescription>
                In DATX, you are able to create different types of attributes to manage the data type of you choice
            </ServiceRequestFormTitleDescription>
        </ServiceRequestHeader>


        <ServiceRequestBody>
            <FormControl>
                <Label className="select-attribute-type-label" required
                       htmlFor="select-attribute-type">Select type of attribute that you would like to create</Label>
                <Select
                    data-testid="select-attribute-type"
                    labelId="select-attribute-type-label"
                    id="select-attribute-type"
                    className="select-attribute-type"
                    displayEmpty
                    value={attributeType}
                    size="small"
                    placeholder="Attribute Type"
                    onChange={(event) => setAttributeType(event?.target?.value)}
                    IconComponent={KeyboardArrowDown}
                    renderValue={(attribute: string) => attribute ??
                        <span className="placeholder">Attribute Type</span>}
                >
                    {Array.from(attributeTypeMap.keys()).map(attribute => <MenuItem
                            value={attribute}
                            key={attribute}
                            autoFocus={false}
                            data-testid={`select-attribute-type-option-${attribute}`}
                            sx={{fontSize: '14px'}}
                        >
                            {attribute}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>

            <div className="select-attribute-type-instructions">
                {attributeType ? <p className="sample-title">Sample Display Set</p> :
                    <Description className="empty-attribute-type-description">Sample of data presentation in DATX will be displayed based on
                        type of attribute selected.</Description>
                }
                {attributeType === 'String' &&
                    <img src="/images/services/PrivateString.svg" alt="Display of a private string attribute"
                         className="select-attribute-type-instruction"/>
                }
                {attributeType === 'Document' &&
                    <img src="/images/services/PrivateArtefact.svg" alt="Infographics of private document attribute"
                         className="select-attribute-type-instruction"/>
                }
            </div>
        </ServiceRequestBody>

        <ServiceRequestFooter>
            <Button component={Link} color="primary" variant="contained" type="submit"
                    disabled={!attributeType}
                    to={generatePath(attributeTypeMap.get(attributeType ?? ''))}
                    data-testid="stepper-next"
                    className="service-request-form-next">Next</Button>
        </ServiceRequestFooter>
    </ServiceRequestTemplate>
}