import React from 'react'
import {DisplaySet} from '../model/DisplaySet'
import {IdType} from '../../util/models/IdType'
import {AttributeDefinitionModel} from '../model/AttributeModel'
import {groupByDefinitionId, sortByDisplayOrder} from '../utils/sortedDefinitions'
import {DefinitionForDisplaySet} from '../definitionForDisplaySet/DefinitionForDisplaySet'

export const DefaultDisplaySetDataWidget = ({displaySet}: { displaySet: DisplaySet }) => {
    const attributes = displaySet.attributes
    const definitionsMap: Map<IdType, AttributeDefinitionModel> = groupByDefinitionId(attributes)
    const sortedDefinitions = sortByDisplayOrder([...definitionsMap.values()])

    return <>
        {sortedDefinitions.map(definition => <DefinitionForDisplaySet definition={definition} key={definition.attributeDefinitionId}></DefinitionForDisplaySet>)}
    </>
}
