import React, {ReactNode} from 'react'

export const OrganizationFakeDefinition = ({label, value, children}: {
    label: string,
    value?: string,
    children?: ReactNode
}) => {
    return <div className="display-set-definition">
        <span className="definition-name">{label}</span>
        <div className="display-set-attributes">
            {value && <span className="item-attribute-value">{value}</span>}
            {children}
        </div>
    </div>
}


