import React from 'react'
import {Icon} from '@mui/material'

const DisplaySetIcon = ({iconName}: {iconName?: string}) => {
    if (!iconName) return <></>
    return <Icon className="display-set-icon">{iconName}</Icon>
}

const DisplaySetTitle = ({title = 'Unknown information package', iconName}: {title?: string; iconName?: string}) => {
    return (
        <div className="display-set-title" data-testid="test-details-simple-attribute-displaySet">
            <DisplaySetIcon iconName={iconName}></DisplaySetIcon>
            {title === 'Conformity Links' ? 'Conformity' : title}
        </div>
    )
}

export default DisplaySetTitle
