import React, {useMemo} from 'react'
import {FormControl, TextField} from '@mui/material'
import './SearchFilterCard.scss'
import {DisplayFilterDTO, ValueOption} from '../model/DisplayFiltersDTO'
import {isNaN, useFormik} from 'formik'
import {number, object} from 'yup'

const RangeFilterInput = ({filter, setSelectableValues}: {
    filter: DisplayFilterDTO,
    setSelectableValues: React.Dispatch<React.SetStateAction<ValueOption[]>>,
}) => {

    const minRangeValue = Math.min(...(filter.values
        .map(option => parseFloat(option.value))
        .filter(value => !isNaN(value))))

    const maxRangeValue = Math.max(...(filter.values
        .map(option => parseFloat(option.value))
        .filter(value => !isNaN(value))))
    const sortValues = (a: ValueOption, b: ValueOption): number => {
        // if values could be numbers, compare them as number
        return +(a.value) - +(b.value)
    }

    const filterSelectableValues = (values: ValueOption[]) => {
        const sortedValues = [...values]
        sortedValues.sort(sortValues)
        const min = !isNaN(rangeFormik.values.rangeMin) ? rangeFormik.values.rangeMin : minRangeValue
        const max = !isNaN(rangeFormik.values.rangeMax) ? rangeFormik.values.rangeMax : maxRangeValue
        if (!min && !max) return sortedValues

        return sortedValues.filter(option => {
            const numberValue = parseFloat(option?.value)
            return option?.value !== undefined && numberValue >= min && numberValue <= max
        })

    }

    const validationSchema = object({
        rangeMin:  number().required().min(minRangeValue),
        rangeMax:  number().required().max(maxRangeValue)
    })

    const rangeFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            rangeMin: minRangeValue,
            rangeMax: maxRangeValue
        },
        validationSchema: validationSchema,
        onSubmit: () => {/*Do nothing*/}
    })

    useMemo(() =>
            setSelectableValues(filterSelectableValues(filter?.values ?? []))
        , [filter.values, rangeFormik.values.rangeMin, rangeFormik.values.rangeMax])

    return (
            <div className="search-filter-content-range">
                <FormControl>
                    <TextField
                        className="search-filter-input"
                        label={'Min'}
                        value={rangeFormik.values.rangeMin}
                        onChange={event => {
                            rangeFormik.setFieldValue('rangeMin', parseFloat(event.target.value))
                        }}
                        variant="outlined"
                        margin="normal"
                        type="number"
                        InputLabelProps={{
                            classes: {
                                root: 'cssLabel',
                            },
                        }}
                    />
                </FormControl>
                <FormControl>
                    <TextField
                        className="search-filter-input"
                        label={'Max'}
                        value={rangeFormik.values.rangeMax}
                        onChange={event => {
                            rangeFormik.setFieldValue('rangeMax', parseFloat(event.target.value))
                        }}
                        variant="outlined"
                        margin="normal"
                        type="number"
                        InputLabelProps={{
                            classes: {
                                root: 'cssLabel',
                            },
                        }}
                    />
                </FormControl>
            </div>
    )


}

export default RangeFilterInput