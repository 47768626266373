import {getServiceName, ServiceEnum} from './ServiceEnum'
import {ROUTES} from '../../constants/routing'
import { ReactElement } from 'react'

export interface Service {
    title: string
    description?: string | ReactElement
    summary: string
    available: boolean
    serviceFormPath?: string
    logoPath?: string
    buttonName: string
}

const NewPartsDescription = () => {
    return <span>Public items<ul className="service-description-list"><li>Defined by a manufacturer and managed by DATX. Items will be visible to all users on the DATX platform (commercial items).</li></ul>Private items<ul className="service-description-list"><li>Defined and managed by your organization. Items be visible by default only to your guild but can be shared to another guild.</li></ul></span>
}

export const serviceDetails: Map<ServiceEnum, Service> = new Map<ServiceEnum, Service>([
    [ServiceEnum.PART_MATCHING, {
        title: getServiceName(ServiceEnum.PART_MATCHING),
        summary: 'Identify if an item already exists in DATX',
        description: 'DATX will analyse an uploaded list of items to identify whether they are on the platform or not in order to help users to decide which items have to be created into DATX.',
        logoPath: '/images/services/PartMatching.svg',
        available: true,
        serviceFormPath: ROUTES.partMatchingServiceChoice.path,
        buttonName: 'Match Items'
    }], [ServiceEnum.NEW_PARTS, {
        title: getServiceName(ServiceEnum.NEW_PARTS),
        summary: 'Creation and Management of items in DATX',
        description: <NewPartsDescription />,
        logoPath: '/images/services/NewParts.svg',
        available: true,
        serviceFormPath: ROUTES.newPartsServiceForm.path,
        buttonName: 'Create Items'
    }], [ServiceEnum.EXTRACTION, {
        title: getServiceName(ServiceEnum.EXTRACTION),
        summary: 'Extract relevant items once or by recurrence',
        description: 'Configure the extraction (One-time or Scheduled) of Data packages containing freely accessible content, subscribed information packages, private attributes, etc.',
        logoPath: '/images/services/Extraction.svg',
        available: true,
        serviceFormPath: ROUTES.partExtractionServiceChoice.path,
        buttonName: 'Extract Items'
    }],
    [ServiceEnum.MANAGE_PRIVATE_ATTRIBUTES, {
        title: getServiceName(ServiceEnum.MANAGE_PRIVATE_ATTRIBUTES),
        summary: 'Create or update private attributes of items',
        description: 'Manage private information on existing items (Public or Private) such as procurement policies, qualification statuses or any other label used in the context of your organization.\n\nThese private data are uploaded by your organization, visible to your guild but can be shared to other guilds.',
        logoPath: '/images/services/ManagePrivateAttributes.svg',
        available: true,
        serviceFormPath: ROUTES.managePrivateAttribute.path,
        buttonName: 'Manage Attributes'
    }], [ServiceEnum.SUBSCRIBE_INFORMATION_PACKAGE, {
        title: getServiceName(ServiceEnum.SUBSCRIBE_INFORMATION_PACKAGE),
        summary: 'Access already available information',
        description: 'These information are sets of up-to-date data related to public items.\n\nThe accessibility to the data has to be renewed on a yearly basis starting from delivery date.',
        logoPath: '/images/services/AccessInformationPackage.svg',
        available: true,
        serviceFormPath: ROUTES.accessToInformationPackageServiceForm.path,
        buttonName: 'Request Access'
    }], [ServiceEnum.ITEM_MONITORING, {
        title: getServiceName(ServiceEnum.ITEM_MONITORING),
        summary: 'Proactive monitoring of data',
        description: 'Required information packages, when not existing, are collected on demand and updated regularly from one or multiple sources.\n\nThe accessibility to the monitoring service has to be renewed on a yearly basis starting from delivery date.',
        logoPath: '/images/services/BundleMonitoring.svg',
        available: true,
        serviceFormPath: ROUTES.informationPackageMonitoring.path,
        buttonName: 'Start Monitoring'
    }],
    [ServiceEnum.CAD_MODEL, {
        title: getServiceName(ServiceEnum.CAD_MODEL),
        summary: 'Request CAD Models (Electrical/Mechanical)',
        description: 'Service to update your components library in your CAD environment.\n\nTypes of models available: Footprint/Symbol/3D models for CADENCE, CATIA, CREO tools chains.',
        logoPath: '/images/services/Modelling.svg',
        available: true,
        serviceFormPath: ROUTES.cadModelCreation.path,
        buttonName: 'Request Creation'
    }], [ServiceEnum.CONSULTING_AND_EXPERTISE, {
        title: getServiceName(ServiceEnum.CONSULTING_AND_EXPERTISE),
        summary: 'Professional service from DATX Team',
        description: 'DATX Team experts can provide expertise around data management for your organization.\n\nFor instance, DATX can support your organization on topics such as support to deployment, data continuity (PLM, ERPs, etc.)',
        logoPath: '/images/services/ConsultingExpertises.svg',
        available: false,
        buttonName: 'Consult An Expert'
    }], [ServiceEnum.MARKETPLACE, {
        title: getServiceName(ServiceEnum.MARKETPLACE),
        summary: 'Services by tier companies',
        description: 'Services in the Market Place have been selected as they are considered to be complementary to DATX offer.\n' +
            '\n' +
            'DATX aims to evaluate the most appropriate and reliable partners that provides high quality of service.\n',
        logoPath: '/images/services/Marketplace.svg',
        available: false,
        buttonName: 'Access Service'
    }], [ServiceEnum.REQUEST_FOR_SUPPORT, {
        title: getServiceName(ServiceEnum.REQUEST_FOR_SUPPORT),
        summary: 'Hotline for technical assistance',
        description:'During you experience in DATX you may encounter some technical issues.\n\nThis service is the entry point to trigger a new issue or declare an incident.',
        logoPath: '/images/services/RequestSupport.svg',
        available: false,
        buttonName: 'Create Ticket'
    }],
])

export const catalogueServices: Service[] = [
    serviceDetails.get(ServiceEnum.PART_MATCHING) as Service,
    serviceDetails.get(ServiceEnum.NEW_PARTS) as Service,
    serviceDetails.get(ServiceEnum.EXTRACTION) as Service,
    serviceDetails.get(ServiceEnum.MANAGE_PRIVATE_ATTRIBUTES) as Service,
    // serviceDetails.get(ServiceEnum.SUBSCRIBE_INFORMATION_PACKAGE) as Service,
    serviceDetails.get(ServiceEnum.ITEM_MONITORING) as Service,
    serviceDetails.get(ServiceEnum.CAD_MODEL) as Service,
    serviceDetails.get(ServiceEnum.CONSULTING_AND_EXPERTISE) as Service,
    serviceDetails.get(ServiceEnum.MARKETPLACE) as Service,
    serviceDetails.get(ServiceEnum.REQUEST_FOR_SUPPORT) as Service,

]

const subscriptionLogos = new Map<ServiceEnum, string>([
    [ServiceEnum.SUBSCRIBE_INFORMATION_PACKAGE, serviceDetails.get(ServiceEnum.SUBSCRIBE_INFORMATION_PACKAGE)?.logoPath ?? ''],
    [ServiceEnum.ITEM_MONITORING, serviceDetails.get(ServiceEnum.SUBSCRIBE_INFORMATION_PACKAGE)?.logoPath ?? ''],
    [ServiceEnum.RECURRING_EXTRACTION, serviceDetails.get(ServiceEnum.EXTRACTION)?.logoPath ?? '']
])

export const getServiceSubscriptionLogo = (value: ServiceEnum): string => {
    return subscriptionLogos.get(value) ?? ''
}

