import FilterListIcon from '@mui/icons-material/FilterList'
import {Button, Chip, IconButton} from '@mui/material'
import {
    ColumnHeaderFilterIconButtonProps,
    ColumnHeaderFilterIconButtonPropsOverrides,
    DataGrid,
    GridColDef,
    GridFilterItem,
    GridFilterModel,
    GridFilterOperator,
    GridLogicOperator,
    gridSortModelSelector,
    useGridApiContext
} from '@mui/x-data-grid'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import React, {useEffect, useState} from 'react'
import {operatorMap} from './gridFilterOperators'
import './DaTXDataGrid.scss'
import {GridColumnMenuProps} from '@mui/x-data-grid/components/menu/columnMenu/GridColumnMenuProps'
import {ColumnMenuPropsOverrides} from '@mui/x-data-grid/models/gridSlotsComponentsProps'
import {DatePicker, LocalizationProvider} from '@mui/lab'
import AdapterDayjs from '@mui/lab/AdapterDayjs'

type ColumnMenuProps = Partial<GridColumnMenuProps & ColumnMenuPropsOverrides>
type ColumnFilterIconButtonProps = Partial<
    ColumnHeaderFilterIconButtonProps & ColumnHeaderFilterIconButtonPropsOverrides
>
type Row = any

declare module '@mui/x-data-grid' {
    interface ColumnMenuPropsOverrides {
        setFilter: (filter: GridFilterItem) => void
        filters: GridFilterItem[]
        rows: Row[]
    }

    interface ColumnHeaderFilterIconButtonPropsOverrides {
        setFilter: (filter: GridFilterItem) => void
        filters: GridFilterItem[]
    }
}

/*
    As we do not directly use MUI filter feature we need to compute the actual cell value if needed
*/
const getActualCellValue = (row: Row, colDef: any) => {
    return colDef.valueGetter ? colDef.valueGetter({row}) : row[colDef.field]
}

const TableMenuFilterIcon = () => {
    return <FilterListIcon />
}

/*
    The menu displayed for each column, can have a sort and filter section based on the column definition
*/
const DaTXColumnMenu = (props: NonNullable<ColumnMenuProps>) => {
    if (!props.colDef) {
        return <span>No column selected</span>
    }

    const activeFilters: GridFilterItem[] =
        props.filters?.find((filter: GridFilterItem) => filter.id === props.colDef?.field)?.value || []

    return (
        <div className="datagrid-sorting-panel">
            {props.colDef.sortable && <SortSection colDef={props.colDef} />}
            {props.colDef.filterable && props.colDef.filterOperators?.length && props.setFilter && props.rows && (
                <FilterSection
                    rows={props.rows}
                    colDef={props.colDef}
                    activeFilters={activeFilters}
                    setFilter={props.setFilter}
                />
            )}
        </div>
    )
}

const ActiveFilterIcon = (props: NonNullable<ColumnFilterIconButtonProps>) => {
    if (!props?.field) {
        return <></>
    }

    const filter = props?.filters?.find((item: GridFilterItem) => item.field == props.field)

    const deleteFilter = (event: React.MouseEvent) => {
        event.stopPropagation()
        if (props.setFilter && filter) {
            props.setFilter({...filter, value: []})
        }
    }

    if (filter && filter?.value[0] !== '') {
        return (
            <IconButton className="active-filter-icon" onClick={deleteFilter}>
                <FilterListIcon />
            </IconButton>
        )
    }
    return <></>
}

const SortSection = ({colDef}: {colDef: GridColDef}) => {
    const apiRef = useGridApiContext()
    const sortingModel = gridSortModelSelector(apiRef)

    const isActiveSorting = (sortingOrder: string) =>
        sortingModel[0]?.field == colDef.field && sortingModel[0].sort === sortingOrder

    return (
        <>
            <h3>Sort</h3>
            <div className="sort-options">
                <Chip
                    icon={<ArrowUpwardIcon />}
                    label="Ascending"
                    onClick={() => apiRef.current.sortColumn(colDef, isActiveSorting('asc') ? null : 'asc')}
                    sx={{boxShadow: 2}}
                    color={isActiveSorting('asc') ? 'primary' : undefined}
                />

                <Chip
                    icon={<ArrowDownwardIcon />}
                    label="Descending"
                    onClick={() => apiRef.current.sortColumn(colDef, isActiveSorting('desc') ? null : 'desc')}
                    sx={{boxShadow: 2}}
                    color={isActiveSorting('desc') ? 'primary' : undefined}
                />
            </div>
        </>
    )
}

const FilterSection = ({
    colDef,
    rows,
    activeFilters,
    setFilter
}: {
    colDef: GridColDef
    rows: Row[]
    activeFilters: GridFilterItem[]
    setFilter: (filter: GridFilterItem) => void
}) => {
    return (
        <>
            <h3>Filter</h3>
            {colDef.filterOperators?.map((filterOperator: GridFilterOperator) => {
                const FilterInput = filterOperator.InputComponent || NoFilterInput
                return (
                    <FilterInput
                        key={`${filterOperator.value}$${colDef.field}`}
                        colDef={colDef}
                        getPossibleValues={(colDef: GridColDef) =>
                            rows
                                .map((row) => getActualCellValue(row, colDef))
                                .filter((item, pos: number, array) => array.indexOf(item) === pos)
                                .sort()
                        }
                        filters={activeFilters}
                        setFilter={setFilter}
                        operator={filterOperator.value}
                    />
                )
            })}
        </>
    )
}

const DaTXDataGridActionHolder = ({
    resetFilterButton,
    children
}: {
    resetFilterButton?: React.ReactNode
    children: React.ReactNode
}) => {
    return (
        <div className="datagrid-action-holder">
            {children}
            {resetFilterButton}
        </div>
    )
}

const ClearFilterButton = ({onClick}: {onClick: () => void}) => {
    return (
        <Button variant="text" color="primary" type="button" onClick={onClick} className={'clearFilterButton'}>
            Clear filters
        </Button>
    )
}

/*
    A wrapper around MUI DataGrid. Particularly to implement style and filtering features.
*/
const DaTXDataGrid = ({
    columns,
    rows,
    getRowId,
    applyFilters = applyFiltersLocally,
    children
}: {
    columns: GridColDef[]
    rows: Row[]
    getRowId: any
    applyFilters?: any
    children?: React.ReactNode
}) => {
    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: [],
        quickFilterLogicOperator: GridLogicOperator.And
    })

    //For offline filtering, we need to keep track of the actually displayed rows but also of the full set of rows, in order to be able to build dynamic option lists for filters
    const [filteredRows, setFilteredRows] = useState<Row[]>(rows)

    const handleFilter = (filterItem: GridFilterItem) => {
        const definedFilterIndex = filterModel.items.findIndex((existingItem) => existingItem.id == filterItem.id)

        //If we are dealing with a new filter
        if (definedFilterIndex == -1) {
            setFilterModel({...filterModel, items: [...filterModel.items, filterItem]})
            return
        }

        //If we need to update an already defined filter (ex: add a possible value to a multi-select)
        if (filterItem.value?.length) {
            setFilterModel({
                ...filterModel,
                items: [
                    ...filterModel.items.slice(0, definedFilterIndex),
                    filterItem,
                    ...filterModel.items.slice(definedFilterIndex + 1)
                ]
            })
            return
        }

        //Finally, if we get here, we actually need to remove the filter
        setFilterModel({
            ...filterModel,
            items: [
                ...filterModel.items.slice(0, definedFilterIndex),
                ...filterModel.items.slice(definedFilterIndex + 1)
            ]
        })
    }

    useEffect(() => {
        setFilteredRows(applyFilters({filters: filterModel.items, columns, rows}))
    }, [filterModel, columns, rows, applyFilters])

    const clearFilters = () => {
        setFilterModel({items: [], quickFilterLogicOperator: GridLogicOperator.And})
    }

    return (
        <>
            <DaTXDataGridActionHolder children={children} />

            <DataGrid
                autoHeight={true}
                columnHeaderHeight={70}
                getRowId={getRowId}
                rows={filteredRows}
                columns={columns}
                filterModel={filterModel}
                className="datx-datagrid"
                slots={{
                    columnMenu: DaTXColumnMenu,
                    columnHeaderFilterIconButton: ActiveFilterIcon
                }}
                slotProps={{
                    columnMenu: {
                        setFilter: handleFilter,
                        filters: filterModel.items,
                        rows: rows
                    },
                    columnHeaderFilterIconButton: {
                        setFilter: handleFilter,
                        filters: filterModel.items
                    }
                }}
                initialState={{
                    pagination: {paginationModel: {page: 0, pageSize: 20}},
                    sorting: {
                        sortModel: [
                            {
                                field: 'updatedDate',
                                sort: 'desc'
                            }
                        ]
                    }
                }}
            />
        </>
    )
}

/*
    By default, we need a way to apply filters localy on multiple Grid columns at once
*/
const applyFiltersLocally = ({
    filters,
    columns,
    rows
}: {
    filters: GridFilterItem[]
    columns: GridColDef[]
    rows: Row[]
}): Row[] => {
    /*
        First we build the complete list of filters
    */
    const filterFunctions = filters.map((filter) => {
        const column = columns.find((col) => filter.id === col.field)
        if (column) {
            const actualFilter = operatorMap[filter.operator].getApplyFilterFn(filter, column)
            if (actualFilter) {
                return (row: Row) =>
                    actualFilter({
                        value: getActualCellValue(row, column),
                        formattedValue: getActualCellValue(row, column)
                    })
            }
        }
        return () => true
    })

    //Then we iterate once on rows and keep only rows matching all the defined filters
    return rows.filter((row) => filterFunctions.every((filter) => filter(row)))
}

const NoFilterInput = () => <div>No Filter defined for column</div>

export default DaTXDataGrid
