import React, {forwardRef, RefObject} from 'react'
import {MenuItem, MenuItemProps, Typography} from '@mui/material'
import {Box, styled} from '@mui/system'
import './CustomMenu.scss'

interface IconMenuItemProps {
    leftIcon?: React.ReactNode
    rightIcon?: React.ReactNode
    onClick?: () => void
    label?: string
    className?: string
    MenuItemProps?: MenuItemProps
    ref?: RefObject<HTMLLIElement>
    disabled?: boolean
}

// imported from opensource project https://github.com/steviebaa/mui-nested-menu/blob/main/src/mui-nested-menu/components/IconMenuItem.tsx

const CustomIconMenuItem = forwardRef<HTMLLIElement, IconMenuItemProps>(
    (
        {leftIcon, rightIcon, onClick, label, MenuItemProps, className, ...props},
        ref,
    ) => {
        return (
            <MenuItem
                {...MenuItemProps}
                ref={ref}
                className={`${className} custom-icon-menu-item`}
                onClick={onClick}
                {...props}
            >
                <div className={'custom-icon-menu-box'}>
                    {leftIcon}
                    <Typography
                        className={'custom-icon-menu-typography'}>{label}</Typography>
                </div>
                {rightIcon}
            </MenuItem>
        )
    },
)

CustomIconMenuItem.displayName = 'CustomIconMenuItem'
export default CustomIconMenuItem
