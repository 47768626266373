import React from 'react'
import {ServiceSubscriptionSummary} from '../../model/subscription/ServiceSubscription'
import {getServiceName} from '../../model/ServiceEnum'
import {getServiceSubscriptionLogo} from '../../model/Service'
import ServiceSubscriptionStatus from '../../model/subscription/ServiceSubscriptionStatus'
import {Divider} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import './SubscriptionRow.scss'
import {setSnackbarMessage} from '../../../genericComponents/commonSlice'
import {useTypedDispatch} from '../../../util/store'
import {Link, useMatch} from 'react-router-dom'
import SubscriptionDate from './SubscriptionDate'
import {useDatxPathGenerator} from '../../../util/routing'
import {ROUTES} from '../../../constants/routing'
import { CopyButton } from '../../../genericComponents/CopyButton'

const subscriptionsStatusLabels = {
    [ServiceSubscriptionStatus.ACTIVE]: 'Valid Till',
    [ServiceSubscriptionStatus.EXPIRED]: 'Expired',
    [ServiceSubscriptionStatus.CANCELLED]: 'Cancelled',
}

const SubscriptionRow = ({subscription}: { subscription: ServiceSubscriptionSummary }) => {
    const generatePath = useDatxPathGenerator()
    const dispatch = useTypedDispatch()
    const label = getServiceName(subscription.serviceName)
    const logoPath = getServiceSubscriptionLogo(subscription.serviceName)
    const {id, cost, requesterEmail, endDate} = subscription
    const relatedToGuildServiceSubscriptions = useMatch({path: ROUTES.guildServiceSubscriptions.path ?? '', end: false})
    const relatedToUserServiceSubscriptions = useMatch({path: ROUTES.userServiceSubscriptions.path ?? '', end: false})

    const isInActiveSubscription = subscription.status === ServiceSubscriptionStatus.ACTIVE

    const manageServiceSubscriptionUrl = relatedToGuildServiceSubscriptions ? ROUTES.manageServiceSubscription.path : relatedToUserServiceSubscriptions ? ROUTES.manageUserServiceSubscriptions.path : ''

    return <div key={id} className="subscription-container">
        <div className="subscription-details">
            <img src={logoPath} alt={label} className={`service-card-logo ${!isInActiveSubscription && 'inactive'}`}/>
            <div className="subscription-title-column">
                <span className="subscription-title">{label}</span>
                <div className="id-container" data-testid="id-container">
                    <span className="id-value">{id}</span>
                    <CopyButton toCopy={id} onCopySuccess={() => dispatch(setSnackbarMessage('Id was successfully copied to your clipboard'))} />
                </div>
            </div>
            <div className={`subscription-title-column ${!isInActiveSubscription && 'inactive'}`}>
                <span className="subscription-content">{cost}</span>

                <span className="subscription-content" data-testid="requester-email">{requesterEmail}</span>
            </div>
            <SubscriptionDate date={endDate} prefix={subscriptionsStatusLabels[subscription.status]}
                              hasWarningExpiration={true}
                              isActiveSubscription={isInActiveSubscription}/>
            <div className="subscription-manage-column">
                <LoadingButton
                    data-testid="manage-subscription"
                    className="subscription-manage-button"
                    variant="outlined"
                    color="primary"
                    type="button"
                    component={Link}
                    to={generatePath(manageServiceSubscriptionUrl, {serviceSubscriptionId: subscription.id})}
                >
                    {isInActiveSubscription ? 'Manage' : 'View'}
                </LoadingButton>
            </div>

        </div>
        <Divider/>
    </div>
}
export default SubscriptionRow
