import IconButton from '@mui/material/IconButton'
import {Add, Remove} from '@mui/icons-material'

export const ExpandedIcon = ({name, expanded}: {
    name: string,
    expanded: boolean,
}) => {
    return (
        <IconButton className="accordion-expanded-icon icon-button"
                    data-testid={`expanded-icon-${name}`}>
            {expanded ? <Remove/> : <Add/>}
        </IconButton>
    )
}