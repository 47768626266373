enum QuotationRuleTypeEnum {
    ADJUSTMENT = 'Adjustment',
    MULTIPLY_PRICE_PER_QUANTITY = 'MultiplyPricePerQuantity',
    MIN_PRICE = 'MinPrice',
    FIXED_PRICE = 'FixedPrice',
    MIN_QUANTITY = 'MinQuantity',
    RULE_WITH_QUANTITY = 'RuleWithQuantity',
    GROUP_BY_PACK = 'GroupByPack',
    ITEM_PRICE = 'ItemPrice'
}

export default QuotationRuleTypeEnum