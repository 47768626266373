import {ServiceRequest} from '../../service/model/ServiceRequest'
import {DateDisplay} from '../date/dateDisplay'
import React from 'react'
import getUserServiceType, {UserServiceRequestStatus} from '../../service/model/UserServiceRequestType'
import {DownloadServiceFile} from '../../service/serviceRequests/DownloadServiceFile/DownloadServiceFile'
import {GridCellParams, GridRenderCellParams} from '@mui/x-data-grid'
import {CircularProgress, Link} from '@mui/material'
import {DownloadServiceFileDetail} from '../../service/serviceRequests/DownloadServiceFile/DownloadServiceFileDetail'
import {getServiceName} from '../../service/model/ServiceEnum'
import {ExtractionFrequencyEnum} from '../../service/model/ExtractionFrequencyEnum'
import {getTotalFromRequest} from '../../service/model/Quotation'
import {useGetServiceRequestQuotationFileMutation} from '../../service/serviceRequests/rtkServiceRequestApi'

const extractionFrequencyMap = new Map<string, string>([
    [ExtractionFrequencyEnum.WEEKLY, 'Weekly'],
    [ExtractionFrequencyEnum.MONTHLY, 'Monthly'],
    [ExtractionFrequencyEnum.DAILY, 'Daily'],
])


export const getCreatedDate = ({row}: { row: ServiceRequest }) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <DateDisplay entryDate={row.createdDate} time={true}/>
}

export const getLastModifiedDate = ({row}: { row: ServiceRequest }) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <DateDisplay entryDate={row.updatedDate} time={true}/>
}

export const getStatus = ({row}: { row: ServiceRequest }) => {
    const status = row?.status == undefined ? '' : row?.status?.charAt(0).toUpperCase() + row?.status?.slice(1)
    const type: UserServiceRequestStatus = getUserServiceType(status)
    return <span className="label-format" style={{color: type.color}} title={type.label}>{type.label}</span>
}

export const getFileName = ({row}: { row: ServiceRequest }) => {
    return <DownloadServiceFile userServiceRequest={row}/>
}

export const getFileNameDetail = ({row}: { row: ServiceRequest }) => {
    return <DownloadServiceFileDetail serviceRequestId={row.id} file={row.file}/>
}

const getFormattedServiceName = (service: string) => {
    const serviceName = getServiceName(service)
    if (serviceName === 'Create Company Items' || serviceName === 'Create Manufacturer Items') {
        return 'New Items'
    }
    return serviceName
}

export const getService = ({row}: { row: ServiceRequest }) => {
    const service = row?.service ? getFormattedServiceName(row.service) : ''
    return <span className="label-format" title={service}>{service}</span>

}

export const getWorkflowWithServcie = ({row}: { row: ServiceRequest }) => {
    const workflowName = row?.workflowConfiguration?.name ?? ''
    const service = row?.service ? getFormattedServiceName(row.service) : ''
    return<div>
            <span className="label-format workflow-label" title={workflowName}>{workflowName}</span>
            <span className="label-format service-label" title={service}>{service}</span>
    </div>
}

export const formatExtractionFormat = (frequency: string) => {
    if (frequency) {
        return extractionFrequencyMap.get(frequency)
    }
    //If frequency is null, it is a One-Time Extraction
    return 'One-Time'
}

export const getModifiedDate = (row: GridRenderCellParams) => {
    return <DateDisplay entryDate={row.row[row.field]} time={false}/>
}

export const getDisplayedValueForQuotationFile = (v1: any) => {
    if (v1.row?.file?.status === 'Valid') {
        return v1?.row?.file?.name
    }
    return v1?.row?.file?.status
}

export const getRequester = ({row}: { row: ServiceRequest }) => {
    if (!row.requesterEmail) {
        return '-'
    }
    return <span title={row.requesterEmail} className="label-format">{row.requesterEmail}</span>
}


export const getQuotationCost = ({row}: { row: ServiceRequest }) => {
    const total = getTotalFromRequest(row) ?? '-'
    return <span title={total.toString()} className="label-format">{total}</span>
}

export const getValidatedBy = ({row}: { row: ServiceRequest }) => {
    const email = row.isAutoApproved ? 'Automatically approved' : (row.validatedByEmail || '-')
    return <span title={email.toString()} className="label-format">{email}</span>
}

export const formatIdLink = (params: GridRenderCellParams, onClick: (params: GridCellParams) => void): React.ReactNode => {
    const {row} = params
    const value = row.id
    if (!value) {
        return '-'
    }
    return <span onClick={() => onClick(params)} title={value} className="label-clickable-format">{value}</span>
}

export const GetQuotationFile = ({row}: { row: ServiceRequest }) => {

    const [getServiceRequestQuotationFile, {isLoading}] = useGetServiceRequestQuotationFileMutation()
    const getFile = () => {
        getServiceRequestQuotationFile(row.id)
    }
    return (
        <>
            {(row.quotation?.quotationFile !== null && row.quotation?.quotationFile.status === 'Valid') ?
                <div>
                    <Link component="button" onClick={getFile}>{row.quotation?.quotationFile?.name}</Link>
                    {!isLoading || <CircularProgress size="16px"/>}
                </div>
                :
                <span>-</span>
            }
        </>
    )
}
