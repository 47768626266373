import React, {useEffect, useMemo, useState} from 'react'
import PropTypes from 'prop-types'
import './CreateNewListDialog.scss'
import {TextField} from '@mui/material'
import CustomDialog from '../../genericComponents/customDialog/CustomDialog'
import {LoadingButton} from '@mui/lab'
import {useDebounce} from 'use-debounce'
import {ItemList} from '../model/ItemList'
import {getIsListNameDuplicated} from '../ItemLists'
import {useCurrentGuildContext} from '../../util/routing'
import {useCreateItemListMutation} from '../rtkItemListApi'


const CreateNewListDialog = ({ open, closeDialog, myLists }:
                                 {open: boolean, closeDialog: () => void, myLists: ItemList[]}) => {
    const [currentGuildContext] = useCurrentGuildContext()

    const [listName, setListName] = useState('')
    const [inputErrorMsg, setInputErrorMsg] = useState('')
    const [createItemList, createItemListResult] = useCreateItemListMutation()
    const [isSubmitted, setIsSubmitted] = useState(false)


    const [debouncedListName] = useDebounce(listName, 500)

    const isListNameValid = useMemo(() => {
        const isNameDuplicated = getIsListNameDuplicated(myLists, listName)
        if (!listName || isNameDuplicated) {
            return false
        }
        return true
    }, [listName, myLists])

    const onListNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsSubmitted(false)
        setListName(e.target.value)
        // as long as the user type something, the error will be gone
        setInputErrorMsg('')
    }

    // when the name is duplicated, an error message will be displayed
    // we don't when the error message to be displayed when the user is still typing
    // so the debounced value is used instead
    useEffect(() => {
        const isDebouncedNameDuplicated = getIsListNameDuplicated(
            myLists,
            debouncedListName
        )
        if (
            isDebouncedNameDuplicated &&
            //since the component is not re-rendered when reopening after successfully creating a new list
            //so a new flag is added to prevent the error message from showing up
            !isSubmitted &&
            // since submitting the form will update myList and cause the error message to be displayed
            // this condition is added to prevent the error message from showing up
            !createItemListResult.isLoading
        ) {
            setInputErrorMsg(
                'A list by this name already exists. Kindly provide a different name.'
            )
        }
    }, [myLists, debouncedListName])

    const onSubmit = () => {
        if(!currentGuildContext) {
            return
        }
        createItemList({
            guildId: currentGuildContext.guildId,
            name: listName,
        }).then(_ => {
            // clear the input
            setListName('')
            setIsSubmitted(true)
            // close the dialog on succeed
            closeDialog()
        })
    }

    return (
        <CustomDialog
            className="create-new-list-dialog"
            open={open}
            title="Create a new list"
            onClose={closeDialog}
        >
            <label
                className="new-list-name-label"
                htmlFor="new_list_name_input"
            >
                Enter name of the new list
            </label>
            <div className="new-list-name">
                <TextField
                    id="new_list_name_input"
                    className="new-list-name-input-container"
                    value={listName}
                    onChange={onListNameChange}
                    helperText={inputErrorMsg}
                    variant="outlined"
                />
                <LoadingButton
                    className="new-list-submit"
                    color="primary"
                    variant="contained"
                    onClick={onSubmit}
                    disabled={!isListNameValid}
                    loading={createItemListResult.isLoading}
                >
                    Confirm
                </LoadingButton>
            </div>
        </CustomDialog>
    )
}

CreateNewListDialog.propTypes = {
    open: PropTypes.bool,
    closeDialog: PropTypes.func.isRequired,
    myLists: PropTypes.array,
}

export default CreateNewListDialog
