import {Search} from '../../../model/SearchFilter'
import {useSearchClassificationsRelatedToSearchingItemsQuery, useSearchItemsByNameQuery} from '../../../rtkSearchApi'
import React, {ChangeEvent} from 'react'
import {Box} from '@mui/material'
import {
    ClassificationComponentInPhraseSearchPage
} from '../ClassificationsResult/ClassificationComponentInPhraseSearchPage'
import {
    SearchResultsComponentForClassificationAndPhraseSearch
} from '../SearchResultsComponent/SearchResultsComponentForClassificationAndPhraseSearch'
import { ExactSearchResult } from '../SearchResultTable/ExactSearchResult'

export const SearchResultPageForPhrase = ({search, page, pageSize, handlePageChange}: {
    search: Search,
    page: number,
    pageSize: number,
    handlePageChange: (e: ChangeEvent<any>, pageNumber: number) => void
}) => {
    const {data: searchResult, isFetching: isItemsFetching} = useSearchItemsByNameQuery({
        search: {...search, phrase: {phraseDetail: search?.phrase?.phraseDetail ?? '', isExact: true}},
        pageNumber: page - 1,
        pageSize: pageSize
    })

    const {data: classificationsResult, isFetching: isClassificationsFetching} = useSearchClassificationsRelatedToSearchingItemsQuery({search: search})

    const getUnit = (number: number) => number <= 1 ? 'Result' : 'Results'

    return (
        <Box className="item-list-page">
            <div className="search-caption">Showing {searchResult?.totalElements || 0} {getUnit(searchResult?.totalElements || 0)} for "{search?.phrase?.phraseDetail}"
            </div>
            <div>
            <div className="search-title">
                Exact match
            </div>
                <ExactSearchResult
                    searchResult={searchResult}
                    page={page}
                    search={search}
                    handlePageChange={handlePageChange}
                    isFetching={isItemsFetching}
                    // Added key : If one of these attribute changes, the searchResults Component is reredended
                    key={`${search.guildId}_${search.phrase}_${search.technicalClassificationNodeId}`}
                />
            </div>
            <div className="classification-filter">
                <div className="search-title">Filter your search by classification</div>
                <ClassificationComponentInPhraseSearchPage searchResult={classificationsResult}
                                                           isFetching={isClassificationsFetching}
                />
            </div>
        </Box>
    )
}