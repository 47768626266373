import * as React from 'react'
import {ReactNode} from 'react'
import TreeItem, {useTreeItem} from '@mui/lab/TreeItem'
import Typography from '@mui/material/Typography'
import {useNavigate, useParams} from 'react-router-dom'
import {selectClassificationTree} from '../../search/rtkSearchApi'
import {useSelector} from 'react-redux'
import {ROUTES} from '../../constants/routing'
import {useDatxPathGenerator} from '../../util/routing'


interface CustomContentType {
    label: string,
    nodeId: string,
    icon: ReactNode,
    expansionIcon: ReactNode,
    displayIcon: ReactNode,
}

const CustomContent = React.forwardRef(function CustomContent(
    {
        label,
        nodeId,
        icon: iconProp,
        expansionIcon,
        displayIcon,
    }: CustomContentType,
    ref
) {
    const navigate = useNavigate()
    const generatePath = useDatxPathGenerator()
    const classificationTree = useSelector(selectClassificationTree)
    const {classificationId} = useParams()

    const {
        handleExpansion,
        handleSelection,
        preventSelection,
        expanded

    } = useTreeItem(nodeId)

    const icon = iconProp || expansionIcon || displayIcon


    const isClassificationNodeAParent = (nodeId: any) => {
        return classificationTree.children?.some(
            (node) => node.id === nodeId)
    }

    const doesClassificationNodeHaveChildren = (nodeId: any, node: any) => {
        return node.children?.some((subNode: any) => {
                if (subNode.id === nodeId && subNode.children?.length > 0) {
                    return true
                } else if (subNode.id !== nodeId) {
                    return doesClassificationNodeHaveChildren(nodeId, subNode)
                }
                return false
            }
        )
    }

    const handleMouseDown = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        preventSelection(event)

    }

    const handleExpansionClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        handleExpansion(event)
    }

    const handleOnClick = () => {
        navigate(
            {pathname: generatePath(ROUTES.searchByClassificationNodeDescendants.path, {classificationId : nodeId}),
            search: '?page=1'
        })
    }

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            onMouseDown={handleMouseDown}
            ref={ref as React.Ref<HTMLDivElement>}
            id="custom-tree-view-component-children"
        >
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
                 id={isClassificationNodeAParent(nodeId) && expanded ? 'expand-classification-node-parent'
                     :
                     (doesClassificationNodeHaveChildren(nodeId, classificationTree) ? 'expand-classification-node-children' : 'expand-classification-node-children-no-children')
                 }>
                <div id={isClassificationNodeAParent(nodeId) ? 'classification-node-icon-parent' : 'classification-node-icon-children'}
                     data-testid={nodeId + '-view-children'} onClick={handleExpansionClick}>{icon}</div>

                <Typography
                    data-testid={nodeId + '-view-items'}
                    component="div"
                    id={isClassificationNodeAParent(nodeId) ? 'classification-node-label-parent' : 'classification-node-label-children'}
                    className={nodeId === classificationId ? 'clicked' : 'default'}
                    onClick={handleOnClick}
                >
                    {label}
                </Typography>
            </div>
        </div>
    )
})

export default function CustomTreeItem(props: any) {
    return <TreeItem ContentComponent={CustomContent} {...props} />
}
