import {IdType} from '../../../../util/models/IdType'
import React, {useEffect, useState} from 'react'
import {ItemList} from '../../../../itemList/model/ItemList'

import {Button, MenuItem, Select, SelectChangeEvent, Tooltip} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import LoadingButton from '@mui/lab/LoadingButton'
import {Confirmation} from './AddItemToListConfirmationModal'
import {useCurrentGuildContext} from '../../../../util/routing'
import {useGetPersonalItemListsQuery, useUpdateItemsOfItemListMutation} from '../../../../itemList/rtkItemListApi'
import {UpdatedItemList} from '../../../../itemList/model/UpdatedItemList'

export const AddItemToExistingList = ({itemIds, handleSubmit}: {itemIds: IdType[]; handleSubmit: () => void}) => {
    const [currentList, setCurrentList] = useState<ItemList>({id: '', name: null, userOauthId: '', items: []})
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

    const [currentGuildContext] = useCurrentGuildContext()

    const {data: myLists = []} = useGetPersonalItemListsQuery(currentGuildContext?.guildId, {
        skip: !currentGuildContext?.guildId
    })
    const [updateItemList, updatedItemListResult] = useUpdateItemsOfItemListMutation()

    const onSubmit = () => {
        if (!currentList || !currentGuildContext) {
            return
        }
        const {id, name, items} = currentList

        // need a deep copy of the object to be able to extend it

        const newItems = [...items, ...itemIds]
        const guildId = currentGuildContext.guildId
        const updatedItemList: UpdatedItemList = {id, name, guildId, items: newItems}
        updateItemList({listId: id, data: updatedItemList})
    }

    const handleChange = (event: SelectChangeEvent<ItemList>) => setCurrentList(event.target.value as ItemList)

    return (
        <>
            {updatedItemListResult.isSuccess && currentList ? (
                <Confirmation currentListName={currentList.name} />
            ) : (
                <>
                    <div className="add-item-to-list">
                        <div className="info-header">
                            <InfoIcon className="info-icon" />
                            <div className="info-title">Add to List</div>
                        </div>
                        <label className="info-label">
                            Choose an existing list, or create a new one to add the selected items
                        </label>
                        <div className="item-list" data-testid="guild-item-list-selection">
                            <Select
                                id="item_list_input"
                                className="item-list-input-container"
                                data-cy={'itemlist-select'}
                                value={currentList ?? ''}
                                displayEmpty
                                onChange={handleChange}
                                renderValue={(itemList: ItemList) => itemList.name ?? 'Choose a list'}
                            >
                                {myLists?.length > 0 &&
                                    myLists.map((list) => (
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        <MenuItem
                                            value={list}
                                            className="list-to-add-item"
                                            key={list.id}
                                            autoFocus={false}
                                            data-cy={`select-option-${list.name}`}
                                            sx={{fontSize: '14px'}}
                                        >
                                            <Tooltip
                                                title={list.name && list.name.length > 32 ? list.name : ''}
                                                placement={'right'}
                                            >
                                                <span className="list-name">{list.name}</span>
                                            </Tooltip>
                                        </MenuItem>
                                    ))}
                            </Select>
                            <LoadingButton
                                className="item-list-submit"
                                data-testid="next-button"
                                type="submit"
                                id="next-button"
                                variant="contained"
                                color="primary"
                                disabled={!currentList?.id}
                                loading={updatedItemListResult.isLoading}
                                onClick={onSubmit}
                            >
                                Add to List
                            </LoadingButton>
                        </div>

                        <span className="item-addlist">
                            <span>OR</span>
                            <Button onClick={handleSubmit} variant="outlined" data-testid="create-a-new-list-button">
                                Create a New List
                            </Button>
                        </span>
                    </div>
                </>
            )}
        </>
    )
}
export default AddItemToExistingList
