import React from 'react'
import {TextField, InputLabel, Checkbox, IconButton} from '@mui/material'
import {ROUTES} from '../../../constants/routing'
import {Link, useNavigate} from 'react-router-dom'
// styles
import './GuildCreationForm.scss'
import {GuildCreationStatus} from '../GuildCreation'
import {useDatxPathGenerator} from '../../../util/routing'
import CardContainer from '../../../genericComponents/cardContainer/CardContainer'
import {ArrowBackIosNew as ArrowBack} from '@mui/icons-material'
import {LoadingButton} from '@mui/lab'

function GuildCreationForm({
    canSubmit,
    onSubmit,
    isSubmitting,
    guildState,
    onGuildNameChange,
    isTermsChecked,
    setIsTermsChecked,
    warningAboutCharacters
}: {
    canSubmit: () => boolean
    onSubmit: () => void
    isSubmitting: boolean
    guildState: GuildCreationStatus
    onGuildNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    isTermsChecked: boolean
    setIsTermsChecked: (checked: boolean) => void
    warningAboutCharacters: string
}) {
    const generatePath = useDatxPathGenerator()
    const navigate = useNavigate()

    return (
        <CardContainer>
            <div className="guild-creation">
                <div className="guild-creation-form-container">
                    <div className="guild-creation-header-back-nav">
                        <IconButton onClick={() => navigate(-1)}>
                            <ArrowBack className="guild-creation-back-nav" />
                        </IconButton>
                        <div className="guild-creation-header">Guild Creation</div>
                    </div>
                    <div className="guild-creation-content">
                        <div className="guild-creation-description">
                            <p>
                                Guild Creation will begin a process of acquiring a new private space with our sales
                                department, with annual contract and cost for centralized management of needs for all
                                your Guildians. <b>This is the key element of a collaborative and shared approach</b>,
                                dedicated to companies but also to consortia (several companies), specific professions
                                or large projects.
                            </p>
                            <p>
                                <b>Created by industry for industry,</b> DATX and its Guild concept, resulting from very
                                long experience, guarantee you confidentiality, security and sovereignty. We'll tell you
                                how.
                            </p>
                            <p>
                                <i>
                                    Please Note: Creating a Guild requires the completion of a contract of at least 12
                                    months between DATX and your legal entity. This will include the validation of our
                                    T&Cs as a DATX customer.
                                </i>
                            </p>
                        </div>
                        <div className="guild-creation-form">
                            <InputLabel className="guild-name-label" htmlFor="guild-name-input" required>
                                Kindly provide a name for this guild
                            </InputLabel>
                            <TextField
                                className="guild-name-input-container"
                                id="guild-name-input"
                                onChange={onGuildNameChange}
                                value={guildState.name}
                                variant="outlined"
                                helperText={
                                    <span style={{whiteSpace: 'pre-wrap'}}>
                                        {guildState.errors.length
                                            ? guildState.errors.map((error) => `${error}\n`)
                                            : warningAboutCharacters}
                                    </span>
                                }
                                fullWidth
                                autoFocus
                                required
                                error={!!guildState.errors.length}
                                placeholder="Enter guild name"
                            />

                            <div className="gc-terms">
                                <div className="gc-terms-content">
                                    <Checkbox
                                        className="gc-terms-checkbox"
                                        color="primary"
                                        size="small"
                                        checked={isTermsChecked}
                                        onChange={(e) => setIsTermsChecked(e.target.checked)}
                                    />
                                    <span className="gc-terms-statement">
                                        <label className="red-asterisk">*</label> I acknowledge that I have read and
                                        understood the{' '}
                                        <Link
                                            className="gc-terms-link"
                                            to={generatePath(ROUTES.termsOfSales.path)}
                                            target="_blank"
                                        >
                                            Terms of Use
                                        </Link>{' '}
                                        .
                                    </span>
                                </div>
                            </div>
                            <div className="create-guild-button">
                                <LoadingButton
                                    className="guild-form-submission-button"
                                    id="next-button"
                                    variant="contained"
                                    disabled={canSubmit ? !canSubmit() : false}
                                    onClick={onSubmit}
                                    loading={isSubmitting}
                                >
                                    Create Guild
                                </LoadingButton>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="infographic-container">
                    <img src="/images/guild/Guild-Creation-infographic.png" alt="Guild-Creation-infographic" />
                    <div className="infographic-description">
                        <p>
                            * Guild Item List served as a purpose to: Manage Service requests, Preferred Item List /
                            black list, and Internal references / qualification status / documents / authorization code,
                            or specific tags
                        </p>
                        <p>
                            Depending on your business needs, you will be able to provide data for all your internal
                            tools.
                        </p>
                    </div>
                </div>
            </div>
        </CardContainer>
    )
}

export default GuildCreationForm
