import React, {useState} from 'react'
import {DataGrid, GridCellParams, GridColDef, GridRenderCellParams, GridValueGetterParams} from '@mui/x-data-grid'
import {IconButton, Menu, MenuItem} from '@mui/material'
import {MoreHoriz} from '@mui/icons-material'
import {Member} from '../../models/Member'
import GuildMemberManagementDialog from './GuildMemberManagementDialog'
import {IdType} from '../../../util/models/IdType'
import getGuildMemberType, {MemberStatus} from './models/GuildMemberType'
import MemberStatusEnum from './models/GuildMemberStatus'
import GuildMemberPromoteAdminDialog from './GuildMemberPromoteAdminDialog'
import {useMsal} from '@azure/msal-react'
import {getEmailFromMsal} from '../../../authentication/AuthHooksAndFunctions'
import {getLastModifiedDate} from './GuildMembers'

const MoreOptionsMenu = ({guildId, member, membersCount}: { guildId: IdType | undefined, member: Member, membersCount: number }) => {
    const [openDialog, setOpenDialog] = useState(false)
    const [isPromoteAdminDialogOpen, setIsPromoteAdminDialogOpen] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState<Element | null>(null)
    const open = Boolean(anchorEl)
    const isMemberStatusSentOrRejected = member?.status === MemberStatusEnum.SENT || member?.status === MemberStatusEnum.REJECTED
    const isMemberAdmin = member?.admin
    const {instance} = useMsal()

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const closeMenu = () => {
        setAnchorEl(null)
    }

    const closeDialog = () => {
        setOpenDialog(false)
    }

    const openConfirmationDialog = () => {
        closeMenu()
        setOpenDialog(true)
    }

    function openPromoteAdminDialog() {
        closeMenu()
        setIsPromoteAdminDialogOpen(true)
    }

    function closePromoteAdminDialog() {
        setIsPromoteAdminDialogOpen(false)
    }

    const canUserBeRemoved = () => (getEmailFromMsal(instance) !== member.user.email) && membersCount > 1

    return <>
        <IconButton onClick={handleClick}>
            <MoreHoriz/>
        </IconButton>
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={closeMenu}
        >
            {isMemberStatusSentOrRejected ?
                <MenuItem onClick={openConfirmationDialog}>Cancel invitation</MenuItem>
                :
                <MenuItem onClick={openConfirmationDialog}>Remove</MenuItem>
            }
            {!isMemberAdmin ?
                <MenuItem onClick={openPromoteAdminDialog}>Promote to Admin</MenuItem> : null
            }
        </Menu>

        <GuildMemberManagementDialog
            open={openDialog}
            closeDialog={closeDialog}
            params={{guildId: guildId, memberId: member?.user?.oauthProviderId}}
            isMemberStatusSentOrRejected={isMemberStatusSentOrRejected}
            email={member?.user?.email}
            canUserBeRemoved={canUserBeRemoved()}
        />

        <GuildMemberPromoteAdminDialog
          isOpen={isPromoteAdminDialogOpen}
          closeDialog={closePromoteAdminDialog}
          params={{guildId: guildId, memberId: member?.user?.oauthProviderId, memberEmail: member?.user?.email}}
        />
    </>
}

const getRole = (row: GridValueGetterParams) => {
    if (row?.value === null) {
        return '-'
    }
    return row.value ? 'Guild Administrator' : 'Guild Member'
}

const getEmail = (row: GridValueGetterParams) => {
    if (row?.value === null) {
        return '-'
    }
    return row.value?.email
}

const getStatus = (row: GridRenderCellParams) => {
    const status = row?.value?.charAt(0).toUpperCase() + row?.value?.slice(1)
    const type: MemberStatus = getGuildMemberType(status)
    return <div style={{color: type.color}}>{type.label}</div>
}

const getLastModifiedDateForTable = ({row}: { row: Member }) => {
    return getLastModifiedDate(row)
}

const GuildMemberTable = ({members, guildId}: { members: Member[], guildId: IdType | undefined }) => {

    const columns: GridColDef[] = [
        {
            field: 'user', headerName: 'Email',
            flex: 2, minWidth: 408,
            disableColumnMenu: true,
            valueGetter: getEmail
        },
        {
            field: 'admin', headerName: 'Role',
            flex: 1, minWidth: 160,
            disableColumnMenu: true,
            valueGetter: getRole
        },
        {
            field: 'status', headerName: 'Status',
            flex: 1, minWidth: 144,
            disableColumnMenu: true,
            renderCell: getStatus
        },
        {
            field: 'lastStatusUpdate', headerName: 'Last updated',
            flex: 1, minWidth: 184,
            disableColumnMenu: true,
            valueGetter: getLastModifiedDateForTable
        },
        {
            field: 'action', headerName: 'Actions',
            align: 'center', width: 128,
            sortable: false, disableColumnMenu: true,
            headerClassName: 'more-option-menu-header',
            renderCell: (params: GridCellParams) => {
                return <MoreOptionsMenu guildId={guildId} member={params.row} membersCount={members.length}/>
            }
        }
    ]

    return (
        <div className="guild-member-grid-container">
            <DataGrid
                className="guild-member-grid"
                getRowId={(member: Member) => member?.user?.id}
                initialState={{pagination: {paginationModel:{page: 0, pageSize: 20}}}}
                rows={members}
                columns={columns}
                autoHeight
            />
        </div>
    )
}
export default GuildMemberTable

