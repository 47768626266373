import React, {useEffect, useState} from 'react'
import './Header.scss'
import {AppBar} from '@mui/material'
import {AuthenticatedTemplate} from '@azure/msal-react'
import Logo from './menuComponents/Logo'
import {SearchPhraseForm} from './menuComponents/SearchPhraseForm'
import GuildMenu from './menuComponents/GuildMenu'
import UserMenu from './menuComponents/UserMenu'
import ServicesMenu from './menuComponents/ServicesMenu'
import ItemListMenu from './menuComponents/ItemListMenu'
import Notifications from './menuComponents/Notification/Notifications'
import {useTypedSelector} from '../util/store'
import DrawerHeader from './classificationTreeDrawer/DrawerHeader'
import BannersComponent from '../banner/BannersComponent'
import {useDatxPathGenerator, useSearchNavigateWithPathName} from '../util/routing'
import {useSearchParams} from 'react-router-dom'
import {ROUTES} from '../constants/routing'
import {phraseUrlSearchParamsName} from '../search/SearchParams'

const SearchHeader = ({hideSearch = false}: { hideSearch?: boolean }) => {
    const [searchParams] = useSearchParams()
    const navigate = useSearchNavigateWithPathName()
    const generatePath = useDatxPathGenerator()
    const [inputText, setInputText] = useState(searchParams.get(phraseUrlSearchParamsName) ?? '')
    const [isSearchDisabled, setSearchDisabled] = useState(true)
    const [displayTooltip, setDisplayTooltip] = useState(false)

    useEffect(() => {
        setInputText(searchParams.get(phraseUrlSearchParamsName) ?? '')
    }, [searchParams])

    const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event?.target?.value
        setSearchDisabled(value.length < 3)
        if (!isSearchDisabled || !value.length) setDisplayTooltip(false)
        setInputText(value)
    }

    const onSearch = (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        if (isSearchDisabled) {
            setDisplayTooltip(true)
        } else {
            navigate({
                pathname: generatePath(ROUTES.search.path),
                search: inputText
            })
        }
    }

    return <div className="search-header">
        {!hideSearch && <>
            <SearchPhraseForm displayTooltip={displayTooltip} isSearchDisabled={isSearchDisabled} onSearch={onSearch}
                              inputHandler={inputHandler} inputText={inputText} inputTestId="header-search-field"
                              tooltipMessage="Please type at least 3 characters to perform a search"
            />
        </>}
    </div>
}

const Divider = () => <div className="header-divider"/>

const NavigationHeader = () => (
    <div className="navigation-header">
        <GuildMenu/>
        <ServicesMenu/>
        <ItemListMenu/>
        <Divider />
        <Notifications/>
        <Divider />
        <UserMenu/>
    </div>
)
const Header = ({
                    hideSearch = false,
                }: { hideSearch?: boolean }) => {
    const {userIsAuthenticated} = useTypedSelector(state => state.header)

    return (<div className="main-header-container" id="main-header-container">
            <AppBar className="main-header" id="main-header" position="relative">
                <div className="app-bar-left">
                    <Logo/>
                    <Divider />
                    <DrawerHeader/>
                </div>
                {userIsAuthenticated && <AuthenticatedTemplate>
                    <SearchHeader hideSearch={hideSearch}/>
                    <NavigationHeader/>
                </AuthenticatedTemplate>}
            </AppBar>
            <BannersComponent/>
        </div>
    )
}

export default Header
