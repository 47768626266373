import {GridCellParams} from '@mui/x-data-grid/models/params/gridCellParams'
import {
    formatExtractionFormat,
    getCreatedDate,
    getFileNameDetail,
    getLastModifiedDate,
    getQuotationCost,
    GetQuotationFile,
    getRequester,
    getService,
    getStatus
} from '../../genericComponents/serviceRequest/ServiceRequestFormatter'
import {CREDITS} from '../../constants/credits'
import {isExtraction, isRecurringExtraction, ServiceEnum} from '../../service/model/ServiceEnum'

function GuildServiceRequestContent({selectedServiceRequest}: { selectedServiceRequest: GridCellParams }) {

    const format = selectedServiceRequest.row.serviceTypeAttributes?.format
    const extractionFrequency = selectedServiceRequest.row.serviceTypeAttributes?.frequency

    const isRecurringExtractionType = isRecurringExtraction(selectedServiceRequest.row.service)
    const isExtractionType = isExtraction(selectedServiceRequest.row.service)

    const extractionPath = selectedServiceRequest.row.serviceTypeAttributes?.extractionPath


    return (
        <div className="body">
            <p data-testid="Requester">
                <span className="title" title="Requester">Requestor:</span>
                <span className="body-value">{getRequester(selectedServiceRequest)}</span>
            </p>
            <p data-testid="Service">
                <span className="title" title="Service">Service:</span>
                <span className="body-value">{getService(selectedServiceRequest)}</span>
            </p>

            {isExtractionType && (
                <p data-testid="ExtractionFormat">
                    <span className="title" title="Format">Format:</span>
                    <span className="body-value">{format}</span>
                </p>
            )}

            {isRecurringExtractionType && (
                <p data-testid="ExtractionFrequency">
                    <span className="title" title="Extraction Frequency">Extraction Frequency:</span>
                    <span className="body-value">{formatExtractionFormat(extractionFrequency)}</span>
                </p>
            )}

            {isRecurringExtractionType && (
                <p data-testid="ExtractionPath">
                    <span className="title" title="Extraction Path">Extraction Path:</span>
                    <span className="body-value">{extractionPath != undefined ? extractionPath : '/'}</span>
                </p>
            )}

            <p data-testid="Details">
                <span className="title" title="Details">Details:</span>
                <span className="body-value">{getFileNameDetail(selectedServiceRequest)}</span>
            </p>

            <div data-testid="Quotation Amount" className="quotation-section">
                <div className="title">
                    <div title="Quotation Amount">Quotation Amount:</div>
                    <div className="caption">({CREDITS})</div>
                </div>
                <div className="body-value">{getQuotationCost(selectedServiceRequest)}</div>
            </div>

            <p data-testid="Quotation Document">
                <span className="title" title="Quotation Document">Quotation Document:</span>
                <span className="body-value"><GetQuotationFile row={selectedServiceRequest.row}/></span>
            </p>

            <p data-testid="Status">
                <span className="title" title="Status">Status:</span>
                <span className="body-value">{getStatus(selectedServiceRequest)}</span>
            </p>

            <p data-testid="Creation Date">
                <span className="title" title="Creation Date">Creation Date:</span>
                <span className="body-value">{getCreatedDate(selectedServiceRequest)}</span>
            </p>

            <p data-testid="Last Modified">
                <span className="title" title="Last Modified">Last Modified:</span>
                <span className="body-value">{getLastModifiedDate(selectedServiceRequest)}</span>
            </p>
        </div>
    )
}

export default GuildServiceRequestContent