import Grid from '@mui/material/Grid'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {SearchItemCard} from './SearchItemCard'
import {SearchItem} from '../../model/SearchItem'
import {useDatxPathGenerator} from '../../../util/routing'
import {ROUTES} from '../../../constants/routing'

const SearchResultCardGrid = ({
                                  searchItems,
                                  addToMultiSelect,
                                  removeFromMultiSelect,
                                  isSelected,
                                  handleAddSingleItemToList,
                                  highlightPhrase,
                                  isMultiSelectionEnabled
                              }: {
    searchItems: SearchItem[]
    isSelected?: (item: SearchItem) => boolean,
    addToMultiSelect?: (item: SearchItem) => void,
    removeFromMultiSelect?: (item: SearchItem) => void
    handleAddSingleItemToList: (item: SearchItem) => void
    highlightPhrase?: string | null,
    isMultiSelectionEnabled: boolean
}) => {
    const navigate = useNavigate()
    const generatePath = useDatxPathGenerator()

    const onChange = ((item: SearchItem, checked: boolean) => {
        if (checked) {
            addToMultiSelect?.(item)
        }
        if (!checked) {
            removeFromMultiSelect?.(item)
        }
    })
    
    return (<Grid container spacing={2} padding={3}>
        {searchItems.map((item) => {
            return (
                <Grid key={item.id} item xs={3} data-testid="test-grid-cards">
                    <div onClick={() => navigate(generatePath(ROUTES.detailItem.path, {itemId : item.id}))} data-testid={'link-' + item.id}>
                        <SearchItemCard
                            item={item}
                            key={item.id}
                            onSelect={onChange}
                            handleAddSingleItemToList={handleAddSingleItemToList}
                            isSelected={isSelected?.(item)}
                            highlightPhrase={highlightPhrase}
                            displaySelectionCheckbox={isMultiSelectionEnabled}
                        />
                    </div>
                </Grid>
            )
        })
        }
    </Grid>)
}

export default SearchResultCardGrid