import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import './ConfirmServiceSubscriptionActionDialog.scss'
import CustomDialog from '../../../genericComponents/customDialog/CustomDialog'
import {Button} from '@mui/material'

const ConfirmServiceSubscriptionActionDialog = ({
    open,
    closeDialog,
    children,
    title,
    onSubmit,
    isLoading,
    className
}: {
    open: boolean
    closeDialog: any
    children: React.ReactNode
    title: string
    onSubmit: () => void
    isLoading: boolean
    className?: string
}) => {
    return (
        <CustomDialog
            className={`subscription-action-dialog ${className}`}
            open={open}
            title={title}
            onClose={closeDialog}
        >
            <div>
                <span className="subscription-action-dialog-text">{children}</span>

                <div className="subscription-action-dialog-buttons">
                    <Button className="subscription-action-dialog-button" onClick={closeDialog}>
                        Cancel
                    </Button>

                    <LoadingButton
                        className="subscription-action-dialog-submit"
                        color="primary"
                        variant="contained"
                        onClick={onSubmit}
                        data-testid="confirm-action-subscription"
                        loading={isLoading}
                    >
                        Confirm
                    </LoadingButton>
                </div>
            </div>
        </CustomDialog>
    )
}

export default ConfirmServiceSubscriptionActionDialog
