import React from 'react'
import {UploadMonitoredBundleMonitoringForm} from './Forms/UploadForm/UploadMonitoredBundleMonitoringForm'
import {setSnackbarMessage} from '../../../genericComponents/commonSlice'
import {useTypedDispatch} from '../../../util/store'
import {ItemList} from '../../../itemList/model/ItemList'
import {MonitoredBundle} from '../../../item/model/MonitoredBundle'
import {useCreateItemMonitoringServiceRequestMutation} from '../rtkServiceRequestApi'
import {useCurrentGuildContext, useDatxPathGenerator} from '../../../util/routing'
import {ServiceRequestStep} from '../model/ServiceRequestStep'
import {ReviewMonitoredBundleMonitoringForm} from './Forms/ReviewStep/ReviewMonitoredBundleMonitoringForm'
import {mixed, object, string} from 'yup'
import {ROUTES} from '../../../constants/routing'
import {ServiceRequestFormTemplate} from '../genericComponents/formTemplate/ServiceRequestFormTemplate'
import {ServiceRequestWorkflowInput} from '../model/ServiceRequestWorkflowInput'

export interface RequestMonitoredBundleMonitoringInput extends ServiceRequestWorkflowInput {
    itemList?: ItemList,
    monitoredBundle?: MonitoredBundle
}

const selectItemMonitoring = {
    itemList: mixed().required(),
    monitoredBundle: mixed().required(),
}

const validationSchema = {
    ...selectItemMonitoring,
    selectedWorkflowId: string().required()
}

const MonitorMonitoredBundleForm = () => {
    const dispatch = useTypedDispatch()
    const [currentGuildContext] = useCurrentGuildContext()
    const [createRequest] = useCreateItemMonitoringServiceRequestMutation()
    const generatePath = useDatxPathGenerator()

    const steps: ServiceRequestStep<RequestMonitoredBundleMonitoringInput>[] = [{
        name: 'Request Item Monitoring',
        component: <UploadMonitoredBundleMonitoringForm/>,
        pageValidation: object<RequestMonitoredBundleMonitoringInput>(selectItemMonitoring),
        description: 'By filling this form you will be requesting to monitor your chosen list of components.'
    }, {
        name: 'Request Item Monitoring',
        component: <ReviewMonitoredBundleMonitoringForm/>,
        pageValidation: object<RequestMonitoredBundleMonitoringInput>(validationSchema)
    }]

    const handleCompleteUploadForm = ({
                                          itemList,
                                          monitoredBundle,
                                          selectedWorkflowId,
                                      }: RequestMonitoredBundleMonitoringInput): Promise<any> | undefined => {
        if (!monitoredBundle) {
            dispatch(setSnackbarMessage('You have to select an information package in order to monitor one.'))
            return
        }

        if (!selectedWorkflowId) return

        return createRequest({
            guildId: currentGuildContext?.guildId ?? '',
            itemListIds: [itemList?.id ?? ''],
            monitoredBundleId: monitoredBundle.id,
            monitoredBundleName: monitoredBundle.label,
            workflowConfigurationId: selectedWorkflowId,
        })
    }

    return <ServiceRequestFormTemplate
        steps={steps}
        handleFormSubmit={handleCompleteUploadForm}
        initialValues={{
            itemList: undefined,
            monitoredBundle: undefined,
            selectedWorkflowId: '',
        }}
        previousUrl={generatePath(ROUTES.servicesCatalogue.path)}
        title="Request Item Monitoring"
    />
}


export default MonitorMonitoredBundleForm
