import React, {useState, useEffect} from 'react'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import {ROUTES} from '../../constants/routing'
import './HomeSearch.scss'
import {Tooltip} from '@mui/material'
import {useDatxPathGenerator, useSearchNavigateWithPathName} from '../../util/routing'
import {SearchRounded} from '@mui/icons-material'

export const HomePageSearch = () => {
    const navigate = useSearchNavigateWithPathName()
    const generatePath = useDatxPathGenerator()

    const [inputText, setInputText] = useState('')
    const [isSearchDisabled, setSearchDisabled] = useState(true)
    const [displayTooltip, setDisplayTooltip] = useState(false)
    const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputText(event?.target?.value)
    }
    useEffect(() => {
        setSearchDisabled(inputText.length < 3)
        if (!isSearchDisabled || !inputText.length) {
            setDisplayTooltip(false)
        }
    }, [inputText])
    const onSearch = (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        if (isSearchDisabled) {
            setDisplayTooltip(true)
        } else {
            navigate({
                pathname: generatePath(ROUTES.search.path),
                search: inputText
            })
        }
    }
    // I didn't find a better way to put the background image in react
    return <div className="home-search-container" style={{
        backgroundImage: 'url("/images/home/Landing_Page_Image.jpeg")'
    }}>
        <h1 className="welcome-to-datx-title">
            Welcome to <b>DATX</b>
        </h1>
        <form className="homePageSearchBar" onSubmit={onSearch}>

            <Tooltip open={displayTooltip && isSearchDisabled}
                     title="Please type at least 3 characters to perfom a search">
                <InputBase
                    name="homePageSearchBar"
                    inputProps={{'data-testid': 'homePageSearchInput'}}
                    className="homePageSearchInput"
                    id="homePageSearchInput"
                    sx={{ml: 1,}}
                    type="input"
                    placeholder="Search items by part name / number"
                    onChange={inputHandler}
                />
            </Tooltip>
            <IconButton className={` ${isSearchDisabled && 'disabled'} homePageSearchIconContainer`} type="submit"
                        disableRipple={true} data-testid={'SearchIcon'}>
                <SearchRounded className="homePageSearchIcon" id="homePageSearchIcon"/>
            </IconButton>
        </form>
    </div>
}

export default HomePageSearch
