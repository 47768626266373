import React from 'react'
import {formatDate, formatMilliseconds, formatMonth, formatUnixTime, fromNow} from './DateService'
import {string} from 'yup'

export const DateDisplay = ({entryDate, className, time = true, from = false, initialValue = ''}: {
    entryDate: number[],
    className?: string | undefined,
    time?: boolean,
    from?: boolean,
    long?: boolean,
    initialValue?: string
}) => {

    const dateToDisplay = formatMilliseconds([...entryDate])
    formatMonth(dateToDisplay)

    const timeToDisplay = formatDate(dateToDisplay)

    return <span className={className}>
        {timeToDisplay === 'Invalid Date' ? initialValue :
            (timeToDisplay && `${timeToDisplay} 
               ${time ? (formatUnixTime(dateToDisplay)) : ''} 
               ${from ? (' - ' + fromNow(dateToDisplay)) : ''}`
            )
        }
    </span>

}