import React from 'react'
import {ArtifactAttribute} from '../attributeTemplates/ArtifactAttribute'
import {TemplateDefinitionForDisplaySet} from './TemplateDefinitionForDisplaySet'
import {sortByStartValidity} from '../utils/sortedDefinitions'
import {AttributeDefinitionModel} from '../model/AttributeModel'

export const ArtifactDataSheetDefinitionTemplate = ({definition}: { definition: AttributeDefinitionModel }) => {
    const sortedAttributes = sortByStartValidity(definition.attributes)
    return (
        <TemplateDefinitionForDisplaySet definition={definition}>
            {sortedAttributes.map(attribute => <ArtifactAttribute key={attribute.id} attribute={attribute}/>)}
        </TemplateDefinitionForDisplaySet>
    )
}
