import {ToggleButton, ToggleButtonGroup, Typography} from '@mui/material'

export enum ItemDetailView {
    CUSTOMISED = 'customised',
    DEFAULT = 'default'
}

interface ItemDetailToggleButtonProps {
    selectedItemDetailView?: ItemDetailView,
    setSelectedItemDetailView?: (value: (((prevState: ItemDetailView) => ItemDetailView) | ItemDetailView)) => void
}

const ItemDetailToggleButton = ({
                                     selectedItemDetailView,
                                     setSelectedItemDetailView
                                 }: ItemDetailToggleButtonProps) => {


    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newItemDetailView: ItemDetailView,
    ) => {
        if (setSelectedItemDetailView) {
            setSelectedItemDetailView(newItemDetailView)
        }
    }

    return (
        <ToggleButtonGroup
            color="primary"
            value={selectedItemDetailView}
            exclusive
            onChange={handleChange}
            className="item-detail-view-toggle"
        >
            <ToggleButton className="item-detail-view-toggle-button custom" value={ItemDetailView.CUSTOMISED}>
                <Typography className="tabs-view">Customised View</Typography>
            </ToggleButton>
            <ToggleButton className="item-detail-view-toggle-button default" value={ItemDetailView.DEFAULT}>
                <Typography className="tabs-view">Default View</Typography>
            </ToggleButton>
        </ToggleButtonGroup>
    )
}

export default ItemDetailToggleButton
