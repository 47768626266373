import {ServiceRequestChoiceCard} from '../genericComponents/ServiceRequestChoiceCard'
import {ServiceRequestFormTitle} from '../genericComponents/ServiceRequestFormTitle'
import React from 'react'
import {ServiceRequestTemplate} from '../genericComponents/structure/ServiceRequestTemplate'
import {ServiceRequestHeader} from '../genericComponents/structure/ServiceRequestHeader'
import {ServiceRequestBody} from '../genericComponents/structure/ServiceRequestBody'
import {useDatxPathGenerator} from '../../../util/routing'
import {ROUTES} from '../../../constants/routing'
import PlaylistAddRoundedIcon from '@mui/icons-material/PlaylistAddRounded'
import './PrivateAttributeServiceRequestForm.scss'
import DrawOutlinedIcon from '@mui/icons-material/DrawOutlined'

export const SelectPrivateAttributeRequestType = () => {
    const generatePath = useDatxPathGenerator()
    return <ServiceRequestTemplate>

        <ServiceRequestHeader>
            <ServiceRequestFormTitle title="Manage Private Attributes"
                                     previousUrl={generatePath(ROUTES.servicesCatalogue.path)}/>
        </ServiceRequestHeader>

        <ServiceRequestBody className="service-request-choice-card-container">
            <ServiceRequestChoiceCard title="Create New" id="create-new-attribute"
                                      icon={<PlaylistAddRoundedIcon/>}
                                      description="Create a new private attribute"
                                      to={generatePath(ROUTES.selectTypeCreationPrivateAttribute.path)}/>
            <ServiceRequestChoiceCard title="Update Existing" id="update-existing-attribute"
                                      icon={<DrawOutlinedIcon/>}
                                      description="Update an existing private attribute"
                                      to={generatePath(ROUTES.updateStringAttribute.path)}/>
        </ServiceRequestBody>
    </ServiceRequestTemplate>
}