import ItemCardClickableProperty from '../generic/ItemRowCardClickableProperty'
import {ROUTES} from '../../../../../constants/routing'
import * as React from 'react'
import {useDatxPathGenerator} from '../../../../../util/routing'
import {SearchItem} from '../../../../model/SearchItem'

export const ItemTechnicalClassificationComponent = ({item}: {item: SearchItem}) => {
    const generatePath = useDatxPathGenerator()

    return <ItemCardClickableProperty
                               value={item?.technical?.label}
                               linkTo={generatePath(ROUTES.searchByClassificationNodeDescendants.path, { classificationId: item?.technical?.id })}
                               testId={`link-${item.technical.id}-technical-node-id`}
    />
}