import CloseIcon from '@mui/icons-material/Close'

const NoAutoApproveStatus = ({noApprovalStatus}: {noApprovalStatus: string}) => {
    return (
        <div className="details-form-no-auto-quotation">
            <CloseIcon className="details-icon"/>
            <div className="details-value">{noApprovalStatus}</div>
        </div>
    )
}

export default NoAutoApproveStatus
