import {cyan} from '@mui/material/colors'
import {createTheme} from '@mui/material/styles'

export const palette = {
    //need to keep this for override original style from theme
    //primary1Color duplicated with primaryBlueColor(named in this way for have suggested name)
    //textColor duplicated with darkGreyColor(named in this way for have suggested name)
    primary1Color: 'rgb(70, 109, 219)',
    textColor: '#333333',

    //blue
    primaryBlueColor: 'rgb(70, 109, 219)',
    lightBlueColor: 'rgb(137, 187, 227)', // Primary200 (#89BBE3)
    hoverBlueColor: 'rgba(70,142,199,0.4)', // Primary300(#468EC7)
    extraLightBlueColor: 'rgb(204, 232, 255)', // Primary100 (#CCE8FF)
    inactiveBlueColor: 'rgba(71, 143, 200, 0.16)', //
    selectedBlueColor: 'rgba(70,142,199,0.08)',
    textBlueColor: 'rgb(5,56,79)', // (#2C3040)
    darkBlueColor: 'rgb(44,48,64)', // (#05384F)

    //gray
    black: 'rgb(44, 48, 64)', // Dark500 (#2C3040)
    darkGreyColor: '#333333',
    defaultGreyColor: 'rgb(133, 139, 166)', //  Dark100 (#858BA6)
    borderGreyColor: '#E6E6E6',
    lightGreyColor: 'rgba(247,248,249,1)', // Light 200 (#F7F8F9)
    backgroundGreyColor: 'rgb(247, 248, 249)',
    white: 'rgb(255, 255, 255)',

    //other
    redColor: 'rgb( 230, 58, 86)', // Error300 (#E63A56)
    lightRedColor: 'rgb(255, 205, 212)', // Error100 (#FFCDD4)
    errorRed: 'rgb(255, 96, 96)', // added by jinglin, please dc, copying colors directly from figma
    successGreen: 'rgb(82, 214, 129)', // added by jinglin, please dc, copying colors directly from figma
    orangeColor: 'rgb( 255, 193, 64)', // Warning300 = Yellow300 (#FFC140) - used in warning/status
    orangeColorInterface: 'rgb(242, 138, 61)', // Orange 300 (#F28A3D) - is used in the interface (on a graph, button)
    greenColor: 'rgb(45, 180, 83)', // Success300 (#2DB453)

    //part
    partDarkColor: 'rgb(71, 143, 200)',
    partLightColor: 'rgba(71, 143, 200, 0.50)',

    //manufacturer
    manfucaturerDarkColor: cyan[700],
    manufacturerLightColor: cyan[700] + '80',

    requestDarkColor: 'rgb(132, 111, 171)',

    //request
    green300: 'rgb(169, 204, 71)',
    magenta300: 'rgb(180, 54, 117)',
    yellow300: 'rgb(255, 233, 64)',
    grayishBlue: 'rgb(103, 176, 204)',
}

const textDimensions = {
    bodyTextSize: 11,
    bodyTextLine: '15px',
    titleSize: 20,
    titleLine: '24px',
    widgetTitleSize: 18,
    widgetTitleLine: '22px',
    listItemLabelSize: 12,
    listItemLabelLine: '16px',
    labelSize: 14,
    labelLine: '18px',
}

const iconDimensions = {
    small: '16px',
    medium: '24px',
    large: '32px',
    reduceViewSize: '38px',
    xlarge: '64px',
}

const fontSize = {
    xsmall: '12px',
    small: '14px',
    medium: '16px',
    large: '18px',
    xlarge: '24px',
}

const fontStyle = {
    regular: {
        fontStyle: 'normal',
        fontWeight: 400,
    },
    medium: {
        fontStyle: 'normal',
        fontWeight: 500,
    },
}

const fonts = {
    smallText: {
        ...fontStyle.regular,
        fontSize: fontSize.xsmall,
    },
    smallLabel: {
        ...fontStyle.regular,
        fontSize: fontSize.medium,
    },
    label: {
        ...fontStyle.regular,
        fontSize: fontSize.large,
    },
    bigLabel: {
        ...fontStyle.regular,
        fontSize: fontSize.xlarge,
        textTransform: 'uppercase',
    },
    smallTextMed: {
        ...fontStyle.medium,
        fontSize: fontSize.xsmall,
    },
    text: {
        ...fontStyle.regular,
        fontSize: fontSize.small,
    },
    importantSmallLabel: {
        ...fontStyle.medium,
        fontSize: fontSize.medium,
        textTransform: 'uppercase',
    },
    importantLabel: {
        ...fontStyle.medium,
        fontSize: fontSize.medium,
    },
}

const grid = {
    height: {
        x1: '160px',
        x2: '410px',
    },
}

const widget = {
    width: 250,
    height: 250,
}

const header = {
    height: '56px',
}

const footer = {
    height: '60px',
}

const iconButton = {
    small: {
        button: {
            width: 32,
            height: 32,
            padding: '6px',
            zIndex: 'initial', // This forces tooltip display on top of the button
        },
        icon: {
            width: 20,
            height: 20,
        },
    },
    medium: {
        button: {
            width: 72,
            height: 72,
            padding: 16,
        },
        icon: {
            width: 36,
            height: 36,
        },
    },
}

const input = {
    height: '15px',
    lineHeight: '15px',
    fontSize: '13px',
    padding: '5px 3px',
    color: '#333333',
    border: `1px solid ${palette.borderGreyColor}`,
    borderRadius: '4px',
    boxShadow: '0px 0px',
}

// TODO Find a way to correctly manage the theme in typescript
export const thalesTheme = createTheme({
        palette: {
            ...palette,
            primary: {
                main: palette.primaryBlueColor,
            },
            secondary: {
                main: palette.defaultGreyColor,
            },
            text: {
                primary: palette.textColor,
            },
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        size: textDimensions,
        iconSize: iconDimensions,
        iconButton,
        input,
        appBar: {
            height: 50,
        },
        drawer: {
            color: palette.white,
        },
        menuItem: {
            hoverColor: palette.lightGreyColor,
            selectedTextColor: palette.primaryBlueColor,
        },
        typography: {
            fontFamily: [
                '"Source Sans 3"',
                'sans-serif',
            ].join(','),
            ...fonts,
        },
        fonts,
        grid,
        widget,
        header,
        footer,
        components: {
        },
    }
)
