import React from 'react'
import {Outlet, useLocation, useNavigate} from 'react-router-dom'
import {redirectToLogin, useTryToGetToken} from '../../authentication/AuthHooksAndFunctions'
import {useIsAuthenticated, useMsal} from '@azure/msal-react'
import './SecuredApp.scss'
import LoadingSpinner from '../../genericComponents/LoadingSpinner'


const SecuredApp = () => {
    const {search, pathname} = useLocation()
    const navigate = useNavigate()
    const {inProgress} = useMsal()
    const isAuthenticated = useIsAuthenticated()

    useTryToGetToken(
        () => null,
        () => redirectToLogin(pathname + search, navigate),
        [pathname, search]
    )

    if (isAuthenticated && inProgress === 'none') {
        return (
            <div className="authenticated-container" data-testid="secured-app-test-component">
                <Outlet/>
            </div>
        )
    }

    return <LoadingSpinner/>
}

export default SecuredApp
