import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {useAddSpecificItemsToShareMutation, useGetSharingByIdQuery} from '../../../rtkGuildApi'
import {Button, Divider, FormControlLabel, Radio, RadioGroup} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import React, {useState} from 'react'
import {ItemList} from '../../../../itemList/model/ItemList'
import SharingItemListSuccessfully from './SharingItemListSuccessfully'
import ItemListSelect from '../../common/ItemListSelect'

const SelectItemListForSharing = ()=> {
    const {sharingId} = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const targetGuildName = location?.state?.targetGuildName ?? 'Target Guild'

    const {data: sharingDetail} = useGetSharingByIdQuery(sharingId!, {skip: !sharingId})
    const [addSpecificItemsToShare, updateSharingResult] = useAddSpecificItemsToShareMutation()
    const [itemListAction, setItemListAction] = useState('')
    const [selectedList, setSelectedList] = useState<ItemList | undefined>()

    const setNewList = (list: ItemList) => {
        setSelectedList(list)
    }
    const handleSubmit = () => {
        addSpecificItemsToShare(
            {
                sharingId: sharingId ?? '',
                itemIdsOfItemList: selectedList?.items
            }
        )
    }

    const handleSelectionChange = (event: any) => {
        setItemListAction(event.target.value)
    }

    const selectItemListForm = () => {
        return <div className="manage-specific-item-page-container">
            <div className="share-by-title-container">
                <h5 id="shareToOtherGuildTitle" className="page-title">
                    Share Specific Items with <span>{sharingDetail?.targetGuildName}</span>
                </h5>
                <Divider className="divider-title"/>
            </div>

            <div className="management-section">
                <div className="management-description">
                    <p>By choosing the list for sharing, the <b>Guild {sharingDetail?.targetGuildName}</b> will receive
                        view access for these items.<br/>
                        You may share by selecting items from existing list or create new list by uploading an item data
                        template.</p>
                    <p>* When <b>new items</b> are added to the list in the future, sharing will <b>not be updated
                        automatically</b>.</p>
                </div>
                <RadioGroup row value={itemListAction} onChange={handleSelectionChange}>
                    <FormControlLabel className="form-control-label" value={''} control={<Radio/>}
                                      label="Items From Existing Item List"/>
                </RadioGroup>
                <p>Future changes made to the list of shared items will <b>not be updated</b>.</p>
                <ItemListSelect value={selectedList} onListSelection={setNewList}/>
            </div>

            <Divider className="divider"/>
            <div className="step-footer">
                <Button onClick={() => navigate(-1)} className="back-button">Back</Button>
                <LoadingButton
                    data-testid="shareSelectedItemList"
                    className="share-button"
                    variant="contained"
                    color="primary"
                    disabled={!selectedList}
                    onClick={handleSubmit}
                    loading={updateSharingResult.isLoading}
                >
                    Share
                </LoadingButton>
            </div>
        </div>
    }

    const sharingItemListSuccessfullyPage = <SharingItemListSuccessfully
        targetGuildId={updateSharingResult?.data?.targetGuildId ?? ''}
        organizationsSharing={updateSharingResult?.data?.organizationsSharing ?? []}
        targetGuildName={targetGuildName}
        itemListName={selectedList?.name}
        specificItemsShared={updateSharingResult.data?.specificItemsSharing?.itemIds ?? []}
        itemsOfItemList={selectedList?.items ?? []}
    />

    const isUpdateSharingSuccessful = !updateSharingResult.isUninitialized && updateSharingResult.isSuccess && updateSharingResult.data

    if (isUpdateSharingSuccessful)
        return <>{ sharingItemListSuccessfullyPage }</>
    return <>{ selectItemListForm() }</>
}

export default SelectItemListForSharing
