import {IdType} from '../../util/models/IdType'

export const directionURLSearchParamsName = 'order'
export const fieldURLSearchParamsName = 'orderBy'

export const definitionIdUrlSearchParamsName = 'definitionId'

export interface Sorting {
    direction: SortingDirection
    field: SortingField,
    definitionId?: IdType
}


export type SortingDirection = 'asc' | 'desc';
export const isSortingDirection = (input: string | undefined | null): input is SortingDirection => {
    if (!input) return false
    return ['asc', 'desc'].includes(input)
}
export const toSortingDirection = (input: string | undefined | null): SortingDirection => {
    if (isSortingDirection(input)) {
        return input
    }
    return 'asc'
}


export enum SortingField {
    PART_NUMBER = 'PartNumber',
    NAME_DEFINED_BY = 'NameDefinedBy',
    NAME_TECHNICAL_NODE = 'NameTechnicalNode',
    DEFINITION_VALUE = 'DefinitionValue'
}


export const isSortingField = (input: SortingField | undefined | null) => {
    if (!input) return false
    return Object.values(SortingField).includes(input)
}
export const toSortingField = (input: string | undefined | null): SortingField => {
    if (isSortingField(input as SortingField)) return input as SortingField
    return SortingField.PART_NUMBER
}