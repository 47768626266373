import Hotjar from '@hotjar/browser'
import {getCookieConsentFor, removeCookie} from '../../Cookies/cookiesUtils'
import {CookiesCategory, CookieVendor} from '../../Cookies/CookiesTypes'


const getHotJarCookieConsent = () => {
    return getCookieConsentFor(CookiesCategory.PerformanceCookies, CookieVendor.Hotjar)
}

const cleanAllHotJarCookies = () => {
    const siteId = process.env.REACT_APP_hotjar_site_id
    removeCookie('_hjSessionUser_' + siteId)
    removeCookie('_hjFirstSeen')
    removeCookie('_hjHasCachedUserAttributes')
    removeCookie('_hjUserAttributesHash')
    removeCookie('_hjUserAttributes')
    removeCookie('hjViewportId')
    removeCookie('hjActiveViewportIds')
    removeCookie('_hjSession_' + siteId)
    removeCookie('_hjSessionTooLarge')
    removeCookie('_hjSessionResumed')
    removeCookie('_hjCookieTest')
    removeCookie('_hjLocalStorageTest')
    removeCookie('_hjSessionStorageTest')
    removeCookie('_hjIncludedInSessionSample_' + siteId)
    removeCookie('_hjAbsoluteSessionInProgress')
    removeCookie('_hjTLDTest')
    removeCookie('_hjClosedSurveyInvites')
    removeCookie('_hjDonePolls')
    removeCookie('_hjMinimizedPolls')
    removeCookie('_hjShownFeedbackMessage')
}

export const stopHotjar = () => {
    cleanAllHotJarCookies()
}

export const startHotjar = () => {
    if (process.env.REACT_APP_hotjar_enabled !== 'true')
        return

    const siteId = process.env.REACT_APP_hotjar_site_id
    const hotjarVersion = process.env.REACT_APP_hotjar_version

    if (siteId && hotjarVersion) {
        Hotjar.init(Number.parseInt(siteId), Number.parseInt(hotjarVersion))
    }
}

export const initHotJar = () => {
    if(!getHotJarCookieConsent()) {
        cleanAllHotJarCookies()
        return
    }

    startHotjar()
}