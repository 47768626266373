import React from 'react'
import './SFTPPasswordModal.scss'
import CustomModal from '../../../genericComponents/CustomModal'
import {Button, IconButton, InputAdornment, OutlinedInput} from '@mui/material'
import {Visibility, VisibilityOff} from '@mui/icons-material'
import { CopyButton } from '../../../genericComponents/CopyButton'

export const SFTPPasswordModal = ({password, setModalOpen}: {
    password?: string,
    setModalOpen: (setModalOpen: boolean)=> void
}) => {
    const [isPasswordVisible, setPasswordVisible] = React.useState(false)

    const handleClickShowPassword = () => {
        setPasswordVisible(!isPasswordVisible)
    }

    return (
        <CustomModal handleClose={() => setModalOpen(false)}>
            <div className="ftp-password-modal">
                <div className="header">
                    <p className="title">Generate New Password</p>
                </div>
                <div className="ftp-password-subtitle">Password</div>
                <OutlinedInput
                    id="outlined-adornment-password"
                    fullWidth={true}
                    type={isPasswordVisible ? 'text' : 'password'}
                    value={password}
                    disabled={true}
                    endAdornment={
                    <>
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                color="primary"
                                onClick={handleClickShowPassword}
                                edge="end"
                            >
                                {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        <InputAdornment position="end">
                            <CopyButton toCopy={password}/>
                        </InputAdornment>
                    </>
                    }
                    label="Password"
                />
                <div className="ftp-notice"><p><span>* </span>If you have previously generated a password for your SFTP account, it has been be overwritten.</p></div>
                <div className="ftp-notice"><p><span>** </span>New password propagation may take over a minute</p></div>
                <div className="footer">
                    <Button variant= "outlined" color="primary" onClick={() => setModalOpen(false)} >Close</Button>
                </div>
            </div>
        </CustomModal>
    )
}