import React, {ReactNode, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'
import axios from 'axios'
import { signinRequest } from '../authentication/authConfig'

const RequestInterceptor = ({children}: { children?: ReactNode }) => {
    const { instance } = useMsal()
    const navigate = useNavigate()


    useEffect(() => {
        const interceptor = axios.interceptors.request.use(async (config) => {
            const response = await instance
                .acquireTokenSilent(signinRequest)
                .catch(() => {
                    console.error(
                        'Not able to get the access token for this active account'
                    )
                    navigate('/login')
                })

            if (!response?.accessToken) {
                console.error('No access token available')
                navigate('/login')
            }

            const bearer = `Bearer ${response?.accessToken}`
            if (config.headers) {
                config.headers.Authorization = bearer
                config.headers['Content-Type'] = 'application/json'
            }
            return config
        })
        return () => {
            axios.interceptors.request.eject(interceptor)
        }
    }, [])

    return <>{children}</>
}
export { RequestInterceptor }
