import {getServiceName, ServiceEnum} from '../../../../service/model/ServiceEnum'

export type TransactionTypeEnum = ServiceEnum | TransactionAdditionalTypes

enum TransactionAdditionalTypes {
    ADD_CREDITS = 'ADD_CREDITS'
}

const transactionTypeNames = {
    [TransactionAdditionalTypes.ADD_CREDITS]: {
        title: 'Credit Top Up'
    }
}

export const getTransactionTypeName = (value: TransactionTypeEnum | ServiceEnum): string => {
    // Check if it's an additional transaction type and then fallback to service name
    return (transactionTypeNames as any)[value]?.title ?? getServiceName(value)
}
