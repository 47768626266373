import React from 'react'
import {NavLink, useLocation, useMatch, useResolvedPath} from 'react-router-dom'
import {List, ListItemButton, Typography} from '@mui/material'
import {legalMenus} from '../legalConstants'
import './LegalManagementMenu.scss'
import {useDatxPathGenerator} from '../../util/routing'
import {guildContextPrefix} from '../../constants/routing'

const LegalManagementMenu = () => {
    const {pathname} = useLocation()
    const isInGuildContextAwareLocation = pathname.includes(`/${guildContextPrefix}/`)

    return (
        <List component="nav" aria-label="legal management menu" className="legal-management-menu">
            {legalMenus.map((item) => (
                <MenuItem key={item.label} label={item.label}
                          linkTo={isInGuildContextAwareLocation ? item.linkToGuildContextAwarePath : item.linkToPublicPath}/>
            ))}
        </List>
    )
}

const MenuItem = ({label, linkTo}: { label: string, linkTo: string }) => {
    const generatePath = useDatxPathGenerator()
    const menuLevel = 0
    const resolved = useResolvedPath(generatePath(linkTo))
    const match = useMatch({path: resolved.pathname, end: true})

    const menuContent = (
        <ListItemButton
            className={'nav-link-content'}
            style={{paddingLeft: (menuLevel + 1) * 16}}
        >
            <Typography className="title" data-testid="only-label-without-link">{label}</Typography>
        </ListItemButton>
    )

    return (
        <React.Fragment key={label}>
            {linkTo ? (
                <NavLink
                    to={resolved}
                    className={`nav-link ${
                        match && 'selected-menu'
                    }`}
                    data-testid="nav-link-test"
                >
                    {menuContent}
                </NavLink>
            ) : (
                menuContent
            )}
        </React.Fragment>
    )
}

export {LegalManagementMenu, MenuItem}