import {searchAPI} from '../search/rtkSearchApi'
import {DisplaySet} from './model/DisplaySet'

export const DISPLAY_SETS_TAG = 'DisplaySets'
export const DISPLAY_SET_TREE_TAG = 'DisplaySetTree'

export const displaySetApi = searchAPI.enhanceEndpoints({
    addTagTypes: [DISPLAY_SETS_TAG, DISPLAY_SET_TREE_TAG]
}).injectEndpoints({
    endpoints :(builder) => ({
        getAllDisplaySets : builder.query<DisplaySet[], void>({
            query: () => ({
                url :'displaysets',
                method: 'GET'
            }),
            transformErrorResponse: () => 'Something went wrong during display set retrieval',
            providesTags: [DISPLAY_SETS_TAG],
            keepUnusedDataFor: 300
        }),
        getDisplaySetTree : builder.query<DisplaySet[], void>({
            query: () => ({
                url :'displaysets/tree',
                method: 'GET'
            }),
            transformErrorResponse: () => 'Something went wrong during display set retrieval',
            providesTags: [DISPLAY_SET_TREE_TAG],
            keepUnusedDataFor: 300
        }),
    })
})

export const {useGetAllDisplaySetsQuery, useGetDisplaySetTreeQuery} = displaySetApi


