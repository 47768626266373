import { NOTIFICATION_CATEGORIES } from '../constants'
import GuildInvitationNotification from './GuildInvitationNotification'
import React from 'react'
import GuildUserRemoved from './GuildUserRemoved'
import ServiceRequestDeliveryNotification from './ServiceRequestDeliveryNotification'
import ServiceRequestQuotationDeliveredNotification from './ServiceRequestQuotationDeliveredNotification'
import ServiceRequestCreatedNotification from './ServiceRequestCreatedNotification'
import GenericNotificationDisplay from './GenericNotificationDisplay'
import { Notification } from '../../../models/Notification'
import './NotificationItem.scss'
import GuildCreationNotification from './GuildCreationInvitation'


const NotificationItem = (notification:Notification):any=>{
    const notificationItemGenerateMap = new Map<string,any>([])
    notificationItemGenerateMap.set(NOTIFICATION_CATEGORIES.guildUserInvitation,<GuildInvitationNotification notification={notification} />)
    notificationItemGenerateMap.set(NOTIFICATION_CATEGORIES.guildUserRemoved,<GuildUserRemoved notification={notification} />)
    notificationItemGenerateMap.set(NOTIFICATION_CATEGORIES.guildCreationApproved,<GuildCreationNotification notification={notification} />)
    notificationItemGenerateMap.set(NOTIFICATION_CATEGORIES.guildCreationDenied,<GuildCreationNotification notification={notification} />)
    notificationItemGenerateMap.set(NOTIFICATION_CATEGORIES.serviceRequestCompleted,<ServiceRequestDeliveryNotification notification={notification}/>)
    notificationItemGenerateMap.set(NOTIFICATION_CATEGORIES.serviceRequestQuotationUpdate,<ServiceRequestQuotationDeliveredNotification notification={notification}/>)
    notificationItemGenerateMap.set(NOTIFICATION_CATEGORIES.serviceRequestCreated,<ServiceRequestCreatedNotification notification={notification}/>)
    return notificationItemGenerateMap.get(notification.category) ??
        (<GenericNotificationDisplay
            notification={notification}
            title="Notification"
            canClose={true}
        />)
}

export default NotificationItem
