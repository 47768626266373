import './GuildMemberManagementDialog.scss'
import CustomDialog from '../../../genericComponents/customDialog/CustomDialog'
import {Button, Divider} from '@mui/material'
import {IdType} from '../../../util/models/IdType'
import LoadingButton from '@mui/lab/LoadingButton'
import {useRemoveUserFromGuildMutation} from '../../rtkGuildApi'

const GuildMemberManagementDialog = ({open, closeDialog, params, isMemberStatusSentOrRejected, email, canUserBeRemoved}:
                                         {
                                             open: boolean,
                                             closeDialog: () => void,
                                             params: { guildId: IdType | undefined, memberId: IdType | undefined },
                                             isMemberStatusSentOrRejected: boolean,
                                             email: string,
                                             canUserBeRemoved: boolean,
                                         }
) => {

    const removeDialog = <RemoveUserDiaglog
        open={open}
        closeDialog={closeDialog}
        params={params}
        confirmationMsg={`${email} will no longer be able to access this guild.`}
        title="Remove User"
        actionButtonTitle="Remove User"
        canUserBeRemoved={canUserBeRemoved}
    />

    const cancelDialog = <RemoveUserDiaglog
        open={open}
        closeDialog={closeDialog}
        params={params}
        confirmationMsg={`${email} will no longer invited in this guild.`}
        title="Cancel invitation"
        actionButtonTitle="Cancel Invitation"
        canUserBeRemoved={canUserBeRemoved}
    />

    return isMemberStatusSentOrRejected ? cancelDialog : removeDialog

}


const RemoveUserDiaglog = ({
                               open,
                               closeDialog,
                               params,
                               confirmationMsg,
                               title,
                               actionButtonTitle,
                               canUserBeRemoved
                           }:
                               {
                                   open: boolean,
                                   closeDialog: () => void,
                                   params: { guildId: IdType | undefined, memberId: IdType | undefined },
                                   confirmationMsg: string,
                                   title: string,
                                   actionButtonTitle: string,
                                   canUserBeRemoved: boolean,
                               }) => {

    const [removeUser, removeUserResult] = useRemoveUserFromGuildMutation()
    const onSubmit = () => {
        removeUser({guildId: params.guildId, memberId: params.memberId})
    }

    return (<div>
            {canUserBeRemoved ?
                <CustomDialog
                    className="confirmation-dialog"
                    title={title}
                    open={open}
                    onClose={closeDialog}
                >
                    <div>
                        <p className="confirmation-dialog-text">
                            {confirmationMsg}
                        </p>
                        <Divider sx={{mt: 5, mb: 2}}/>

                        <div className="confirmation-dialog-buttons">
                            <Button
                                className="delete-item-list-back-button"
                                onClick={() => closeDialog()}
                            >
                                Cancel
                            </Button>

                            <LoadingButton
                                className="delete-item-list-submit"
                                color="primary"
                                variant="contained"
                                loading={removeUserResult.isLoading}
                                onClick={() => onSubmit()}
                            >
                                {actionButtonTitle}
                            </LoadingButton>
                        </div>
                    </div>
                </CustomDialog>
                :
                <ErrorDialog
                    open={open}
                    closeDialog={closeDialog}
                    confirmationMsg={'Cannot remove yourself from admin or atleast one admin should be available'}
                />
            }
        </div>
    )
}

export const ErrorDialog = ({open, closeDialog, confirmationMsg}: {
    open: boolean,
    closeDialog: () => void,
    confirmationMsg: string
}) => {
    return (<CustomDialog
            className="confirmation-dialog"
            open={open}
            title="Error"
            onClose={closeDialog}
        >
            <div className="remove-user">
                <p className="info-element">{confirmationMsg}</p>
            </div>
        </CustomDialog>
    )
}


export default GuildMemberManagementDialog
