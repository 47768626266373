import React from 'react'
import {ExportFormatEnum} from '../../../../../model/ExportFormatEnum'
import {ExtractionFrequencyEnum} from '../../../../../model/ExtractionFrequencyEnum'
import {ItemList} from '../../../../../../itemList/model/ItemList'
import {useCurrentGuildContext, useDatxPathGenerator} from '../../../../../../util/routing'
import {GroupOfAttributes} from '../../../../../../item/model/Package'
import {useCreatePartExtractionServiceRequestMutation} from '../../../../rtkServiceRequestApi'
import {ServiceRequestStep} from '../../../../model/ServiceRequestStep'
import {DefineListAndDisplaySets} from './DefineListAndDisplaySets'
import {ExtractionPreference} from './ExtractionPreference'
import {ServiceRequestFormTemplate} from '../../../../genericComponents/formTemplate/ServiceRequestFormTemplate'
import {ROUTES} from '../../../../../../constants/routing'
import {array, boolean, mixed, object, string} from 'yup'
import {ReviewAndSubmitPartExtraction} from '../../../submit/ReviewAndSubmitPartExtraction'
import {ServiceRequestWorkflowInput} from '../../../../model/ServiceRequestWorkflowInput'

export interface RecurringExtractionRequestForm extends ServiceRequestWorkflowInput {
    itemList: ItemList | undefined
    format: ExportFormatEnum | undefined
    extractionFrequency: ExtractionFrequencyEnum | undefined
    extractionPath: string | undefined
    selectedDisplaySets: GroupOfAttributes[] | undefined
    includesPrivateAttributes: boolean
}

export const RecurringExtractionRequest = () => {

    const [currentGuildContext] = useCurrentGuildContext()
    const generatePath = useDatxPathGenerator()

    const [createService] = useCreatePartExtractionServiceRequestMutation()

    const allowedExtractionPathChars = /[^a-zA-Z0-9/_-]/

    const isValidPath = (str: string) => {
        const parts = str.split('/')
        const hasSpecialChars = allowedExtractionPathChars.test(str)
        const startsOrEndsWithSlash = str.startsWith('/') || str.endsWith('/')
        return !startsOrEndsWithSlash && parts.length <= 3 && !hasSpecialChars
    }

    const defineListAndDisplaySets = {
        itemList: mixed().required('Item list is required'),
        selectedDisplaySets: array()
            .of(
                object().shape({
                    id: string().required()
                })
            )
            .required('At least one display set must be selected'),
        includesPrivateAttributes: boolean().optional()
    }
    const extractionPreference = {
        format: mixed().required('Format is required'),
        extractionFrequency: mixed().required('Extraction frequency is required'),
        extractionPath: string()
            .test(
                'valid-path',
                'Please ensure that the file path follows these rules: The path depth should not exceed 3 levels. Only alphanumeric characters, hyphens (-), and underscores (_) are permitted. The path should not start or end with a slash.',
                (value) => {
                    if (!value) return true
                    return value ? isValidPath(value) : false
                }
            )
    }

    const submitValidation = {
        ...defineListAndDisplaySets,
        ...extractionPreference,
        selectedWorkflowId: string().required()
    }

    const steps: ServiceRequestStep<RecurringExtractionRequestForm>[] = [
        {
            name: 'Define Extraction',
            component: <DefineListAndDisplaySets/>,
            pageValidation: object<RecurringExtractionRequestForm>({...defineListAndDisplaySets}),
            description:
                'By filling this form, you will be requesting extraction of pre-defined attributes belonging to your chosen list of items in the selected format and extraction schedule.'
        },
        {
            name: 'Extraction Preference',
            component: <ExtractionPreference/>,
            pageValidation: object<RecurringExtractionRequestForm>({...extractionPreference}),
            description:
                'By filling this form, you will be requesting extraction of pre-defined attributes belonging to your chosen list of items in the selected format and extraction schedule.'
        },
        {
            name: 'Review and Submit',
            component: <ReviewAndSubmitPartExtraction/>,
            pageValidation: object<RecurringExtractionRequestForm>(submitValidation),
            description:
                'By filling this form, you will be requesting extraction of pre-defined attributes belonging to your chosen list of items in the selected format and extraction schedule.'
        }
    ]

    const handleFormSubmit = (formResult: RecurringExtractionRequestForm): Promise<any> | undefined => {
        if (!formResult.itemList) return
        if (!formResult.extractionFrequency) return
        if (!formResult.format) return
        if (!formResult.selectedDisplaySets) return
        if (!formResult.selectedWorkflowId) return

        return createService({
            guildId: currentGuildContext?.guildId ?? '',
            itemListIds: [formResult.itemList.id],
            displaySetIds: formResult.selectedDisplaySets.map((pack) => pack.id),
            includesPrivateAttributes: formResult.includesPrivateAttributes,
            format: formResult.format,
            frequency: formResult.extractionFrequency,
            extractionPath: formResult.extractionPath ? formResult.extractionPath : undefined,
            workflowConfigurationId: formResult.selectedWorkflowId,
        })
    }

    return (
        <ServiceRequestFormTemplate
            steps={steps}
            handleFormSubmit={handleFormSubmit}
            initialValues={{
                itemList: undefined,
                format: undefined,
                extractionFrequency: undefined,
                extractionPath: undefined,
                selectedDisplaySets: undefined,
                includesPrivateAttributes: false,
                selectedWorkflowId: '',
            }}
            withStepper={true}
            title="Recurring Extraction"
            previousUrl={generatePath(ROUTES.partExtractionServiceChoice.path)}
        ></ServiceRequestFormTemplate>
    )
}
