import {FormControl} from '@mui/material'
import React, {ChangeEvent, SyntheticEvent} from 'react'
import UploadCommonLoadingButton from '../../../genericComponents/UploadCommonLoadingButton'
import {useDispatch} from 'react-redux'
import {setSnackbarMessage} from '../../../../../genericComponents/commonSlice'
import * as XLSX from 'xlsx'
import {CreateAttributeExcelFileModel} from '../../../../model/private/CreateAttributeExcelFileModel'
import {useFormikContext} from 'formik'
import {AttributeStringCreationForm} from './PrivateAttributeStringCreation'
import {NewValueByItemDTO} from '../../../../model/private/NewValueByItemDTO'
import {ServiceRequestStep} from '../../../genericComponents/formTemplate/steps/ServiceRequestStep'
import {Description} from '../../../../../genericComponents/typographie/Description'
import {Label} from '../../../../../genericComponents/typographie/Label'

export const UploadTemplateStringCreation = () => {
    const formik = useFormikContext<AttributeStringCreationForm>()
    const dispatch = useDispatch()

    const setFile = (file: File | undefined) => {
        formik.setFieldValue('file', file)
    }

    const setPrivateAttributes = (privateAttributes: NewValueByItemDTO[] | undefined) => {
        if (!privateAttributes || privateAttributes.length <= 0) return

        const privateAttributesToUpdate = privateAttributes.filter(attribute =>
            attribute.value !== null
            && attribute.value !== ''
            && attribute.value !== undefined
            && attribute.itemId
        )
        formik.setFieldValue('privateAttributes', privateAttributesToUpdate)
    }

    const unselectFile = (event: SyntheticEvent) => {
        setPrivateAttributes(undefined)
        setFile(undefined)
        event.preventDefault()
    }


    const parseFile = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target?.files?.length) return

        const reader = new FileReader()
        reader.onerror = () => dispatch(setSnackbarMessage('Error on the file format. We are not able to parse it.'))
        reader.onabort = () => dispatch(setSnackbarMessage('Error downloading the file. Please try again'))
        reader.onload = (event) => {
            try {
                const buffer = event?.target?.result
                const workbook = XLSX.read(buffer, {type: 'array'})
                const sheetName = workbook.SheetNames[0]
                const sheet = workbook.Sheets[sheetName]

                const data = XLSX.utils.sheet_to_json<CreateAttributeExcelFileModel>(sheet)
                const dataForService = data.slice(1).map((record, i) => {
                    if (!record.DATX_ID) {
                        dispatch(setSnackbarMessage(`A DATX Id is missing on line ${i + 3}. The line will be be ignored.`))
                    }
                    return {
                        itemId: record.DATX_ID,
                        value: record.PRIVATE_ATTRIBUTE
                    }
                })

                setPrivateAttributes(dataForService)
            } catch (e: any) {
                dispatch(setSnackbarMessage('Error on the file format. We are not able to parse it.'))
            }
        }

        const tempFile = event.target.files[0]
        reader.readAsArrayBuffer(tempFile)
        setFile(tempFile)
    }

    return <ServiceRequestStep>
        <Description className="service-request-step-description">
            By submitting the completed template, the values you have provided will be updated for the relevant items.
        </Description>

        <FormControl className="download-upload-template-container">
            <Label className="file-download-upload-label"
                   htmlFor="upload-file">Upload the completed template</Label>
            <UploadCommonLoadingButton className="upload-template-button"
                                       selectedFileName={formik?.values?.file?.name ?? ''}
                                       unselectFile={unselectFile}
                                       parseFile={parseFile}/>
        </FormControl>


    </ServiceRequestStep>
}