import {Button, Typography} from '@mui/material'
import React, {ReactNode, useContext} from 'react'
import './DisplaySetWrapper.scss'
import {DisplaySetContext} from './DisplaySetContext'
import ItemDetailToggleButton, {ItemDetailView} from '../item/itemDetails/itemDetailsPage/ItemDetailToggleButton'
import {DisplaySetOrdered} from '../user/model/DisplaySetOrdered'

export const DisplaySetTabsWrapperComponent = ({
                                                   displaySets,
                                                   children,
                                                   defaultTabName = 'Item Details',
                                                   selectedItemDetailView,
                                                   setSelectedItemDetailView
                                               }: {
    displaySets: DisplaySetOrdered[] | undefined,
    children: ReactNode,
    defaultTabName?: string,
    selectedItemDetailView?: ItemDetailView,
    setSelectedItemDetailView?: (value: (((prevState: ItemDetailView) => ItemDetailView) | ItemDetailView)) => void
}) => {
    const {selectedDisplaySet, setSelectedDisplaySet} = useContext(DisplaySetContext)

    return <div className="tabs-parent-component">
        <div className="displayset-tabs">
            <div className={selectedItemDetailView ? 'item-details-display-set-tabs' : ''}>
                <div>
                    {displaySets && displaySets.length > 0 ? (
                        displaySets.map((displaySet) => (
                            <Button
                                key={displaySet.id + '-tab-button'}
                                data-testid={`ds-tab-${displaySet.id}`}
                                size="medium"
                                className={
                                    displaySet.id === selectedDisplaySet?.id ? 'tab-button active' : 'tab-button'
                                }
                                onClick={() => setSelectedDisplaySet(displaySet.id)}
                            >
                                <Typography className="display-set-tab">{displaySet.displaySet.label}</Typography>
                            </Button>
                        ))
                    ) : (
                        <Button
                            key={'default-display-set-tab-button'}
                            data-testid="ds-tab-itemdetails"
                            size="medium"
                            className="tab-button active"
                        >
                            <Typography className="display-set-tab">{defaultTabName}</Typography>
                        </Button>
                    )}
                </div>
                {selectedItemDetailView && (
                    <ItemDetailToggleButton
                        selectedItemDetailView={selectedItemDetailView}
                        setSelectedItemDetailView={setSelectedItemDetailView}
                    />
                )}
            </div>
            {children}
        </div>

    </div>
}
