import React from 'react'

export const IconDisplay = ({
    className,
    iconName,
    iconSourceMap,
    iconLabel
}: {
    className: string
    iconName: string | undefined
    iconSourceMap: Map<string | undefined, string>
    iconLabel: string | undefined
}) => {
    const imageUrl = iconSourceMap.get(iconName?.toUpperCase()) ?? iconSourceMap.get('default')
    const altIconLabel = iconLabel ?? iconName ?? 'icon image'

    return <img className={className} alt={altIconLabel} src={imageUrl} title={iconName} data-testid={imageUrl} />
}
