import {Link} from 'react-router-dom'
import {Button} from '@mui/material'
import './ServiceRequestConfirmationSuccess.scss'
import React from 'react'
import {useDatxPathGenerator} from '../../../../../util/routing'
import {ServiceRequestBody} from '../../structure/ServiceRequestBody'
import {Description} from '../../../../../genericComponents/typographie/Description'
import {Label} from '../../../../../genericComponents/typographie/Label'
import {ServiceRequestFooter} from '../../structure/ServiceRequestFooter'
import {ROUTES} from '../../../../../constants/routing'

export const ServiceRequestConfirmationSuccess = ({requestId}: { requestId: string }) => {
    const generatePath = useDatxPathGenerator()

    return <>
        <ServiceRequestBody>
            <Description>Your request has been sent for validation</Description>
            <div className="request-confirmation-id-container">
                <Label>Request ID: </Label>
                <span className="request-confirmation-value" data-testid="requestId">{requestId}</span>
            </div>
            <Description>This service is a commercial process requiring interaction with the DATX commercial team.
                After your request is approved, a DATX representative will contact you soon on your
                request.</Description>
        </ServiceRequestBody>
        <ServiceRequestFooter>
            <Button component={Link} color="primary" variant="contained" type="button"
                    to={generatePath(ROUTES.userServiceRequests.path)}
                    data-testid="to-my-requests"
                    className="view-my-service-requests-button">View My Service Requests</Button>
        </ServiceRequestFooter>
    </>
}