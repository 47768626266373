import React, {useEffect, useState} from 'react'
import {IconButton, Slide} from '@mui/material'
import {FiberManualRecord} from '@mui/icons-material'
import {News} from './model/News'
import LoadingSpinner from '../../genericComponents/LoadingSpinner'
import {useGetEnabledNewsQuery} from './rtkNewsApi'
import './NewsArea.scss'
import {Headline} from '../../genericComponents/typographie/Headline'
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined'

const NewsArea = () => {
    const [isActive, setIsActive] = useState(0)
    const [displayed, setDisplayed] = useState(0)
    const [goRight, setGoRight] = useState(true)
    const {data: news} = useGetEnabledNewsQuery()
    const currentNews: News[] = news ?? []


    let timer: number
    let timeout: NodeJS.Timeout

    useEffect(() => {
        start()
        return () => clearInterval(timer)
    }, [currentNews])

    const setActive = (index = -1) => {
        if (index === -1) {
            setIsActive((prev) => {
                const newIndex = prev + 1 > currentNews?.length - 1 ? 0 : prev + 1
                setDisplayed(prev)
                setGoRight(true)

                timeout = setTimeout(() => {
                    setDisplayed(newIndex)
                }, 400)
                return newIndex
            })
        } else {
            clearInterval(timer)
            clearTimeout(timeout)
            setGoRight(index > isActive)
            setIsActive(index)
            setDisplayed(index)
            if (timer) {
                start()
            }
        }
    }

    const next = (event: Event) => {
        if (currentNews?.length) {
            setActive()
            if (event) {
                event.stopPropagation()
            }
        }
    }

    const start = () => {
        timer = setInterval(next, 10000)
    }

    return (
        <div className="home-card home-news-container">
            <Headline title="News & Announcement">News & Announcement</Headline>

            <div className="home-card-content news-list"
                onMouseEnter={() => {
                    clearInterval(timer)
                }}
                onMouseLeave={() => {
                    if (timer) {
                        start()
                    }
                }}
            >
                {news === undefined && <LoadingSpinner/>}
                {currentNews.length === 0 &&
                    <p><strong>There is currently no available News & Announcements</strong></p>}
                {currentNews?.map((elem, index) => (
                    <CarouselItem
                        data={elem}
                        key={index}
                        isNext={goRight}
                        isActive={index === isActive}
                        display={displayed === index}
                    />
                ))}
                {getIndicators(isActive, currentNews?.length, setActive)}
            </div>
        </div>
    )
}

const CarouselItem = ({isActive, display, data, isNext}:
                          { isActive: boolean, display: boolean, data: News, isNext: boolean }) => {
    return display ? (
        <Slide
            direction={
                isActive
                    ? isNext
                        ? 'left'
                        : 'right'
                    : isNext
                        ? 'right'
                        : 'left'
            }
            in={isActive}
            timeout={600}
        >
            <div id="news-holder" className="news-holder">
                <Headline title={data.title}>{data.title}</Headline>
                <div
                    className="news-content"
                    dangerouslySetInnerHTML={{__html: data.body}}
                >
                </div>
            </div>
        </Slide>
    ) : null
}

const getIndicators = (active: number, elements: number, setActive: (index: number) => void) => {
    const indicators = []
    if (!elements) {
        return
    }
    for (let i = 0; i < elements; i++) {
        indicators.push(
            <IconButton
                id={`news-carousel-item-${i}`}
                key={i}
                size="small"
                className={'indicator'}
                onClick={() => {
                    setActive(i)
                }}
            >
                {
                    i === active
                        ? <FiberManualRecord className="indicator-icon"/>
                        : <FiberManualRecordOutlinedIcon className="indicator-icon"/>
                }
            </IconButton>
        )
    }

    return <div className="indicators">{indicators}</div>
}

export default NewsArea
