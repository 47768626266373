import {Checkbox, FormControl, Tooltip} from '@mui/material'
import {GroupOfAttributesSelection} from '../../UploadForm/GroupOfAttributesSelection'
import InfoIcon from '@mui/icons-material/Info'
import React from 'react'
import {SelectItemList} from '../../../../genericComponents/SelectItemList'
import {useFormikContext} from 'formik'
import {RecurringExtractionRequestForm} from './RecurringExtractionRequest'
import {DisplaySet} from '../../../../../../displaySet/model/DisplaySet'
import {Label} from '../../../../../../genericComponents/typographie/Label'
import {ServiceRequestStep} from '../../../../genericComponents/formTemplate/steps/ServiceRequestStep'

export const DefineListAndDisplaySets = () => {
    const formik = useFormikContext<RecurringExtractionRequestForm>()

    const handleChangeDisplaySets = (selected: DisplaySet[]) => {
        formik.setFieldValue('selectedDisplaySets', selected)
    }
    return (
        <ServiceRequestStep>
            <FormControl className="form-select" required>
                <SelectItemList
                    selectedList={formik.values.itemList}
                    handleChange={formik.handleChange}
                    touched={formik.touched.itemList}
                    errors={formik.errors.itemList}
                    required={true}
                />
            </FormControl>
            <GroupOfAttributesSelection onChangeDisplaySets={handleChangeDisplaySets} />
            <FormControl className="form-select">
                <div className="include-private-attributes">
                    <Checkbox
                        id="includes-private-attributes"
                        size="small"
                        color="primary"
                        checked={formik.values.includesPrivateAttributes}
                        onChange={(e) =>
                            formik.setFieldValue('includesPrivateAttributes', !formik.values.includesPrivateAttributes)
                        }
                    />
                    <Label className="include-private-attributes-statement" htmlFor="includes-private-attributes">Include Private Attributes</Label>
                    <Tooltip
                        title="By selecting this option, private attributes will be included in your extraction output"
                        placement="bottom-start"
                    >
                        <InfoIcon fontSize="small" className="extraction-form-info-icon" id="extractionFormInfoIcon" />
                    </Tooltip>
                </div>
            </FormControl>
        </ServiceRequestStep>
    )
}
