import React from 'react'

export const SelfServiceDescription = () => {
    return (
        <div className="step-description">
            <p>
                Check the availability of your required data in our database.
                <br />
                <br />
                Upload a spreadsheet containing all the required part numbers with their corresponding manufacturer
                reference number.
                <br />
                <br />
                You may use this{' '}
                <a href="/files/services/Matching_Part_Creation_Template_Self_Service_DATX.xlsx">
                    {' '}
                    part data template
                </a>{' '}
                for an example of how you can upload your parts.
                <br />
            </p>
        </div>
    )
}
