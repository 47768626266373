import React from 'react'
import {Box, Drawer} from '@mui/material'
import {GuildManagementMenu} from './menu/GuildManagementMenu'
import './GuildManagement.scss'
import {Outlet} from 'react-router-dom'

const GuildManagementStructure = () => {

    return (
        <Box
            className="guild-management-page-structure">
            <Drawer variant="permanent" classes={{
                paper: 'guild-left-bar'
            }} className="guild-left-bar-drawer">
                <GuildManagementMenu/>
            </Drawer>
            <div data-testid="guild-management-container"
                 className="guild-management-container">
                <Outlet/>
            </div>
        </Box>
    )
}

export default GuildManagementStructure
