import {ServiceRequestStep} from '../genericComponents/formTemplate/steps/ServiceRequestStep'
import {FormControl, MenuItem, Select, TextareaAutosize} from '@mui/material'
import {useFormikContext} from 'formik'
import {Label} from '../../../genericComponents/typographie/Label'
import React, {ChangeEvent, SyntheticEvent} from 'react'
import {KeyboardArrowDown} from '@mui/icons-material'
import LoadingSpinner from '../../../genericComponents/LoadingSpinner'

import {ItemUpdateRequestForm} from './ItemUpdateRequest'
import {useGetAllDisplaySetsQuery} from '../../../displaySet/rtkDisplaySet'
import {DisplaySet} from '../../../displaySet/model/DisplaySet'
import UploadCommonLoadingButton from '../genericComponents/UploadCommonLoadingButton'
import './ItemUpdateRequest.scss'

export const DataDomainUpload = () => {

    const formik = useFormikContext<ItemUpdateRequestForm>()

    const {data: displaySets = [], isLoading} = useGetAllDisplaySetsQuery()

    const setFile = (file: File | undefined) => {
        formik.setFieldValue('file', file)
    }

    const unselectFile = (event: SyntheticEvent) => {
        setFile(undefined)
        event.preventDefault()
    }

    async function parseFile(event: ChangeEvent<HTMLInputElement>) {
        if (event.target?.files?.length) {
            const tempFile = event.target.files[0]
            setFile(tempFile)
        }
    }

    return <ServiceRequestStep>
        <FormControl required>
            <Label
                htmlFor="dataDomain"
                required
            >
                Data Domain
            </Label>
            <Select
                size="small"
                displayEmpty
                variant="outlined"
                data-testid="display-set-dropdown-selector"
                required
                id="displaySet"
                name="displaySet"
                value={formik.values.displaySet}
                onChange={formik.handleChange}
                className="format-selector"
                renderValue={(displaySet: DisplaySet) =>
                    displaySet?.label ?? <span className="placeholder">Select Data Domain</span>
                }
                aria-invalid={formik.touched.displaySet && Boolean(formik.errors.displaySet)}
                IconComponent={KeyboardArrowDown}
            >
                {isLoading ? (
                    <LoadingSpinner/>
                ) : (
                    displaySets?.length > 0 &&
                    displaySets.map((displaySet) => (
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        <MenuItem
                            value={displaySet}
                            key={displaySet.id}
                            autoFocus={false}
                            data-cy={`select-option-${displaySet.label}`}
                            sx={{fontSize: '14px'}}
                        >
                            {displaySet.label}
                        </MenuItem>
                    ))
                )}
            </Select>
        </FormControl>
        <FormControl className="form-textarea" fullWidth={true} required>
            <Label className="description-label" htmlFor="description" required>
                Description
            </Label>
            <TextareaAutosize id="description"
                              minRows={3}
                              placeholder="Type here"
                              name="description"
                              value={formik.values.description}
                              onChange={formik.handleChange}
                              aria-invalid={formik.touched.description && Boolean(formik.errors.description)}
                              data-cy="description"
            />
        </FormControl>
        <FormControl required>
            <Label className="file-download-upload-label" htmlFor="upload-file" required>
                Upload Supporting Document:
            </Label>
            <UploadCommonLoadingButton
                name=""
                className="upload-template-button"
                selectedFileName={formik.values.file?.name ?? ''}
                unselectFile={unselectFile}
                parseFile={parseFile}
            />
        </FormControl>
    </ServiceRequestStep>
}