import { createSelector } from '@reduxjs/toolkit'
import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { NOTIFICATION_API_ENDPOINT } from '../../../constants/backendUrls'
import { axiosBaseQuery } from '../../../rtkQueryUtils'
import { IdType } from '../../../util/models/IdType'
import { Notification } from '../../models/Notification'

export const NOTIFICATION_TAG = 'Notification'

export const notificationAPI = createApi({
    reducerPath: 'notificationAPI',
    keepUnusedDataFor: 0,
    tagTypes: [NOTIFICATION_TAG],
    baseQuery: axiosBaseQuery({baseUrl: `${NOTIFICATION_API_ENDPOINT}/api/v1/datx/`}),
    endpoints: (builder) => ({
        getNotifications: builder.query<Notification[], void>({
            query: () => ({
                url: 'notifications/me',
                method: 'GET'
            }),
            providesTags: [NOTIFICATION_TAG]
        }),
        deleteNotification: builder.mutation<any, IdType>({
            query: (notificationId) => ({
                url: `notifications/${notificationId}`,
                method: 'DELETE'
            }),
            transformResponse: (response:any) => response.data,
            invalidatesTags: [NOTIFICATION_TAG]
        }),
        readAllNotifications: builder.mutation<any, void>({
            query: () => ({
                url : 'notifications/readAll',
                method: 'PATCH'
            }),
            transformResponse: (response:any) => response.data,
            invalidatesTags: [NOTIFICATION_TAG]      
        })
    })
})

export const selectNotifications = notificationAPI.endpoints.getNotifications.select()

export const selectUnreadNotifications = createSelector(selectNotifications, notificationResult => notificationResult.data ? notificationResult.data.filter((notification: Notification) => !notification.read).length : 0)

export const {useGetNotificationsQuery, useDeleteNotificationMutation, useReadAllNotificationsMutation} = notificationAPI