import React, {useState} from 'react'

import {Button, Divider, Grid, TextField} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import './GuildInvitation.scss'
import {Info} from '@mui/icons-material'
import {IdType} from '../../../../util/models/IdType'
import {useInviteUserToGuildMutation} from '../../../rtkGuildApi'


const GuildInvitation = ({guildId, handleClose}:
                             { guildId: IdType , handleClose: () => void }) => {
    const SUCCESS_MESSAGE =
        'The request to join your guild has been sent to invited user. You will be able manage guild permissions of the user after they have accepted the invitation to join the guild.'
    const FAILURE_MESSAGE =
        'The invited recipient does not seem to have a DATX account. Kindly request the recipient to sign up for DATX.'
    const NUMBERS_OF_MEMBERS_REACHED =
        'You have reached the maximum number of users for this guild. Please contact your support to update this limitation.'

    const [email, setEmail] = useState('')
    const [isValidInput, setIsValidInput] = useState(false)

    const [inviteUser, userInviteResult] = useInviteUserToGuildMutation()
    const isValidEmail = (value: string) =>
        value.length > 0 &&
        /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event?.target?.value?.trim()
        setEmail(input)
        setIsValidInput(isValidEmail(input))
    }

    const onSubmit = () => {
        const userEmail = email.toLocaleLowerCase()
        inviteUser({userEmail, guildId})
    }

    const userInvitationMessage = () => {
        if (userInviteResult.isSuccess) {
            return SUCCESS_MESSAGE
        }
        if (userInviteResult.isError) {
            const errorStatus = (userInviteResult.error as { status: number }).status
            if( errorStatus === 403) return NUMBERS_OF_MEMBERS_REACHED
            return FAILURE_MESSAGE
        }
        return ''
    }

    if (userInviteResult.isSuccess || userInviteResult.isError){
        const title = userInviteResult.isSuccess ? 'Invite sent' : 'Invite not sent'
        return (
            <div className="user-invitation">
                <div className="info-title">
                    <Info className="info-icon"/>
                    <h2 className="guild-form-title">
                        {title}
                    </h2>
                </div>
                <p className="info-element">{userInvitationMessage()}</p>
            </div>
        )
    }

    return (
        <div className="user-invitation">
            <h2 className="guild-form-title">
                Add member
            </h2>
            <p className="form-element">
                Only DATX account holders can be invited to the guild.
            </p>

            <TextField
                id="mail-input"
                variant="outlined"
                onChange={onChange}
                placeholder="Enter email address"
                value={email}
                className="form-element"
            />

            <Divider className="form-divider"/>
            <div className="guild-form-action-container">
                <Grid className="guild-form-action-button-grid" item>
                    <Button
                        className="guild-form-back-button"
                        type="button"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid className="guild-form-action-button-grid" item>
                    <LoadingButton
                        className="guild-form-submission-button"
                        type="submit"
                        id="next-button"
                        variant="contained"
                        color="primary"
                        disabled={!isValidInput}
                        loading={userInviteResult.isLoading}
                        onClick={onSubmit}
                    >
                        Add
                    </LoadingButton>
                </Grid>
            </div>
        </div>
    )
}
export default GuildInvitation
