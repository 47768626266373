import React from 'react'
import { ArtifactAttribute } from '../attributeTemplates/ArtifactAttribute'
import {sortAttributesByDisplayOrder} from '../utils/sortedDefinitions'
import {AttributeDefinitionModel} from '../model/AttributeModel'


export const RegulationProofsDefinition = ({definition}: {definition: AttributeDefinitionModel}) => {
    return <div className="display-set-definition display-set-attribute-regulation" data-testid="test-details-regulation-status-definition"
                id={definition.attributeDefinitionId}>
        <>
            {sortAttributesByDisplayOrder(definition.attributes).map(attribute =>
                attribute.value.composedByAttributes.map(composedAttribute =>
                    <>
                        <span>{composedAttribute.name}</span>
                        {attribute.value.composedByAttributes.length > 0 &&
                            <ArtifactAttribute key={attribute.id} attribute={attribute.value.composedByAttributes[0]}/>}
                    </>
                )
            )}
        </>
    </div>
}