import React from 'react'
import {Button} from '@mui/material'
import {ArrowDropDown} from '@mui/icons-material'

const CustomHeaderButton: React.FC<any> = ({activeNavLink, ...other}) => {

    return <Button
        size="medium"
        className={(activeNavLink ? 'active-nav-link ' : '') + 'header-button' }
        endIcon={
            <ArrowDropDown className="headerArrowDropDown"/>
        }
        {...other}
    />
}

export default CustomHeaderButton
