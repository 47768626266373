import React, {useState} from 'react'
import {Checkbox, FormControl, FormControlLabel} from '@mui/material'
import './UploadPartExtractionForm.scss'
import {GroupOfAttributes} from '../../../../../item/model/Package'
import {DisplaySet} from '../../../../../displaySet/model/DisplaySet'
import {useGetAllDisplaySetsQuery} from '../../../../../displaySet/rtkDisplaySet'
import {Label} from '../../../../../genericComponents/typographie/Label'

export const GroupOfAttributesSelection = ({
                                               onChangeDisplaySets
                                           }: {
    onChangeDisplaySets: (extractTypes: GroupOfAttributes[]) => void
}) => {
    const {data: displaySets} = useGetAllDisplaySetsQuery()

    const onSelectDisplaySet = (newDisplaySetSelected: DisplaySet[]) => {
        onChangeDisplaySets([...newDisplaySetSelected])
    }

    return (
        <FormControl className="form-select" required>
            <Label id="itemlist-label" className="itemlist-label" required={true}>
                Select Display Sets to be Extracted
            </Label>
            {displaySets && (
                <GroupOfAttributesList headerLabel="Display Sets" packs={displaySets} onChange={onSelectDisplaySet}/>
            )}
        </FormControl>
    )
}

const GroupOfAttributesList = ({
                                   packs,
                                   onChange,
                                   headerLabel
                               }: {
    packs: GroupOfAttributes[]
    onChange: (pack: GroupOfAttributes[]) => void
    headerLabel: string
}) => {
    const [selectedGroupOfAttributes, setSelectedGroupOfAttributes] = useState<Set<GroupOfAttributes>>(new Set())

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, pack: GroupOfAttributes) => {
        const {checked} = event.target

        if (checked) {
            selectedGroupOfAttributes.add(pack)
        } else {
            selectedGroupOfAttributes.delete(pack)
        }

        const newSelection = new Set(selectedGroupOfAttributes)
        setSelectedGroupOfAttributes(newSelection)
        onChange([...newSelection])
    }

    const handleSelectAll = () => {
        const newSelection = new Set(packs)
        setSelectedGroupOfAttributes(newSelection)
        onChange([...newSelection])
    }

    const handleSelectNone = () => {
        setSelectedGroupOfAttributes(new Set())
        onChange([])
    }

    return (
        <div className="display-set-selection" role="group">
            {packs?.length > 0 && (
                <>
                    <Label className="display-set">{headerLabel}</Label>
                    <div className="display-set-selection-list" role="group">
                        <div className="display-set-checkbox-container" role="group">
                            <Checkbox size="small"
                                      id="select-all-display-set"
                                      checked={packs.every((pack) => selectedGroupOfAttributes.has(pack))}
                                      onChange={
                                          packs.every((pack) => selectedGroupOfAttributes.has(pack))
                                              ? handleSelectNone
                                              : handleSelectAll
                                      }
                            />
                            <Label htmlFor="select-all-display-set">(Select All)</Label>
                        </div>

                        {packs?.length > 0 &&
                            packs.map((pack) => (
                                <div className="display-set-checkbox-container" role="group">
                                    <Checkbox
                                        id={pack.id}
                                        size="small"
                                        checked={selectedGroupOfAttributes.has(pack)}
                                        onChange={(event) => handleChange(event, pack)}
                                        name={pack.label}
                                    />
                                    <Label htmlFor={pack.id} dataTestid={pack.label + '-display-set-selection'} id={pack.id + '-display-set-selection'}>{pack.label}</Label>
                                </div>
                            ))}
                    </div>
                </>
            )}
        </div>
    )
}
