import {Tooltip} from '@mui/material'
import React from 'react'
import Highlighter from 'react-highlight-words'
import {Link} from 'react-router-dom'

const ItemCardClickableProperty = ({
                                       label,
                                       value,
                                       linkTo,
                                       highlightPhrase,
                                       testId,
                                       opensInNewTab
                                   }: {
    label?: string,
    value: string,
    linkTo: string,
    highlightPhrase?: string,
    testId?: string,
    opensInNewTab?: boolean
}) => {
    const target = opensInNewTab ? '_blank' : ''

    return (<div className="item-card-property">
        {label && <div className="property-label">{label.toUpperCase()}</div>}
        <Tooltip title={value}>
            <Link data-testid={testId}
                  to={linkTo}
                  className="property-clickable"
                  target={target}>
                <Highlighter
                    searchWords={[highlightPhrase || '']}
                    autoEscape={true}
                    textToHighlight={value}
                    highlightClassName	={'match-element'}

                />
            </Link>
        </Tooltip>
    </div>)
}

export default ItemCardClickableProperty
