import React, {useMemo} from 'react'
import {Formik} from 'formik'
import {boolean, mixed, object, string} from 'yup'
import {Button, MenuItem, Select, TextField} from '@mui/material'
import {ROUTES} from '../../../constants/routing'
import {useCurrentGuildContext, useDatxPathGenerator} from '../../../util/routing'
import {useNavigate} from 'react-router-dom'
import {getServiceName, ServiceEnum} from '../../../service/model/ServiceEnum'
import CustomSwitch from '../../../genericComponents/customSwitch/CustomSwitch'
import {WorkflowConfigurationStage} from '../model/WorkflowConfigurationStage'
import {IdType} from '../../../util/models/IdType'
import {WorkflowConfiguration} from '../model/WorkflowConfiguration'
import {LoadingButton} from '@mui/lab'
import {User} from '../../../user/model/User'
import {Label} from '../../../genericComponents/typographie/Label'
import {availableServices} from '../WorkflowConfigurationManagement'
import {Guild} from '../../models/Guild'
import {UserSelectionDropdown} from './dropdownFilter/UserSelectionDropdown'
import {QuotationApprovalSelection} from './dropdownFilter/QuotationApprovalSelection'

export interface WorkflowConfigurationForm {
    id: IdType
    guildId: IdType
    service: ServiceEnum | string
    isDefault: boolean
    name: string
    quotationAutoApproval: boolean
    quotationAutoApprovalThreshold: number | null
    serviceRequestAutoValidation: boolean
    requestorIds: IdType[]
    approverIds: IdType[]
}

const WorkflowConfigurationTemplate = ({
                                           pageTitle, guild, onSubmit, workflowDetails, isLoading, submitButtonLabel
                                       }: {
    pageTitle: string
    guild: Guild | undefined
    onSubmit: (formValues: WorkflowConfiguration) => void
    workflowDetails?: WorkflowConfiguration
    isLoading: boolean
    submitButtonLabel: string
}) => {

    const generatePath = useDatxPathGenerator()
    const navigate = useNavigate()
    const [currentGuildContext] = useCurrentGuildContext()

    const workflowInitialValues: WorkflowConfigurationForm = {
        id: '',
        guildId: currentGuildContext?.guildId ?? '',
        service: '',
        isDefault: false,
        name: '',
        quotationAutoApproval: false,
        quotationAutoApprovalThreshold: null,
        serviceRequestAutoValidation: false,
        requestorIds: [],
        approverIds: []
    }

    const users = useMemo(() => guild?.guildUsers?.map(guildUser => guildUser.user)?.sort((a: User, b: User) => a.email.localeCompare(b.email)) ?? [], [guild])

    const usersMap = useMemo(() => users.reduce((map: Map<IdType, User>, user: User) => {
        map.set(user.id, user)
        return map
    }, new Map()), [users])

    const workflowFormDetails: WorkflowConfigurationForm | undefined = useMemo(() => {
        return workflowDetails ? {
            id: workflowDetails.id,
            guildId: workflowDetails.guildId,
            service: workflowDetails.service,
            isDefault: workflowDetails.isDefault,
            name: workflowDetails.name,
            quotationAutoApproval: workflowDetails.quotationAutoApproval,
            quotationAutoApprovalThreshold: workflowDetails.quotationAutoApprovalThreshold,
            serviceRequestAutoValidation: workflowDetails.serviceRequestAutoValidation,
            approverIds: workflowDetails.approvers.map(el => el.id).filter(el => usersMap.has(el)),
            requestorIds: workflowDetails.requestors.map(el => el.id).filter(el => usersMap.has(el))
        } : undefined
    }, [workflowDetails, usersMap])

    const getUsers = (userIds: IdType[]) => {
        return userIds.reduce((users: User[], userId: IdType) => {
            const user = usersMap.get(userId)
            if (user) users.push(user)
            return users
        }, [])
    }

    const handleSubmit = (values: WorkflowConfigurationForm) => {
        const workflowConfiguration = {
            ...values,
            approvers: getUsers(values.approverIds),
            requestors: getUsers(values.requestorIds)
        }
        onSubmit(workflowConfiguration)
    }

    const validationSchema = object({
        service: string().required(),
        name: string().required(),
        quotationAutoApproval: boolean(),
        quotationAutoApprovalThreshold: mixed() // could be '', null, number
            .when('quotationAutoApproval', ([isAutoApproval], schema) => {
                if (isAutoApproval) return schema.nullable().defined('Quotation threshold is required')
                return schema.nullable()
            })
    })

    const allGuildAdminsLabel = 'All Guild Admins'
    const allGuildMembersLabel = 'All Guild Members'
    const isExistingWorkflow = !!workflowFormDetails
    const isDefaultWorkflow = workflowFormDetails?.isDefault || false

    return (
        <Formik initialValues={workflowFormDetails || workflowInitialValues} enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}>
            {props => {
                return (
                    <form className="workflow-configuration-container" onSubmit={props.handleSubmit}>
                        <h1 className="workflow-configuration-title">{pageTitle}</h1>
                        <div className="workflow-configuration-content">
                            <div className="workflow-form-container">
                                <div className="workflow-service-container">
                                    <Label
                                        id="workflow-service-label"
                                        className="workflow-label"
                                        htmlFor="workflowServiceSelect"
                                        required
                                    >
                                        Service
                                    </Label>
                                    <Select
                                        data-testid="workflow-service-dropdown-selector"
                                        id="workflow-service-dropdown-selector"
                                        value={props.values.service}
                                        onChange={(event) => props.setFieldValue('service', event?.target?.value)}
                                        name="workflowServiceSelect"
                                        displayEmpty
                                        disabled={isDefaultWorkflow || isExistingWorkflow}
                                        renderValue={(service) =>
                                            service !== '' ? (getServiceName(service)) :
                                                <span className="placeholder">Choose Service</span>
                                        }
                                    >
                                        {availableServices?.length &&
                                            availableServices.map((service) => (
                                                <MenuItem
                                                    className="service-items"
                                                    value={service}
                                                    key={service}
                                                    autoFocus={false}
                                                    data-cy={`select-option-${getServiceName(service)}`}
                                                >
                                                    {getServiceName(service)}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </div>
                                <div className="workflow-name-container">
                                    <Label
                                        id="workflow-name-label"
                                        className="workflow-label"
                                        htmlFor="workflowNameTextInput"
                                        required
                                    >
                                        Name of Workflow
                                    </Label>
                                    <TextField
                                        data-testid="workflow-name-text-input"
                                        value={props.values.name}
                                        placeholder="Input Workflow Name"
                                        disabled={isDefaultWorkflow || isExistingWorkflow}
                                        onChange={(e) => props.setFieldValue('name', e?.target?.value)}
                                    />

                                </div>
                                <div className="workflow-approvers-container">
                                    <Label
                                        id="workflow-request-approvers-label"
                                        className="workflow-label"
                                        htmlFor="workflowApproversSelect"
                                    >
                                        Approvers
                                    </Label>
                                    {isDefaultWorkflow ? allGuildAdminsLabel :
                                        <UserSelectionDropdown name="approverIds" defaultValue={allGuildAdminsLabel}
                                                               users={users} />}
                                </div>
                                <div className="workflow-requestors-container">
                                    <Label
                                        id="workflow-requestors-label"
                                        className="workflow-label"
                                        htmlFor="workflowRequestorsSelect"
                                    >
                                        Requestors
                                    </Label>
                                    {isDefaultWorkflow ? allGuildMembersLabel :
                                        <UserSelectionDropdown name="requestorIds" defaultValue={allGuildMembersLabel}
                                                               users={users} />}
                                </div>
                                <div className="workflow-request-approval-container">
                                    <div className="edit-value">Request Approval</div>
                                    <div>
                                        <CustomSwitch
                                            disabled={false}
                                            value={WorkflowConfigurationStage.AUTO_APPROVE_SERVICE_REQUEST}
                                            isChecked={props.values.serviceRequestAutoValidation}
                                            toggleChecked={(e) => props.setFieldValue('serviceRequestAutoValidation', e.target.checked)}
                                            dataTestid="auto-request-approval-toggle"
                                        />
                                    </div>
                                </div>
                                <QuotationApprovalSelection />
                            </div>
                            <div className="workflow-configuration-form-footer">
                                <Button
                                    className="workflow-configuration-form-previous"
                                    variant="text"
                                    type="button"
                                    onClick={() => navigate(generatePath(ROUTES.workflowConfiguration.path))}
                                >
                                    Cancel
                                </Button>
                                <LoadingButton
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    className="workflow-configuration-form-next"
                                    data-testid="workflow-configuration-save-button"
                                    disabled={!props.dirty || !props.isValid}
                                    loading={isLoading}
                                >
                                    {submitButtonLabel}
                                </LoadingButton>
                            </div>
                        </div>
                    </form>
                )
            }}
        </Formik>
    )
}

export default WorkflowConfigurationTemplate
