import './VariancePackagingWidget.scss'
import {Link} from 'react-router-dom'
import {PackVariant, RelatedItem} from '../../model/AttributeValue'
import {useDatxPathGenerator} from '../../../util/routing'
import {ROUTES} from '../../../constants/routing'

const PackVariantCard = ({linkedItem, packVariant}: {
    linkedItem: RelatedItem,
    packVariant: PackVariant | null,
}) => {
    const generatePath = useDatxPathGenerator()

    const buildTitle = (): string => {
        if (!packVariant) return 'Pack Variant'
        const container = `${packVariant.containerType || ''}`
        const quantityAndUnit = `${packVariant.quantityOfContent || ''}` + `${packVariant.unit || ''}`
        return container + `${container && quantityAndUnit ? ' of ' : ''}` + quantityAndUnit
    }

    return <Link
        to={generatePath(ROUTES.detailItem.path || '', {itemId: linkedItem.id})}
        className="pack-variant-card"
        data-testid={`pack-variant-${linkedItem.id}`}
    >
        <p className="pack-variant-title">{buildTitle()}</p>
        <div className="part-number">
            <p className="title">Part Number</p>
            <p className="value">{linkedItem.partNumber}</p>
        </div>
    </Link>

}
export default PackVariantCard