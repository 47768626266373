import React from 'react'
import {Button, Divider} from '@mui/material'
import {Link} from 'react-router-dom'
import {IdType} from '../../../util/models/IdType'
import { useDatxPathGenerator } from '../../../util/routing'
import { ROUTES } from '../../../constants/routing'

export const ConfirmationListForm = ({listName, itemIds}: { listName: string, itemIds: IdType[] }) => {
    const generatePath = useDatxPathGenerator()

    return (
        <div className="service-request-form upload-form">
            <div className="step-header">
                <p>Import List</p>
                <Divider/>
            </div>
            <div className="step-description">
                <p>
                    Your new list has been imported
                </p>
                <p>
                    <div>Name of New list: <b>{listName}</b></div>
                    <div>Number of Items: <b>{itemIds?.length}</b></div>
                </p>
            </div>

            <Divider/>

            <div className="step-footer">
                <Button component={Link} to={generatePath(ROUTES.manageItemLists.path)} color="primary" variant="contained">Back to Lists</Button>
            </div>
        </div>
    )

}
