import GenericNotificationDisplay from './GenericNotificationDisplay'
import { Notification } from '../../../models/Notification'

const GuildCreationNotification = ({ notification }: { notification: Notification }) => (
  <GenericNotificationDisplay
    notification={notification}
    title="User removed from Guild"
    canClose={true}
  />
)

export default GuildCreationNotification