import React from 'react'
import {ArrowDropDown} from '@mui/icons-material'
import {Button} from '@mui/material'
import './PageSizeSelection.scss'

interface PageSelectionProps {
    values: number[]
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const PageSizeSelection: React.FC<PageSelectionProps> = ({values, onClick}) => {

    return <Button
        onClick={onClick}
        size="small"
        className="button medium"
        data-testid="page-size-selection"
        startIcon={
            <ArrowDropDown className="icon medium"/>
        }
        {...values}
    />
}

export default PageSizeSelection
