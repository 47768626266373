import React from 'react'
import {UploadCADModelCreationForm} from './Forms/UploadForm/UploadCADModelCreationForm'
import {setSnackbarMessage} from '../../../genericComponents/commonSlice'
import {useTypedDispatch} from '../../../util/store'
import {ItemList} from '../../../itemList/model/ItemList'
import {useCurrentGuildContext, useDatxPathGenerator} from '../../../util/routing'
import {CADModelTypeEnum} from '../../model/cadModelType/CADModelTypeEnum'
import {useCreateCADModelServiceRequestMutation} from '../rtkServiceRequestApi'
import {ReviewCADModelCreationForm} from './Forms/ReviewStep/ReviewCADModelCreationForm'
import {mixed, object, string} from 'yup'
import {ServiceRequestStep} from '../model/ServiceRequestStep'
import {ROUTES} from '../../../constants/routing'
import {ServiceRequestFormTemplate} from '../genericComponents/formTemplate/ServiceRequestFormTemplate'
import {ServiceRequestWorkflowInput} from '../model/ServiceRequestWorkflowInput'

export interface RequestCADModelInput extends ServiceRequestWorkflowInput {
    itemList?: ItemList,
    cadModelType?: CADModelTypeEnum
}

const defineItemAndType = {
    itemList: mixed().required(),
    cadModelType: mixed().required()
}

const validationSchema = {
    ...defineItemAndType,
    selectedWorkflowId: string().required()
}


const CADModelCreationForm = () => {
    const dispatch = useTypedDispatch()
    const [currentGuildContext] = useCurrentGuildContext()
    const [createService] = useCreateCADModelServiceRequestMutation()

    const generatePath = useDatxPathGenerator()

    const steps: ServiceRequestStep<RequestCADModelInput>[] = [{
        name: 'Request CAD Type',
        component: <UploadCADModelCreationForm />,
        pageValidation: object<RequestCADModelInput>(defineItemAndType),
        description: 'By filling this form you will be requesting CAD Model for your chosen list of items.'
    }, {
        name: 'Request CAD Type',
        component: <ReviewCADModelCreationForm />,
        pageValidation: object<RequestCADModelInput>(validationSchema)
    }]

    const handleCompleteUploadForm = ({
                                          itemList,
                                          cadModelType,
                                          selectedWorkflowId
                                      }: RequestCADModelInput): Promise<any> | undefined => {
        if (!cadModelType) {
            dispatch(setSnackbarMessage('You have to select a CAD model type to request a CAD Model Creation.'))
            return
        }

        if (!selectedWorkflowId) return

        return createService({
            guildId: currentGuildContext?.guildId ?? '',
            itemListIds: [itemList?.id ?? ''],
            cadModelValue: {
                cadModelType: cadModelType
            },
            workflowConfigurationId: selectedWorkflowId
        })
    }

    return <ServiceRequestFormTemplate
        steps={steps}
        handleFormSubmit={handleCompleteUploadForm}
        initialValues={{
            itemList: undefined,
            cadModelType: undefined,
            selectedWorkflowId: ''
        }}
        previousUrl={generatePath(ROUTES.servicesCatalogue.path)}
        title="Item Modelling"
    />

}
export default CADModelCreationForm
