import {AppBar, Box, Drawer} from '@mui/material'
import {LegalManagementMenu} from './menu/LegalManagementMenu'
import {Outlet} from 'react-router-dom'
import './LegalPageStructure.scss'

function LegalPageStructure() {

  return (
  <Box className="legal-management-page-structure">
      <Drawer variant="permanent"
              classes={{ paper: 'legal-left-bar'}}
              className="legal-left-bar-drawer">
        <LegalManagementMenu/>
      </Drawer>
        <div className="legal-management-container">
            <Outlet/>
        </div>
    </Box>
  )
}

export default LegalPageStructure
