import {searchAPI} from '../search/rtkSearchApi'
import {IdType} from '../util/models/IdType'
import {SimpleItem} from './model/SimpleItem'

export const itemApi = searchAPI.enhanceEndpoints({})
    .injectEndpoints({
        endpoints: (builder) => ({
            getItemDetails: builder.query<SimpleItem, { itemId?: IdType, guildId?: IdType }>({
                query: ({itemId, guildId}) => ({
                    url: !guildId ? `items/${itemId}` : `guilds/${guildId}/items/${itemId}`,
                    method: 'GET'
                }),
                keepUnusedDataFor: 5,
                transformErrorResponse: () => 'An error occurred while retrieving item details.'
            })
        })
    })

export const {useGetItemDetailsQuery} = itemApi
