import {Search} from '../../../model/SearchFilter'
import {useSearchItemsQuery} from '../../../rtkSearchApi'
import React, {ChangeEvent} from 'react'
import {Box} from '@mui/material'
import {
    SearchResultsComponentForSpecificItemSharing
} from '../SearchResultsComponent/SearchResultsComponentForSpecificItemSharing'


export const SearchResultPageForSpecificItemsSharing = ({search, page, pageSize, handlePageChange}: {
    search: Search,
    page: number,
    pageSize: number,
    handlePageChange: (e: ChangeEvent<any>,  pageNumber: number)=>void
}) => {

    const {data: searchResult, isFetching} = useSearchItemsQuery({
        search: search,
        pageNumber: page - 1,
        pageSize: pageSize
    })

    return <Box className="item-list-page">
        <SearchResultsComponentForSpecificItemSharing
            searchResult={searchResult}
            isFetching={isFetching}
            page={page}
            search={search}
            handlePageChange={handlePageChange}
            // Added key : If one of these attribute changes, the searchResults Component is reredended
            key={`${search.guildId}_${search.phrase}_${searchResult?.elements || ''}`}
        />
    </Box>
}
