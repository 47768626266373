enum MemberStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SENT = 'SENT',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED'
}


export default MemberStatusEnum

