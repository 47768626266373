import React from 'react'

export const PaidFormDescription = () => {
    return (
        <div className="step-description">
            <p>
                At DATX, we can assist you in checking the availability of your required data in our database. This will
                save you the time that you would otherwise spend in checking for each part manually.
                <br />
                <br />
                All you need to do is upload a spreadsheet containing all the required part numbers with their
                corresponding manufacturer reference number.
                <br />
                <br />
                You may use this{' '}
                <a href="/files/services/Matching_Part_Creation_Template_for_Input_DATX.xlsx">
                    {' '}
                    part data template
                </a>{' '}
                for an example of how you can upload your parts.
                <br />
            </p>
        </div>
    )
}
