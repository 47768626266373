import React from 'react'
import NewsArea from './news/NewsArea'
import HomePageSearch from './search/HomePageSearch'
import {useCurrentGuildContext} from '../util/routing'
import {Shortcuts} from './Shortcuts/Shortcuts'
import {GuildCatchup} from './GuildCatchup/GuildCatchup'
import Helpdesk from './helpdesk/Helpdesk'
import './WelcomePage.scss'


export const WelcomePage = () => {
    const [currentGuildContext] = useCurrentGuildContext()
    return <>
        <div className="welcome-page">
            <HomePageSearch/>
            {
                currentGuildContext ? <Shortcuts></Shortcuts> : <GuildCatchup></GuildCatchup>
            }
            <div className="tertiary-content">
                <NewsArea/>
                <Helpdesk/>
            </div>
        </div>
    </>
}

export default WelcomePage
