import {CustomTooltip} from '../../../genericComponents/customTooltip/CustomTooltip'
import InfoIcon from '@mui/icons-material/Info'
import {TextField, Typography} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import React, {ChangeEvent, useState} from 'react'
import { Guild } from '../../models/Guild'
import { validate  as uuidValidate} from 'uuid'
import { GuildContext } from '../../../header/models/GuildContext'
import { GuildSharingCreation } from '../model/GuildSharingCreation'
import {useCreateNewSharingMutation} from '../../rtkGuildApi'

export const AddGuildSharing = ({guild, targetGuildId, setTargetGuildId, currentGuildContext}:
                                    { guild: Guild | undefined,
                                        targetGuildId: string,
                                        setTargetGuildId: React.Dispatch<React.SetStateAction<string>>,
                                        currentGuildContext: GuildContext | null
                                    }) => {
    const [isValidUUID, setIsValidUUID] = useState<boolean>(false)
    const [createSharing, createSharingResult] = useCreateNewSharingMutation()

    const addNewGuildTooltipText = 'By Adding Sharing Guild, you may share your guild items and private attributes with this guild.'
    const shouldDisplayInvalidGuildError = () => {
        return !guild && targetGuildId.trim() !== '' || isCurrentGuild()
    }
    const shouldDisableAddNewButton = () => {
        return shouldDisplayInvalidGuildError() || targetGuildId.trim() === ''
    }
    const isCurrentGuild = () => {
        return targetGuildId === currentGuildContext!.guildId
    }
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {value} = event.target
        setTargetGuildId(value)
        checkUUIDValidity(value)
    }
    const checkUUIDValidity = (guildId: string) => {
        const isValid = uuidValidate(guildId)
        setIsValidUUID(isValid)
    }

    const handleAddNewSharing = () => {
        const sharing: GuildSharingCreation =
            {
                id: '',
                sourceGuildId: currentGuildContext!.guildId,
                targetGuildId: targetGuildId
            }
        createSharing(sharing)
    }

    return <div className="add-new-guild-sharing-container">
        <div className="share-to-guild-title-input-container">
            <div className="add-new-guild-title-container">
                <span className="add-new-guild-title">Add New Guild</span>
                <CustomTooltip title={addNewGuildTooltipText} placement="right-end" arrow>
                    <InfoIcon className="sharing-info-icon"/>
                </CustomTooltip>
            </div>
            <div className="guild-id-name-add-button-container">
                <TextField
                    data-testid="targetGuildId"
                    className="add-new-guild-share-input"
                    placeholder="Enter Guild ID"
                    variant="outlined"
                    value={targetGuildId}
                    onChange={handleInputChange}
                    error={shouldDisplayInvalidGuildError()}
                />
                <div className="guild-name-container">
                    {isCurrentGuild() ? <Typography className="info-message">Cannot share with myself</Typography>
                        : <GuildName guild={guild} isErrorDisplayed={shouldDisplayInvalidGuildError()}/>}
                </div>
                <LoadingButton
                    data-testid="add-new-share"
                    className="share-button"
                    variant="contained"
                    color="primary"
                    type="button"
                    size="small"
                    loading={createSharingResult.isLoading}
                    disabled={shouldDisableAddNewButton()}
                    onClick={handleAddNewSharing}
                >
                    Add New
                </LoadingButton>
            </div>
        </div>
    </div>
}

const GuildName = ({guild, isErrorDisplayed}: { guild?: Guild, isErrorDisplayed: boolean }) => {
    if (guild) {
        return <Typography className="guild-name">{guild.name.toUpperCase()}</Typography>
    } else if (isErrorDisplayed) {
        return <Typography className="guild-name invalid">INVALID GUILD ID</Typography>
    }
    return <Typography></Typography>
}
