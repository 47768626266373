import {useIsAuthenticated} from '@azure/msal-react'
import {generatePath, matchRoutes, RouteMatch, RouteObject, useLocation, useNavigate, useParams} from 'react-router-dom'
import {NO_GUILD_CONTEXT, RouteCustomType, ROUTES} from '../constants/routing'
import {GuildContext} from '../header/models/GuildContext'
import {LocalStorage} from './localStorage'
import {IdType} from './models/IdType'
import {useEffect, useState} from 'react'
import * as thisRouting from './routing'
import {useGetUserQuery} from '../user/rtkUserApi'

type CustomRouteMatch =  RouteMatch & {route: RouteObject & RouteCustomType}

// used to get the object value of the corresponding route in ROUTES
// it is to correctly obtain the right options attached to the route
export const useMatchedRoute = ():CustomRouteMatch[] | null => {
    const location = useLocation()
    return matchRoutes(Object.values(ROUTES), location)
}

export const usePublicPathGenerator = () => {
    return (url?:string, params?:any) => generatePath(url ?? '', {...params})
}

/**
 * A simple wrapper around react-router#generatePath that will handle the currentGuildId parameter defined in all DATX routes
 */
 export const useDatxPathGenerator = () => {
    const [currentGuildContext] = useCurrentGuildContext()

    return (url?:string, params?:any) => generatePath(url ?? '', {...params, currentGuildId: currentGuildContext?.guildId || NO_GUILD_CONTEXT})
}

// Manage "+" value in the search param
export const useSearchNavigateWithPathName = () => {
     const navigate = useNavigate()

    return (options : {pathname:string, search:string}) => {

        const encoded = encodeString(options.search)
        navigate({
            pathname: options.pathname,
            search: `?phrase=${encoded}&page=1`
        })
    }
}


export const useSearchNavigate = () => {
    const navigate = useNavigate()

    return (options: { search: string }) => {

        const encoded = encodeString(options.search)
        navigate({
            search: `?${encoded}`
        })
    }
}

   export const encodeString  = (input : string)  :string => {
        return encodeURIComponent(input)
    }




export function useCurrentGuildContext(): [GuildContext | null, any, boolean, string | undefined, GuildContext[]] {
    const authenticated =  useIsAuthenticated()
    const {data: user, isFetching} = useGetUserQuery(undefined, {skip: !authenticated})
    const navigate = useNavigate()
    const routes = useMatchedRoute()
    const {search, hash} = useLocation()

    const matchedRoute = routes?.[0].route
    const params = useParams()
    const guildContextParam = params?.currentGuildId

    //Context can only be set once the related data has been retrieved
    const isInitialized = authenticated && !isFetching

    const setGuildContext = (newGuildContext: IdType | null) => {
        const guildContext = user?.guildList.find(guildContext => guildContext.guildId === newGuildContext) || null

        LocalStorage.setGuildContext(guildContext)

        navigate({
            pathname: generatePath(matchedRoute?.path ?? '', {...params, currentGuildId: guildContext?.guildId || NO_GUILD_CONTEXT}),
            search: search,
            hash: hash
        })
    }

    return [user?.guildList.find(guildContext => guildContext.guildId === guildContextParam) || null, setGuildContext, isInitialized, guildContextParam, user?.guildList || []]
}

export const useIsGuildAccessible = (): [boolean, boolean] => {
    const [hasAccessToTargetGuild, setHasAccessToTargetGuild] = useState<boolean>(false)
    const [,,isInitialized, guildContextParam, guildList] = thisRouting.useCurrentGuildContext()



    useEffect(() => {
        if (!isInitialized) return
        if (!guildContextParam) {
            setHasAccessToTargetGuild(true)
            return
        }

        if (guildContextParam === NO_GUILD_CONTEXT) {
            setHasAccessToTargetGuild(true)
            return
        }

        if (guildList.find(guildContext => guildContext.guildId === guildContextParam) ){
            setHasAccessToTargetGuild(true)
        }

    }, [guildContextParam, isInitialized, guildList])

    return [hasAccessToTargetGuild, isInitialized]
}

export const useItemDetailView = () => {
     const storedView = LocalStorage.getItemDetailView()
     const initialView = storedView !== '' ? storedView : 'default'

     const [selectedItemDetailView, setSelectedItemDetailView] = useState(initialView)

    useEffect(() => {
        LocalStorage.setItemDetailView(selectedItemDetailView)
    }, [selectedItemDetailView])

    return [selectedItemDetailView, setSelectedItemDetailView]
}
