import {IdType} from '../../util/models/IdType'
import {DisplaySet} from '../../displaySet/model/DisplaySet'
import {DisplaySetOrdered} from './DisplaySetOrdered'

export interface DisplaySetConfiguration {
    id: IdType,
    displayOrder: number,
    displaySetConfigurations?: DisplaySetConfiguration[],
    disable: boolean
}

export const buildDisplaySetConfigurations = (displaySetsOrdered: DisplaySetOrdered[] | undefined): DisplaySetConfiguration[] | undefined => {
    if(!displaySetsOrdered) return undefined
    return displaySetsOrdered.map((displaySet, index) => {
        return {
            id: displaySet.displaySet.id,
            displayOrder: index,
            displaySetConfigurations: buildDisplaySetConfigurations(displaySet.children),
            disable: displaySet.disable
        }
    })
}

// deeply duplicate the objects to be able to update them
export const sanitizeAndSortDisplaySetsAndConfiguration = (displaySets: DisplaySet[] | undefined,  configurations: DisplaySetConfiguration[] | undefined): DisplaySetOrdered[] => {
    const duplicatedDisplaySets = buildDisplaySetsOrdered(displaySets, configurations)
    return sortDisplaySetsFromConfigurations(duplicatedDisplaySets) ?? []
}

export const buildDisplaySetsOrdered = (displaySets: DisplaySet[] | undefined, configurations: DisplaySetConfiguration[] | undefined): DisplaySetOrdered[] | undefined => {
    const configurationsById: Map<IdType, DisplaySetConfiguration> = new Map(configurations?.map(configuration => [configuration.id, configuration]))
    return displaySets?.map(displaySet => {
        const configurationForDisplaySet = configurationsById.get(displaySet.id)
        return {
            id: displaySet.id,
            displaySet: displaySet,
            configuration: configurationForDisplaySet,
            children: buildDisplaySetsOrdered(displaySet.children, configurationForDisplaySet?.displaySetConfigurations),
            displayOrder: configurationForDisplaySet?.displayOrder ?? displaySet.displayOrder,
            disable: configurationForDisplaySet?.disable ?? false
        }
    })
}

export const sortDisplaySetsFromConfigurations = (displaySets: DisplaySetOrdered[] | undefined): DisplaySetOrdered[] | undefined => {
    if(!displaySets) return undefined
    displaySets.forEach(displaySet => {displaySet.children = sortDisplaySetsFromConfigurations(displaySet.children)})
    return displaySets.sort((dsA, dsB) => dsA.displayOrder - dsB.displayOrder)
}
