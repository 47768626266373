import React, {ReactNode} from 'react'
import './Typography.scss'

export const Label = ({
    children,
    className,
    id,
    htmlFor,
    required,
    dataTestid
}: {
    className?: string
    children?: ReactNode
    id?: string
    htmlFor?: string
    required?: boolean,
    dataTestid?: string
}) => {
    return (
        <label className={'label ' + className} htmlFor={htmlFor} id={id} data-testid={dataTestid ?? ('label-' + className)}>
            {children}
            {required && <span className="asterisk">*</span>}
        </label>
    )
}
