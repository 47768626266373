import {ServiceEnum} from '../../service/model/ServiceEnum'
import './WorkflowConfigurationManagement.scss'
import {useGetGuildQuery, useGetWorkflowConfigurationsQuery} from '../rtkGuildApi'
import {useCurrentGuildContext, useDatxPathGenerator} from '../../util/routing'
import LoadingSpinner from '../../genericComponents/LoadingSpinner'
import {NoData} from '../../genericComponents/NoData'
import React from 'react'
import WorkflowConfigurationTable from './WorkflowConfigurationTable'
import {Button} from '@mui/material'
import {ROUTES} from '../../constants/routing'
import {Link} from 'react-router-dom'

export const availableServices = [
    // ServiceEnum.SUBSCRIBE_INFORMATION_PACKAGE,
    ServiceEnum.NEW_COMPANY_ITEMS,
    ServiceEnum.NEW_MANUFACTURER_ITEMS,
    ServiceEnum.PART_MATCHING,
    ServiceEnum.ITEM_MONITORING,
    ServiceEnum.CREATE_PRIVATE_ATTRIBUTES,
    ServiceEnum.UPDATE_PRIVATE_ATTRIBUTES,
    ServiceEnum.ONE_TIME_EXTRACTION,
    ServiceEnum.RECURRING_EXTRACTION,
    ServiceEnum.CAD_MODEL,
    ServiceEnum.ITEM_UPDATE
]

const WorkflowConfigurationManagement = () => {
    const generatePath = useDatxPathGenerator()
    const [currentGuildContext] = useCurrentGuildContext()
    const {data: workflowConfigurations = [], isLoading} = useGetWorkflowConfigurationsQuery(
        currentGuildContext?.guildId,
        {skip: !currentGuildContext?.guildId || !currentGuildContext?.guildAdmin}
    )
    const {data: guild} = useGetGuildQuery(currentGuildContext?.guildId, {skip: !currentGuildContext?.guildId})
    const guildUsers = guild?.guildUsers ?? []

    if (!currentGuildContext?.guildId && !currentGuildContext?.guildAdmin) return <NoData/>

    if (isLoading) return <LoadingSpinner/>

    return (
        <div className="workflow-configuration-container">
            <h1 className="workflow-configuration-title">Workflow Configuration</h1>
            <div className="workflow-service-filter-add-new-workflow-container">
                <Button
                    id="add-new-workflow-btn"
                    data-testid="add-new-workflow-btn"
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={generatePath(ROUTES.createWorkflowConfiguration.path ?? '')}
                >Add Workflow
                </Button>
            </div>
            <div className="workflow-configuration-content">
                <WorkflowConfigurationTable
                    //update table when workflow configurations change, for example, when changing the guild context
                    key={workflowConfigurations.toString()}
                    workflowConfigurations={workflowConfigurations}
                    guildUsers={guildUsers}/>
            </div>
        </div>
    )
}

export default WorkflowConfigurationManagement
