import React, {ChangeEvent, SyntheticEvent, useEffect} from 'react'
import {FormControl} from '@mui/material'
import UploadCommonLoadingButton from '../../../genericComponents/UploadCommonLoadingButton'
import {useFormikContext} from 'formik'
import {NewValueByItemDTO} from '../../../../model/private/NewValueByItemDTO'
import {Description} from '../../../../../genericComponents/typographie/Description'
import {Label} from '../../../../../genericComponents/typographie/Label'
import { setSnackbarMessage } from '../../../../../genericComponents/commonSlice'
import { useDispatch } from 'react-redux'
import { AttributeStringUpdateForm } from '../PrivateAttributeUpdate'
import { generateTimestamp } from '../../../../../genericComponents/date/DateService'
import {ServiceRequestStep} from '../../../genericComponents/formTemplate/steps/ServiceRequestStep'
import {useGetItemListAllPagesQuery} from '../../../../../itemList/rtkItemListApi'
import {useCurrentGuildContext} from '../../../../../util/routing'
import {useGetPrivateValuesForItemQuery} from '../../../../../search/rtkSearchApi'
import {SearchItem} from '../../../../../search/model/SearchItem'
import LoadingSpinner from '../../../../../genericComponents/LoadingSpinner'

export const UploadDocumentUpdate = () => {
    const formik = useFormikContext<AttributeStringUpdateForm>()
    const [currentGuildContext] = useCurrentGuildContext()
    const dispatch = useDispatch()
    const itemListLite = formik?.values?.itemList
    const privateDefinition = formik?.values?.privateDefinition

    const {data: itemListFull, isSuccess: itemListFullIsSuccess} = useGetItemListAllPagesQuery(
        {
            listId: itemListLite?.id
        },
        {skip: !currentGuildContext?.guildId || !itemListLite}
    )

    useEffect(() =>{
        if(itemListFullIsSuccess){
            formik.setFieldValue('itemListWithItems', itemListFull)
        }
    }, [itemListFull])

    const {data: privateValues = [], isSuccess: privateValuesAreSuccess} = useGetPrivateValuesForItemQuery({
        itemUuids: itemListFull?.elements?.map((it: SearchItem) => it?.id),
        guildId: currentGuildContext?.guildId,
        attributeDefinition: privateDefinition?.id
    }, {
        skip: !currentGuildContext?.guildId || !privateDefinition?.id
            || !itemListFull?.elements
            || itemListFull?.elements?.length <= 0
    })

    useEffect(() =>{
        if(privateValuesAreSuccess){
            formik.setFieldValue('privateValues', privateValues)
        }
    }, [privateValues])

    const setArtifactFile = (artifactFile: File | undefined) => {
        formik.setFieldValue('artifactFile', artifactFile)
    }

    const setPrivateAttributes = (privateAttributes: NewValueByItemDTO[] | undefined) => {
        if (!privateAttributes || privateAttributes.length <= 0) return

        const privateAttributesToUpdate = privateAttributes.filter(
            (attribute) =>
                attribute.value !== null && attribute.value !== '' && attribute.value !== undefined && attribute.itemId
        )
        formik.setFieldValue('privateAttributes', privateAttributesToUpdate)
    }

    const unselectArtifactFile = (event: SyntheticEvent) => {
        setPrivateAttributes(undefined)
        setArtifactFile(undefined)
        event.preventDefault()
    }

    const renameFileWithTimestamp = (originalFile: File) => {
        const lastDot = originalFile.name.lastIndexOf('.')
        const fileName = originalFile.name.substring(0, lastDot)
        const ext = originalFile.name.substring(lastDot)

        const newFileName = fileName + '-' + generateTimestamp() + ext

        return new File([originalFile], newFileName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified
        })
    }

    const parseArtifactFile = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target?.files?.length) return
        if (event.target?.files[0].size > 100000000) {
            dispatch(setSnackbarMessage('File selected is larger than 100 MB limit'))
            return
        }

        const tempFile = event.target.files[0]
        const tempFileWithTimestamp = renameFileWithTimestamp(tempFile)
        setArtifactFile(tempFileWithTimestamp)
    }

    return (
        <ServiceRequestStep>
            <Description className="service-request-step-description">
                By submitting the new document, the existing document will be updated for the relevant items.<br/>
                Please note that, file name will be time stamped upon successful document upload.
            </Description>

            {!privateValuesAreSuccess ?
                <div className="service-request-form-step">
                    <LoadingSpinner></LoadingSpinner>
                </div>
                :
                <FormControl className="download-upload-template-container">
                    <Label className="file-download-upload-label" htmlFor="upload-file">
                        Upload Document:
                    </Label>
                    <UploadCommonLoadingButton
                        className="upload-template-button"
                        selectedFileName={formik?.values?.artifactFile?.name ?? ''}
                        unselectFile={unselectArtifactFile}
                        parseFile={parseArtifactFile}
                        acceptedFileType=".pdf, .txt, .ppt, .pptx, .doc, .docx, .xls, .xlsx"
                    />
                </FormControl>
            }
        </ServiceRequestStep>
    )
}
