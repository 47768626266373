import './MaintenancePage.scss'
import {Button} from '@mui/material'
import {generatePath, Link} from 'react-router-dom'
import {ROUTES} from '../constants/routing'
import React from 'react'

const MaintenancePage = () => {
    return (
        <div className="maintenance-panel" data-testid="maintenance-panel">
            <div className="maintenance-icon-container">
                    <img
                        src="/images/DATX_Logo_Black.svg"
                        alt="logo-title"
                        className="error-logo"
                        data-testid="error-logo"
                    />
                    <img
                        src="/images/thought_cloud.svg"
                        alt="Sad cloud"
                        className="cloud"
                    />
            </div>

            <p className="description" >Something went wrong on our end. Don't worry, it's not you - it's us.<br/><br/>We apologize for the inconvenience.</p>
            <p className="description" >Please feel free to <a href="mailto:datx.contact@thalesgroup.com">contact us</a> if you have any urgent queries.</p>
            <Button component={Link} to={generatePath('/')} color="primary" variant="contained" type="button" className="back-button">Back to Homepage</Button>
        </div>
    )
}

export default MaintenancePage
