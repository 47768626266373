import {ItemList} from '../../../itemList/model/ItemList'
import {useCurrentGuildContext} from '../../../util/routing'
import {useGetItemListsQuery} from '../../../itemList/rtkItemListApi'
import {skipToken} from '@reduxjs/toolkit/dist/query/react'
import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from '@mui/material'
import React, {ReactNode} from 'react'

const ItemListSelect = ({value, onListSelection}:
                          { value?: ItemList|undefined,
                              onListSelection: (newList: ItemList) => void
                               }) => {
    const [currentGuildContext] = useCurrentGuildContext()
    const {data: myLists = [], isFetching: myListsLoading} = useGetItemListsQuery(currentGuildContext?.guildId ?? skipToken)
    const onSelect = (event: SelectChangeEvent<ItemList>, child: ReactNode) => {
        onListSelection(event.target.value as ItemList)
    }

    return (
        <FormControl required className="form-select">
            <InputLabel id="item-list-label">Select list</InputLabel>
            <Select
                data-testid="itemlist-dropdown-selector"
                labelId="item-list-label"
                id="item-list-helper"
                value={value ?? ''}
                label="Item List"
                onChange={onSelect}
                disabled={myListsLoading || !myLists || myLists.length === 0}
            >
                {myLists?.length &&
                    myLists.map((list) => (
                        <MenuItem
                            value={list as any}
                            key={list.id}
                            autoFocus={false}
                            data-cy={`select-option-${list.name}`}
                            sx={{fontSize: '14px'}}
                        >
                            {list.name}
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    )
}

export default ItemListSelect
