import React, {useEffect, useState} from 'react'
import './GuildCreation.scss'
import {useMsal} from '@azure/msal-react'
import {getEmailFromMsal} from '../../authentication/AuthHooksAndFunctions'
import GuildCreationForm from './form/GuildCreationForm'
import GuildCreationSuccess from './success/GuildCreationSuccess'
import {useCreateGuildMutation} from '../rtkGuildApi'

const guildNameMaxLength = 25
const errorMessageName = `The name cannot be longer than ${guildNameMaxLength} characters.`
const errorMessageEmptyName = 'The name cannot be empty.'
const errorMessageAlpha = 'The name can contain only alphanumerical characters and cannot start with a space'

const warningAboutCharacters = `Maximum ${guildNameMaxLength} characters`

const guildCreationSteps = {
    form: 1,
    success: 2
}

const checkErrorForName = (name: string): string[] => {
    const errors: string[] = []
    if (!name || name.length === 0) {
        errors.push(errorMessageEmptyName)
    } else if (name.length > guildNameMaxLength) {
        errors.push(errorMessageName)
    }

    if (!/^[\w][\w ]*$/.test(name)) {
        errors.push(errorMessageAlpha)
    }

    return errors
}

export type GuildCreationStatus = {name: string; errors: string[]}

const GuildCreation = () => {
    const {instance} = useMsal()
    const [guildState, setGuildState] = useState<GuildCreationStatus>({name: '', errors: []})
    const [email, setEmail] = useState(getEmailFromMsal(instance))
    const [isTermsChecked, setIsTermsChecked] = useState(false)
    // manage the current step of the guild creation process
    // it is to future-proof in case more steps are introduced
    const [currentStep, setCurrentStep] = useState(guildCreationSteps.form)

    const [createGuild, createGuildResult] = useCreateGuildMutation()

    useEffect(() => {
        setEmail(getEmailFromMsal(instance))
    }, [instance])

    const createGuildAction = () => {
        createGuild({name: guildState.name.trim()})
    }

    useEffect(() => {
        if (createGuildResult.data && createGuildResult.isSuccess) {
            setCurrentStep(guildCreationSteps.success)
        }
    }, [createGuildResult])

    const onGuildNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e?.target?.value
        const error = checkErrorForName(value)
        setGuildState({name: value, errors: error})
    }

    const canSubmit = () => {
        return isTermsChecked && !(guildState.errors.length || !guildState.name || createGuildResult.isLoading)
    }

    const displayFormOrSuccess = () => {
        switch (currentStep) {
            case guildCreationSteps.form:
                return (
                    <GuildCreationForm
                        canSubmit={canSubmit}
                        onSubmit={createGuildAction}
                        isSubmitting={createGuildResult.isLoading}
                        guildState={guildState}
                        onGuildNameChange={onGuildNameChange}
                        isTermsChecked={isTermsChecked}
                        setIsTermsChecked={setIsTermsChecked}
                        warningAboutCharacters={warningAboutCharacters}
                    />
                )
            case guildCreationSteps.success:
                return <GuildCreationSuccess guildName={guildState.name} />
            default:
                // This should never be executed
                // If so, something wants wrong
                return null
        }
    }

    return <div className="create-guild-form">{displayFormOrSuccess()}</div>
}

export default GuildCreation
