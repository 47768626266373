import {ReactElement} from 'react'
import {CustomCard} from '../../genericComponents/customCard/CustomCard'
import {People} from '@mui/icons-material'
import {ItemList} from '../model/ItemList'
import {IdType} from '../../util/models/IdType'


export const ItemListCard = ({list, onSelection, actionList, isSelected}: {
    list: ItemList,
    onSelection: ({listId, selected} : {listId: IdType, selected: boolean}) => void,
    actionList: ReactElement,
    isSelected: boolean
}) => {




    const handleChange = () => {
        onSelection({listId: list.id, selected: !isSelected})

    }

    const bodyContent = (
        <>
            <span className="label">List Name</span>
            <span className="list-name" title={list.name ?? '-'}>{list.name ?? '-'}</span>
            <span className="label">Number of items</span>
            <span className="value" title={list.items.length.toString() ?? '-'}>{list.items.length.toString() ?? '-'}</span>
        </>
    )

    const peopleIcon = (
        <>
            {list?.isSharedWithGuild && (<People className={'more-actions-option'}/>)}
        </>
    )

    


    return (
        
        <CustomCard
            id={list.id}
            name={list.id}
            bodyContent={bodyContent}
            handleChange={handleChange}
            actionList={actionList}
            typeIcon={peopleIcon}
            checked={isSelected}
            moreOptionMenu={true}
        />
    )
}
