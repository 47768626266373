import {TemplateDefinitionForDisplaySet} from '../definitionTemplates/TemplateDefinitionForDisplaySet'
import {getComponentForAttributeValueType} from '../definitionTemplates/AttributeValueToComponentMapper'
import React from 'react'
import {AttributeDefinitionModel} from '../model/AttributeModel'
import {AttributeValueType} from '../model/AttributeValue'
import {sortByStartValidity} from '../utils/sortedDefinitions'

export const ArtifactDefaultDefinition = ({definition}: { definition: AttributeDefinitionModel }) => {
    if(!definition.attributes) return <></>

    const attributes = definition.attributes[0].value.type === AttributeValueType.ARTIFACT
        ? sortByStartValidity(definition.attributes)
        : definition.attributes

    return <TemplateDefinitionForDisplaySet definition={definition}>
        {attributes.map(attribute => getComponentForAttributeValueType(attribute))}
    </TemplateDefinitionForDisplaySet>
}