import {Checkbox} from '@mui/material'
import AddToListButton from '../../../../../genericComponents/AddToListButton'

const ActionBox = ({
                       isItemSelected,
                       handleSelect,
                       handleUnselect,
                       handleAddSingleItemToList,
                   }: {
    isItemSelected: boolean,
    handleSelect: () => void,
    handleUnselect: () => void,
    handleAddSingleItemToList: () => void
}) => {
    return (
        <div className="item-row-card-action-box">
            <div data-testid="item-row-card-checkbox">
                <Checkbox checked={isItemSelected} onClick={() => {
                    if (!isItemSelected) {
                        handleSelect()
                    } else {
                        handleUnselect()
                    }
                }}/>
            </div>
            <AddToListButton onClick={() => handleAddSingleItemToList()}/>
        </div>
    )
}

export default ActionBox