import './legalsPage.scss'
import {AllCookiesDetails} from '../../Cookies/CookiesData'

function CookiePolicy() {

  return (
    <div className="content-body">
      <h1>Cookie policy</h1>
        <p>
            In compliance with the French National Commission for Data Protection and Liberties (CNIL) Guidelines n°2020-091 of September 17, 2020  concerning cookies, and Article 5(3) of European Directive 2002/58/CE amended by Directive 2009/136/CE, DATX informs visitors that cookies are used on its Website.
        </p>
      <h2>What are cookies?</h2>
        <p>Cookies are small text files that a website, when visited by a user, asks your browser to store on your device in order to remember information about you, such as your language preference or login information. More specifically, we use cookies for the following purposes such as assisting you in navigation or analysing your use of our product, services or applications in order to make sure you get the best possible user experience by enhancing the most used features and bring correction where it is necessary.</p>
        <p>There is two categories of cookies :</p>
        <li>First party cookies. They are the cookies set by DATX</li>
        <li>Third party cookies are cookies from a package different than the package of the website you are visiting (DATX).</li>
    <h2>How does DATX use cookies?</h2>
        <p>Below is a detailed list of the cookies we use on our Website. We classify cookies in the following categories:</p>
        <h3>Required Cookies</h3>
        <p>These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not store any personally identifiable information.</p>
        <h3>Functional Cookies</h3>
        <p>These cookies enable the website to provide enhanced functionality and personalisation. They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies then some or all of these services may not function properly</p>
        <h3>Performance Cookies</h3>
        <p>These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and anonymous If you do not allow these cookies we will not know when you have visited our site, and will not be able to monitor its performance.</p>
        <p>You can opt-out of each cookie category (except cookies required and functional cookies) from your profile in order to customize setting.</p>
        <h2>Cookies List:</h2>

        <table className="cookie-cell">
            <thead>
            <tr className="cookie-cell">
                <th className="cookie-cell" >Cookie</th>
                <th className="cookie-cell" >Categorie</th>
                <th className="cookie-cell" >Nom</th>
                <th className="cookie-cell" >Description</th>
                <th className="cookie-cell" >Plus d'informations</th>
            </tr>
            </thead>
            <tbody>
            {AllCookiesDetails.flatMap((category) =>
                category.vendorDetails.flatMap((vendor) =>
                    vendor.vendorList.map((cookie) =>
                        <tr className="cookie-cell">
                            <td className="cookie-cell">{cookie.name}</td>
                            <td className="cookie-cell">{category.category}</td>
                            <td className="cookie-cell">{vendor.type}</td>
                            <td className="cookie-cell"><p>{cookie.description}</p></td>
                            <td className="cookie-cell">
                                <a href={vendor.moreInfoUrl} target="_blank" rel="noreferrer">{vendor.type} Privacy Policy</a>
                            </td>
                        </tr>
                    )
                )
            )}
            </tbody>
        </table>

        <hr/>

        <h1>Politique en matière de cookies</h1>
        <p>
            Conformément à la délibération n°2020-091 du 17 septembre 2020 de la Commission Nationale de l'Informatique et des Libertés (CNIL) relative aux cookies, et à l'article 5(3) de la directive européenne 2002/58/CE modifiée par la directive 2009/136/CE, Thales informe les visiteurs que des cookies sont utilisés sur son Site Web.
        </p>
        <h2>Qu’est-ce que les cookies?</h2>
        <p>Les cookies sont de petits fichiers texte qu’un site Web, lorsqu’il est visité par un utilisateur, demande à votre navigateur de stocker sur votre appareil afin de mémoriser des informations vous concernant, telles que vos préférences linguistiques ou vos informations de connexion. Plus précisément, nous utilisons les cookies aux fins suivantes, par exemple pour vous aider à naviguer ou analyser votre utilisation de notre produit, services ou applications afin de vous assurer d’obtenir la meilleure expérience utilisateur possible en améliorant les fonctionnalités les plus utilisées et apporter des corrections lorsque cela est nécessaire.</p>
        <p>Il existe deux catégories de cookies : </p>
        <li>Cookies interne : ils sont créés et stockés par notre site DATX que vous visitez. </li>
        <li>Cookies tiers: ils sont déposés sur des packagees différents de celui de notre site DATX.</li>
        <h2>Comment les cookies sont utilisés ?</h2>
        <p>Voici une liste détaillée des cookies que nous utilisons sur notre Plateforme. Nous classons les cookies dans les catégories suivantes :</p>
        <h3>Cookies strictement nécessaires</h3>
        <p>Ces cookies sont nécessaires au fonctionnement du site Web et ne peuvent pas être désactivés dans nos systèmes. Ils sont généralement établis en tant que réponse à des actions que vous avez effectuées et qui constituent une demande de services, telles que la définition de vos préférences en matière de confidentialité, la connexion ou le remplissage de formulaires. Vous pouvez configurer votre navigateur afin de bloquer ou être informé de l'existence de ces cookies, mais certaines parties du site Web peuvent être affectées. Ces cookies ne stockent aucune information d’identification personnelle.</p>
        <h3>Cookies de fonctionnalité</h3>
        <p>Ces cookies permettent d’améliorer et de personnaliser les fonctionnalités du site Web. Ils peuvent être activés par nos équipes, ou par des tiers dont les services sont utilisés sur les pages de notre site Web. Si vous n'acceptez pas ces cookies, une partie ou la totalité de ces services risquent de ne pas fonctionner correctement.</p>
        <h3>Cookies de performance</h3>
        <p>Ces cookies nous permettent de déterminer le nombre de visites et les sources du trafic, afin de mesurer et d’améliorer les performances de notre site Web. Ils nous aident également à identifier les pages les plus / moins visitées et d’évaluer comment les visiteurs naviguent sur le site Web. Si vous n'acceptez pas ces cookies, nous ne serons pas informé de votre visite sur notre site. </p>

    </div>
  )
}

export default CookiePolicy