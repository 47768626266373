import React from 'react'
import {Headline} from '../../genericComponents/typographie/Headline'
import './Helpdesk.scss'

const Helpdesk = () => {

    return (
        <div className="home-card home-helpdesk-container">
            <Headline title="Service Desk">Service Desk</Headline>
            <div className="home-card-content helpdesk-content">
                <p>
                    If you need support or have any questions concerning the DATX platform, please feel free to reach
                    out to our support team at <a className="helpdesk-link" href="mailto:datx.contact@thalesgroup.com">
                        datx.contact@thalesgroup.com
                    </a>.
                </p>
                <p>
                    We are available to assist you with technical or general information about DATX.
                </p>
                <p>
                    Rest assured, we will do our best to address your concerns and answer your questions.
                </p>
            </div>
        </div>
    )
}

export default Helpdesk
