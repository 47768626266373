import React, {useEffect, useMemo, useState} from 'react'
import './RenameListDialog.scss'
import {TextField} from '@mui/material'
import CustomDialog from '../../genericComponents/customDialog/CustomDialog'
import {LoadingButton} from '@mui/lab'
import {useDebounce} from 'use-debounce'
import {ItemList} from '../model/ItemList'
import {getIsListNameDuplicated} from '../ItemLists'
import {useUpdateItemListMutation} from '../rtkItemListApi'
import {useCurrentGuildContext} from '../../util/routing'

const RenameListDialog = ({
    open,
    closeDialog,
    myLists,
    selectedList
}: {
    open: boolean
    closeDialog: () => void
    myLists: ItemList[]
    selectedList: ItemList
}) => {
    const [currentGuildContext] = useCurrentGuildContext()
    const [updateItemList, updateItemListResult] = useUpdateItemListMutation()

    const [listName, setListName] = useState('')
    const [inputErrorMsg, setInputErrorMsg] = useState('')

    const [debouncedListName] = useDebounce(listName, 500)

    const isListNameValid = useMemo(() => {
        const isNameDuplicated = getIsListNameDuplicated(myLists, listName)
        if (!listName || isNameDuplicated) {
            return false
        }
        return true
    }, [listName, myLists])

    const onListNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setListName(e.target.value)
        // as long as the user type something, the error will be gone
        setInputErrorMsg('')
    }

    // when the name is duplicated, an error message will be displayed
    // we don't when the error message to be displayed when the user is still typing
    // so the debounced value is used instead
    useEffect(() => {
        const isDebouncedNameDuplicated = getIsListNameDuplicated(myLists, debouncedListName)
        if (
            isDebouncedNameDuplicated &&
            // since submitting the form will update myList and cause the error message to be displayed
            // this condition is added to prevent the error message from showing up
            !updateItemListResult.isLoading
        ) {
            setInputErrorMsg('A list by this name already exists. Kindly provide a different name.')
        }
    }, [myLists, debouncedListName])

    const onSubmit = (event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        event.preventDefault()
        if (!currentGuildContext) {
            return
        }
        updateItemList({
            listId: selectedList.id,
            data: {...selectedList, name: listName, guildId: currentGuildContext?.guildId}
        })
    }

    return (
        <CustomDialog className="rename-list-dialog" open={open} title="Rename list" onClose={closeDialog}>
            {updateItemListResult.isSuccess && listName ? (
                <div className="add-item-to-list--confirmation">
                    <label data-testid="notification-dialog">The list has been renamed to:</label>
                    <p className="addItemToList-confirmation-label-name">{listName}</p>
                </div>
            ) : (
                <>
                    <label className="new-list-name-label" htmlFor="new_list_name_input">
                        Enter the new name of the list
                    </label>
                    <div className="new-list-name">
                        <TextField
                            id="new_list_name_input"
                            className="new-list-name-input-container"
                            value={listName}
                            onChange={onListNameChange}
                            helperText={inputErrorMsg}
                            variant="outlined"
                        />
                        <LoadingButton
                            className="new-list-submit"
                            color="primary"
                            variant="contained"
                            onClick={(event) => onSubmit(event)}
                            disabled={!isListNameValid}
                            loading={updateItemListResult.isLoading}
                        >
                            Confirm
                        </LoadingButton>
                    </div>
                </>
            )}
        </CustomDialog>
    )
}

export default RenameListDialog
