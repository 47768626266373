import {IdType} from '../../util/models/IdType'
import {NotificationAction, NotificationParameter} from './NotificationAction'

export interface Notification {
    id: IdType,
    createdDate: string
    content: string
    read: boolean
    recipientOauthId: IdType
    category: string
    notificationActions: NotificationAction[]
    parameters: NotificationParameter[]
}

export const getGuildId = (notification : Notification) => {
    return notification.parameters.find((param) => param.label === 'Guild Id')?.value
}

