import React, {ReactElement} from 'react'
import {TemplateDefinitionForDisplaySet} from '../definitionTemplates/TemplateDefinitionForDisplaySet'
import {getComponentForAttributeValueType} from '../definitionTemplates/AttributeValueToComponentMapper'
import {ArtifactDefaultDefinition} from './ArtifactDefaultDefinition'
import { DateDefaultDefinition } from './DateDefaultDefinition'
import {AttributeDefinitionTypeEnum, AttributeDefinitionModel} from '../model/AttributeModel'
import {sortAttributesByDisplayOrder} from '../utils/sortedDefinitions'


const mapDefinitionTypeToComponent = new Map<AttributeDefinitionTypeEnum, (definition: AttributeDefinitionModel) => ReactElement>([
    [AttributeDefinitionTypeEnum.ARTIFACT, (definition) => <ArtifactDefaultDefinition definition={definition} key={definition.attributeDefinitionId}/>],
    [AttributeDefinitionTypeEnum.DATE, (definition) => <DateDefaultDefinition definition={definition} key={definition.attributeDefinitionId}/>]
])

export const getComponentForDefinitionType = (definition: AttributeDefinitionModel) => {
    if (definition.attributes.length < 0) return
    const type = definition.attributes[0].attributeDefinitionType?.attributeDefinitionType ?? AttributeDefinitionTypeEnum.STRING
    const componentFunction = mapDefinitionTypeToComponent.get(type)
    if (!componentFunction) return
    return componentFunction(definition)
}

export const DefaultDefinitionTemplate = ({definition}: { definition: AttributeDefinitionModel }) => {

    const definitionTypeComponent = getComponentForDefinitionType(definition)
    if (definitionTypeComponent) return definitionTypeComponent

    const sortedAttributes = sortAttributesByDisplayOrder(definition.attributes)

    return <TemplateDefinitionForDisplaySet definition={definition}>
        {sortedAttributes.map(attribute => getComponentForAttributeValueType(attribute))}
    </TemplateDefinitionForDisplaySet>
}
