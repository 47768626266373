import {Link} from 'react-router-dom'
import './CodificationLinkWidget.scss'
import {useDatxPathGenerator} from '../../../util/routing'
import {ROUTES} from '../../../constants/routing'
import {DateDisplay} from '../../../genericComponents/date/dateDisplay'
import {DisplaySet} from '../../model/DisplaySet'
import {SimpleItem} from '../../../item/model/SimpleItem'
import {AttributeDefinitionTypeEnum} from '../../model/AttributeModel'
import {getLinkedItems, sortByDate} from '../../utils/sortedDefinitions'

const CodificationLinkHeaderRow = () => (
    <div className="header">
        <p className="header-cell">Part Number</p>
        <p className="header-cell">Defined By</p>
        <p className="header-cell">Codification System</p>
        <p className="header-cell">Creation Date</p>
    </div>
)

const CodificationLinkRow = ({id, partNumber, definedBy, codificationSystem, creationDate, isCurrentItem}: {
    id: string,
    partNumber: string,
    definedBy: string,
    codificationSystem: string,
    creationDate: number[] | null | undefined,
    isCurrentItem: boolean
}) => {
    const generatePath = useDatxPathGenerator()

    return (
        <div className="row">
            {!isCurrentItem ?
                (<Link
                    to={generatePath(ROUTES.detailItem.path, {itemId: id})}
                    className="cell"
                    data-testid={`item-${id}`}
                >
                    {partNumber}
                </Link>) :
                (<p className="cell">{partNumber} <span className="this-item">This Item</span></p>)}
            <p className="cell">{definedBy}</p>
            <p className="cell">{codificationSystem}</p>
            <p className="cell">
                {creationDate ? <DateDisplay entryDate={creationDate} time={false}/> : '-'}
            </p>
        </div>
    )
}

const CodificationLinkWidget = ({displaySet, parentItem}: { displaySet: DisplaySet; parentItem?: SimpleItem }) => {
    const linkedItemsAttributeModel = displaySet.attributes.filter((it) => it.attributeDefinitionType?.attributeDefinitionType === AttributeDefinitionTypeEnum.LINK)

    const linkedItems = getLinkedItems(sortByDate(linkedItemsAttributeModel, (attribute) => attribute.createdDate))

    if (linkedItems.length === 0) return <></>

    return (
        <div className="codification-link-widget">
            <div className="related-item-display-set-table">
                <CodificationLinkHeaderRow/>
                <div className="body-container">
                    {linkedItems.map((row, index) => (
                        <CodificationLinkRow key={row.id} id={row.id} partNumber={row.partNumber}
                                             definedBy={row.definedByOrganization.label}
                                             codificationSystem={row.definedByOrganization.codificationSystem}
                                             creationDate={row.linkCreatedDate} isCurrentItem={false}/>
                    ))}
                    {parentItem && <CodificationLinkRow key={parentItem.id} id={parentItem.id} partNumber={parentItem.partNumber}
                                                        definedBy={parentItem.definedByOrganization.label}
                                                        codificationSystem={parentItem.definedByOrganization.codificationSystem}
                                                        creationDate={parentItem.createdDate} isCurrentItem={true}/>}
                </div>
            </div>
        </div>
    )
}

export default CodificationLinkWidget
