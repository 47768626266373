import {Outlet, useNavigate} from 'react-router-dom'
import {Button} from '@mui/material'
import React from 'react'
import { ROUTES } from '../../constants/routing'
import { useCurrentGuildContext, useDatxPathGenerator } from '../../util/routing'

const GuildAdminRoute = () => {
    const [currentGuildContext] = useCurrentGuildContext()
    const generatePath = useDatxPathGenerator()

    const navigate = useNavigate()
    const redirectToLogin = () => {
        navigate(generatePath(ROUTES.welcome.path))
    }

    if (currentGuildContext?.guildAdmin) {
        return (
            <div className="guild-route-structure" data-testid="guild-admin-route-test-component" >
                <Outlet />
            </div>
        )
    }

    return (
        <div className="guild-route-structure-no-content" 
        data-testid="not-guild-admin-route-test-component" >
            <div>
                {(currentGuildContext && !currentGuildContext.guildAdmin) ? 'You are not allowed to manage the current guild.' : 'A guild context must be active to access Guild Management.' }
            </div>
            <Button
                id="connection-button"
                variant="contained"
                color="primary"
                onClick={redirectToLogin}
                >
                Back to home
            </Button>
        </div>
    )
}

export default GuildAdminRoute
