import React from 'react'
import { InputAdornment, ListSubheader, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import {ValueOption} from '../../../../search/model/DisplayFiltersDTO'


const TextFilterInputInSelect = (
    {searchText, setSearchText, inputClassName = '', label = 'Search'}: {
        searchText: string
        setSearchText: React.Dispatch<React.SetStateAction<string>>
        inputClassName?: string,
        label?: string
    }
) => {

    const sortValues = (a: ValueOption, b: ValueOption): number => {
        return a.value.localeCompare(b.value)
    }


    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value?.toLowerCase())
    }

    return (
        <ListSubheader>
            <TextField
                className="search-filter-input"
                placeholder={label}
                value={searchText}
                onChange={handleSearch}
                onKeyDown={(e) => {
                    if (e.key !== 'Escape') {
                        // Prevents autoselecting item while typing (default Select behaviour)
                        e.stopPropagation()
                    }
                }}
                variant="outlined"
                margin="normal"
                InputLabelProps={{
                    classes: {
                        root: 'cssLabel'
                    }
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon className="search-icon" />
                        </InputAdornment>
                    ),
                    className: inputClassName
                }}
            />
        </ListSubheader>
    )

}

export default TextFilterInputInSelect