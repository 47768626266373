import React from 'react'
import {useFormikContext} from 'formik'
import {ServiceRequestStep} from '../../../genericComponents/formTemplate/steps/ServiceRequestStep'
import {Description} from '../../../../../genericComponents/typographie/Description'
import {WorkflowSelector} from '../../../model/WorkflowSelector'
import {ServiceEnum} from '../../../../model/ServiceEnum'
import {Label} from '../../../../../genericComponents/typographie/Label'
import {Link} from '@mui/material'
import * as FileSaver from 'file-saver'
import {ManufacturerItemCreationForm} from '../NewManufacturerItemsForm'
import {CustomerItemCreationForm} from '../../newCompanyItems/NewCompanyItemsForm'

export const ReviewNewItemsForm = ({service}: {service: ServiceEnum}) => {
    const formik = useFormikContext<ManufacturerItemCreationForm | CustomerItemCreationForm>()

    const getFile = () => {
        const file = formik?.values?.file
        if (!file) return
        FileSaver.saveAs(file, file.name)
    }

    return (
        <ServiceRequestStep>
            <Description>Review the perimeters of your selection and submit.</Description>

            <div data-testid="cad-model-creation-summary" className="service-request-form-summary">
                <Label className="form-summary-label"> Uploaded Document</Label>
                <Link onClick={getFile}
                      className="form-summary-value link"
                      data-testid="download-template">{formik.values.file?.name ?? '-'}</Link>
            </div>

            <WorkflowSelector service={service}/>
        </ServiceRequestStep>
    )
}