import React from 'react'
import {getServiceName, ServiceEnum} from '../../model/ServiceEnum'
import {ROUTES} from '../../../constants/routing'
import TimesOneMobiledataIcon from '@mui/icons-material/TimesOneMobiledata'
import EventRepeatIcon from '@mui/icons-material/EventRepeat'
import '../styles/Forms.scss'
import {useDatxPathGenerator} from '../../../util/routing'
import {ServiceRequestTemplate} from '../genericComponents/structure/ServiceRequestTemplate'
import {ServiceRequestHeader} from '../genericComponents/structure/ServiceRequestHeader'
import {ServiceRequestBody} from '../genericComponents/structure/ServiceRequestBody'
import {ServiceRequestFormTitle} from '../genericComponents/ServiceRequestFormTitle'
import {ServiceRequestChoiceCard} from '../genericComponents/ServiceRequestChoiceCard'
import {ServiceRequestFormTitleDescription} from '../genericComponents/ServiceRequestFormTitleDescription'
import './PartExtractionServiceRequestForm.scss'
const PartExtractionServiceChoice = () => {
    const generatePath = useDatxPathGenerator()

    return (
        <ServiceRequestTemplate>
            <ServiceRequestHeader>
                <ServiceRequestFormTitle
                    title={getServiceName(ServiceEnum.EXTRACTION)}
                    previousUrl={generatePath(ROUTES.servicesCatalogue.path)}
                />
                <ServiceRequestFormTitleDescription>
                    Kindly select the type of Extraction you would like to perform:
                </ServiceRequestFormTitleDescription>
            </ServiceRequestHeader>
            <ServiceRequestBody className="service-request-choice-card-container">
                <ServiceRequestChoiceCard
                    title={getServiceName(ServiceEnum.ONE_TIME_EXTRACTION)}
                    id="one-time-extraction"
                    icon={<TimesOneMobiledataIcon fontSize="large" className="card-icon" />}
                    description="One-off extraction of requested display sets based on selected list"
                    to={generatePath(ROUTES.oneTimeExtractionServiceForm.path)}
                />
                <ServiceRequestChoiceCard
                    title={getServiceName(ServiceEnum.RECURRING_EXTRACTION)}
                    id="recurring-extraction"
                    icon={<EventRepeatIcon fontSize="large" className="card-icon" />}
                    description={'Extraction that can be scheduled on a defined frequency'}
                    to={generatePath(ROUTES.recurringExtractionServiceForm.path)}
                />
            </ServiceRequestBody>
        </ServiceRequestTemplate>
    )
}

export default PartExtractionServiceChoice
