import { RegulationStatusColorAttribute } from './RegulationStatusColorAttribute'
import {AttributeModel} from '../model/AttributeModel'


export const RegulationStatusAttribute = ({attribute}: {attribute: AttributeModel}) => {
    const statusColorAttribute = attribute.value.composedByAttributes
        .find(attr => attr.name === 'Color')
    const statusLabelAttribute = attribute.value.composedByAttributes
        .find(attr => attr.name === 'Status Label')

    const statusColor = statusColorAttribute?.value.value
    const statusLabel = statusLabelAttribute?.value.value ?? ''

    return <div className="attribute-regulation-status" data-testid="test-details-regulation-status"
                id={attribute.attributeDefinitionId}>
        <RegulationStatusColorAttribute statusColor={statusColor}/>
        <span data-testid="test-details-attribute-status-label">{statusLabel}</span>
    </div>
}
