import React from 'react'
import './OrganizationDetailsHeader.scss'
import ManufacturerIcon from '@mui/icons-material/Factory'
import {Organization} from '../../model/Organization'
import {ContactType} from '../../model/ContactType'
import {OrganizationDetailsHeaderContent} from './OrganizationDetailsHeaderContent'

const OrganizationDetailsHeader = ({organization}: { organization: Organization }) => {
    const website = organization.contacts.find((contact) => contact.type === ContactType.LINK)

    return <div className="organization-details-page-section details-page-section">
        <div className="pre-header">
            <div className="pre-header-information">
                <span className="pre-header-label">Codification System</span>
                <span className="pre-header-value">{organization?.codificationSystem?.label ?? '-'}</span>
            </div>
            <div className="divider"/>
            <div className="pre-header-information">
                <span className="pre-header-label">Category</span>
                <span className="pre-header-value">{organization.category ?? '-'}</span>
            </div>
            <div className="divider"/>
            {website?.value && <div className="pre-header-information">
                <span className="pre-header-label">Website</span>
                <a className="pre-header-value website" href={website.value} target="_blanck">{website.value}</a>
            </div>}
        </div>
        <div className="content-section">
            <OrganizationDetailsHeaderContent organization={organization}></OrganizationDetailsHeaderContent>
        </div>
    </div>
}

export const OrganizationIcon = () => {
    return <ManufacturerIcon style={{fontSize: '60px'}} data-testid="test-manufacturer-icon"/>
}

export default OrganizationDetailsHeader
