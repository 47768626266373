import React, {ReactNode} from 'react'
import {AttributeDefinitionModel} from '../model/AttributeModel'

export const TemplateDefinitionForDisplaySet = ({definition, children}: { definition: AttributeDefinitionModel, children: ReactNode}) => {
    return <div className="display-set-definition"
                data-testid="test-details-definition-name"
                id={definition.attributeDefinitionId}>
        <span className="definition-name">{definition.name}</span>
        <div className="display-set-attributes">
            {children}
        </div>
    </div>
}