import {Button, Divider} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import {Link, useNavigate, useParams, useSearchParams} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import {useGetSharingByIdQuery} from '../../../rtkGuildApi'
import {useCurrentGuildContext, useDatxPathGenerator} from '../../../../util/routing'
import {ROUTES} from '../../../../constants/routing'
import './SpecificItemSharing.scss'
import SearchResultPageSelection from '../../../../search/searchResultPage/SearchResultPageSelection'
import {SearchPhraseForm} from '../../../../header/menuComponents/SearchPhraseForm'
import {
    managedByGuildIdUrlSearchParamsName,
    pageURLSearchParamsName,
    phraseUrlSearchParamsName
} from '../../../../search/SearchParams'

const ManageSpecificItemSharing = () => {
    const [searchParams] = useSearchParams()
    const phraseUrlSearchParams = searchParams.get(phraseUrlSearchParamsName) ?? ''
    const generatePath = useDatxPathGenerator()
    const [currentGuildContext] = useCurrentGuildContext()
    const {sharingId} = useParams()
    const {data: sharingDetail, isLoading} = useGetSharingByIdQuery(sharingId!, {skip: !sharingId})
    const [inputText, setInputText] = useState(phraseUrlSearchParams)
    const [isSearchDisabled, setSearchDisabled] = useState(true)
    const navigate = useNavigate()

    useEffect(() => {
        if (
            currentGuildContext?.guildId &&
            currentGuildContext.guildId !== searchParams.get(managedByGuildIdUrlSearchParamsName)
        ) {
            navigate(generatePath(ROUTES.itemsSharedToOtherGuilds.path))
        }
    }, [currentGuildContext?.guildId])

    const onSearch = (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement>) => {
        event.preventDefault()

        if (inputText !== '' && !isSearchDisabled) {
            setSearchDisabled(true)
            searchParams.set(pageURLSearchParamsName, '1')
            searchParams.set(phraseUrlSearchParamsName, inputText)
            navigate({search: `?${searchParams}`})
        }
    }

    const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event?.target?.value.trim()
        setSearchDisabled(value?.length < 3)
        setInputText(value)
    }

    const resetButton = () => {
        if (phraseUrlSearchParams) {
            searchParams.delete(phraseUrlSearchParamsName)
            searchParams.set(pageURLSearchParamsName, '1')
            navigate({search: `?${searchParams}`})
            setInputText('')
        }
    }

    if (isLoading) return <LoadingButton />

    return (
        <div className="manage-specific-item-page-container">
            <div className="share-by-title-container">
                <h5 id="shareSpecificItemsTitle" className="page-title">
                    Share Specific Items with <span>{sharingDetail?.targetGuildName}</span>
                </h5>
                <Divider className="divider-title" />
            </div>

            <div className="management-section">
                <div className="management-header">
                    <div className="search-section">
                        <SearchPhraseForm
                            onSearch={onSearch}
                            inputHandler={inputHandler}
                            inputText={inputText}
                            inputTestId="specific-items-search-field"
                            isSearchDisabled={isSearchDisabled}
                            placeholder="Search Items by Part Number (minimum 3 characters to search)"
                            searchButtonTestId="specific-items-search-button"
                        />
                        {phraseUrlSearchParams && (
                            <Button
                                className="reset-button"
                                variant="text"
                                onClick={resetButton}
                                data-testid="search-reset"
                            >
                                Reset
                            </Button>
                        )}
                    </div>
                    <LoadingButton
                        data-testid="add-items"
                        className="select-item-list-sharing-button"
                        variant="contained"
                        color="primary"
                        size="small"
                        component={Link}
                        to={generatePath(ROUTES.selectItemListForSharing.path, {sharingId: sharingId})}
                        state={{targetGuildName: sharingDetail?.targetGuildName}}
                    >
                        Add
                    </LoadingButton>
                </div>
            </div>

            <SearchResultPageSelection />

            <Divider className="divider" />
            <div className="step-footer">
                <div className="footer-contents">
                    <Button
                        className="back-button"
                        component={Link}
                        to={generatePath(ROUTES.itemsShareToOtherGuild.path, {sharingId: sharingId})}
                    >
                        Back to Manage Sharing
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default ManageSpecificItemSharing