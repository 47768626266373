import React, { ReactElement } from 'react'

import { FreeTextDefinitionTemplate } from '../definitionTemplates/FreeTextDefinitionTemplate'
import { DefaultDefinitionTemplate } from './/DefaultDefinitionTemplate'
import { ArtifactDataSheetDefinitionTemplate } from '../definitionTemplates/ArtifactDataSheetDefinitionTemplate'
import { ArtifactDefaultDefinitionTemplate } from '../definitionTemplates/ArtifactDefaultDefinitionTemplate'
import { NoDefinitionNameDisplayTemplate } from '../definitionTemplates/NoDefinitionNameDisplayTemplate'
import { RegulationStatusDefinition } from '../definitionTemplates/RegulationStatusDefinition'
import { RegulationDetailsDefinition } from '../definitionTemplates/RegulationDetailsDefinition'
import {CadModelDefinitionTemplate} from '../definitionTemplates/CadModelDefinitionTemplate'
import { RegulationProofsDefinition } from '../definitionTemplates/RegulationProofsDefinition'
import {DefinitionTemplateEnum, AttributeDefinitionModel} from '../model/AttributeModel'


const mapDefinitionToComponent: Map<DefinitionTemplateEnum | string, (definition: AttributeDefinitionModel) => ReactElement> = new Map([
    [DefinitionTemplateEnum.DEFAULT, (definition) => <DefaultDefinitionTemplate definition={definition} key={definition.attributeDefinitionId}/>],
    [DefinitionTemplateEnum.FREE_TEXT, (definition) => <FreeTextDefinitionTemplate definition={definition} key={definition.attributeDefinitionId}/>],
    [DefinitionTemplateEnum.NO_DEFINITION_DISPLAY, (definition) => <NoDefinitionNameDisplayTemplate key={definition.attributeDefinitionId}
        definition={definition}/>],
    [DefinitionTemplateEnum.ARTIFACT_DATA_SHEET, (definition) => <ArtifactDataSheetDefinitionTemplate key={definition.attributeDefinitionId}
        definition={definition}/>],
    [DefinitionTemplateEnum.ARTIFACT_DEFAULT, (definition) => <ArtifactDefaultDefinitionTemplate key={definition.attributeDefinitionId}
        definition={definition}/>],
    [DefinitionTemplateEnum.REGULATION_STATUS, (definition) => <RegulationStatusDefinition definition={definition} key={definition.attributeDefinitionId}/>],
    [DefinitionTemplateEnum.REGULATION_DETAILS, (definition) => <RegulationDetailsDefinition definition={definition} key={definition.attributeDefinitionId}/>],
    [DefinitionTemplateEnum.REGULATION_PROOFS, (definition) => <RegulationProofsDefinition definition={definition} key={definition.attributeDefinitionId}/>],
    [DefinitionTemplateEnum.CAD_MODEL, (definition) => <CadModelDefinitionTemplate definition={definition} key={definition.attributeDefinitionId}/>]
])


export const DefinitionForDisplaySet = ({definition}: { definition: AttributeDefinitionModel }) => {
    const templateId = definition.templateId ?? DefinitionTemplateEnum.DEFAULT
    const componentFunction = mapDefinitionToComponent.get(templateId)
    if (!componentFunction) return <DefaultDefinitionTemplate definition={definition}/>
    return componentFunction(definition)
}
