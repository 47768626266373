import { DateAttribute } from '../attributeTemplates/DateAttribute'
import {TemplateDefinitionForDisplaySet} from '../definitionTemplates/TemplateDefinitionForDisplaySet'
import {AttributeDefinitionModel} from '../model/AttributeModel'


export const DateDefaultDefinition = ({ definition }: { definition: AttributeDefinitionModel }) => {
    return <TemplateDefinitionForDisplaySet definition={definition}>
        {definition?.attributes?.map(attribute =>
                <DateAttribute attribute={attribute} />
        )}
    </TemplateDefinitionForDisplaySet>
}