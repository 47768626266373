import React from 'react'
import {ROUTES} from '../../../../../constants/routing'
import '../../../styles/Forms.scss'
import {useDatxPathGenerator} from '../../../../../util/routing'
import {ServiceRequestTemplate} from '../../../genericComponents/structure/ServiceRequestTemplate'
import {ServiceRequestHeader} from '../../../genericComponents/structure/ServiceRequestHeader'
import {ServiceRequestFormTitle} from '../../../genericComponents/ServiceRequestFormTitle'
import {ServiceRequestBody} from '../../../genericComponents/structure/ServiceRequestBody'
import {ServiceRequestChoiceCard} from '../../../genericComponents/ServiceRequestChoiceCard'
import {ServiceRequestFormTitleDescription} from '../../../genericComponents/ServiceRequestFormTitleDescription'

export const PartMatchingSelectionStep = () => {
    const generatePath = useDatxPathGenerator()

    return (
        <ServiceRequestTemplate>
            <ServiceRequestHeader>
                <ServiceRequestFormTitle
                    title="Request New Item Creation"
                    previousUrl={generatePath(ROUTES.servicesCatalogue.path)}
                />
            </ServiceRequestHeader>
            <ServiceRequestFormTitleDescription>Kindly select an option:</ServiceRequestFormTitleDescription>
            <ServiceRequestBody className="service-request-choice-card-container">
                <ServiceRequestChoiceCard
                    id={'part-matching-self-service'}
                    title={'Self-Service'}
                    icon={
                        <div>
                            <img src={'/images/services/PartMatchingClick.svg'} alt={'icon'} />
                        </div>
                    }
                    description="This is a free service."
                    to={generatePath(ROUTES.partMatchingServiceSelfServiceForm.path)}
                />
                <ServiceRequestChoiceCard
                    id={'part-matching-consult-datx-expert'}
                    title={'Consult DATX Expert'}
                    icon={
                        <div>
                            <img src={'/images/services/PartMatchingRocket.svg'} alt={'icon'} />
                        </div>
                    }
                    description="This is a paid service."
                    to={generatePath(ROUTES.partMatchingServicePaidForm.path)}
                />
            </ServiceRequestBody>
        </ServiceRequestTemplate>
    )
}

export default PartMatchingSelectionStep
