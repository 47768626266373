import React from 'react'
import {useTypedDispatch, useTypedSelector} from '../../util/store'
import {setDrawerOpen} from '../headerSlice'
import {Button} from '@mui/material'

const DrawerHeader = () => {

    const {drawerOpen} = useTypedSelector(state => state.header)
    const dispatch = useTypedDispatch()

    return (
        <Button size="small" className="browse-button" variant="outlined" data-testid="drawerIcon" color="inherit"
                startIcon={<span className="material-symbols-rounded">grid_view</span>}
                onClick={() => dispatch(setDrawerOpen(!drawerOpen))}
        >
            Browse
        </Button>
    )
}

export default DrawerHeader