import React from 'react'
import {AttributeModel} from '../model/AttributeModel'
import {ComplexAttribute} from './ComplexAttribute'

export const ClassificationLinkAttribute = ({attribute}: { attribute: AttributeModel }) => {
    return <ComplexAttribute attribute={attribute}>
        <div className="display-set-definition"
             data-testid="test-details-definition-name"
             id={attribute.value?.classificationNode?.id}>
            <span>{attribute.value?.classificationNode?.code}</span>
            <div className="display-set-attributes">
                {attribute.value?.classificationNode?.label}
            </div>
        </div>
    </ComplexAttribute>

}