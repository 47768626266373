import {Card, Grid, Tooltip} from '@mui/material'
import {useDatxPathGenerator} from '../../../../util/routing'
import {ROUTES} from '../../../../constants/routing'
import {Link, useSearchParams} from 'react-router-dom'
import {getPathToClassification} from '../../../../util/classifications/Classification'
import {SidebarClassificationNode} from '../../../../header/models/SidebarClassificationNode'
import {IconDisplay} from '../../../../genericComponents/IconDisplay'
import {phraseUrlSearchParamsName} from '../../../SearchParams'

const classificationMap = new Map([
    ['ELECTRONICS', '/images/classification-node-icons/electronics.svg'],
    ['PROCESSES', '/images/classification-node-icons/processes.svg'],
    ['COTS ASSEMBLIES', '/images/classification-node-icons/COTS-Assemblies.svg'],
    ['OPTICS', '/images/classification-node-icons/optics.svg'],
    ['ELECTROMECHANICS', '/images/classification-node-icons/electromechanics.svg'],
    ['GOODS', '/images/classification-node-icons/goods.svg'],
    ['MECHANICS', '/images/classification-node-icons/mechanics.svg'],
    ['PASSIVE HF/RF', '/images/classification-node-icons/passiveHFRF.svg'],
    ['RAW MATERIALS', '/images/classification-node-icons/raw-materials.svg'],
    ['SOFTWARE', '/images/classification-node-icons/software.svg'],
    ['CHEMICAL SUBSTANCES', '/images/classification-node-icons/chemical-substances.svg'],
    ['SECURITY ELEMENTS', '/images/classification-node-icons/security-elements.svg'],
    ['default', '/images/classification-node-icons/unclassified.svg']
])

const ClassificationImage = ({technicalClassification}: {technicalClassification: string}) => {
    return (
        <IconDisplay
            className="item-card-image"
            iconName={technicalClassification}
            iconSourceMap={classificationMap}
            iconLabel={`item nature ${technicalClassification}`}
        />
    )
}

const ClassificationCard = ({
    classification,
    numberOfItems,
    classificationTree
}: {
    classification: SidebarClassificationNode
    numberOfItems: number
    classificationTree: SidebarClassificationNode | undefined
}) => {
    const generatePath = useDatxPathGenerator()
    const path = generatePath(ROUTES.searchByClassificationNodeDescendants.path, {classificationId: classification.id})
    const [searchParams] = useSearchParams()
    const topLevelClassification = classificationTree
        ? getPathToClassification(classification.id, classificationTree)[1]
        : null
    const phraseFilter = searchParams.get(phraseUrlSearchParamsName) ?? undefined
    const getUnit = (number: number) => (number <= 1 ? 'item' : 'items')

    const getPath = () => {
        const phraseParam = phraseFilter ? `?${phraseUrlSearchParamsName}=${encodeURIComponent(phraseFilter)}` : ''
        return path + phraseParam
    }

    if (!topLevelClassification) return <></>
    return (
        <Grid xs={3} item>
            <Card className="classification-filter-card" data-testid="classification-filter-card">
                <Link
                    to={getPath()}
                    className="classification-filter-card-content"
                    data-testid={`classification-filter-card-${classification.id}`}
                >
                    <ClassificationImage technicalClassification={topLevelClassification.label} />
                    <div className="card-content">
                        <Tooltip title={classification.label}>
                            <div className="card-title">{classification.label}</div>
                        </Tooltip>
                        <div className="card-subtitle">
                            <span data-testid="item-count">{numberOfItems}</span> {getUnit(numberOfItems)}
                        </div>
                        <div className="card-caption">{topLevelClassification.label}</div>
                    </div>
                </Link>
            </Card>
        </Grid>
    )
}

export default ClassificationCard
