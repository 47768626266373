import './legalsPage.scss'
import {useDatxPathGenerator, usePublicPathGenerator} from '../../util/routing'
import {guildContextPrefix, ROUTES} from '../../constants/routing'
import {useLocation} from 'react-router-dom'

function TermsOfUse() {
    const generateGuildContextPath = useDatxPathGenerator()
    const generatePublicPath = usePublicPathGenerator()
    const {pathname} = useLocation()
    const isInGuildContextAwareLocation = pathname.includes(`/${guildContextPrefix}/`)
    const generatePath = isInGuildContextAwareLocation ? generateGuildContextPath : generatePublicPath
    const path = isInGuildContextAwareLocation ? 'path' : 'publicPath'


    return (
        <div className="content-body">
            <p><strong>GENERAL TERMS AND CONDITIONS OF USE FOR THE DATX’S PLATFORM</strong></p><p></p><p>The Company
            THALES GLOBAL SERVICES SAS registered in the Versailles Trade and Companies Register under number 424 704
            963 and having its registered office located at 19-21, avenue Morane Saulnier, 78140 Vélizy-Villacoublay,
            provides a platform called DATX (the "Platform"). </p><p>The Customer, by creating an account, accepts these
            General Terms and Conditions of Use (GTCU) of the Platform.</p><p>This Platform is based on a model of
            selling data and services to industrialists.</p><p>The marketing model of DATX is DaaS (Data as a Service)
            and SaaS (Software as a Service).</p><p>The data within the Platform is technical data relating to
            components of all kinds. These technical data are in particular related to environmental compliance
            obsolescence, models and data sheets.</p><p><strong>ARTICLE 1: DEFINITIONS</strong></p>
            <p>"<strong>GTCU</strong>": means the present general terms and conditions of use providing for the terms of
                use of the Platform.</p><p>"<strong>GTCS</strong>": means the Platform's general terms and conditions of
            sale.</p><p>"<strong>Customer</strong>" means a user with an account on the Platform, enabling him/her to
            purchase Products and Services offered by the Operator on the Platform.</p><p>"<strong>Order</strong>":
            means a request made by a Customer to the Operator for the provision of one (or more) Service(s) offered on
            the Platform through an Offer.</p><p>"<strong>Incident</strong>": means the possibility given to the
            Customer to open a claim with the Operator on an order line, after having specified the reason.</p>
            <p>"<strong>Messaging</strong>" means the communication tool used to communicate between users and also with
                the Operator on the platform.</p><p>"<strong>Offer</strong>": means the terms and conditions (price,
            availability, shipping time, delivery options, other) by which the Operator offers Products and Services for
            sale on the Platform.</p><p>"<strong>Operator</strong>" shall mean Thales Global Services</p>
            <p>"<strong>Platform</strong>": means the DATX platform.</p><p>"<strong>Products</strong>" means the
            products offered by the Operator via the Platform.</p><p>"<strong>Services</strong>": means the services
            offered by the Operator via the Platform</p><p>"<strong>Export Control Regulations</strong>": means all
            French, European or international regulations concerning export control. This includes the French law
            n°2011-702 of 22/06/2011, the ITAR regulation (International Traffic in Arms Regulation), Council Regulation
            (EC) n° 428/2009 of 5 May 2009.</p><p>"<strong>Representative</strong>": means any person having access to
            the Customer's account on the Customer's Front Office or on the Platform and acting in the name and on
            behalf of the Customer.</p><p>"<strong>Site</strong>": means the website <a
            href="https://www.datx.eu/"><strong>https://www.datx.eu/</strong></a> dedicated to the Platform,
            allowing the purchase and use of the
            Products and Services offered, through the use of a Customer account.</p><p><strong>ARTICLE 2 :
            PURPOSE</strong></p><p>The purpose of these GTCU is to govern the conditions under which a Customer may
            access the Platform and order the Products and Services available on the Platform.</p><p><strong>ARTICLE 3 :
            THE PLATFORM SERVICES </strong></p><p>3.1. Access to the Service</p><p>Access to the Platform and its use
            are subject to compliance with these GTCU.</p><p>The creation of your user account means that you are aware
            of the conditions of access and use defined in these GTCU and that you agree to abide by them.</p><p>3.2.
            Description of the Service</p><p>- 3.2.1. The Service consists of a set of tools that allow Customers to
            access the DATX and within the deadlines stipulated in the General Terms and Conditions of Sale : <a
                href={generatePath(ROUTES.termsOfSales[path])}>https://portal.datx.eu/legal/termsOfSales</a></p><p>-
            3.2.2. The Service provides the following main functions to Clients.</p><p>In particular, the Platform
            offers the following products and services:</p>
            <ul>
                <li>Search and consultation of data</li>
                <li>Purchase of data</li>
                <li>Data enrichment</li>
                <li>Export of data from the Platform to the customer’s environment</li>
                <li>Setting up a dedicated collaborative workspace</li>
            </ul>
            <p>- A complete list of the Services offered by the Platform is available once the user is connected to the
                platform on the link: <a href={generateGuildContextPath(ROUTES.servicesCatalogue.path)}>Service catalogue</a></p>
            <p>3.3. Customer Service</p><p>- 3.3.1. The Operator offers Customer Service during working hours 8:00 a.m.
            – 6:00 p.m. Monday to Friday (Front Office Customer)</p><p>- 3.3.2. Through this Service, the Customer may
            in particular make :</p><p>- a request for assistance in using the Platform,</p><p>- a request for
            assistance in placing an Order</p><p>- a request relating to an Order in progress</p><p>- a request for
            assistance in managing their Customer account</p><p>- a request relating to the management of personal
            data</p><p>- a request for assistance regarding an Incident,</p><p>- follow-up on the completion of an
            Order.</p><p>3.4 Accessibility and Availability of the Platform</p><p>- 3.4.1. The Platform is accessible 24
            hours a day, 7 days a week, with the understanding that the accessibility of the Service consists of the
            possibility of accessing the Customer's Front Office and of using the functions defined in article 3 of
            these GTCU.</p><p>- 3.4.2. Notwithstanding the generality of the foregoing, the Operator does not guarantee
            full continuity of the Service, particularly due to maintenance operations, bugs, errors or possible
            slowdowns.</p><p>The Operator reserves the right to suspend or alter access to the Platform and its
            Services, in whole or in part, without prior notice to Customers, in particular for the purpose of
            maintaining, monitoring or testing the Platform.</p><p>Such suspension or alteration of Service shall not
            give rise to any rights or compensation from Operator to Customer.</p><p>The Operator guarantees the use of
            advanced security technologies in accordance with the state of the art in order to protect the integrity and
            confidentiality of data exchanged through the Service.</p><p><strong>ARTICLE 4: PRODUCT INFORMATION AND
            REFERENCES</strong></p><p>All information and Product references published and available on the Platform may
            change at any time at the discretion of the Operator without any prior notice to the Operator. They are
            provided AS IS, WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING,
            BUT NOT LIMITED TO, IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT OF THIRD PARTY RIGHTS OR FITNESS FOR A
            PARTICULAR PURPOSE, OR NON-INFRINGEMENT.</p><p>OPERATOR MAKES NO WARRANTY AS TO THE RELIABILITY,
            COMPLETENESS, ACCURACY, TIMELINESS OR CONTINUITY OF THE CONTENT, SERVICES, PRODUCTS, SOFTWARE, TEXT,
            GRAPHICS, LINKS OR COMMUNICATIONS MADE AVAILABLE ON OR THROUGH THIS PLATFORM.</p><p><strong>ARTICLE 5 :
            NON-COMPLIANCE WITH GTCU</strong></p><p>In case of non-compliance with the GTCU, the Operator reserves the
            right to temporarily or permanently suspend the Customer's access to the Platform, in accordance with the
            conditions stipulated in Article 15 of the GTCU.</p><p><strong>ARTICLE 6: CONFORMITY OF THE
            PRODUCTS</strong></p><p>6.1. The information provided by the merchants in the product sheet and in their
            Offers is the responsibility of the merchants and in no case may the Customer hold the Operator liable in
            the event of an error in any of this information. An error in information is understood to be any
            information that is false, inaccurate or incomplete, as well as information that does not comply with
            regulations. Only the Merchant can be held responsible for the conformity of the Product to its Offer.</p>
            <p>6.2 Products for military use, subject to one or more international export control regulations for war
                materials or military or defense-related goods (in particular the French law n°2011-702 of 22/06/2011 or
                the ITAR regulation, "International Traffic in Arms Regulation") are not authorized for purchase on the
                Platform.</p><p><strong>ARTICLE 7: PLATFORM MESSAGING </strong></p><p>The Customer is informed that the
            Operator has set up a messaging system specific to the Platform for the Customers, for the purpose of
            community discussion (intra guild) and public exchanges between experts.</p><p><strong>ARTICLE 8:
            RESPONSIBILITY</strong></p><p>8.1 The Customer acknowledges that it is solely responsible for its use of the
            Platform and the Services provided to it.</p><p>8.2. The Customer shall also assume all responsibility for
            the Sales that it concludes through the Platform, insofar as the Operator's responsibility for the
            non-execution or poor execution of said Sales cannot be demonstrated by the Customer.</p><p>8.3. The
            Operator shall be liable and shall compensate the Customer for any direct loss suffered by the Customer
            arising from the use of the Platform in accordance with the GTCU or relating to the purchase of Products
            through the Platform in accordance with the GTCU and the GTCS and arising exclusively from the Operator's
            direct fault.</p><p>8.4. The Operator's liability shall be limited to the amount of Sales made by the
            Customer in the six (6) months prior to the Operator's fault, except in the case of gross negligence on the
            part of the Operator.</p><p>8.5. The Operator shall not be liable for any indirect damage, in particular any
            loss of profit, turnover or orders, damage to the Customer's image, loss or corruption of data.</p><p>8.6
            The Platform may refer to hypertext links to independent websites not managed or administered by the
            Platform. The Operator is not responsible for the content of these websites. The Operator has no control
            over the content of these websites. The Customer is responsible for taking all necessary precautions to
            ensure that such websites are free from viruses, malware, trojans, worms, Trojan horses or other
            contamination.</p><p>8.7 The Operator shall not be liable for any loss arising from the Customer's misuse of
            the Platform or misuse of the Service or Products.</p><p><strong>ARTICLE 9: INTELLECTUAL PROPERTY</strong>
        </p><p>9.1 All of the content present on the Platform, or made available, including the graphic elements, the
            assemblies constituting it, as well as the Website itself are the exclusive property of the Operator and are
            protected by French intellectual property law.</p><p>9.2 The express written permission of the Operator is
            required to extract, reproduce, download, copy, modify, use or commercially exploit, in whole or in part,
            the content of the Platform. In particular, the use of robots to collect or extract data from the Platform,
            or any other similar data collection or extraction tool for any purpose, is strictly prohibited without the
            express written consent of the Operator. The mere acceptance of these Terms and Conditions of Use does not
            constitute express written consent by Operator.</p><p>9.4 The use of trademarks, logos and any distinctive
            sign of the Platform is prohibited without the express written consent of the Operator.</p><p>9.5 The
            Operator grants Customers a personal, non-exclusive, non-transferable, limited license, without the right to
            sublicense, to access and use, without the right to commercialize, the Platform, so long as the terms and
            conditions of these GT&amp;C are met.</p><p>9.6 Any use of the Platform that does not comply with this
            article constitutes an act of infringement that may be subject to civil and criminal prosecution.</p><p>
            <strong>ARTICLE 10: COMMUNICATION - ADVERTISING</strong></p><p>10.1. The Operator is authorized to identify
            the legal entities using the Platform, and in particular their logos and brands, for communication
            purposes.</p><p>10.2. The Customer's express consent must be obtained for all communications by press
            releases and promotional documents of the Platform.</p><p><strong>ARTICLE 11: FIGHT AGAINST CORRUPTION AND
            INFLUENCE PEDDLING</strong></p><p>11.1. The Customer undertakes both on its own behalf and on behalf of its
            employees, agents, subsidiaries and subcontractors, if any, for whom it acts as a guarantor, to comply with
            all applicable local and international laws and regulations relating to the fight against corruption and, in
            particular, as the Operator is located in France, with Law No. 2016-1691 of 9 December 2016 relating to
            transparency, the fight against corruption and the modernization of economic life.</p><p>11.2. Bribery is
            defined as the offering, promising or granting of an undue advantage, directly or indirectly, to a person,
            for his or her benefit or for the benefit of a third party, in order for that person to act or refrain from
            acting in the performance of his or her duties with a view to obtaining or retaining a contract or other
            undue advantage.</p><p>11.3 Bribery also includes soliciting or accepting, directly or indirectly, an
            improper advantage, for oneself or for a third party, in order to act or refrain from acting in the
            performance of one's duties with a view to obtaining or retaining a contract or other improper
            advantage.</p><p>11.4 Influence peddling is the offering, directly or indirectly, of remuneration to anyone
            who claims or confirms to be able to influence the decision making of any person in order to obtain an
            improper advantage.</p><p>11.5 Accordingly, Customer shall act in a professional and ethical manner in its
            business dealings, including</p><p>- not engage in any corrupt activity of any kind (direct or indirect,
            active or passive, financial or otherwise), influence peddling, extortion, embezzlement or any other conduct
            sanctioned by law.</p><p>- Establish and maintain appropriate ethics and anti-corruption policies and
            procedures.</p><p>- Provide any assistance necessary for the Operator to respond to a request from a duly
            authorized authority relating to anti-corruption.</p><p>- comply with the Integrity and Corporate
            Responsibility Charter, available at the following link: <a
                href="https://thalesgroup.com/sites/default/files/database/document/2019-12/102019_Charte_fournisseurs_FR_Final.pdf">https://thalesgroup.com/sites/default/files/database/document/2019-12/102019_Charte_fournisseurs_FR_Final.pdf</a>
        </p><p>11.6. The Customer shall inform the Operator, as soon as possible and in writing, of any event that could
            result in obtaining an undue advantage, whether financial or of any other nature and regardless of its
            value, using the Platform.</p><p>11.7 The User shall not be liable for any loss, damage, injury or expense
            arising from the use of the Platform or from any proceedings brought against the User in relation to a
            possible violation of the laws and regulations referred to in this article.</p><p>11.8 It is understood that
            in the event of the Customer's failure to comply with the provisions of this article, the Operator may
            suspend the Customer's access to the Platform and then delete the Customer's account in accordance with the
            conditions set out in article 17 of these Customer GTCU.</p><p><strong>ARTICLE 12 : PERSONAL DATA</strong>
        </p><p>12.1. For the purposes of the rules relating to the protection of personal data in connection with the
            Use of the Platform, the following definitions shall apply:</p><p></p><p>« <strong>Applicable Data
            Protection Legislation </strong>» means any data protection regulations that apply in the context of this
            Agreement, including, where applicable, the Regulation (EU) 2016/679 on the protection of natural persons
            with regard to the processing of personal data and on the free movement of such data (GDPR) and the laws and
            regulations adopted to implement the GDPR.</p><p></p><p>« <strong>Personal Data</strong> » means the
            Personal Data which are Processed in the context of the use of the Platform according to GTCU, categories of
            which are the following: authentication data (e-mail address) related to the following Data Subjects .</p>
            <p>« <strong>EEA </strong>» means the European Economic Area.</p><p>« <strong>Data Controller </strong>»
            means the natural or legal person, public authority, agency or other body which, alone or jointly with
            others, determines the purposes and means of the Processing of Personal Data.</p><p>« <strong>Data
            Processor</strong> » means the natural or legal person acting on behalf of the Data Controller.</p>
            <p>« <strong>Data Subject</strong>» means an identified or identifiable natural person. An identifiable
                natural person is one who can be identified, directly or indirectly, in particular by reference to an
                identifier such as a name, an identification number, location data, an online identifier or to one or
                more factors specific to the physical, physiological, genetic, mental, economic, cultural or social
                identity of that natural person.</p><p>« <strong>Processing </strong>» means any operation or set of
            operations which is performed on Personal Data or on sets of Personal Data, whether or not by automated
            means, such as collection, recording, organization, structuring, storage, adaptation or alteration,
            retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available,
            alignment or combination, restriction, erasure or destruction.</p><p></p><p>In the context of the use of the
            Platform, whenever the Operator carries out a Processing of the Personal Data concerned, the Operator
            undertakes to carry out such Processing, as a Processor, solely for the following purposes:</p><p>- to
            authenticate the Platform and ensure the security of the Platform,</p><p>- to improve the products and
            services and the user experience based on usage and preference analyses</p><p>- to carry out behavioral
            analyses, trends by cross-referencing profiles and usage on the Platform in order to improve the quality of
            the products and services offered by the Platform.</p><p>12.2 In this context, the Operator undertakes to
            comply with the Applicable Data Protection Legislation. The Customer undertakes to provide each of the
            interested Data Subjects, with the information notice as provided by the Operator in order to comply with
            Article 14 of the GDPR. The privacy notice is available here : <a
                href={generatePath(ROUTES.privacyPolicy[path])}>https://portal.datx.eu/legal/privacyPolicy</a></p>
            <p></p><p></p><p></p><p><strong>ARTICLE 13: INDEPENDENCE</strong></p><p>13.1. The Operator and the Customer
            act in their own name and undertake to conduct their activities on the Platform as independent traders. The
            Parties acknowledge that they have no authority to act on behalf of the other Party, to bind themselves in
            any way or to sign on behalf of the other Party.</p><p>13.2 Each of the Parties is completely autonomous and
            independent. Neither Party may interfere in the commercial and strategic orientations of the other
            Party.</p><p><strong>ARTICLE 14 : PARTIAL NULLITY</strong></p><p>If one or several stipulations of the
            present GTCU are held to be invalid or declared as such in application of a law, a regulation or a final
            decision of a competent jurisdiction, the other stipulations will keep all their force and all their
            scope.</p><p><strong>ARTICLE 15: SUSPENSION AND DELETION OF THE CUSTOMER ACCOUNT</strong></p><p>15.1
            Suspension of Customer account</p><p>The Operator may suspend the Customer's access to the Platform in the
            cases provided for in these GTCU and more generally in the event of any breach by the Customer of its
            obligations under these GTCU.</p><p>In particular, non-payment of an order following a Sale shall be
            considered as a reason for suspension.</p><p>The Operator shall notify the Customer of the suspension of its
            account by e-mail, indicating the date of its reactivation.</p><p>Suspension of access to the Platform shall
            result in the impossibility for the Customer to validate new Orders and the deletion of Orders not yet
            validated by the Merchants.</p><p>In the event of repeated breaches, the Operator may permanently delete the
            Customer's account.</p><p>During the period of account suspension, the Customer shall remain bound by all of
            its obligations with respect to Sales already concluded.</p><p>Suspension or deletion of an account shall
            not entitle the Customer to any compensation.</p><p>15.2. Deletion of the Customer account</p><p>The
            Customer may request the deletion of his account at any time.</p><p>The deletion of the account does not
            release the Customer from its obligations under the Sales already concluded.</p><p>The deletion of the
            account shall be without prejudice to any claims that either the Customer or the Operator may have against
            the other in relation to these Customer GTCU.</p><p>15.3 Modifications of the service or the conditions of
            use</p><p>The Operator reserves the right to make changes to the GTCU at any time.</p><p>You are subject to
            the terms and conditions of the GTCU, in the latest version in force at the time you use the Platform.</p>
            <p>In the event that any provision of the GTCU is deemed invalid or unenforceable, such provision shall be
                deemed severable and shall not affect the validity and enforceability of any other provision.</p><p>
            <strong>ARTICLE 16: APPLICABLE LAW</strong></p><p>The present GTCU are subject to French law.</p><p><strong>ARTICLE
            17: LITIGATIONS </strong></p><p>Any dispute, controversy or claim arising out of or in connection with this
            Agreement shall be submitted to mediation in accordance with the mediation rules of the CMAP (Centre de
            Médiation et d'Arbitrage de Paris) to which the Parties declare that they adhere and in the event of failure
            of such mediation within a period of time not exceeding two months (unless extended by mutual agreement of
            the Parties) from the date of the dispute, controversy or claim, shall be brought before the Commercial
            Court of Paris.</p><p></p><p></p>
            <hr/>
            <p><strong>CONDITIONS GENERALES D’UTILISATION PLATEFORME DATX</strong></p><p></p><p>La Société Thales Global
            Services («TGS»), société par actions simplifiée, immatriculée au Registre du Commerce et des Sociétés de
            Versailles sous le numéro 424 704 963, dont le siège social est sis 19-21, avenue Morane Saulnier, 78140
            Vélizy-Villacoublay, France, met à disposition une plateforme dénommée DATX (la «Plateforme»). </p><p>Le
            Client, en créant un compte, accepte ces Conditions Générales d’Utilisation (CGU) de la Plateforme.</p>
            <p>Cette Plateforme s'appuie sur un modèle de vente de données et de services à destination des
                industriels.</p><p>Le modèle de commercialisation de DATX estle DaaS (Data as a Service) et le SaaS
            (Software as a Service).</p><p>Les données au sein de la Plateforme sont des données techniques relatives
            aux composants de toute natures. Ces données techniques sont notamment relatives à l’obsolescence conformité
            environnementale, aux modèles et aux data sheet.</p><p><strong>Article 1 : Définitions</strong></p>
            <p>« <strong>CGU </strong>» : désigne les présentes conditions générales d’utilisation prévoyant les
                modalités d’utilisation de la Plateforme.</p><p><strong></strong>«<strong>CGV</strong>»: désigne les
            conditions générales de vente de la Plateforme.</p><p>« <strong>Client»: </strong>désigne un utilisateur
            ayant un compte sur la Plateforme, lui permettant d’acheter des Produits et Services proposés par
            l’Opérateur sur la Plateforme.</p><p>« <strong>Commande</strong> » : désigne la demande faite par un Client
            auprès de l’Opérateur tendant à la fourniture d’un (ou plusieurs) Service (s) proposé(s) sur la Plateforme
            par le biais d’une Offre.</p><p>« <strong>Incident</strong> » : désigne la possibilité donnée au Client
            d’ouvrir une réclamation auprès de l’Opérateur sur une ligne de commande, après en avoir précisé le motif.
        </p><p>«<strong> Messagerie</strong> » : désigne l’outil de communication permettant de communiquer entre
            utilisateurs et également avec l’Opérateur présent sur la plateforme.</p><p>« <strong>Offre</strong> » :
            désigne les conditions (prix, disponibilité, délai d’expédition, options de livraison, autres) par
            lesquelles l’Opérateur propose des Produits et Services à la vente sur la Plateforme.</p>
            <p>« <strong>Opérateur</strong> » : désigne THALES GLOBAL SERVICES SAS .</p><p>«<strong>Plateforme»</strong>:
            désigne la plateforme DATX<strong>.</strong></p><p><strong>«Produits»: </strong>désigne les produits
            proposés par l’Opérateur via la Plateforme</p><p>«<strong> Services</strong> » : désigne les services
            proposés par l’Opérateur via la Plateforme</p><p>« <strong>Règlementation contrôle des
            exportations</strong> » : désigne toutes les règles normatives, tant françaises qu’européennes ou
            internationales, qui concernent le contrôle des exportations. Sont notamment concernées la loi française
            n°2011-702 du 22/06/2011, la réglementation ITAR (International Traffic in Arms Regulation), Règlement (CE)
            n° 428/2009 du Conseil du 5 mai 2009.</p><p>« <strong>Représentant</strong> » : désigne toute personne ayant
            les accès au compte du Client sur le Front Office Client ou sur la Plateforme et agissant au nom et pour le
            compte dudit Client.</p><p>« <strong>Site</strong> »: désigne le site Internet <a
            href="https://www.datx.eu/"><strong>https://www.datx.eu/</strong></a></p><p>dédié à la Plateforme,
            permettant d’acheter et d’utiliser les Produits et Services proposés, moyennant l’utilisation d’un compte
            Client.</p><p></p><p><strong>Article 2 : Objet</strong></p><p>Les présentes CGU ont pour objet d’encadrer
            les conditions dans lesquelles un Client peut accéder à la Plateforme et commander les Produits et Services
            disponibles sur la Plateforme.</p><p><strong>Article 3 : le Services de la Plateforme </strong></p><p>3.1.
            Accès au Service</p><p>L’accès à la Plateforme et son utilisation sont soumis au respect des présentes
            CGU.</p><p>La création de votre compte utilisateur signifie que vous avez pris connaissance des conditions
            d’accès et d’utilisation définies dans les présentes CGU et que vous vous engagez à respecter.</p><p>3.2.
            Description du Service</p><p>- 3.2.1. Le Service est constitué d’un ensemble d’outils permettant aux
            Clients d’accéder aux offres de la Plateforme DATX, de passer et de suivre des Commandes, ainsi que de
            procéder au paiement des Commandes, dans les conditions et délais stipulés dans les Conditions générales de
            ventes <a href={generatePath(ROUTES.termsOfSales[path])}>https://portal.datx.eu/legal/termsOfSales</a></p>
            <p></p><p>- 3.2.2. Le Service assure les principales fonctions suivantes destinées aux Clients.</p><p>La
            Plateforme propose notamment les produits et services suivants:</p>
            <ul>
                <li>Recherche &amp; Consultation de données</li>
                <li>Achat de données</li>
                <li>Enrichissement de données</li>
                <li>Export de données depuis la plateforme DATX vers l’environnement client</li>
                <li>Mise en place d’un espace de travail collaboratif dédié</li>
            </ul>
            <p>Une liste complète des Services proposé par la Plateforme est disponibleune fois l’utilisateur connecté
                sur la plateforme sur le lien: <a
                    href={generateGuildContextPath(ROUTES.servicesCatalogue.path)}>Service catalogue</a></p><p>3.3. Service
            Client</p><p>- 3.3.1. L’Opérateur propose un Service Client au heures ouvrables 8:00 – 18:00 Lundi au
            Vendredi (Front Office Client)</p><p>- 3.3.2. Par le biais de ce Service, le Client peut notamment faire
            :</p>
            <ul>
                <li>une demande d’aide relative à l’utilisation de la Plateforme,</li>
                <li>une demande d’aide relative au passage d’une Commande</li>
                <li>une demande relative à une Commande en cours</li>
                <li>une demande relative à la gestion de son compte Client</li>
                <li>une demande relative à la gestion de ses données personnelles</li>
                <li>une demande d’aide relative à un Incident,</li>
                <li>le suivi de la réalisation d’une Commande.</li>
            </ul>
            <p></p><p>3.4 Accessibilité et Disponibilité de la Plateforme</p><p>- 3.4.1. La Plateforme est accessible
            24h/24 et 7jours/7, étant étendu que l’accessibilité du Service, consiste en la possibilité d’accéder au
            Front Office Client et de pouvoir utiliser les fonctions définies à l'article 3 des présentes CGU.</p><p>-
            3.4.2. Nonobstant la généralité de ce qui précède, l’Opérateur ne garantit pas une totale continuité dans le
            Service, notamment du fait d’opérations de maintenance, de bugs, erreurs ou éventuels ralentissements.</p>
            <p>L’Opérateur se réserve le droit de suspendre ou d’altérer l’accès à la Plateforme et à ses Services, de
                manière totale ou partielle, sans notification préalable aux Clients, notamment dans le but de
                maintenir, de contrôler ou de tester la Plateforme.</p><p>Une telle suspension ou altération de Service
            ne donnera lieu à aucun droit ou indemnisation de la part de l’Opérateur envers le Client.</p><p>L’Opérateur
            garantit l’utilisation de technologies de sécurisations avancées conformes aux règles de l’art afin de
            protéger l’intégrité et la confidentialité des données échangées à travers le Service.</p><p><strong>Article
            4: Information et références Produits</strong></p><p>Toute information et références des Produits publiées
            et disponibles sur la Plateforme peuvent évoluer à tout moment à la discrétion de l’Opérateur sans aucune
            notification préalable de l’Opérateur. Ils sont fournis <em>EN L’ETAT</em>, SANS GARANTIE D'AUCUNE SORTE,
            EXPRESSE OU IMPLICITE, STATUTAIRE OU AUTRE, Y COMPRIS, MAIS SANS SE LIMITER AUX GARANTIES IMPLICITES
            RELATIVES A LA PROPRIETE, A LA NON-VIOLATION DE DROITS DE TIERS OU ENCORE A L'ADÉQUATION À UNE UTILISATION
            PARTICULIERE, OU DE NON-VIOLATION.</p><p>L’OPERATEUR NE DONNE AUCUNE GARANTIE CONCERNANT, LA FIABILITE,
            L’EXHAUSTIVITE, L’EXACTITUDE, L’OPPORTUNITE OU LA CONTINUITE DU CONTENU, DES SERVICES, PRODUITS, LOGICIEL,
            TEXTE, ELEMENTS GRAPHIQUES, LIENS OU COMMUNICATIONS MIS A DISPOSITION SUR CETTE PLATEFORME OU A TRAVERS
            L’UTILISATION DE CETE DERNIERE.</p><p><strong>Article 5 : Non-respect des conditions d'utilisation</strong>
        </p><p>En cas de non-respect des CGU, l’Opérateur se réserve le droit d’interrompre de manière temporaire ou
            définitive l’accès du Client à la Plateforme, dans le respect des conditions stipulées à l’article 17 des
            CGU.</p><p><strong>Article 6 : Conformité des Produits</strong></p><p>6.1. Les informations, fournies par
            les marchands dans la fiche produit et dans leurs Offres, sont de la responsabilité des marchands et en
            aucun cas le Client ne pourra engager la responsabilité de l’Opérateur en cas d’erreur dans l’une de ces
            informations. Est entendue comme une erreur d’information toutes les informations fausses, inexactes,
            incomplète ainsi que les informations non conformes à la réglementation. Seul le marchand peut être tenu
            responsable de la conformité du Produit à son Offre.</p><p>6.2 Les Produits à usage militaire, soumis à une
            ou des réglementations internationales de contrôles des exportations de matériels de guerre ou de bien
            militaires ou liés à la défense (notamment la loi française n°2011-702 du 22/06/2011 ou encore la
            réglementation ITAR, « International Traffic in Arms Regulation ») ne sont pas autorisés à l’achat sur la
            Plateforme.</p><p><strong>Article 7 : Messagerie de Plateforme </strong></p><p>Le Client est informé que
            l’Opérateur a mis en place un système de Messagerie propre à la Plateforme à destination des Clients, à des
            fins de discussion communautaire (intra guilde) et d’échanges publics entre experts.</p><p><strong>Article 8
            : Responsabilité</strong></p><p>8.1 Le Client reconnait être seul responsable de son utilisation de la
            Plateforme et des Services qui lui sont fournis.</p><p>8.2. Le Client assumera également toutes ses
            responsabilités relatives aux Ventes qu’il conclut au moyen de la Plateforme, dès lors que la responsabilité
            de l’Opérateur dans la non-exécution ou la mauvaise exécution desdites Ventes ne pourra être démontrée par
            le Client.</p><p>8.3. L’Opérateur sera tenu responsable et devra indemniser le Client, au titre de tout
            préjudice direct subi par le Client, découlant de l’utilisation de la Plateforme conformément aux CGU ou se
            rapportant à l’achat de Produits par le biais de la Plateforme conformément aux CGU et aux CGV et trouvant
            exclusivement sa cause dans une faute directe de l’Opérateur.</p><p>8.4. La responsabilité de l’Opérateur
            est limitée au montant des Ventes passées par le Client dans les six (6) mois précédents la faute de
            l’Opérateur, à l’exception d’une faute lourde (caractérisée par une négligenced'une extrême gravité) de
            l’Opérateur.</p><p>8.5. L’Opérateur ne saurait être tenu responsable de tout dommage indirect notamment
            d’une quelconque perte de bénéfice, de profit, de chiffre d’affaires ou de commande, atteinte à l’image du
            Client, pertes ou altération des données.</p><p>8.6 La Plateforme peut faire référence à des liens
            hypertextes renvoyant à des sites Web indépendants non gérés ou administrés par la Plateforme. L’Opérateur
            n’est aucunement responsable du contenu de ces sites web. L’Opérateur ne dispose d'aucun contrôle sur le
            contenu de ces sites Web. Le Client est tenu de prendre toutes les précautions nécessaires pour s’assurer
            que ces sites web ne sont pas contaminés par des virus, malware, trojans, vers, chevaux de Troie ou
            autres.</p><p>8.7 La responsabilité de l’Opérateur ne peut être engagée pour un préjudice découlant d’une
            mauvaise utilisation de la Plateforme par le Client ou d’une utilisation fautive du Service ou des
            Produits.</p><p></p><p><strong>Article 9 : Propriété intellectuelle</strong></p><p>9.1 L’intégralité du
            contenu présent sur la Plateforme, ou rendu disponible, y compris les éléments graphiques, les assemblages
            le constituant, ainsi que le Site lui-même sont la propriété exclusive de l’Opérateur et sont protégés par
            le droit français de la propriété intellectuelle.</p><p>9.2 L'autorisation expresse et écrite de l’Opérateur
            est obligatoire pour extraire, reproduire, télécharger, copier, modifier, utiliser, exploiter
            commercialement, en tout ou partie, le contenu de la Plateforme. En particulier, l’utilisation de robots
            visant à collecter, aspirer les données de la Plateforme, ou tout autre outil similaire de collecte ou
            d’extraction de données pour une quelconque finalité est strictement interdite sans l’accord express et
            écrit de l’Opérateur. La seule acceptation des présentes conditions générales d’utilisation ne constitue pas
            un accord express et écrit de l’Opérateur.</p><p>9.4 L’usage des marques, logos et tout signe distinctif de
            la Plateforme est interdite sauf accord express et écrit de l’Opérateur.</p><p>9.5 L’Opérateur concède aux
            Clients une licence limitée personnelle, non-exclusive, non-transférable, sans droit de sous-licence pour
            accéder et utiliser, sans droit de commercialisation, la Plateforme, tant que les conditions des présentes
            CGU sont respectées.</p><p>9.6 Toute utilisation de la Plateforme ne respectant pas le présent article est
            constitutif d'un acte de contrefaçon pouvant faire l’objet de poursuites sanctionnnables sur le plan civil
            et pénal.</p><p><strong>Article 10 : Communication - Publicité</strong></p><p>10.1. L’Opérateur est autorisé
            à identifier les personnes morales utilisatrices de la Plateforme, et notamment leurs logos et marques, à
            des fins de communication.</p><p>10.2. L’accord express du Client devra être obtenu pour toutes les
            communications par communiqués de presse et documents promotionnels de la Plateforme.</p><p><strong>Article
            11 : Lutte contre la corruption et le trafic d’influence</strong></p><p>11.1. Le Client s’engage tant pour
            son propre compte que pour celui, le cas échéant, de ses salariés, préposés, filiales, sous-traitants, dont
            il se porte fort, à respecter toutes les lois et règlementations applicables locales et internationales en
            matière de lutte contre la corruption et notamment, l’Opérateur étant situé en France, la loi n° 2016-1691
            du 9 décembre 2016 relative à la transparence, à la lutte contre la corruption et à la modernisation de la
            vie économique.</p><p>11.2. La corruption s’entend du fait, pour le corrupteur, d’offrir, de promettre ou
            d’octroyer un avantage indu, directement ou indirectement, à une personne, à son profit ou au profit d’un
            tiers, pour que cette personne agisse ou s’abstienne d’agir dans l’exécution de ses fonctions en vue
            d’obtenir ou conserver un marché ou un autre avantage indu.</p><p>11.3 La corruption s’entend également du
            fait, pour le corrompu, de solliciter ou d’accepter, directement ou indirectement, un avantage indu, à son
            profit ou au profit d’un tiers, pour agir ou s’abstenir d’agir dans l’exécution de ses fonctions en vue de
            l’attribution ou de la conservation d’un marché ou autre avantage indu.</p><p>11.4 Le trafic d’influence
            s’entend du fait de proposer, d’offrir, directement ou indirectement, une rémunération à quiconque affirme
            ou confirme être capable d’exercer une influence sur la prise de décision de tout personne dans le but
            d’obtenir un avantage indu.</p>11.5. En conséquence, le Client doit agir, dans le cadre de ses relations
            commerciales, de façon professionnelle et éthique, et notamment :<p>- ne pas s’adonner à toute activité
            de corruption quelle que soit sa nature (directe ou indirecte, active ou passive, financière ou non), trafic
            d’influence, extorsion, détournement de fond ou toute autre conduite sanctionnée par la loi.</p><p>- mettre
            en place et maintenir en vigueur des politiques et procédures adéquates relatives à l’éthique et à la lutte
            contre la corruption.</p><p>- fournir toute assistance nécessaire à l’Opérateur pour répondre à une demande
            d’une autorité dûment habilitée relative à la lutte contre la corruption.</p><p>- être conforme à la charte
            Intégrité et Responsabilité d’Entreprise, disponible via le lien suivant : <a
                href="https://thalesgroup.com/sites/default/files/database/document/2019-12/102019_Charte_fournisseurs_FR_Final.pdf">https://thalesgroup.com/sites/default/files/database/document/2019-12/102019_Charte_fournisseurs_FR_Final.pdf</a>
        </p><p>11.6. Le Client informera l’Opérateur, dans les plus bref délais et par écrit, de tout évènement qui
            pourrait avoir pour conséquence l’obtention d’un avantage indu, financier ou de toute autre nature et quelle
            que soit sa valeur, à l’occasion de l’utilisation de la Plateforme. Ainsi que de toute procédure faite à son
            encontre ayant attrait à une possible violation des lois et règlementations visées par cet article.</p>
            <p>11.7. Il est entendu, qu’en cas de non-respect des dispositions du présent article par le Client,
                l’Opérateur pourra suspendre les accès du Client à la Plateforme puis supprimer son compte dans les
                conditions prévues à l’article 17 des présentes CGU Clients.</p><p></p><p><strong>Article 12 : Données à
            caractère personnel</strong></p><p>12.1. Pour les règles relatives à la protection des données personnelles
            dans le cadre de l’Utilisation de la Plateforme, les définitions suivantes sont applicables :</p>
            <p>« <strong>Données Personnelles</strong> » signifie les Données Personnelles qui font l’objet d’un
                Traitement dans le contexte l’utilisation de la Plateforme conformément aux présentes CGU Clients, dont
                les catégories sont les suivantes : données professionnelles, données de commande, données de paiement
                et qui sont relatives aux Représentant du Client.</p><p>« <strong>EEE </strong>» signifie l’Espace
            Economique Européen.</p><p>« <strong>Législation Applicable à la Protection des Données</strong> »: signifie
            toute réglementation, loi, ordonnance, norme et texte similaire relatif à la protection des données
            applicable à la Plateforme et aux présentes CGU Marchands, en particulier (i) le Règlement (UE) 2016/679
            relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et
            à la libre circulation de ces données (<strong>RGPD</strong>), quelle que soit la manière dont il est mis en
            œuvre par les lois nationales, et (ii) toute autre loi ou règlement relatif au Traitement de Données
            Personnelles.</p><p>« <strong>Responsable de Traitement</strong> » signifie la personne physique ou morale,
            seul ou conjointement avec d'autres, détermine les finalités et les moyens du Traitement de Données
            Personnelles.</p><p>« <strong>Traitement</strong> » signifie toute opération ou ensemble d’opérations
            effectuées ou non à l'aide de procédés automatisés et appliquées à des Données Personnelles ou à des
            ensembles de Données Personnelles, telles que la collecte, l'enregistrement, l'organisation, la
            structuration, la conservation, l'adaptation ou la modification, l'extraction, la consultation,
            l'utilisation, la communication par transmission, la diffusion ou toute autre forme de mise à disposition,
            le rapprochement ou l'interconnexion, la limitation, l'effacement ou la destruction.</p><p>Modalités de
            Traitement des Données Personnelles Concernées :</p><p>Dans le cadre de l’utilisation de la Plateforme, dès
            lors que l’Opérateur procède à un Traitement des Données Personnelles Concernées, l’Opérateur s’engage à
            réaliser un tel Traitement, en tant que Responsable du Traitement, uniquement pour les finalités suivantes
            :</p><p>- authentification de la Plateforme et assurer la sécurité de la Plateforme,</p><p>- améliorer les
            produits et services ainsi que l'expérience utilisateur sur la base des analyses d'utilisation et des
            préférences.</p><p>- réaliser des analyses comportementales, des tendances par croisement de profils et
            d’usage sur la Plateforme visant à renforcer la qualité des Offres de produits et services proposés par la
            Plateforme.</p><p>12.2 Dans ce cadre, l’Opérateur s’engage à se conformer à la Législation Applicable à la
            Protection des Données. Le Client s’engage à fournir à chacune des Personnes Concernées intéressées, à
            savoir ses Représentants, la notice d’information telle que fournie par l’Opérateur afin de se conformer à
            l’article 14 du RGPD: <a
                href={generatePath(ROUTES.privacyPolicy[path])}>https://portal.datx.eu/legal/privacyPolicy</a></p><p>
            <strong></strong></p><p><strong>Article 13 : Indépendance</strong></p><p>13.1. L’Opérateur et le Client
            agissent en leur nom propre et s’engagent à conduire leurs activités sur la Plateforme en tant que
            commerçants indépendants. Les Parties reconnaissent n’avoir aucune autorité à agir pour le compte de l’autre
            Partie, de se lier d’une quelconque façon ou de signer au nom de l’autre Partie.</p><p>13.2. Chacune des
            Parties est complètement autonome et indépendante. Aucune des Parties ne saurait donc s'immiscer dans les
            orientations commerciales et stratégiques de l'autre Partie.</p><p></p><p><strong>Article 14 : Nullités
            partielles</strong></p><p>Si une ou plusieurs stipulations des présentes CGU sont tenues pour non valides ou
            déclarées comme tel en application d’une loi, d’un règlement ou d’une décision définitive d’une juridiction
            compétente, les autres stipulations garderont toute leur force et toute leur portée.</p><p></p><p><strong>Article
            15 : Suspension et suppression du compte Client</strong></p><p>15.1. Suspension du compte Client</p>
            <p>L’Opérateur pourra suspendre l’accès du Client à la Plateforme dans les cas prévus aux présentes CGU et
                plus généralement en cas de tout manquement du Client à ses obligations au titre des présentes CGU .</p>
            <p>Sera notamment considéré comme un motif de suspension, le non-paiement d’une Vente.</p><p>L’Opérateur
            notifiera au Client la suspension de son compte par mail, dans lequel sera indiquée la date de sa
            réactivation.</p><p>La suspension de l’accès à la Plateforme se traduit par l’impossibilité pour le Client
            de valider de nouvelle Commande et la suppression des Commandes non encore validées par les Marchands.</p>
            <p>En cas de manquement répété, l’Opérateur pourra supprimer de manière définitive le compte du Client.</p>
            <p>Pendant la période de suspension de son compte, le Client reste tenu de toutes ses obligations au titre
                des Ventes déjà conclues.</p><p>Une suspension ou suppression de compte ne donnera lieu à aucun droit à
            indemnisation du Client.</p><p>15.2. Suppression du compte Client</p><p>Le Client peut demander la
            suppression de son compte à tout moment.</p><p>La suppression de son compte ne libère pas le Client de ses
            obligations au titre des Ventes déjà conclues.</p><p>La suppression du compte sera sans préjudice des
            réclamations que le Client ou que l’Opérateur sera en droit de faire à l’encontre de l’autre relativement
            aux présentes CGU Clients.</p><p>15.3. Modification du service ou des conditions d'utilisation</p>
            <p>L’Opérateur se réserve le droit de faire des modifications sur les CGU à tout moment.</p><p>Vous
            êtes soumis aux termes et conditions des CGU, dans la dernière version en vigueur au moment où vous utilisez
            la Plateforme.</p><p>Dans l’hypothèse où une des stipulations des présentes CGU est réputée nulle ou
            inapplicable, cette stipulation sera réputée divisible et n'affectera pas la validité et l'opposabilité des
            autres dispositions.</p><p><strong>Article 16 : Droit applicable</strong></p>
            <p>Les présentes CGU sont soumises à la loi française.</p><p><strong>Article 17 : Juridictions
            compétentes</strong></p><p>Les litiges, controverses ou
            réclamations s’élevant à l’occasion ou en relation avec la présente Convention seront soumis à la médiation
            conformément au règlement de médiation du CMAP (Centre de Médiation et d’Arbitrage de Paris) auquel les
            Parties déclarent adhérer et en cas d’échec de celle-ci dans un délai qui ne saurait excéder deux mois (sauf
            prolongation d’un commun accord par les parties) à compter de la survenance du litige, controverse ou
            réclamation, seront portés devant le Tribunal de Commerce de Paris.</p><p></p>
        </div>
    )
}

export default TermsOfUse
