import {SearchResultDTO} from '../../../model/SearchResultDTO'
import {Search} from '../../../model/SearchFilter'
import React, {ChangeEvent} from 'react'
import LoadingSpinner from '../../../../genericComponents/LoadingSpinner'
import {SearchResultTableForSpecificItemSharing} from '../SearchResultTable/SearchResultTableForSpecificItemSharing'

export const SearchResultsComponentForSpecificItemSharing = ({searchResult, isFetching, page, search, handlePageChange}: {
    searchResult: SearchResultDTO | undefined,
    isFetching: boolean,
    page: number,
    search: Search,
    handlePageChange: (e: ChangeEvent<unknown>, pageNumber: number) => void
}) => {

    if (isFetching) return <LoadingSpinner/>
    return <SearchResultTableForSpecificItemSharing searchResult={searchResult} page={page}
                                                    search={search} handlePageChange={handlePageChange} />
}