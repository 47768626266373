import React from 'react'
import {
    AdjustmentRule
} from '../QuotationRule'

export const AdjustmentRuleComponent = ({rule}: {rule: AdjustmentRule}) => {
    return <div className="quotation-detail-rule-structure">
        <p className="quotation-detail-description">Adjustment</p>
        <p className="quotation-detail-adjustment-reason">{rule.adjustmentReason}</p>
        <p className="quotation-detail-adjustment-price">{rule.price}</p>
    </div>
}