import {DateDisplay} from '../../../genericComponents/date/dateDisplay'
import {Tooltip} from '@mui/material'
import React from 'react'
import './SubscriptionDate.scss'
import InfoIcon from '@mui/icons-material/Info'
import {
    formatMilliseconds,
    formatMonth,
    isAfterCurrentDatePlusMonths
} from '../../../genericComponents/date/DateService'

const SubscriptionRow = ({date, prefix, hasWarningExpiration, isActiveSubscription}: {
    date: number[] | null,
    prefix: string,
    hasWarningExpiration: boolean,
    isActiveSubscription: boolean
}) => {
    const displayWarning = isExpiringSoon(date)
    const dateDisplay = <div data-testid="subscription-date"
                             className={!isActiveSubscription ? 'inactive' : undefined}
    >{prefix}: {date ?
        <DateDisplay entryDate={date} time={false}/> : 'No end date'} </div>

    if (isActiveSubscription && displayWarning && hasWarningExpiration)
        return <div className="expiring-subscription-date">
            {dateDisplay}
            <Tooltip title={displayWarning ? 'Your subscription is expiring within the next 2 months' : ''}
                     placement="top">
                <InfoIcon className="warning-icon"/>
            </Tooltip>
        </div>

    return dateDisplay
}

export const isExpiringSoon = (endDate: number[] | null): boolean => {
    if (!endDate) return false
    const dateToDisplay = formatMilliseconds([...endDate])
    formatMonth(dateToDisplay)
    return isAfterCurrentDatePlusMonths(dateToDisplay, 2)
}

export default SubscriptionRow