import React from 'react'
import {array, mixed, object, string} from 'yup'
import {useCreateDefaultServiceRequestMutation} from '../../../rtkServiceRequestApi'
import {useCurrentGuildContext, useDatxPathGenerator} from '../../../../../util/routing'
import {ServiceRequestStep} from '../../../model/ServiceRequestStep'
import {ServiceEnum} from '../../../../model/ServiceEnum'
import {ServiceRequestFormTemplate} from '../../../genericComponents/formTemplate/ServiceRequestFormTemplate'
import {ROUTES} from '../../../../../constants/routing'
import {UploadTemplateItemCreation} from '../../../newItems/common/UploadTemplateItemCreation'
import {getItemsFromFile} from '../../../readImportedFile'
import {PaidFormDescription} from '../Description/PaidFormDescription'
import {ReviewAndSubmitPartMatchingPaid} from '../ReviewAndSubmit/PartMatchingPaid'
import {ItemMatchingInputDTO, NewItemInputDTO} from '../../../../model/NewItemInput'
import {ServiceRequestWorkflowInput} from '../../../model/ServiceRequestWorkflowInput'

export interface PaidPartMatchingItemForm extends ServiceRequestWorkflowInput {
    file: File | undefined
    items: NewItemInputDTO[] | ItemMatchingInputDTO[]
}

export const PartMatchingPaidForm = () => {
    const [currentGuildContext] = useCurrentGuildContext()
    const generatePath = useDatxPathGenerator()

    const [createRequest] = useCreateDefaultServiceRequestMutation()

    const uploadValidationSchema ={
        file: mixed(),
        items: array().required().min(1)
    }

    const validationSchema ={
        ...uploadValidationSchema,
        selectedWorkflowId: string().required()
    }

    const steps: ServiceRequestStep<PaidPartMatchingItemForm>[] = [
        {
            name: 'Upload part matching paid form',
            component: <UploadTemplateItemCreation description={PaidFormDescription}
                                                   handleFileUpload={handleFileUpload} />,
            pageValidation: object<PaidPartMatchingItemForm>(uploadValidationSchema)
        },
        {
            name: 'Review & Submit',
            component: <ReviewAndSubmitPartMatchingPaid isPaidForm={true} />,
            pageValidation: object<PaidPartMatchingItemForm>(validationSchema)
        }
    ]

    async function handleFileUpload(tempFile: File) {
        return await getItemsFromFile(tempFile)
    }

    function handleCompleteUploadForm(itemCreation: PaidPartMatchingItemForm): Promise<any> | undefined {
        if (!itemCreation.file) return
        if (!itemCreation.items) return
        if (!itemCreation.selectedWorkflowId) return

        return createRequest({
            service: ServiceEnum.PART_MATCHING,
            guildId: currentGuildContext?.guildId ?? '',
            file: itemCreation.file,
            numberOfItems: itemCreation.items.length,
            workflowConfigurationId: itemCreation.selectedWorkflowId
        })
    }

    return (
        <ServiceRequestFormTemplate
            steps={steps}
            handleFormSubmit={handleCompleteUploadForm}
            initialValues={{
                file: undefined,
                items: [],
                selectedWorkflowId: ''
            }}
            title="Item Matching - Consult DATX Expert"
            previousUrl={generatePath(ROUTES.partMatchingServiceChoice.path)}
        ></ServiceRequestFormTemplate>
    )
}
