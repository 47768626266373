import {Box, Chip, Divider} from '@mui/material'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import '../common/ItemSharing.scss'
import LoadingButton from '@mui/lab/LoadingButton'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useGetSharingByIdQuery} from '../../rtkGuildApi'
import {useGetOrganizationsQuery} from '../../../organization/rtkOrganizationApi'
import {useCurrentGuildContext, useDatxPathGenerator} from '../../../util/routing'
import {ROUTES} from '../../../constants/routing'
import LoadingSpinner from '../../../genericComponents/LoadingSpinner'
import {IdType} from '../../../util/models/IdType'
import {skipToken} from '@reduxjs/toolkit/query'
import {OrganizationItemSharing} from '../model/OrganizationItemSharing'
import {useGetDefinitionsByIdsForGuildQuery} from '../../../search/rtkSearchApi'
import MenuBar from './MenuBar'
import {NavigateToSpecificItemsPageButton} from './SpecificItem/NavigateToSpecificItemsPageButton'

const ManageItemSharing = () => {
    const generatePath = useDatxPathGenerator()
    const navigate = useNavigate()
    const {sharingId} = useParams()
    const [itemsSharing, setItemsSharing] = useState<OrganizationItemSharing[] | []>()
    const [currentGuildContext] = useCurrentGuildContext()
    const previousGuildContext = useRef(currentGuildContext?.guildId)

    const {
        data: sharingDetail,
        isFetching: isSharingLoading
    } = useGetSharingByIdQuery(sharingId!, {skip: !sharingId})

    // This component does not explicitly depend upon a guild id, hence we need a specific hook to check
    // for user behaviour
    useEffect(() => {
        // If user changes guild context, redirect to sharing page
        if (currentGuildContext?.guildId != previousGuildContext.current) {
            navigate(generatePath(ROUTES.itemsSharedTo.path), {replace: true})
        }
        // If fetched sharing does not match the url guild, redirect to sharing page
        if (sharingDetail && sharingDetail?.sourceGuildId != currentGuildContext?.guildId) {
            navigate(generatePath(ROUTES.itemsSharedTo.path), {replace: true})
        }
    }, [currentGuildContext, sharingDetail])

    const organizationIds: IdType[] = useMemo(() =>
            sharingDetail?.organizationsSharing?.map((itemSharing: OrganizationItemSharing) => itemSharing.targetOrganizationId) ?? [],
        [sharingDetail])

    const definitionIds: IdType[] = useMemo(() =>
            sharingDetail?.definitionsSharing ?? [],
        [sharingDetail])

    const {
        data: organizations,
        isFetching: isOrganizationsLoading
    } = useGetOrganizationsQuery(organizationIds.length !== 0 ? organizationIds : skipToken)

    const {
        data: definitions,
        isFetching: isDefinitionsLoading
    } = useGetDefinitionsByIdsForGuildQuery({
        definitionIds: definitionIds,
        guildId: currentGuildContext?.guildId
    }, {skip: !currentGuildContext?.guildId})

    useMemo(() => {
        if (!organizations || organizations.length === 0 || !sharingDetail) return

        const organizationMapper = new Map<IdType, string>()

        organizations.forEach(({label, id}) => {
            organizationMapper.set(id, label)
        })

        const itemsSharing = sharingDetail.organizationsSharing.reduce((list: OrganizationItemSharing[], itemSharing: OrganizationItemSharing) => {
            if (itemSharing.targetOrganizationId) {
                list.push({
                    ...itemSharing,
                    targetOrganizationName: organizationMapper.get(itemSharing.targetOrganizationId)
                })
            }
            return list
        }, [])

        setItemsSharing(itemsSharing)
    }, [organizations, sharingDetail])

    const renderPrivateAttributesShared = () => {
        if (isSharingLoading) return <LoadingSpinner/>

        if (!definitions?.length) return <div className="no-items" data-testid="no-items">There are no Private
            Attributes shared with this guild</div>
        const elements = definitions.map(definition => <Chip className="chip-definition-name" label={definition.name}
                                                             variant="outlined" data-testid={definition.name}/>)
        return elements
    }

    if (isSharingLoading) return <LoadingSpinner/>
    return <Box className="page-container">
        <div className="guild-form-title-container">
            <h5 id="shareToOtherGuildTitle" className="page-title">
                Manage Sharing with <span>{sharingDetail?.targetGuildName}</span>
            </h5>
            <Divider className="divider-title"/>
        </div>

        <div className="management-section">
            <div className="management-header">
                <h3 className="management-header-title">Private Attributes Shared</h3>
                <LoadingButton
                    data-testid="edit-private-attributes"
                    variant="contained"
                    color="primary"
                    size="small"
                    component={Link}
                    to={generatePath(ROUTES.addSharingPrivateAttributes.path, {sharingId: sharingId})}
                    state={{targetGuildName: sharingDetail?.targetGuildName}}
                >Edit Private Attributes Sharing</LoadingButton>
            </div>
            <div>By sharing attributes, the guild will have access to the Private Attributes all the time.</div>
            {/*<div className="no-items">There are no items shared with this guild</div>*/}
            <div>
                {renderPrivateAttributesShared()}
            </div>

        </div>

        <div className="management-section">
            <div className="management-header">
                <h3 className="management-header-title">Items</h3>
            </div>
            <div>By sharing items to the guild, the guild will receive view access to these items.</div>
            <div>
                <MenuBar cardTitle="Shared by Organisation" iconType="ORGANISATION"
                         cardDescription="Always share items defined by a selected organisation">
                    <LoadingButton
                        data-testid="manage-org-item-sharing"
                        variant="contained"
                        color="primary"
                        size="medium"
                        component={Link}
                        to={generatePath(ROUTES.manageOrganisationSharedItems.path, {sharingId: sharingId})}>
                        Manage
                    </LoadingButton>
                </MenuBar>
                <MenuBar cardTitle="Specific Items" iconType="ITEMS"
                         cardDescription="Sharing based on your selection of items">
                    <NavigateToSpecificItemsPageButton label="Manage"
                                                       size="medium"
                                                       targetGuildId={sharingDetail?.targetGuildId ?? ''}
                                                       organizationsSharing={sharingDetail?.organizationsSharing ?? []}
                                                       manageButtonTestId="manage-specific-item-sharing"/>

                </MenuBar>
            </div>
        </div>

    </Box>
}

export default ManageItemSharing
