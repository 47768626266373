export const pageURLSearchParamsName = 'page'
export const pageSizeURLSearchParamsName = 'size'
export const visibleByGuildIdUrlSearchParamsName = 'visibleByGuildId'
export const phraseUrlSearchParamsName = 'phrase'

export const isPublicSearchParamsName = 'isPublic'

export const managedByGuildIdUrlSearchParamsName = 'managedByGuildId'

export const workflowGuildId = 'workflowGuildId'

export const notDefinedByOrganizationsUrlParamsName = 'notDefinedByOrganizations'