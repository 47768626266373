export enum ContactType {
    LINK = 'LINK',
    PHONE = 'PHONE',
    EMAIL = 'EMAIL',
}

export const getContactTypeLabel = (type: ContactType): string => {
    switch (type) {
        case ContactType.LINK:
            return 'Website'
        case ContactType.PHONE:
            return 'Phone number'
        case ContactType.EMAIL:
            return 'Email address'
        default:
            return ''
    }
}
