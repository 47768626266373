import './legalsPage.scss'

function TermsOfSales() {

  return (
    <div className="content-body">
    </div>
     )
}

export default TermsOfSales
