import React from 'react'
import '../../../guild/guildManagement/members/GuildMembers.scss'
import UserServiceRequestsTable from './UserServiceRequestsTable'
import LoadingSpinner from '../../../genericComponents/LoadingSpinner'
import './UserServiceRequests.scss'
import {useCurrentGuildContext} from '../../../util/routing'
import {useGetUserServiceRequestsQuery} from '../rtkServiceRequestApi'


const UserServiceRequests = () => {
    const [currentGuildContext] = useCurrentGuildContext()
    const {
        data: serviceRequests = [],
        isLoading
    } = useGetUserServiceRequestsQuery(currentGuildContext?.guildId, {skip: !currentGuildContext?.guildId})

    if (!currentGuildContext) {
        return (
            <div className="service-request-no-content" data-testid="service-request-no-content">
                <LoadingSpinner/>
            </div>
        )
    }

    return (
        <div className="service-request-table-container" data-testid="service-request-table-container">
            <div className="header-title">
                My Service Requests
            </div>
            <UserServiceRequestsTable userServiceRequests={serviceRequests} isLoading={isLoading}/>
        </div>
    )
}

export default UserServiceRequests
