import './QuotationDetail.scss'
import React, {ReactElement} from 'react'
import {ServiceRequest} from '../../../../../service/model/ServiceRequest'
import {CREDITS} from '../../../../../constants/credits'
import {Divider} from '@mui/material'
import QuotationRuleTypeEnum from '../../../../../service/model/QuotationRuleTypeEnum'
import {getTypedQuotationRule} from '../../../../../service/model/QuotationRules/QuotationRule'
import {getTotalFromRequest} from '../../../../../service/model/Quotation'
import {getServiceName, ServiceEnum} from '../../../../../service/model/ServiceEnum'
import {getCadModelDisplayName} from '../../../../../service/model/cadModelType/CADModelTypeEnum'


const CadModelDetails = ({serviceRequest}: {
    serviceRequest: ServiceRequest
}) => {
    const cadModelType = serviceRequest?.serviceTypeAttributes?.cadModelType
    const cadVariant = serviceRequest?.serviceTypeAttributes?.cadModelVariant
    if (!cadModelType) return <></>
    return <>
        <div>CAD Model Type:</div>
        <div>{getCadModelDisplayName[cadModelType] ?? ''}</div>
        {cadVariant && <>
            <div>Variant:</div>
            <div>{serviceRequest?.serviceTypeAttributes?.cadModelVariant}</div>
        </>
        }
    </>
}

const ServiceRequestDetails = ({serviceRequest}: {
    serviceRequest: ServiceRequest
}) => {
    if(!serviceRequest.service) return <></>
    const detailsByService: Map<ServiceEnum, ReactElement> = new Map([
        [ServiceEnum.CAD_MODEL, <CadModelDetails serviceRequest={serviceRequest} />]
    ])
    return detailsByService.get(serviceRequest.service ) ?? <></>
}

const QuotationDetail = ({serviceRequest}: {
    serviceRequest: ServiceRequest
}) => {


    const genericRules = serviceRequest?.quotation?.quotationRules
        ?.filter((rule: {ruleType: QuotationRuleTypeEnum}) => rule.ruleType !== QuotationRuleTypeEnum.ADJUSTMENT)
        .map((rule) => getTypedQuotationRule(rule)) ?? []

    const adjustmentRules = serviceRequest?.quotation?.quotationRules
        ?.filter((rule) => rule.ruleType === QuotationRuleTypeEnum.ADJUSTMENT)
        .map((rule) => getTypedQuotationRule(rule)) ?? []
    return (
        <>
            <div className="service-request-detail-container">
                <div>Request ID:</div>
                <div>{serviceRequest?.id}</div>
                <div>Service:</div>
                <div>{serviceRequest?.service && (getServiceName(serviceRequest?.service) ?? '')}</div>
                <ServiceRequestDetails serviceRequest={serviceRequest} />
            </div>
            <Divider />
            <div className="quotation-detail-container">
                <div className="quotation-detail-rule-structure quotation-detail-header">
                    <p className="quotation-detail-description"><strong>Description</strong></p>
                    <p className="quotation-detail-unit-price"><strong>Unitary Cost</strong>
                        <div className="caption">({CREDITS})</div>
                    </p>
                    <p className="quotation-detail-nb-object"><strong>Qty</strong></p>
                    <p className="quotation-detail-min-quantity"><strong>Min. Qty</strong></p>
                    <p className="quotation-detail-total"><strong>Sub-Total</strong></p>

                </div>
                {
                    genericRules.map((rule, index) =>
                        rule.getDisplayComponent(serviceRequest, 0, index + '-genericRule')
                    )
                }
            </div>
            <Divider />
            <div className="quotation-detail-container">
                {
                    adjustmentRules.map((rule, index) =>
                        rule.getDisplayComponent(serviceRequest, 0, index + '-adjustment')
                    )
                }
            </div>
            <div className="quotation-detail-rule-structure">
                <div className="quotation-detail-total-title"><strong>Total</strong>
                    <div className="caption">({CREDITS})</div>
                </div>
                <div data-testid="quotation-detail-total-value"
                     className="quotation-detail-total-value">{getTotalFromRequest(serviceRequest)}</div>
            </div>

        </>
    )
}

export default QuotationDetail