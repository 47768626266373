import {BrowserCacheLocation, Configuration, RedirectRequest} from '@azure/msal-browser'

export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_oauth_clientId ?? '',
        authority: `https://${process.env.REACT_APP_oauth_tenant_url}/${process.env.REACT_APP_oauth_tenant}.onmicrosoft.com/${process.env.REACT_APP_oauth_b2c_flow_signin}`,
        knownAuthorities: [
            process.env.REACT_APP_oauth_tenant_url ?? '',
        ], // array of URIs that are known to be valid
        redirectUri: '/',
        postLogoutRedirectUri: 'https://www.datx.eu',
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to 'true' if you are having issues on IE11 or Edge
    },
}

const backendScopes: string[] = [
    process.env.REACT_APP_oauth_scope_guild_api ?? '',
    process.env.REACT_APP_oauth_scope_notification_api ?? '',
]

export type CustomSilentRequest = {redirectStartPage?: string} & RedirectRequest

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const signinRequest: CustomSilentRequest = {
    scopes: [...backendScopes, 'openid', 'offline_access'],
}

export const signupRequest: CustomSilentRequest = {
    authority: `https://${process.env.REACT_APP_oauth_tenant_url}/${process.env.REACT_APP_oauth_tenant}.onmicrosoft.com/${process.env.REACT_APP_oauth_b2c_flow_signup}`,
    ...signinRequest,
}
