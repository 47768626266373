import React, {useState} from 'react'
import {FileDownload} from '@mui/icons-material'
import LoadingButton from '@mui/lab/LoadingButton'
import {Member} from '../../models/Member'
import * as XLSX from 'xlsx'
import FileSaver from 'file-saver'
import getGuildMemberType from './models/GuildMemberType'
import {getLastModifiedDate} from './GuildMembers'
import {setSnackbarMessage} from '../../../genericComponents/commonSlice'
import {useTypedDispatch} from '../../../util/store'

interface ExcelMember {
    email: string
    role: string
    status: string
    lastStatusUpdate: string
}

const ExportGuildMembers = ({members, guildName}: { members: Member[], guildName: string }) => {
    const [isLoading, setIsLoading] = useState(false)

    const dispatch = useTypedDispatch()


    const constructRows = (): ExcelMember[] => {
        return members.map((member: Member) => {
            return {
                email: member.user.email ?? '',
                role: member.admin ? 'Guild Administrator' : 'Guild Member',
                status: getGuildMemberType(member.status.toUpperCase())?.label,
                lastStatusUpdate: getLastModifiedDate(member) ?? '-'
            }
        })
    }

    const downloadExcel = () => {
        try {
            const currDate = new Date()
            const fileName = `Datx-members-of-${guildName}-${currDate.toLocaleString('en-GB').replace(' ', '_').replace(',', '')}`
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'
            // Init worksheet
            const ws = XLSX.utils.json_to_sheet(constructRows())

            // Set custom headers
            const headers = [['Email', 'Role', 'Status', 'Last status update']]
            XLSX.utils.sheet_add_aoa(ws, headers)

            // Write to workbook
            const wb = {Sheets: {'Guild members': ws}, SheetNames: ['Guild members']}
            const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'})
            const data = new Blob([excelBuffer], {type: fileType})
            FileSaver.saveAs(data, fileName + fileExtension)
            dispatch(setSnackbarMessage('List of all guild members has been exported'))
        } catch (e) {
            dispatch(setSnackbarMessage('There was an error exporting the list of guild members'))
        }
    }

    const downloadMembers = () => {
        setIsLoading(true)
        downloadExcel()
        setIsLoading(false)
    }

    return <LoadingButton
        data-testid="export-members-button"
        className="member-button export-members-button"
        variant="outlined"
        color="primary"
        type="button"
        disabled={isLoading}
        onClick={downloadMembers}
        startIcon={<FileDownload />}
    >
        Export
    </LoadingButton>
}

export default ExportGuildMembers