import React, {ReactNode} from 'react'
import {Chip, Grid, Typography} from '@mui/material'
import './SearchTabs.scss'

const SearchTabs = ({children, itemCount, tabName}: {children?: ReactNode; itemCount: number; tabName: string}) => {
    return (
        <>
            <div className={'tabs-container'}>{children}</div>
            <Grid container className={'search-tabs'}>
                {
                    <Grid item className={'search-tab active'}>
                        <Typography title={tabName} className={'search-tab-label'} variant="inherit" noWrap>
                            {tabName}
                        </Typography>
                        <span className={'search-tab-chip'} data-testid="item-count-text">
                            <Chip color="primary" size="small" label={itemCount} />
                        </span>
                    </Grid>
                }
            </Grid>
        </>
    )
}

export default SearchTabs
