import React, {useState} from 'react'
import {ServiceRequest} from '../../../service/model/ServiceRequest'
import {IconButton, Menu, MenuItem} from '@mui/material'
import {MoreHoriz} from '@mui/icons-material'
import UpdateApprovalDialog from '../modals/UpdateApprovalDialog'
import {UpdateDialogContent} from '../modals/model/UpdateDialogContent'
import {useTypedSelector} from '../../../util/store'
import UserRequestStatusEnum from '../../../service/model/UserRequestStatusEnum'
import { useCurrentGuildContext } from '../../../util/routing'

const ApproveOptionsMenu = ({row, updateDialogContent, approvedRequest, rejectedRequest,isLoading}:
{
    row: ServiceRequest,
    updateDialogContent: UpdateDialogContent,
    approvedRequest: () => void,
    rejectedRequest: () => void,
    isLoading :boolean
     }
) => {
    const [anchorEl, setAnchorEl] = useState<Element | null>(null)
    const [openDialog, setOpenDialog] = useState(false)
    const [isServiceRequestAwaitingApproval, setIsServiceRequestAwaitingApproval] = useState<boolean | null>(null)
    const [currentGuildContext] = useCurrentGuildContext()
    const [currentServiceRequestId,setCurrentServiceRequestId] = useState('')
    const isActionsDisabled = isLoading ||(!(row.status===UserRequestStatusEnum.AWAITING_APPROVAL || row.status===UserRequestStatusEnum.AWAITING_QUOTATION_APPROVAL))

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>,serviceRequestId : string) => {
        setAnchorEl(event.currentTarget)
        setCurrentServiceRequestId(serviceRequestId)
    }

    const open = Boolean(anchorEl)

    const closeMenu = () => {
        setAnchorEl(null)
    }

    const closeDialog = () => {
        setOpenDialog(false)
    }

    const approve = () => {
        setIsServiceRequestAwaitingApproval(true)
        closeMenu()
        setOpenDialog(true)
    }

    const reject = () => {
        setIsServiceRequestAwaitingApproval(false)
        closeMenu()
        setOpenDialog(true)
    }

    return <>
            <IconButton data-testid="action-button" onClick={event => handleClick(event, row.id)} disabled={isActionsDisabled}>
                <MoreHoriz />
            </IconButton>
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={closeMenu}
        >
            <MenuItem data-testid="approve-menu-item" onClick={event => approve()} disabled={isActionsDisabled} >{updateDialogContent.approvedContent.approveMenu}</MenuItem>
            <MenuItem data-testid="reject-menu-item" onClick={event => reject()} disabled={isActionsDisabled} >{updateDialogContent.rejectedContent.approveMenu}</MenuItem>
        </Menu>
        <UpdateApprovalDialog
                open={openDialog}
                closeDialog={closeDialog}
                params={{ serviceRequestId: currentServiceRequestId,guildId:currentGuildContext?.guildId}}
                approved={isServiceRequestAwaitingApproval}
                updateDialogContent={updateDialogContent}
                approvedRequest={approvedRequest}
                rejectedRequest={rejectedRequest}
                isLoading={isLoading}/>
    </>
}

export default ApproveOptionsMenu
