import React, {useState} from 'react'
import {Badge, Grid, Popover} from '@mui/material'
import {NotificationsOutlined} from '@mui/icons-material'
import './Notifications.scss'
import CustomHeaderButton from '../../headerGenericComponents/CustomHeaderButton'
import NotificationsPanel from './NotificationsPanel'
import {
    selectUnreadNotifications,
    useGetNotificationsQuery,
    useReadAllNotificationsMutation
} from './rtkNotificationApi'
import {useSelector} from 'react-redux'

const Notifications = () => {
    const [menuAnchorEl, setMenuAnchorEl] = useState<Element | null>(null)

    const {data:notifications = [], isLoading} = useGetNotificationsQuery(undefined, {pollingInterval : 30000})
    const unreadNotifications = useSelector(selectUnreadNotifications)
    const [readAllNotifications] = useReadAllNotificationsMutation()
    
    const isMenuOpen = Boolean(menuAnchorEl)

    const handleMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(e.currentTarget)
        readAllNotifications()
    }

    const handleMenuClose = () => {
        setMenuAnchorEl(null)
    }

    return (
        <div>
            <CustomHeaderButton
                onClick={handleMenuOpen}
                endIcon={null}
                className={`notification-button ${isMenuOpen ? 'opened' : ''}`}
                data-testid="datx-notification-button-with-border"
            >
                <Badge
                    sx={{
                        '& .MuiBadge-badge': {
                            backgroundColor: 'red',
                            minWidth: '0px',
                            width: '16px',
                            height: '16px',
                            fontSize: '12px',
                        },
                    }}
                    badgeContent={unreadNotifications}
                    color="secondary"
                    overlap="circular"
                    max={99}
                >
                    <Grid container direction="column" alignItems="center">
                        <NotificationsOutlined />
                    </Grid>
                </Badge>
            </CustomHeaderButton>
            <Popover
                open={isMenuOpen}
                anchorEl={menuAnchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={handleMenuClose}
                classes={{ paper: 'datx-notification-paper-popover' }}
            >
                <NotificationsPanel notifications={notifications} isLoading={isLoading}/>
            </Popover>
        </div>
    )
}

export default Notifications
