import React, {useMemo, useState} from 'react'
import {Button} from '@mui/material'
import CustomModal from '../../../genericComponents/CustomModal'
import GuildInvitation from './invitations/GuildInvitation'
import GuildMemberTable from './GuildMemberTable'
import './GuildMembers.scss'
import {useGetGuildQuery} from '../../rtkGuildApi'
import {useCurrentGuildContext} from '../../../util/routing'
import InfoIcon from '@mui/icons-material/Info'
import ExportGuildMembers from './ExportGuildMembers'
import {Member} from '../../models/Member'
import {formatDate, formatMilliseconds, formatMonth} from '../../../genericComponents/date/DateService'
import {PageTitle} from '../../../genericComponents/typographie/PageTitle'
import {SearchMembers} from './SearchMembers'
import LoadingSpinner from '../../../genericComponents/LoadingSpinner'

const GuildMembers = () => {
    const [isModalOpen, setModalOpen] = useState(false)
    const [currentGuildContext] = useCurrentGuildContext()
    const {data: guild, isLoading: guildLoading} = useGetGuildQuery(currentGuildContext?.guildId,
        {skip: !currentGuildContext?.guildId || !currentGuildContext?.guildAdmin})


    const numberOfMembersMax = guild?.maxUsersInGuild
    const currentNumberOfMembers = guild?.numberOfUsers
    const isMaxMembersReached = (currentNumberOfMembers && numberOfMembersMax) && (currentNumberOfMembers >= numberOfMembersMax)

    const [filteredMembers, setFilteredMembers] = useState<Member[] | undefined>(undefined)
    const handleMemberFiltering = (email: string | undefined) => {
        if(!email || !guild){
            setFilteredMembers(undefined)
            return
        }
        const users = guild.guildUsers.filter((user) => user?.user?.email?.includes(email))
        setFilteredMembers(users)
    }

    const members = useMemo(() => {
            if (filteredMembers)
                return filteredMembers
            return guild?.guildUsers ?? []
        }
        , [guild, filteredMembers]
    )


    if (!guild) return <div>Guild Not Found</div>
    if (guildLoading) return <LoadingSpinner></LoadingSpinner>

    return <>
        <div className="guild-member-container">
            <div className="guild-member-header">
                <PageTitle id="guildMembersTitle" title={'Guild Members - ' + guild.name}>
                    Guild Members - {guild.name}
                </PageTitle>
                <div className="manage-member-container">
                    <SearchMembers handleSearchMember={handleMemberFiltering}></SearchMembers>

                    <div className="manage-member-toolbar">
                        <Button
                            data-testid="add-member-button"
                            className="member-button add-member-button"
                            variant="contained"
                            type="button"
                            color="primary"
                            disabled={isMaxMembersReached || undefined}
                            onClick={() => setModalOpen(true)}
                        >
                            Add member
                        </Button>
                        <ExportGuildMembers members={members} guildName={guild.name}/>
                        {isMaxMembersReached && <div className="info-container"><InfoIcon className="info-icon"/> <label
                            data-testid="guild-members-limit-reached">Total capacity of {numberOfMembersMax} members
                            reached</label></div>}
                    </div>
                </div>
            </div>

            <GuildMemberTable members={members} guildId={guild.id}/>
        </div>
        {
            isModalOpen && guild && (
                <CustomModal handleClose={() => setModalOpen(false)}>
                    <GuildInvitation
                        guildId={guild.id}
                        handleClose={() => setModalOpen(false)}
                    />
                </CustomModal>
            )
        }
    </>
}

export const getLastModifiedDate = (member: Member) => {
    const date = member?.lastStatusUpdate
    if (!date) return '-'
    const lastUpdatedDate = formatMilliseconds([...date])
    formatMonth(lastUpdatedDate)
    if (lastUpdatedDate) {
        return formatDate(lastUpdatedDate)
    }
}

export default GuildMembers
