import React from 'react'
import {NavLink, useMatch, useResolvedPath} from 'react-router-dom'
import {List, ListItemButton, Typography} from '@mui/material'
import {GuildMenuItem, guildMenus} from '../../guildConstants'
import './GuildManagementMenu.scss'
import {useCurrentGuildContext, useDatxPathGenerator} from '../../../util/routing'
import {ROUTES} from '../../../constants/routing'

const GuildManagementMenu = () => {
    const [currentGuildContext, setCurrentGuild] = useCurrentGuildContext()

    return (
        <div className="manage-guild-panel">
            <div className="guild-management-header">
                <div className="header-title">
                    Manage Guild
                </div>
                <div className="header-guild-name">
                    {currentGuildContext?currentGuildContext?.guildName: ''}
                </div>
            </div>
            <List component="nav" aria-label="guild management menu" className="guild-management-menu">
                {guildMenus.map((item) => (
                    <MenuItem key={item.label} item={item}/>
                ))}
            </List>
        </div>
    )
}

const MenuItem = ({item}: { item: GuildMenuItem }) => {
    const menuLevel = 0
    const generatePath = useDatxPathGenerator()
    const resolved = useResolvedPath(generatePath(ROUTES.guildManagement.path+item.linkTo))
    const match = useMatch({path: resolved.pathname, end: false})

    const menuContent = (
        <ListItemButton
            className={'nav-link-content'}
            style={{ paddingLeft: (menuLevel + 1) * 16 }}
        >
            <Typography className="title" data-testid= {'only-label-without-link ' + item.label}>{item.label}</Typography>
        </ListItemButton>
    )

    return (
        <React.Fragment key={item.label}>
            {item.linkTo ? (
                <NavLink
                    to={resolved}
                    className={`nav-link ${
                        match && 'selected-menu'
                    }`}
                    data-testid= {'nav-link-test ' + item.label}
                >
                    {menuContent}
                </NavLink>
            ) : (
                menuContent
            )}
        </React.Fragment>
    )
}

export {GuildManagementMenu,MenuItem}
