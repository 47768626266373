import React from 'react'
import './DownloadServiceFile.scss'
import {CircularProgress, Link} from '@mui/material'
import {useGetServiceRequestFileMutation} from '../rtkServiceRequestApi'


export const DownloadServiceFileDetail = ({serviceRequestId, file}: { serviceRequestId: string, file: any }) => {
    const [getServiceRequestFile, {isLoading}] = useGetServiceRequestFileMutation()

    const getFile = () => {
        getServiceRequestFile(serviceRequestId)
    }

    if (!file?.name) {
        return <></>
    }

    if (file?.status !== 'Valid') {
        return <div className="text-error" title={file?.status}>{file?.status}</div>
    }

    return <>
        <Link className="file-name-format" component="button" onClick={() => getFile()}>{file?.name}</Link>
        {!isLoading || <CircularProgress size="16px"/>}
    </>
}