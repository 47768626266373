import {SearchResultDTO} from '../../../model/SearchResultDTO'
import {Search} from '../../../model/SearchFilter'
import React, {ChangeEvent} from 'react'
import {useSearchResultControl} from '../../useSearchResultControl'
import SearchToolBarForClassificationAndPhraseSearch
    from '../SearchToolBar/SearchToolBarForClassificationAndPhraseSearch'
import SearchResultDataTable from '../SearchResultDataTable/SearchResultDataTable'
import SearchResultCardGrid from '../SearchResultCardGrid'
import {Pagination} from '@mui/lab'
import ItemSearchModal from '../AddItemToListModal/AddItemToListModal'
import {SearchPagination} from '../../../searchPagination/SearchPagination'

export const SearchResultTableForClassificationAndPhrase = ({searchResult, page, search, handlePageChange}: {
    searchResult: SearchResultDTO | undefined,
    page: number,
    search: Search,
    handlePageChange: (e: ChangeEvent<unknown>, pageNumber: number) => void,
}) => {
    const {
        addPageResultToSelection,
        clearPageSelection,
        handleAddMultipleItemsToList,
        setLegacyView,
        legacyView,
        isAnItemInPageSelected,
        areAllItemsInPageSelected,
        itemsSelectedList,
        startIndex,
        endIndex,
        addToMultiSelect,
        removeFromMultiSelect,
        handleAddSingleItemToList,
        isSelected,
        pageSize,
        addItemsToListModalVisible,
        setAddItemsToListModalVisible,
        finalItemsToBeAddedToList
    } = useSearchResultControl({searchResult, page})

    return <div>
        <div className="search-results-table">
            <SearchToolBarForClassificationAndPhraseSearch
                handleSelectAll={addPageResultToSelection}
                handleUnselectAll={clearPageSelection}
                handleCreateItemList={handleAddMultipleItemsToList}
                toggleLegacyView={() => setLegacyView(!legacyView)}
                isAnItemOnPageSelected={isAnItemInPageSelected()}
                allItemsSelected={areAllItemsInPageSelected()}
                selectedItemsList={itemsSelectedList}
                startIndex={startIndex}
                endIndex={endIndex}
                totalResultCount={searchResult?.totalElements ?? 0}
                legacyView={legacyView}
            />
            {
                !legacyView &&
                <SearchResultDataTable
                    definitionsToDisplay={searchResult?.definitionsToDisplay ?? []}
                    list={searchResult?.elements ?? []}
                    addToMultiSelect={(item) => addToMultiSelect([item])}
                    removeFromMultiSelect={removeFromMultiSelect}
                    handleAddSingleItemToList={handleAddSingleItemToList}
                    isSelected={isSelected}
                    highlightPhrase={search?.phrase?.phraseDetail ?? ''}

                />
            }
            {
                legacyView &&
                <SearchResultCardGrid
                    searchItems={searchResult?.elements ?? []}
                    addToMultiSelect={(item) => addToMultiSelect([item])}
                    removeFromMultiSelect={removeFromMultiSelect}
                    handleAddSingleItemToList={handleAddSingleItemToList}
                    isSelected={isSelected}
                    highlightPhrase={search?.phrase?.phraseDetail ?? ''}
                    isMultiSelectionEnabled={true}
                />
            }
        </div>
        <SearchPagination totalElements={searchResult?.totalElements || 0}
                          pageSize={pageSize}
                          page={page}
                          handlePageChange={handlePageChange}/>
        {addItemsToListModalVisible &&
            <ItemSearchModal closeModal={() => setAddItemsToListModalVisible(false)}
                             itemList={finalItemsToBeAddedToList}/>}
    </div>
}