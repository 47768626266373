import {CookiesCategory, CookieVendor} from '../CookiesTypes'
import {Button, Icon} from '@mui/material'
import {CookieOutlined} from '@mui/icons-material'
import {Link} from 'react-router-dom'
import {ROUTES} from '../../constants/routing'
import {AllCookiesDetails} from '../CookiesData'
import {CategoryCookieAccordion} from './Accordion/CategoryCookieAccordion'
import {CookiesButtonsSection} from './CookiesButtonsSection'
import {useDatxPathGenerator} from '../../util/routing'
import {ChangeEvent} from 'react'

export const CookiesCategoriesDialog = ({
                                            classes,
                                            handleClose,
                                            allowAllCookies,
                                            declineAllCookies,
                                            handleSwitch,
                                            checkedSwitch,
                                            openDialogType
                                        }: {
    classes: string,
    handleClose: () => void
    allowAllCookies: () => void
    declineAllCookies: () => void
    handleSwitch: (e: ChangeEvent<HTMLInputElement>, category: CookiesCategory) => void
    checkedSwitch: (category: CookiesCategory) => boolean
    openDialogType: (category: CookiesCategory) => void
}) => {
    const generatePath = useDatxPathGenerator()

    return <div className={`cookies-settings-dialog-content ${classes}`}>
        <div className="privacy-preference-center">
            <div className="title-section">
                <Icon className="title-section-icon"><CookieOutlined/></Icon>
                <span>Privacy Preference Center</span>
            </div>
            <div>
                <p>When you visit any website, it may store or retrieve information on your browser, mostly
                    in
                    the form of cookies. This information might be about you, your preferences or your
                    device
                    and is mostly used to make the site work as you expect it to. The information does not
                    usually directly identify you, but it can give you a more personalized web experience.
                    Because we respect your right to privacy, you can choose not to allow some types of
                    cookies.
                    Click on the different category headings to find out more and change our default
                    settings.
                    However, blocking some types of cookies may impact your experience of the site and the
                    services we are able to offer.</p>
                <Link to={generatePath(ROUTES.cookiePolicy.path)} className="cookie-link"
                      onClick={handleClose}>Cookie policy and list of cookies</Link>
            </div>
            <Button type="button" variant="contained" onClick={allowAllCookies}
                    data-testid="accept-all-cookies-button">Allow
                All</Button>
        </div>
        <div className="manage-consent-preferences">
            <div className="title-section"><span>Manage Consent Preferences</span></div>
            <div className="management-section">
                {AllCookiesDetails.map(({category, description, vendorDetails}) =>
                    <CategoryCookieAccordion key={`category-${category}`}
                                             category={category}
                                             description={description}
                                             vendorDetails={vendorDetails}
                                             handleSwitch={(e) => handleSwitch(e, category)}
                                             checkedSwitch={checkedSwitch(category)}
                                             openVendorsDialog={() => openDialogType(category)}
                                             inCategoryDialog={true}
                                             type={CookieVendor.MicrosoftApplicationInsights}
                    />
                )}
            </div>
        </div>
        <CookiesButtonsSection inCategoryDialog={true} declineAllCookies={declineAllCookies}/>
    </div>
}