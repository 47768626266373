import {createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios'
import {SEARCH_API_ENDPOINT} from '../constants/backendUrls'
import {setSnackbarMessage} from '../genericComponents/commonSlice'
import {IdType} from '../util/models/IdType'
import FileSaver from 'file-saver'
import {FileResource} from './model/FileResource'

type GetItemDocumentType = { documentId: IdType | undefined, itemId: IdType | undefined, guildId?: IdType | undefined }


const getItemDocumentEndpoint = (itemId: IdType | undefined, guildId: IdType | undefined, documentId: IdType | undefined) => {
    if (!guildId) return `${SEARCH_API_ENDPOINT}/api/v1/datx/items/${itemId}/artifact/${documentId}`

    return `${SEARCH_API_ENDPOINT}/api/v1/datx/guilds/${guildId}/items/${itemId}/artifact/${documentId}`
}
export const getItemDocument = createAsyncThunk('getItemDocument', async ({
                                                                              documentId,
                                                                              itemId,
                                                                              guildId = undefined,
                                                                          }: GetItemDocumentType, {dispatch}) => {
    try {
        const response = await axios.get(getItemDocumentEndpoint(itemId, guildId, documentId))
        const file: FileResource = response?.data
        FileSaver.saveAs(file.uri, file.name)
    } catch (err) {
        dispatch(setSnackbarMessage('Document is not available.'))
        return null
    }
})