import React from 'react'
import {MinQuantityRule} from '../QuotationRule'

export const MinQuantityRuleComponent = ({rule, quantity}: {rule: MinQuantityRule, quantity: number}) => {

    return <div className="quotation-detail-rule-structure">
        <p className="quotation-detail-description">No. of Items</p>
        <p className="quotation-detail-unit-price">{rule?.subRule?.price}</p>
        <p className="quotation-detail-nb-object">{quantity}</p>
        <p className="quotation-detail-min-quantity">{rule.minQuantity}</p>
        <p className="quotation-detail-total">{rule.calculate(0, quantity)}</p>
    </div>
}