import React, {useEffect, useState} from 'react'
import './ImportList.scss'
import {Paper} from '@mui/material'
import {UploadListForm} from './UploadListForm/UploadListForm'
import {IdType} from '../../util/models/IdType'
import {useTypedDispatch} from '../../util/store'
import {ConfirmationListForm} from './ConfirmationStep/ConfirmationListForm'
import {setSnackbarMessage} from '../../genericComponents/commonSlice'
import LoadingSpinner from '../../genericComponents/LoadingSpinner'
import {useCurrentGuildContext} from '../../util/routing'
import {useCreateItemListMutation, useGetItemListsQuery} from '../rtkItemListApi'
import {skipToken} from '@reduxjs/toolkit/query/react'

export default function ImportList() {
    const [currentGuildContext] = useCurrentGuildContext()

    const dispatch = useTypedDispatch()
    const [listName, setListName] = useState('')
    const [itemIds, setItemIds] = useState<IdType[]>()
    const {
        data: myLists = [],
        isFetching: myListsLoading
    } = useGetItemListsQuery(currentGuildContext?.guildId ?? skipToken)
    const [createItemList, createItemListResult] = useCreateItemListMutation()


    useEffect(() => {
        setItemIds(myLists.find(list => list.name === listName)?.items)
    }, [myLists])

    const handleCompleteListUploadForm = (listName: string, itemIds: IdType[] | null) => {
        if (!currentGuildContext) {
            dispatch(setSnackbarMessage('You have to select a guild in order to add items to list.'))
            return
        }
        setListName(listName)
        createItemList({
            guildId: currentGuildContext.guildId,
            name: listName,
            items: itemIds!
        })

    }

    return (
        <div className="service-subscription-page">
            {myListsLoading ? <LoadingSpinner/> :
                <div className="stepper-container">
                    {createItemListResult.isSuccess &&
                        <ConfirmationListForm listName={listName} itemIds={itemIds!}/>}
                    {createItemListResult.isUninitialized &&
                        <UploadListForm existingLists={myLists} handleImportAction={handleCompleteListUploadForm}
                                        isLoading={createItemListResult.isLoading}/>}
                </div>
            }
        </div>
    )
}
