import React, {ReactElement} from 'react'
import TextFilterInput from '../searchFilterCard/TextFilterInput'
import RangeFilterInput from '../searchFilterCard/RangeFilterInput'

export type DisplayFiltersDTO = {
    definitionFilters: DisplayFilterDTO[],
    organizationFilter: DisplayFilterDTO | null
    itemTypeFilter: DisplayFilterDTO
}

export interface DisplayFilterDTO {
    id: string,
    name: string,
    suffix: string,
    values: ValueOption[]
    type: DisplayFilterType
}

export interface ValueOption {
    value: string,
    targetId: string
}

export enum DisplayFilterType {
    STRING = 'String',
    INTEGER = 'Integer',
    BOOLEAN = 'Boolean',
    ARTIFACT = 'Artifact',
    CLASSIFICATION = 'Classification',
    DATE = 'Date',
    COMPLEX = 'Complex'
}

export const DisplayFilterComponent = ({filter, setSelectableValues}: {filter: DisplayFilterDTO, setSelectableValues: React.Dispatch<React.SetStateAction<ValueOption[]>>}) => {
    const filterType = filter?.type ?? DisplayFilterType.STRING
    const filterComponent =  mapDisplayFilterTypeToComponent.get(filterType)
    if(!filterComponent) return <TextFilterInput filter={filter} setSelectableValues={setSelectableValues}/>
    return filterComponent(filter, setSelectableValues)
}

const mapDisplayFilterTypeToComponent = new Map<DisplayFilterType, (filter: DisplayFilterDTO, setSelectableValues: React.Dispatch<React.SetStateAction<ValueOption[]>>) => ReactElement>([
    [DisplayFilterType.STRING, (filter, setSelectableValues) => <TextFilterInput filter={filter} setSelectableValues={setSelectableValues}/>],
    [DisplayFilterType.INTEGER, (filter, setSelectableValues) => <RangeFilterInput filter={filter} setSelectableValues={setSelectableValues}/>],
])