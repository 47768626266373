import React from 'react'
import {useFormikContext} from 'formik'
import {AttributeDocumentCreationForm} from './PrivateAttributeDocumentCreation'
import * as FileSaver from 'file-saver'
import {ServiceRequestStep} from '../../../genericComponents/formTemplate/steps/ServiceRequestStep'
import {Description} from '../../../../../genericComponents/typographie/Description'
import {Label} from '../../../../../genericComponents/typographie/Label'
import {Link} from '@mui/material'
import {WorkflowSelector} from '../../../model/WorkflowSelector'
import {ServiceEnum} from '../../../../model/ServiceEnum'

export const ReviewAndSubmitDocumentCreation = () => {
    const formik = useFormikContext<AttributeDocumentCreationForm>()

    const getFile = () => {
        const file = formik?.values?.artifactFile
        if (!file) return
        FileSaver.saveAs(file, file.name)
    }

    return (
        <ServiceRequestStep>
            <Description>Review the parameters of your selection and submit.</Description>
            <div className="service-request-form-summary">
                <Label className="form-summary-label">New Private Attribute</Label>
                <span className="form-summary-value">{formik?.values?.attributeName ?? '-'}</span>

                <Label className="form-summary-label">Attribute Type</Label>
                <span className="form-summary-value">Document</span>

                <Label className="form-summary-label">Item List</Label>
                <span className="form-summary-value">{formik?.values?.itemList?.name ?? '-'}</span>

                <Label className="form-summary-label">Uploaded Document</Label>
                <Link onClick={getFile} className="form-summary-value link" data-testid="download-document">
                    {formik?.values?.artifactFile?.name ?? '-'}
                </Link>
            </div>
            <WorkflowSelector service={ServiceEnum.CREATE_PRIVATE_ATTRIBUTES} />
        </ServiceRequestStep>
    )
}
