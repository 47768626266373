import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const DatxNoFile = (props: { [x: string]: any }) => {
    return (
        <SvgIcon {...props} viewBox="0 0 26 26">
            <g id="Calque_2" data-name="Calque 2">
                <g id="Init">
                    <path d="M6.49.63A2.16,2.16,0,0,0,4.34,2.8L21.66,19.51V7.13L15.16.63Zm7.58,1.63,6,6h-6" />
                    <path style={{ fill: 'none' }} d="M0,0H26V26H0Z" />
                    <path d="M3.27,4.6,1.9,6l6.89,6.89,9.61,9.61,2.17,2.17,1.38-1.38Z" />
                    <path d="M4.32,8.32V21.39a2.16,2.16,0,0,0,2.15,2.17h13C20.66,23.55,4.32,8.32,4.32,8.32Z" />
                </g>
            </g>
        </SvgIcon>
    )
}

export default DatxNoFile
