import React from 'react'
import ActionBox from './generic/ItemRowCardActionBox'

import './ItemRowCard.scss'
import ItemCardClickableProperty from './generic/ItemRowCardClickableProperty'
import {SearchItem} from '../../../model/SearchItem'
import {useDatxPathGenerator} from '../../../../util/routing'
import {ROUTES} from '../../../../constants/routing'
import ItemNature from './ItemMainProperties/ItemNature'

export const ItemRowHeaderCard = ({
                                    item,
                                    addToMultiSelect,
                                    removeFromMultiSelect,
                                    isSelected,
                                    handleAddSingleItemToList,
                                    highlightPhrase
                                }: {
    item: SearchItem,
    addToMultiSelect: (item: SearchItem) => void,
    removeFromMultiSelect: (item: SearchItem) => void,
    isSelected: (item: SearchItem) => boolean,
    handleAddSingleItemToList: (item: SearchItem) => void,
    highlightPhrase?: string | null,
}) => {
    const generatePath = useDatxPathGenerator()

    return <div className={'item-row-header-card'} data-testid={item.id}>
            <ActionBox handleSelect={() => addToMultiSelect(item)}
                       handleUnselect={() => removeFromMultiSelect(item)}
                       isItemSelected={isSelected(item)}
                       handleAddSingleItemToList={() => handleAddSingleItemToList(item)}/>
            <ItemNature nature={item.nature} />
            <ItemCardClickableProperty highlightPhrase={highlightPhrase || ''}
                                       value={item.partNumber}
                                       linkTo={generatePath(ROUTES.detailItem.path, {itemId : item.id})}
                                       testId={`link-${item.id}`}
            />
        </div>

}
