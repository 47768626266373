import React from 'react'

export const Confirmation = ({currentListName}: { currentListName: string | null }) => {
    return (
        <div className="add-item-to-list--confirmation">
            <label data-testid="notification-dialog">The selected components are added to:</label>
            <p className="addItemToList-confirmation-label-name">
                <span className = "confirmation-modal-text">{currentListName}</span>
            </p>
        </div>
    )
}
