import {Button} from '@mui/material'
import {Link} from 'react-router-dom'
import {ROUTES} from '../../constants/routing'
import {useDatxPathGenerator} from '../../util/routing'
import './GuildCatchup.scss'

export const GuildCatchup = () => {
    const generatePath = useDatxPathGenerator()
    return <div className="home-guild-catchup-container">
        <div className="guild-catchup-card">
            <img className="guild-catchup-image"
                 src="/images/home/guild_lookup.svg"
                 alt="someone on a rocket looking to the future"
            />
            <div className="guild-catchup-paragraph">
                <h2 className="guild-catchup-title">Be part of a guild</h2>
                <p><b>Select an existing guild</b> for complete DATX experience.</p>
                <p>You may also <b>create a guild</b> and invite your colleagues to DATX to explore more DATX features!
                </p>
                <Button component={Link} color="primary" variant="contained"
                        to={generatePath(ROUTES.guildCreation.path)}
                        className="guild-catchup-link">Create Guild</Button>
            </div>
        </div>
    </div>
}