import {FormControl, SelectChangeEvent, TextField} from '@mui/material'
import React, {ReactNode} from 'react'
import {useFormikContext} from 'formik'
import {AttributeStringCreationForm} from './PrivateAttributeStringCreation'
import {SelectItemList} from '../../../genericComponents/SelectItemList'
import {Label} from '../../../../../genericComponents/typographie/Label'
import {ServiceRequestStep} from '../../../genericComponents/formTemplate/steps/ServiceRequestStep'
import {ItemList} from '../../../../../itemList/model/ItemList'

export const DefineCreationStringCreation = ({ itemListSelectLabel = 'Create new private attribute for items in this list' }:
                                                 { itemListSelectLabel: string }) => {
    const formik = useFormikContext<AttributeStringCreationForm>()

    const resetUploadedFile = () => {
        formik.setFieldValue('file', undefined)
        formik.resetForm({values: {...formik.values, file: formik.initialValues.file}})
    }

    const handleListChange = (event: SelectChangeEvent<ItemList>, _: ReactNode) => {
        resetUploadedFile()
        formik.handleChange(event)
    }

    return <ServiceRequestStep>
        <FormControl required>
            <Label id="private-attribute-name-label" className="private-attribute-name-label"
                   required
                   htmlFor="attributeName">Name of new private attribute</Label>
            <TextField
                id="attributeName"
                size="small"
                name="attributeName"
                value={formik.values.attributeName}
                onChange={formik.handleChange}
                variant="outlined" required
                data-testid="private-attribute-name"
                placeholder="Enter name of new private attribute"
                inputProps={{maxLength: 100}}
                aria-invalid={formik.touched.attributeName && Boolean(formik.errors.attributeName)}
            />
        </FormControl>

        <SelectItemList selectedList={formik.values.itemList}
                        handleChange={handleListChange}
                        touched={formik.touched.itemList}
                        label={itemListSelectLabel}
                        errors={formik.errors.itemList}
                        required
        />
    </ServiceRequestStep>
}