import {IdType} from '../../../../util/models/IdType'
import React, {useEffect, useMemo, useState} from 'react'
import {getIsListNameDuplicated} from '../../../../itemList/ItemLists'
import {useDebounce} from 'use-debounce'
import InfoIcon from '@mui/icons-material/Info'
import {Button, Divider, TextField} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import {KeyboardArrowLeft} from '@mui/icons-material'
import {Confirmation} from './AddItemToListConfirmationModal'
import {useCurrentGuildContext} from '../../../../util/routing'
import {useCreateItemListMutation, useGetPersonalItemListsQuery} from '../../../../itemList/rtkItemListApi'

export const AddItemToNewList = ({itemIds, handleSubmit}: { itemIds: IdType[], handleSubmit: () => void }) => {







    const [inputUserListName, setInputUserListName] = useState('')
    const [inputErrorMsg, setInputErrorMsg] = useState('')

    const [currentGuildContext] = useCurrentGuildContext()

    const {data: myLists = []} = useGetPersonalItemListsQuery(currentGuildContext?.guildId, {skip: !currentGuildContext?.guildId})
    const [createItemList, createItemListResult] = useCreateItemListMutation()


    const onSubmit = () => {
        if (currentGuildContext) {
            //UC creation de liste et assignation
            createItemList({
                guildId: currentGuildContext.guildId,
                name: inputUserListName,
                items: itemIds
            })


        }

    }


    const onListNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputUserListName(e.target.value)
        // as long as the user type something, the error will be gone
        setInputErrorMsg('')
    }



    const isListNameValid = useMemo(() => {
        const isNameDuplicated = getIsListNameDuplicated(myLists, inputUserListName)
        return inputUserListName && !isNameDuplicated
    }, [inputUserListName,myLists])


    const [newItemListName] = useDebounce(inputUserListName, 500)

    useEffect(() => {
        const isDebouncedNameDuplicated = getIsListNameDuplicated(
            myLists,
            newItemListName
        )
        if (
            isDebouncedNameDuplicated &&
            // since submitting the form will update myList and cause the error message to be displayed
            // this condition is added to prevent the error message from showing up
            !createItemListResult.isLoading
        ) {
            setInputErrorMsg(
                'A list by this name already exists. Kindly provide a different name.'
            )
        }
    }, [newItemListName])

    return (
        <>
            {createItemListResult.isSuccess ? (
                <Confirmation currentListName={inputUserListName}/>
            ) : (
                <>
                    <div className="add-item-to-list">
                        <div className="info-header">
                            <InfoIcon className="info-icon"/>
                            <div className="info-title">Add to List</div>
                        </div>
                        <label className="info-label">
                            Create a new list and add the selected component
                        </label>
                        <div className="item-list" data-testid="guild-item-list-selection">

                            <TextField
                                id="new_list_name_input"
                                className="new-list-name-input-container"
                                value={inputUserListName}
                                onChange={onListNameChange}
                                helperText={inputErrorMsg}
                                variant="outlined"
                                placeholder={'Enter name of new component list'}
                                data-testid="new-list-name-input"
                            />

                            <LoadingButton
                                className="item-list-submit"
                                data-testid="next-button"
                                type="submit"
                                id="next-button"
                                variant="contained"
                                color="primary"
                                disabled={!isListNameValid}
                                loading={createItemListResult.isLoading}
                                onClick={onSubmit}
                            >
                                Add to New List
                            </LoadingButton>

                        </div>

                        <Divider className="search-result-divider"/>


                        <Button className="back-button"
                                onClick={handleSubmit}
                        >
                            <KeyboardArrowLeft className="arrow-left-button"/> Back
                        </Button>
                    </div>
                </>
            )}
        </>
    )
}
