import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material'
import {ExpandedIcon} from './ExpandedIcon'
import {ReactNode, useState} from 'react'
import './Accordion.scss'

export const AccordionTemplate = ({
                                   toggleSwitch,
                                   children,
                                   title,
                                   category,
                                   accordionDetailsClasses = '',
                               }: {
    category: string,
    title: string,
    toggleSwitch: ReactNode,
    children: ReactNode,
    accordionDetailsClasses?: string,
}) => {

    const [accordionsExpanded, setAccordionsExpanded] = useState(false)

    return (
        <Accordion expanded={accordionsExpanded}
                   className="accordion"
                   onChange={()=> setAccordionsExpanded(!accordionsExpanded)}
        >
            <AccordionSummary className="accordion-summary">
                <ExpandedIcon name={category} expanded={accordionsExpanded}/>
                <span className="accordion-summary-title">{title}</span>
                {toggleSwitch}
            </AccordionSummary>
            <AccordionDetails className={`accordion-details ${accordionDetailsClasses}`}>
                {children}
            </AccordionDetails>
        </Accordion>
    )
}