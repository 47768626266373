import React, {useState} from 'react'
import {useTypedDispatch} from '../../../util/store'
import * as XLSX from 'xlsx'
import FileSaver from 'file-saver'
import {setSnackbarMessage} from '../../../genericComponents/commonSlice'
import {Transaction} from './models/Transaction'
import {IconButton, Tooltip} from '@mui/material'
import {useCurrentGuildContext} from '../../../util/routing'
import {useGetGuildTransactionsQuery} from '../../rtkGuildApi'
import {formatDate, formatMilliseconds, formatMonth} from '../../../genericComponents/date/DateService'

interface ExcelCreditHistory {
    requestId: string
    serviceType: string
    requesterEmail: string
    transactionDate: string
    creditsChange: string
}

const ExportCreditHistory = () => {
    const [isLoading, setIsLoading] = useState(false)

    const dispatch = useTypedDispatch()

    const pageSize = 10000
    const [currentGuildContext] = useCurrentGuildContext()

    const {data: transactionsHistory} = useGetGuildTransactionsQuery(
        {
            pageNumber: 0,
            pageSize: pageSize,    //Limit 10K results for extraction
            guildId: currentGuildContext?.guildId
        },
        {
            skip: !currentGuildContext?.guildId || !currentGuildContext?.guildAdmin
        }
    )

    const constructRows = (): ExcelCreditHistory[] => {
        const transactions = transactionsHistory?.content ?? []
        return transactions.map((transaction: Transaction) => {
            return {
                'requestId': transaction.serviceRequestId ?? '',
                'serviceType': transaction.type ?? '',
                'requesterEmail': transaction.user?.email ?? '',
                'transactionDate': getTransactionDate(transaction) ?? '',
                'creditsChange': `${transaction.amount}` ?? '',
            }
        })
    }

    const downloadExcel = () => {
        try {
            const currDate = new Date()
            const fileName = `Datx-credit-history-${currDate.toLocaleString('en-GB').replace(' ', '_').replace(',', '')}`
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'
            // Init worksheet
            const ws = XLSX.utils.json_to_sheet(constructRows())

            // Set custom headers
            const headers = [['Request ID', 'Service', 'Requestor', 'Transaction Date', 'Credits Change']]
            XLSX.utils.sheet_add_aoa(ws, headers)

            // Write to workbook
            const wb = {Sheets: {'Credit History': ws}, SheetNames: ['Credit History']}
            const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'})
            const data = new Blob([excelBuffer], {type: fileType})
            FileSaver.saveAs(data, fileName + fileExtension)
            dispatch(setSnackbarMessage('List of all Credits history has been exported'))
        } catch (e) {
            dispatch(setSnackbarMessage('There was an error exporting the list of credit history'))
        }
    }

    const downloadCreditHistory = () => {
        setIsLoading(true)
        downloadExcel()
        setIsLoading(false)
    }

    const getTransactionDate = (transaction: Transaction) => {
        const date = transaction?.createdDate
        if (!date) return ''
        const dateArray = date.toString().split(',').map(Number)
        const dateToDisplay = formatMilliseconds([...dateArray])
        formatMonth(dateToDisplay)

        return formatDate(dateToDisplay)
    }

    return <Tooltip title="Download Credit history to Excel">
        <IconButton
            data-testid="export-credits-icon-button"
            disabled={isLoading}
            onClick={() => downloadCreditHistory()}
        >
            <img
                src="/images/custom-icons/DownloadRounded.svg"
                alt="download-rounded-icon"
            />
        </IconButton>
    </Tooltip>
}

export default ExportCreditHistory