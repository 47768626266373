import React from 'react'
import {ROUTES} from '../../constants/routing'
import ItemNavigationMenu from '../headerGenericComponents/ItemNavigationMenu'
import {ItemNavigation} from '../headerGenericComponents/ItemNavigation'
import {useCurrentGuildContext, useDatxPathGenerator} from '../../util/routing'


const GuildMenu = () => {
    const [currentGuildContext] = useCurrentGuildContext()
    const generatePath = useDatxPathGenerator()

    const data: ItemNavigation[] = [
        {
            label: 'Create a guild',
            objId: '1',
            url: generatePath(ROUTES.guildCreation.path),
            dataTestId: 'create-a-guild'
        },
        {
            label: 'Manage guild',
            objId: '2',
            url: generatePath(ROUTES.guildManagement.path),
            disabled: !currentGuildContext?.guildAdmin,
            dataTestId: 'manage-guild'
        },
    ]

    return (
        <ItemNavigationMenu
            label="My Guild"
            itemList={data}
            subRoute="/guilds"
        />
    )
}

export default GuildMenu
