import {ItemCard} from '../../item/itemCard/ItemCard'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {DeleteForever} from '@mui/icons-material'
import Grid from '@mui/material/Grid'
import React, {ChangeEvent, useEffect, useState} from 'react'
import {Checkbox, Divider, IconButton, Paper, Toolbar} from '@mui/material'
import SearchTabs from '../../search/searchTabs/SearchTabs'
import DeleteItemFromListDialog from '../DeleteItemFromList/DeleteItemFromListDialog'
import {SearchListItemSelectable} from '../../search/model/SearchListItemSelectable'
import LoadingSpinner from '../../genericComponents/LoadingSpinner'
import {SelectionDownload} from '../../util/component/SelectionDownload'
import './ItemListDetail.scss'
import {selectCurrentUser} from '../../user/rtkUserApi'
import {useSelector} from 'react-redux'
import {useCurrentGuildContext, useDatxPathGenerator} from '../../util/routing'
import {ROUTES} from '../../constants/routing'
import {useGetItemListQuery} from '../rtkItemListApi'
import {IdType} from '../../util/models/IdType'
import {SearchPagination} from '../../search/searchPagination/SearchPagination'
import {pageSizeURLSearchParamsName, pageURLSearchParamsName} from '../../search/SearchParams'
import {toPageSize} from '../../util/component/search'

export const ItemListDetail = () => {
    const [checkAll, setCheckAll] = useState(false)
    const [listItems, setListItems] = useState<SearchListItemSelectable[]>([]) // To populate items from currentList (redux)
    const [itemsSelected, setItemsSelected] = useState<SearchListItemSelectable[]>([]) //For delete button
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [isEmptyItemSelection, setIsEmptyItemSelection] = useState(true) //For delete button
    const [renderDeleteButton, setRenderDeleteButton] = useState(false)

    const initialiseListSelection = () => {
        setIsEmptyItemSelection(true)
        setCheckAll(false)
    }
    const navigate = useNavigate()
    const generatePath = useDatxPathGenerator()
    const {itemListId} = useParams()
    const [currentGuildContext] = useCurrentGuildContext()
    const user = useSelector(selectCurrentUser)
    const [itemListParams, setItemListParams] = useSearchParams()
    const page = +(itemListParams.get(pageURLSearchParamsName) ?? 1)
    const pageSize = toPageSize(itemListParams.get(pageSizeURLSearchParamsName))
    const {data: currentList = null, isFetching} = useGetItemListQuery(
        {
            pageNumber: page - 1,
            pageSize: pageSize,
            listId: itemListId
        },
        {skip: !currentGuildContext}
    )

    const [previousGuildContext, setPreviousGuildContext] = useState(currentGuildContext)
    useEffect(() => {
        if (previousGuildContext && previousGuildContext.guildId !== currentGuildContext?.guildId) {
            navigate(generatePath(ROUTES.manageItemLists.path))
        }
        setPreviousGuildContext(currentGuildContext)
    }, [currentGuildContext])

    useEffect(() => {
        if (currentList) {
            setListItems([])
            if (currentList.elements.length >= 0) {
                currentList.elements.forEach((item1) => {
                    setListItems((listItems) => [
                        ...listItems,
                        {item: item1, selected: false, listItem: currentList.id}
                    ])
                })
            }

            if (currentList.userOauthId == user?.oauthProviderId) {
                setRenderDeleteButton(true)
            }
        }
    }, [currentList, currentGuildContext, itemListId])

    useEffect(() => {
        initialiseListSelection()
    }, [itemListId, currentGuildContext])

    const selectAllItems = () => {
        if (!checkAll) {
            listItems.forEach((item) => (item.selected = true))
            setCheckAll(true)
        } else {
            listItems.forEach((item) => (item.selected = false))
            setCheckAll(false)
        }

        setListItems(listItems)

        const noSelectedItem = !listItems.some((item) => item.selected)
        setIsEmptyItemSelection(noSelectedItem)
    }

    const handleChildChecked = () => {
        const noSelectedItems = !listItems.some((item) => item.selected)
        const allSelectedItems = !listItems.some((item) => !item.selected)

        setIsEmptyItemSelection(noSelectedItems)
        setCheckAll(allSelectedItems)
    }
    const deleteItems = () => {
        setItemsSelected(listItems.filter((item) => item.selected))
        setIsDialogOpen(true)
    }

    const handleCloseDeleteDialog = (deletedItems?: IdType[] | undefined) => {
        setIsDialogOpen(false)
        if (deletedItems && deletedItems.length > 0) setIsEmptyItemSelection(true)
    }

    const handlePageChange = (e: ChangeEvent<unknown>, pageNumber: number) => {
        if (pageNumber === page || page < 0) {
            return
        }

        itemListParams.set(pageURLSearchParamsName, `${pageNumber}`)
        itemListParams.set(pageSizeURLSearchParamsName, `${pageSize}`)
        setItemListParams(itemListParams)
    }

    return (
        <>
            <div className="item-list-page">
                <Paper square={true} className="toolbar-paper">
                    <Toolbar id="search-result-area" className="toolbar toolbar-regular">
                        {currentList && currentList.name && (
                            <SearchTabs tabName={currentList.name} itemCount={currentList.totalElements} />
                        )}
                    </Toolbar>
                </Paper>
                <Paper>
                    <div className="content-list-detail">
                        <div className="item-list-details-header">
                            <Checkbox
                                data-testid="check-all-items"
                                checked={checkAll}
                                onClick={() => selectAllItems()}
                            />
                            <Divider orientation="vertical" variant="middle" flexItem />
                            <div className="header-icons">
                                <IconButton
                                    aria-label="download"
                                    disabled={isEmptyItemSelection}
                                    onClick={() => {
                                        return null
                                    }}
                                    color="primary"
                                >
                                    <SelectionDownload
                                        selectedItems={listItems.filter((i) => i.selected).map((i) => i.item)}
                                        isDisabled={isEmptyItemSelection}
                                    />
                                </IconButton>

                                {renderDeleteButton ? (
                                    <IconButton
                                        aria-label="delete"
                                        disabled={isEmptyItemSelection}
                                        onClick={deleteItems}
                                        color="primary"
                                        data-testid="delete-icon"
                                    >
                                        <DeleteForever className={'more-actions-option'} />
                                    </IconButton>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <Divider sx={{mb: 2}} />

                        <Grid className="item-list-detail-grid-container" container spacing={2}>
                            {isFetching ? (
                                <LoadingSpinner />
                            ) : (
                                listItems.map((res) => {
                                    return (
                                        <Grid key={res.item.id} item xs={3} mb={2} data-testid="item-card">
                                            <div
                                                onClick={() =>
                                                    navigate(
                                                        generatePath(ROUTES.detailItem.path, {itemId: res.item.id})
                                                    )
                                                }
                                            >
                                                <ItemCard
                                                    content={res}
                                                    key={res.item.id.toString()}
                                                    id={res.item.id.toString()}
                                                    handleChildChecked={handleChildChecked}
                                                    highlightPhrase=""
                                                    actionIcon={<></>}
                                                />
                                            </div>
                                        </Grid>
                                    )
                                })
                            )}
                        </Grid>
                    </div>
                </Paper>
                <SearchPagination
                    totalElements={currentList?.totalElements || 0}
                    pageSize={pageSize}
                    page={page}
                    handlePageChange={handlePageChange}
                />
                <DeleteItemFromListDialog
                    open={isDialogOpen}
                    closeDialog={handleCloseDeleteDialog}
                    itemsToDelete={itemsSelected}
                    currentList={currentList}
                />
            </div>
        </>
    )
}
