export type WidgetId = WidgetEnum | string

export enum WidgetEnum {
    DEFAULT = 'DEFAULT',
    PROCUREMENT = 'PROCUREMENT',
    COMING_SOON = 'COMING_SOON',
    CHEMICAL_COMPOSITION = 'CHEMICAL_COMPOSITION',
    DANGEROUSNESS = 'DANGEROUSNESS',
    CONFORMITY_LINK = 'CONFORMITY_LINK',
    CODIFICATION_LINK = 'CODIFICATION_LINK',
    ITEM_VARIANCE_LINK_PACKAGING = 'ITEM_VARIANCE_LINK_PACKAGING',
    DOUBLE_ID_LINK = 'DOUBLE_ID_LINK'
}


