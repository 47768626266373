import { IconButton, Tooltip } from '@mui/material'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAddRounded'
import './datxIcon.scss'
import { CustomTooltip } from './customTooltip/CustomTooltip'

const AddToListButton = ({onClick, tooltip} : {onClick : () => void, tooltip?: string}) => {
    return (
        <IconButton className="datxIcon" onClick={() => onClick()} data-testid="add-to-list-button">
            <CustomTooltip title={tooltip}>
                <PlaylistAddIcon/>
            </CustomTooltip>
        </IconButton>
    )
}

export default AddToListButton