import {AttributeModel} from '../model/AttributeModel'

export const getAttributeByDefinitionId = (attribute: AttributeModel,
                                           attributeDefinitionId: string): AttributeModel | null | undefined => {
    if (attribute.attributeDefinitionId === attributeDefinitionId) return attribute
    if (attribute?.value?.composedByAttributes) {
        return findAttributeByDefinitionId(attribute.value.composedByAttributes, attributeDefinitionId)
    }
    return null
}

export const findAttributeByDefinitionId = (attributes: AttributeModel[],
                                            attributeDefinitionId: string): AttributeModel | null | undefined => {
    for (const subAttribute of attributes) {
        const result = getAttributeByDefinitionId(subAttribute, attributeDefinitionId)
        if (result) return result
    }
    return null
}