import React from 'react'
import {useFormikContext} from 'formik'
import {
    RecurringExtractionRequestForm
} from '../Forms/ExtractionFormOptions/RecurringExtractionRequest/RecurringExtractionRequest'
import {ServiceRequestStep} from '../../genericComponents/formTemplate/steps/ServiceRequestStep'
import {Label} from '../../../../genericComponents/typographie/Label'
import {Description} from '../../../../genericComponents/typographie/Description'
import {WorkflowSelector} from '../../model/WorkflowSelector'
import {ServiceEnum} from '../../../model/ServiceEnum'

export const ReviewAndSubmitPartExtraction = () => {
    const formik = useFormikContext<RecurringExtractionRequestForm>()

    const serviceType = formik?.values?.extractionFrequency === 'ONE_TIME'
        ? ServiceEnum.ONE_TIME_EXTRACTION
        : ServiceEnum.RECURRING_EXTRACTION

    return (
        <ServiceRequestStep>
            <Description>Review the parameters of your selection and submit.</Description>
            <div className="service-request-form-summary">
                <Label className="form-summary-label">Item List</Label>
                <span className="form-summary-value">{formik?.values?.itemList?.name ?? '-'}</span>

                <Label className="form-summary-label">Display Sets</Label>
                <span className="form-summary-value">
                    {formik?.values?.selectedDisplaySets?.map((set) => set.label).join(', ') ?? '-'}
                </span>

                <Label className="form-summary-label">Format</Label>
                <span className="form-summary-value">{formik?.values?.format ?? '-'}</span>

                <Label className="form-summary-label">Extraction Frequency</Label>
                <span className="form-summary-value">{formik?.values?.extractionFrequency ?? '-'}</span>

                <Label className="form-summary-label">Extraction Pathway</Label>
                <span className="form-summary-value">{formik?.values?.extractionPath ?? '-'}</span>
            </div>
            <WorkflowSelector service={serviceType}/>
        </ServiceRequestStep>
    )
}
