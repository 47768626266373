import {ROUTES} from '../constants/routing'

export interface LegalMenuItem {
    label: string
    linkToGuildContextAwarePath: string
    linkToPublicPath: string
}

export const legalMenus: LegalMenuItem[] = [
    {
        label: 'Terms of Use',
        linkToGuildContextAwarePath: `${ROUTES.termsOfUse.path}`,
        linkToPublicPath : `${ROUTES.termsOfUse.publicPath}`,
    },
    {
        label: 'Terms of Sales',
        linkToGuildContextAwarePath: `${ROUTES.termsOfSales.path}`,
        linkToPublicPath : `${ROUTES.termsOfSales.publicPath}`,
    },
    {
        label: 'Legal Notices',
        linkToGuildContextAwarePath: `${ROUTES.legalNotices.path}`,
        linkToPublicPath : `${ROUTES.legalNotices.publicPath}`,
    },
    {
        label: 'Privacy Policy',
        linkToGuildContextAwarePath: `${ROUTES.privacyPolicy.path}`,
        linkToPublicPath : `${ROUTES.privacyPolicy.publicPath}`,
    },
    {
        label: 'Cookie Policy',
        linkToGuildContextAwarePath: `${ROUTES.cookiePolicy.path}`,
        linkToPublicPath : `${ROUTES.cookiePolicy.publicPath}`,
    },
]

