import {SimpleItem} from '../../../../item/model/SimpleItem'
import React, {useEffect, useMemo, useState} from 'react'
import {AddItemToExistingList} from './AddItemToExistingListModalView'
import {AddItemToNewList} from './AddItemToNewListModalView'
import CustomModal from '../../../../genericComponents/CustomModal'
import './AddItemToListModal.scss'
import {SearchItem} from '../../../model/SearchItem'
import { IdType } from '../../../../util/models/IdType'

const ItemSearchModal = ({
                             itemList,
                             closeModal,
                         }: {
    closeModal: () => void, itemList: SearchItem[] | SimpleItem[]}) => {
    enum ItemSearchModalStateEnum {
        ADD_TO_EXISTING = 'ADD_TO_EXISTING',
        ADD_TO_NEW = 'ADD_TO_NEW',
        CONFIRMATION = 'CONFIRMATION'
    }

    const [itemModalState, setItemModalState] = useState<ItemSearchModalStateEnum>(ItemSearchModalStateEnum.ADD_TO_EXISTING)

    return (<CustomModal handleClose={closeModal}>
        {itemModalState === ItemSearchModalStateEnum.ADD_TO_EXISTING &&
            <AddItemToExistingList handleSubmit={() => setItemModalState(ItemSearchModalStateEnum.ADD_TO_NEW)}
                                   itemIds={itemList.map(i => i.id)}/>}
        {itemModalState === ItemSearchModalStateEnum.ADD_TO_NEW &&
            <AddItemToNewList handleSubmit={() => setItemModalState(ItemSearchModalStateEnum.ADD_TO_EXISTING)}
                              itemIds={itemList.map(i => i.id)}/>}
    </CustomModal>)
}

export default ItemSearchModal
