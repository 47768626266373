import React from 'react'

export const NewCompanyItemDescription = () => {
    return (
        <div className="step-description">
            <p>
                Company items will be created as private and not visible to the public.
                <br />
                <br />
                Download a template based on the intended use of the company item you would like to create:
                <br />
                <br />
                You may use this{' '}
                <a href="/files/services/New_Grouping_Part_Creation_Template_for_Input_DATX.xlsx">
                    Company Item Data Template
                </a>{' '}
                for an example of how you can upload your parts.
                <br />
            </p>
        </div>
    )
}
