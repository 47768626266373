import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {resetRedirectToLogin, useTryToGetToken} from './AuthHooksAndFunctions'
import {signinRequest} from './authConfig'
import './LoginPage.scss'
import {useMsal} from '@azure/msal-react'
import {LocalStorage} from '../util/localStorage'
import LoadingSpinner from '../genericComponents/LoadingSpinner'

const AutoSignInPage = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const {instance} = useMsal()
    signinRequest.loginHint = LocalStorage.getUserEmail() ?? ''

    useTryToGetToken(
        () => navigate(signinRequest.redirectStartPage ?? '/'),
        () => setLoading(false)
    )

    if (loading) {
        return <LoadingSpinner />
    }

    const startSignIn = () => {
        signinRequest.redirectStartPage = signinRequest.redirectStartPage ?? '/'
        instance
            .loginRedirect(signinRequest)
            .then((_) => {
                resetRedirectToLogin()
            }).catch(() => {
                //Prevent 'interaction_in_progress' msal exception from propagating
            })
    }

    startSignIn()
    return <LoadingSpinner />
}

export default AutoSignInPage
