import {Organization} from '../../model/Organization'
import DisplaySetTitle from '../../../displaySet/DisplaySetTitle'
import {ContactType, getContactTypeLabel} from '../../model/ContactType'
import {Contact} from '../../model/Contact'
import React from 'react'
import {OrganizationFakeDefinition} from './OrganizationFakeDefinition'

export const OrganizationContactFakeDisplaySet = ({organization}: { organization: Organization }) => {
    if (!organization.contacts || organization.contacts.length <= 0)
        return <></>

    return <div className="display-set" data-testid="test-first-level-display-set">
        <DisplaySetTitle title={'Contacts'}/>
        <div className="display-set-content">
            {organization.contacts.map((contact) =>
                <OrganizationFakeDefinition label={getContactTypeLabel(contact.type)}>
                    <ContactDisplay contact={contact}></ContactDisplay>
                </OrganizationFakeDefinition>
            )}
        </div>
    </div>
}

const ContactDisplay = ({contact}: { contact: Contact }) => {
    switch (contact.type) {
        case ContactType.EMAIL:
            return <a className="item-attribute-value website" href={`mailto:${contact.value}`}>{contact.value}</a>
        case ContactType.LINK:
            return <a className="item-attribute-value website" href={contact.value} target="_blank"
                      rel="noreferrer">{contact.value}</a>
        case ContactType.PHONE:
            return <a className="item-attribute-value website" href={`tel:${contact.value}`}>{contact.value}</a>
        default:
            return <span className="item-attribute-value">{contact.value}</span>
    }
}