import {Divider} from '@mui/material'
import React from 'react'
import './SpecificItemSharing.scss'
import {IdType} from '../../../../util/models/IdType'
import {NavigateToSpecificItemsPageButton} from './NavigateToSpecificItemsPageButton'
import {OrganizationItemSharing} from '../../model/OrganizationItemSharing'

const SharingItemListSuccessfully = ({targetGuildName, itemListName, specificItemsShared, itemsOfItemList, targetGuildId, organizationsSharing}: {
    targetGuildName: string,
    itemListName: string | null | undefined,
    specificItemsShared: IdType[]
    itemsOfItemList: IdType[],
    targetGuildId: IdType,
    organizationsSharing: OrganizationItemSharing[]
}) => {

    const itemsInBothList: IdType[] = specificItemsShared.filter(value => itemsOfItemList.includes(value))

    return <div className="page-container itemlist-share-success-page-container">
        <div className="guild-form-title-container">
            <h5 id="shareItemListSuccessTitle" className="page-title">
                Items shared with <span>{targetGuildName}</span>
            </h5>
            <Divider className="divider-title"/>
        </div>
        <div className="add-item-list-sharing-body" data-testid="add-item-list-sharing-message">
            <b>{itemsInBothList.length + '/' + itemsOfItemList.length}</b> items from "{itemListName}" will be
            shared.<br/>
            <p>Data will be updated asynchronously, thank you for your patience.</p>
        </div>
        <div>
            <NavigateToSpecificItemsPageButton
                manageButtonTestId="back-btn"
                organizationsSharing={organizationsSharing}
                targetGuildId={targetGuildId}
                label="Back to Item Sharing" />
        </div>
    </div>

}

export default SharingItemListSuccessfully
