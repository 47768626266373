import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

const NoAutoApproveStatus = ({thresholdApprovalStatus}: {thresholdApprovalStatus: string}) => {
    return (
        <div className="details-form-nbr-quotation">
            <div className="details-value">{thresholdApprovalStatus}</div>
        </div>
    )
}
export default NoAutoApproveStatus
