import {Typography} from '@mui/material'
import './GuildDetails.scss'
import {useTypedDispatch} from '../../../util/store'
import {useGetGuildQuery} from '../../rtkGuildApi'
import {Guild} from '../../models/Guild'
import {setSnackbarMessage} from '../../../genericComponents/commonSlice'
import {useGetOrganizationQuery} from '../../../organization/rtkOrganizationApi'
import {useCurrentGuildContext} from '../../../util/routing'
import { CopyButton } from '../../../genericComponents/CopyButton'

const GuildDetails = () => {
    const [currentGuildContext] = useCurrentGuildContext()
    const dispatch = useTypedDispatch()

    const {data:guild} = useGetGuildQuery(currentGuildContext?.guildId, {skip: !currentGuildContext?.guildId || !currentGuildContext?.guildAdmin })
    const {data:organization} = useGetOrganizationQuery(guild?.defaultOrganization, {skip: !guild?.defaultOrganization})

    const getAdminDisplay = (guild?: Guild) => {
        const adminUsers = guild?.guildUsers.filter((guildUser) => guildUser.admin)
        const adminUserEmails = adminUsers?.map(adminUser => adminUser?.user.email)
        const adminUsersForDisplaying = adminUserEmails?.join(',')

        return adminUsersForDisplaying
    }

    return (
        <div className="guildDetailsMain">
            <Typography variant="h5" className="guildDetailsTitle">
                Guild Details
            </Typography>

            <div className="guild-details">
                <span className="guildId">
                    <label data-testid="guild-details-id">Guild ID: <strong>{guild?.id} </strong></label>
                    <CopyButton toCopy={guild?.id} 
                        onCopySuccess={() => dispatch(setSnackbarMessage('Guild id was successfully copied to your clipboard'))}
                        onCopyFailure={() => dispatch(setSnackbarMessage('Error during the copy of the guild Id'))}
                    />
                </span>
                <label data-testid="guild-details-name">Guild Name: <strong>{guild?.name}</strong></label>
                <label data-testid="guild-details-status">Guild Status: <strong>{guild?.status}</strong></label>
                <label data-testid="guild-details-contractId">Contract ID: <strong>{guild?.contractId}</strong></label>
                <label data-testid="guild-details-contractOwner">Contract Owner: <strong>{guild?.contractOwner}</strong></label>
                <label data-testid="guild-details-guildAdmin" >Guild Admins: <strong>{getAdminDisplay(guild)}</strong></label>
                <label data-testid={`guild-details-linkedOrganization-${organization?.id}`} >Linked Organisation: <strong>{organization?.label}</strong></label>

            </div>
        </div>
    )
}

export default GuildDetails
