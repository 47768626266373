import React from 'react'
import './ChemicalCompositionWidget.scss'
import {Link} from 'react-router-dom'
import {SimpleItem} from '../../../item/model/SimpleItem'
import {ClassificationType} from '../../../item/model/ClassificationType'
import {ItemNatureIcon} from '../../../item/itemNature/ItemNatureIcon'
import ItemTypeChip from '../../../item/ItemTypeChip'
import {AttributeDefinitionModel, AttributeModel, LinkTypeEnum} from '../../model/AttributeModel'
import {useDatxPathGenerator} from '../../../util/routing'
import {ROUTES} from '../../../constants/routing'
import {DisplaySet} from '../../model/DisplaySet'
import {IdType} from '../../../util/models/IdType'
import {DefinitionForDisplaySet} from '../../definitionForDisplaySet/DefinitionForDisplaySet'
import {groupByDefinitionId, sortAttributesByDisplayOrder, sortByDisplayOrder} from '../../utils/sortedDefinitions'


const MAX_QUANTITY_DEF_ID = 'f4febb05-a9eb-56ad-8738-13e67c35a311'
const MIN_QUANTITY_DEF_ID = '808600b3-5b48-5937-b616-084feed4b198'
const UNIT_QUANTITY_DEF_ID = '704b9c6d-e13e-53c2-b945-f343684b328b'

const CurrentItemRow = ({item}: { item: SimpleItem }) => {
    const nature: ClassificationType = {
        label: item.nature?.label ?? '',
        id: item.nature?.id ?? ''
    }

    return <div className="chemical-composition-row current-item-row">
        <p className="cell item-nature"><ItemNatureIcon nature={nature}></ItemNatureIcon></p>
        <p className="cell part-number">{item.partNumber}</p>
        <p className="cell defined-by">{item.definedByOrganization.label}</p>
        <p className="cell name">{item.label}</p>
        <p className="cell item-type"><ItemTypeChip itemTypeLabel={item.type.label}></ItemTypeChip></p>
        <p className="cell min-quantity"> - </p>
        <p className="cell max-quantity"> - </p>
    </div>
}

const CompositionItemRow = ({attribute}: { attribute: AttributeModel }) => {
    const generatePath = useDatxPathGenerator()

    const linkedItem = attribute?.value?.linkedItem
    if (!linkedItem) return <></>

    const nature: ClassificationType = {
        label: linkedItem.nature?.label ?? '',
        id: linkedItem.nature?.id ?? ''
    }

    const minQuantity = attribute.value?.composedByAttributes?.find((subAttr) => subAttr.attributeDefinitionId === MIN_QUANTITY_DEF_ID)?.value?.value ?? ' - '
    const maxQuantity = attribute.value?.composedByAttributes?.find((subAttr) => subAttr.attributeDefinitionId === MAX_QUANTITY_DEF_ID)?.value?.value ?? ' - '
    const unitOfQuantity = attribute.value?.composedByAttributes?.find((subAttr) => subAttr.attributeDefinitionId === UNIT_QUANTITY_DEF_ID)?.value?.value ?? ''

    return <div className="chemical-composition-row composition-item-row">
        <p className="cell item-nature"><ItemNatureIcon nature={nature}></ItemNatureIcon></p>
        <Link to={generatePath(ROUTES.detailItem.path, {itemId: linkedItem.id})} className="cell part-number">
            {linkedItem.partNumber}
        </Link>
        <p className="cell defined-by">{linkedItem.definedByOrganization.label}</p>
        <p className="cell name">{linkedItem.label}</p>
        <p className="cell item-type"><ItemTypeChip itemTypeLabel={linkedItem.type.label}></ItemTypeChip></p>
        <p className="cell min-quantity">{minQuantity} {unitOfQuantity}</p>
        <p className="cell max-quantity">{maxQuantity} {unitOfQuantity}</p>
    </div>
}

export const ChemicalCompositionWidget = ({displaySet, parentItem}: { displaySet: DisplaySet, parentItem?: SimpleItem }) => {
    const attributes = displaySet.attributes
    const chemicalLinkAttributes = attributes.filter((attr) => attr.attributeDefinitionType?.linkType === LinkTypeEnum.ITEM_CHEMICAL_COMPOSITION)
    const sortedChemicalLinkAttributes = sortAttributesByDisplayOrder(chemicalLinkAttributes)

    const otherAttributes = attributes.filter((attr) => attr.attributeDefinitionType?.linkType !== LinkTypeEnum.ITEM_CHEMICAL_COMPOSITION)
    const otherDefinitionsMap: Map<IdType, AttributeDefinitionModel> = groupByDefinitionId(otherAttributes)
    const sortedOtherDefinitions = sortByDisplayOrder([...otherDefinitionsMap.values()])


    return <>
        {sortedChemicalLinkAttributes && sortedChemicalLinkAttributes.length > 0 &&
            <div className="chemical-composition-widget">
                <header className="chemical-composition-header">
                    <label className="cell item-nature">Item Nature</label>
                    <label className="cell part-number">Part Number</label>
                    <label className="cell defined-by">Defined By</label>
                    <label className="cell name">Name</label>
                    <label className="cell item-type">Item Type</label>
                    <label className="cell min-quantity">Min Quantity</label>
                    <label className="cell max-quantity">Max Quantity</label>
                </header>

                <div className="chemical-composition-items">
                    {parentItem && <CurrentItemRow item={parentItem}></CurrentItemRow>}
                    {sortedChemicalLinkAttributes.flatMap((attr) => <CompositionItemRow attribute={attr}
                                                                                        key={attr.id}></CompositionItemRow>)}
                </div>
            </div>}

        {sortedOtherDefinitions.map(definition => <DefinitionForDisplaySet definition={definition}
                                                                           key={definition.attributeDefinitionId}></DefinitionForDisplaySet>)}
    </>
}