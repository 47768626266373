import {ServiceRequestChoiceCard} from '../genericComponents/ServiceRequestChoiceCard'
import {ServiceRequestFormTitle} from '../genericComponents/ServiceRequestFormTitle'
import React, {useState} from 'react'
import {ServiceRequestTemplate} from '../genericComponents/structure/ServiceRequestTemplate'
import {ServiceRequestHeader} from '../genericComponents/structure/ServiceRequestHeader'
import {ServiceRequestBody} from '../genericComponents/structure/ServiceRequestBody'
import {useCurrentGuildContext, useDatxPathGenerator} from '../../../util/routing'
import {ROUTES} from '../../../constants/routing'
import './NewItemServiceRequestForm.scss'
import ItemTypeChip from '../../../item/ItemTypeChip'
import {ItemTypeEnum} from '../../../item/model/ItemType'
import {useGetGuildQuery} from '../../../guild/rtkGuildApi'
import {getServiceName, ServiceEnum} from '../../model/ServiceEnum'

export const SelectNewItemRequestType = () => {
    const generatePath = useDatxPathGenerator()
    const [currentGuildContext] = useCurrentGuildContext()
    const {data:guild} = useGetGuildQuery(currentGuildContext?.guildId, {skip: !currentGuildContext?.guildId || !currentGuildContext?.guildAdmin })

    return <ServiceRequestTemplate>

        <ServiceRequestHeader>
            <ServiceRequestFormTitle title="Request New Item Creation"
                                     previousUrl={generatePath(ROUTES.servicesCatalogue.path)}/>
        </ServiceRequestHeader>

        <div className="step-description">
            Select the type of item you want to create in DATX:
        </div>

        <ServiceRequestBody className="service-request-choice-card-container">
            <ServiceRequestChoiceCard id={`${getServiceName(ServiceEnum.NEW_MANUFACTURER_ITEMS)}`}
                                      icon={<ItemTypeChip itemTypeLabel={ItemTypeEnum.MANUFACTURER}/>}
                                      description="Items in the catalogue of a Manufacturer"
                                      to={generatePath(ROUTES.newManufacturerPartsServiceForm.path)}/>
            <ServiceRequestChoiceCard id={`${getServiceName(ServiceEnum.NEW_COMPANY_ITEMS)}`}
                                      icon={<ItemTypeChip itemTypeLabel={ItemTypeEnum.COMPANY}/>}
                                      description="Items that represent a Manufacturer item with your own company identification"
                                      to={generatePath(ROUTES.newCompanyPartsServiceForm.path)}
                                      disabled={{
                                                  isDisabled:!guild?.defaultOrganization || !currentGuildContext?.guildId,
                                                  reason:'To create Company item, your guild admin needs to contact DATX to associate an organization to your guild.'
                                                }}
            />
        </ServiceRequestBody>
    </ServiceRequestTemplate>
}