import {IconDisplaySVG} from '../../genericComponents/IconDisplaySVG'
import {ClassificationType} from '../model/ClassificationType'

import {ReactComponent as ChemicalSubstance} from './images/Chemical substance.svg'
import {ReactComponent as Process} from './images/Process.svg'
import {ReactComponent as Goods} from './images/goods.svg'
import {ReactComponent as Hardware} from './images/repair-service 1.svg'
import {ReactComponent as Raw} from './images/Raw.svg'
import {ReactComponent as Mixture} from './images/mixture.svg'
import {ReactComponent as Software} from './images/software.svg'
import {ReactComponent as DefaultFallback} from './images/default-fallback.svg'

const itemImageMap = new Map([
    ['CHEMICAL SUBSTANCE', ChemicalSubstance],
    ['PROCESS', Process],
    ['GOODS', Goods],
    ['HW', Hardware],
    ['HARDWARE', Hardware],
    ['HW COMPONENT', Hardware],
    ['HARDWARE COMPONENT', Hardware],
    ['HARDWARE ASSEMBLY', Hardware],
    ['RAW', Raw],
    ['RAW MATERIAL', Raw],
    ['MIXTURE', Mixture],
    ['CHEMICAL MIXTURE', Mixture],
    ['SW', Software],
    ['SOFTWARE', Software],
    ['default', DefaultFallback]
])

export const ItemNatureIcon = ({nature}: { nature: ClassificationType | undefined }) => {
    //map item image based on classification type
    return <IconDisplaySVG
        className="item-card-image"
        iconName={nature?.label}
        iconSourceMap={itemImageMap}
        defaultIcon={DefaultFallback}
    />
}
