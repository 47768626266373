import {FormControl} from '@mui/material'
import React from 'react'
import {LoadingButton} from '@mui/lab'
import {
    generateDownloadableExcelFile,
    generateTemplateFileName,
    getCommonHeader,
    getCommonItemsForExcelForCreation
} from '../../model/PrivateAttributeExcelFileUtil'
import {useFormikContext} from 'formik'
import {AttributeStringUpdateForm} from '../PrivateAttributeUpdate'
import {useGetItemListAllPagesQuery} from '../../../../../itemList/rtkItemListApi'
import {useCurrentGuildContext} from '../../../../../util/routing'
import LoadingSpinner from '../../../../../genericComponents/LoadingSpinner'
import {Label} from '../../../../../genericComponents/typographie/Label'
import {Description} from '../../../../../genericComponents/typographie/Description'
import {ServiceRequestStep} from '../../../genericComponents/formTemplate/steps/ServiceRequestStep'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import {useGetPrivateValuesForItemQuery} from '../../../../../search/rtkSearchApi'
import * as XLSX from 'xlsx'
import {SearchItem} from '../../../../../search/model/SearchItem'

export const DownloadTemplateStringUpdate = () => {
    const formik = useFormikContext<AttributeStringUpdateForm>()
    const [currentGuildContext] = useCurrentGuildContext()

    const privateDefinition = formik?.values?.privateDefinition
    const itemListLite = formik?.values?.itemList
    const fileName = generateTemplateFileName(itemListLite?.name, privateDefinition?.name)

    const {data: itemListFull, isLoading: itemListFullIsLoading} = useGetItemListAllPagesQuery(
        {
            listId: itemListLite?.id
        },
        {skip: !currentGuildContext?.guildId || !itemListLite})

    const {data: privateValues = [], isLoading: privateValuesAreLoading} = useGetPrivateValuesForItemQuery({
        itemUuids: itemListFull?.elements?.map((it: SearchItem) => it?.id),
        guildId: currentGuildContext?.guildId,
        attributeDefinition: privateDefinition?.id
    }, {
        skip: !currentGuildContext?.guildId || !privateDefinition?.id
            || !itemListFull?.elements
            || itemListFull?.elements?.length <= 0
    })


    if (!privateDefinition || !itemListLite)
        return <div className="service-request-form-step">
            <p className="service-request-step-desription">
                Private Attribute or ItemList are missing, please fill them to generate the template file
            </p>
        </div>


    const retrievePrivateValue = (str: string | number): string => {
        const matchingPrivateValue = privateValues.find(value => value.itemId === str)
        return (matchingPrivateValue?.attributeValues && matchingPrivateValue?.attributeValues[0]) ?? ''
    }

    const generateUpdateTemplate = (privateDefinitionName: string | undefined) => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        const fileExtension = '.xlsx'

        let headers = getCommonHeader()
        let itemsForExcel = getCommonItemsForExcelForCreation(itemListFull?.elements ?? [])

        // Add Updating column
        headers = [
            [...headers[0], 'PRIVATE_ATTRIBUTE'],
            [...headers[1], `${privateDefinitionName} (currentValue)`, `${privateDefinitionName}(Enter New Value Here)`]
        ]
        // Add private Values for the two specific column for updating
        itemsForExcel = itemsForExcel.map(row => [...row, retrievePrivateValue(row[1])])
        itemsForExcel = itemsForExcel.map(row => [...row, retrievePrivateValue(row[1])])


        const ws = XLSX.utils.aoa_to_sheet([...headers, ...itemsForExcel])

        // Write to workbook
        generateDownloadableExcelFile(ws, fileType, fileExtension, fileName)
    }

    return <ServiceRequestStep>
        <Description className="service-request-step-description">
            The data template below has been generated based on your selection in Step 1.<br/>
            Update the relevant values into the column for your specified attribute and proceed
            Step 3
        </Description>

        {(itemListFullIsLoading || privateValuesAreLoading) ?
            <div className="service-request-form-step">
                <LoadingSpinner></LoadingSpinner>
            </div>
            : <FormControl className="download-upload-template-container">
                <Label className="file-download-upload-label">Generated template based on your
                    selection:</Label>
                <LoadingButton sx={{marginTop: '16px'}} onClick={() => generateUpdateTemplate(privateDefinition?.name)} variant="outlined"
                               className="download-template-button"
                               color="primary"
                               startIcon={<DownloadOutlinedIcon/>}
                               data-testid="download-template">{`${fileName}.xlsx`}</LoadingButton>
            </FormControl>
        }

    </ServiceRequestStep>
}
