import {Box} from '@mui/material'
import React from 'react'
import {getItemType, ItemType, ItemTypeEnum} from '../../../../../item/model/ItemType'
import {SearchItem} from '../../../../model/SearchItem'

export const ItemTypeIcon = ({item}: { item: SearchItem }) => {
    const itemType = getItemType(item)
    return <Box className={'item-type-icon ' + getItemTypeClass(itemType)}>
        {itemType.icon}
    </Box>
}

const getItemTypeClass = (type: ItemType) =>{
    switch(type.type){
        case ItemTypeEnum.MANUFACTURER: 
            return 'manufacturer'
        case ItemTypeEnum.COMPANY:
            return 'company'
        case ItemTypeEnum.ABSTRACT:
            return 'abstract'
        default: 
            return ''
    }
}