import {Button, Skeleton} from '@mui/material'
import './SearchFilters.scss'
import '../searchFilterCard/SearchFilterCard.scss'
import React from 'react'

import {ApplyFilterDTO, Search} from '../model/SearchFilter'
import SearchFilterCard from '../searchFilterCard/SearchFilterCard'
import {createSearchParams, useNavigate, useSearchParams} from 'react-router-dom'
import {useGetDisplayFiltersQuery} from '../rtkSearchApi'
import {IdType} from '../../util/models/IdType'
import {Form, Formik, FormikState} from 'formik'
import {availableItemTypes} from '../model/ItemType'
import ItemTypeFilter from './ItemTypeFilter'

interface FilterFormFormat {
    definitions: {
        [x: IdType]: string[]
    },
    organizations: IdType[],
    itemTypes : string[]
}

const generateDefinitionMap = (selectedDefinitionFilters: ApplyFilterDTO[]): Map<IdType, string[]> => {
    return selectedDefinitionFilters.reduce<Map<IdType, string[]>>((accumulator, current) => {
        accumulator.set(current.attributeDefinitionId,
            current.values.map(value => returnValueOrReturnConvertedBooleanValue(value)))
        return accumulator
    }, new Map())
}

const returnValueOrReturnConvertedBooleanValue = (value : string) => {
    switch (value) {
        case '"false"':
        case '"true"':
            return JSON.parse(value)
        case 'false':
        case 'true':
            return JSON.stringify(value)
        default:
            return value
    }
}

export const checkIfSameAttributeDefinitionAndTheirValuesSelected = (selectedDefinitions: { [x: IdType]: string[] }, search: Search) => {
    const selectedAttributeDefinitionIds = Object.keys(selectedDefinitions)
    const notEmptySelectedDefinitions = selectedAttributeDefinitionIds.filter(definitionId => (selectedDefinitions[definitionId]?.length > 0) ?? false)
    const currentAttributeDefinitionIds = search.filters?.map(currentDefinition => currentDefinition.attributeDefinitionId) ?? []
    const attributeDefinitionIdsIdentical = areArraysIdentical(notEmptySelectedDefinitions,currentAttributeDefinitionIds)

    if(!attributeDefinitionIdsIdentical) return false

    return notEmptySelectedDefinitions.every(definition => {
        const definitionFiltersValues = selectedDefinitions[definition]
        const definitionFiltersValuesCurrentlySelected = search.filters
            ?.filter(currentDefinition => currentDefinition.attributeDefinitionId === definition) // find the same definition
            ?.flatMap(filter => filter.values) ?? [] // get the values of the definition
        return areArraysIdentical(definitionFiltersValues,definitionFiltersValuesCurrentlySelected)
    })
}

export const areArraysIdentical = (arr1: string[], arr2: string[]): boolean => {
    if(arr1.length !== arr2.length) return false

    const sortedArr1 = [...arr1].sort()
    const sortedArr2 = [...arr2].sort()

    return sortedArr1.every((value, index) => value === sortedArr2[index])
}

const SearchFilters = ({search}: { search: Search }) => {
    const {filters: selectedDefinitionFilters,
        organizationFilter: selectedOrganizationFilter,
        itemTypeFilter : selectedItemTypeFilter} = search
    const {data: allDisplayFilters, isFetching} = useGetDisplayFiltersQuery({...search, filters: []})
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const selectedValuesByDefinition = generateDefinitionMap(selectedDefinitionFilters ?? [])
    const getCurrentDefinitionFilters = (definitions: { [x: IdType]: string[] }) => {
        return Object.entries(definitions)
            .filter(definitionSet => definitionSet[1].length > 0)
            .map(definitionSet => ({
                attributeDefinitionId: definitionSet[0],
                values: definitionSet[1].map(value => returnValueOrReturnConvertedBooleanValue(value))
            }))
    }




    const applySearchOption = ({
                                   definitions: selectedDefinitions,
                                   organizations: selectedOrganizationIds,
                                   itemTypes : selectedItemTypes
                               }: FilterFormFormat) => {


        // When applying a new filter, the page should be resetted to 1.
        searchParams.set('page', JSON.stringify(1))

        const definitionFilters = getCurrentDefinitionFilters(selectedDefinitions)
        const organizations = {
            organizationIds: selectedOrganizationIds
        }
        const itemTypeFilters = {
            itemTypes: selectedItemTypes
        }

        if (selectedOrganizationIds?.length > 0)
            searchParams.set('organizationFilter', JSON.stringify(organizations))
        else searchParams.delete('organizationFilter')

        if (selectedItemTypes?.length >0)
            searchParams.set('itemTypeFilter', JSON.stringify(itemTypeFilters))
        else searchParams.delete('itemTypeFilter')

        if (definitionFilters?.length > 0)
            searchParams.set('filters', JSON.stringify(definitionFilters))
        else searchParams.delete('filters')

        navigate({
            search: `?${searchParams}`,
        })
        return
    }

    const resetFilters = (resetForm: (nextState?: (Partial<FormikState<FilterFormFormat>>)) => void) => {
        resetForm()
        navigate({
            search:  search.phrase?.phraseDetail ? `?phrase=${search.phrase?.phraseDetail}`: createSearchParams({}).toString(),
        })
    }



    const isSelectedAndCurrentFiltersIdentical = ({definitions, organizations,itemTypes}: FilterFormFormat): boolean => {
        const isSameOrganizationFilterSelected = areArraysIdentical(organizations,search.organizationFilter?.organizationIds??[])
        const isSameDefinitionsSelected = checkIfSameAttributeDefinitionAndTheirValuesSelected(definitions,search)
        const isSameItemTypes = areArraysIdentical(itemTypes,search.itemTypeFilter?.itemTypes ??[])
        return isSameDefinitionsSelected && isSameOrganizationFilterSelected && isSameItemTypes
    }
    const anyFilterSelected = ({definitions, organizations, itemTypes}: FilterFormFormat): boolean => {
        const allDefinitionFiltersValues = Object.values(definitions).flat()
        return allDefinitionFiltersValues?.length > 0 || organizations?.length > 0  || itemTypes?.length> 0
    }

    return <>
        {
            isFetching ? <div className="content-filter">
                    <Skeleton variant="rounded" className="search-filter-skeleton"/>
                    <Skeleton variant="rounded" className="search-filter-skeleton"/>
                    <Skeleton variant="rounded" className="search-filter-skeleton"/>
                    <Skeleton variant="rounded" className="search-filter-skeleton"/>
                    <Skeleton variant="rounded" className="search-filter-skeleton"/>
                </div> :
                (!allDisplayFilters) ? '' :
                    <Formik
                        enableReinitialize
                        initialValues={{
                            definitions: Object.fromEntries(selectedValuesByDefinition),
                            organizations: selectedOrganizationFilter?.organizationIds ?? [],
                            itemTypes : selectedItemTypeFilter?.itemTypes ?? []
                        }}
                        onSubmit={applySearchOption}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validateOnMount={false}
                    >{({
                           values,
                           resetForm
                       }) => (
                        <Form>
                            <div className="content-filter">
                                {
                                    allDisplayFilters?.organizationFilter &&
                                        <SearchFilterCard 
                                            filter={allDisplayFilters.organizationFilter}
                                            shouldGetId={true}
                                            name="organizations"
                                        />
                                }
                                {
                                    allDisplayFilters?.definitionFilters?.map(definitionFilter => (
                                        <SearchFilterCard 
                                            key={definitionFilter.id} 
                                            filter={definitionFilter}
                                            name={'definitions.' + definitionFilter.id}
                                        />
                                    ))
                                }
                            </div>

                            <div className="item-type-filters">
                                {availableItemTypes.map(itemType =>
                                    <ItemTypeFilter key={itemType} itemType={itemType} className={'item-type-filter ' + (values.itemTypes?.includes(itemType) ? 'selectedLabel' : 'unselectedLabel')}/>
                                )}
                            </div>

                            <div className="action-button-filter">
                                <Button 
                                    variant="text" 
                                    onClick={() => resetFilters(resetForm)}
                                    type="button"
                                    data-testid="reset-search-filters"
                                    disabled={!anyFilterSelected(values)}
                                >
                                    Reset All
                                </Button>
                                <Button 
                                    variant="contained" 
                                    color="primary"
                                    data-testid="apply-search-filters"
                                    type="submit" 
                                    disabled={isSelectedAndCurrentFiltersIdentical(values)}
                                >
                                    Apply Filter
                                </Button>
                            </div>
                        </Form>
                    )
                    }
                    </Formik>
        }
    </>
}
export default SearchFilters
