import {createSlice} from '@reduxjs/toolkit'
import {getItemDocument} from './itemAsyncActions'

export type ItemStateType = {
    itemDocument: {
        isLoading: boolean,
        error: boolean
    }
}

const initialState: ItemStateType = {
    itemDocument: {
        isLoading: false,
        error: false
    }
}

const itemSlice = createSlice({
    name: 'item',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getItemDocument.pending, (state: ItemStateType, action) => {
                state.itemDocument.isLoading = true
                state.itemDocument.error = false
            })
            .addCase(getItemDocument.fulfilled, (state: ItemStateType, action) => {
                state.itemDocument.isLoading = false
                state.itemDocument.error = false
            })
            .addCase(getItemDocument.rejected, (state: ItemStateType, action) => {
                state.itemDocument.isLoading = false
                state.itemDocument.error = true
            })
    }
})

export default itemSlice.reducer