import React from 'react'

    export const ShareItemListMessage = ({ itemListName }: { itemListName: string | null }) => {
        return (
            <div className="share-item-list-dialog-text">
                <p>
                    Are you sure that you want to share the following list with the guild?
                </p>
                <p>
                    List name: <label className="bold-text">{itemListName} </label>
                </p>
                <p>
                    By doing so, members of the guild will have access to this list to make service requests.
                    They will not be able to edit the list.
                </p>
            </div>
        )
    }

    export const UnShareItemListMessage = ({ itemListName }: { itemListName: string | null }) => {
        return (
            <div className="share-item-list-dialog-text">
                <p>
                    Are you sure that you want to stop sharing the following list with the guild?
                </p>
                <p>
                    List name: <label className="bold-text">{itemListName} </label>
                </p>
                <p>
                    By doing so, members of the guild will no longer have access to this list to make service requests.
                </p>
            </div>
        )
    }
