import React from 'react'

import {ArtifactAttribute} from '../ArtifactAttribute'
import './CadModelAttribute.scss'
import {SimpleAttribute} from '../SimpleAttribute'
import {findAttributeByDefinitionId} from '../../utils/common'
import {AttributeModel} from '../../model/AttributeModel'


const CAD_MODEL_NAME = '75e53d74-7bde-542d-905a-12bce7763c9a'
const CAD_MODEL_VERSION = '91c8a2c4-dd9a-5788-a618-bb20b78cf031'
const CAD_MODEL_TOOL_VERSION = 'c54357f4-8b12-51f7-a4dd-fbb09e72949d'
const CAD_MODEL_PARTICULARITIES = '42e243d9-c363-527d-b216-c8f370c9c9df'

export const CadModelAttribute = ({attribute}: { attribute: AttributeModel }) => {
    const documentAttributes = attribute.value.document?.attributes ?? []
    const name = findAttributeByDefinitionId(documentAttributes, CAD_MODEL_NAME)?.value?.value
        ?? attribute?.value?.document?.name

    const versionAttr = findAttributeByDefinitionId(documentAttributes, CAD_MODEL_VERSION)
    const toolVersionAttr = findAttributeByDefinitionId(documentAttributes, CAD_MODEL_TOOL_VERSION)
    const particularitiesAttr = findAttributeByDefinitionId(documentAttributes, CAD_MODEL_PARTICULARITIES)

    if (!name) return <ArtifactAttribute attribute={attribute}></ArtifactAttribute>

    return <div className="cad-model-attribute">
        <h3 className="cad-model-title">{name}</h3>

        <div className="cad-model-body display-set-attributes">

            {versionAttr && <div className="cad-model-row display-set-definition">
                <span className="definition-name">Model version</span>
                <SimpleAttribute attribute={versionAttr}></SimpleAttribute>
            </div>}

            {toolVersionAttr && <div className="cad-model-row display-set-definition">
                <span className="definition-name">Tool version</span>
                <SimpleAttribute attribute={toolVersionAttr}></SimpleAttribute>
            </div>}

            {particularitiesAttr && <div className="cad-model-row display-set-definition">
                <span className="definition-name">Description</span>
                <SimpleAttribute attribute={particularitiesAttr}></SimpleAttribute>
            </div>}

            <div className="cad-model-row display-set-definition">
                <span className="definition-name">Model</span>
                <ArtifactAttribute attribute={attribute}></ArtifactAttribute>
            </div>

        </div>
    </div>
}
