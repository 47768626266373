 import {WorkflowConfiguration} from './model/WorkflowConfiguration'
import ServiceWorkflowConfigurationDetails from './ServiceWorkflowConfigurationDetails'
import {Member} from '../models/Member'

const ServiceWorkflowConfiguration = ({ workflowConfiguration, guildUsers }: {
    workflowConfiguration: WorkflowConfiguration,
    guildUsers: Member[]
}) => {

    return (
        <ServiceWorkflowConfigurationDetails
            workflowConfiguration={ workflowConfiguration }
            guildUsers={guildUsers} />
    )
}

export default ServiceWorkflowConfiguration
