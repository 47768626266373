import {AccordionTemplate} from './AccordionTemplate'
import {CookiesCategory, CookiesDialogType, CookieVendor, Vendor, VendorType} from '../../CookiesTypes'
import {ChangeEvent} from 'react'
import {Button} from '@mui/material'
import {AccordionSwitch} from './AccordionSwitch'

export const CategoryCookieAccordion = ({
                                            category,
                                            type,
                                            description,
                                            vendorDetails,
                                            handleSwitch,
                                            checkedSwitch,
                                            openVendorsDialog,
                                            inCategoryDialog,
                                        }: {
    category: CookiesCategory
    type: CookieVendor
    description?: string,
    vendorDetails: VendorType[] | Vendor[],
    handleSwitch: (event: ChangeEvent<HTMLInputElement>) => void,
    checkedSwitch: boolean,
    openVendorsDialog: (dialogType: CookiesDialogType, category: CookiesCategory) => void,
    inCategoryDialog: boolean,
}) => {

    return <AccordionTemplate
        category={category}
        title={category}
        toggleSwitch={<AccordionSwitch inCategoryDialog={inCategoryDialog} type={type} category={category}
                                       handleSwitch={handleSwitch} checked={checkedSwitch}/>}
    >
        <div>{description}</div>
        {vendorDetails.length > 0 &&
            <Button className="view-vendor-details" data-testid={`view-vendor-details-${category}`}
                    onClick={() => openVendorsDialog(CookiesDialogType.VendorsDialog, category)}>View Vendor
                Details</Button>}
    </AccordionTemplate>
}