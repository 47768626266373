import {Divider, Paper} from '@mui/material'
import {Link} from 'react-router-dom'
import {ROUTES} from '../constants/routing'
import './Footer.scss'
import {useDatxPathGenerator} from '../util/routing'
import {CookiesAlert} from '../Cookies/CookiesAlert/CookiesAlert'

const Footer = () => {

    const generatePath = useDatxPathGenerator()

    return (
        <>
            <CookiesAlert></CookiesAlert>
            <Paper
                square={false}
                elevation={5}
                className="footer"
            >
                <div>
                    DATX - Copyright © Thales {new Date().getFullYear()}
                </div>
                <div className="footer-links">
                    <Link className="footer-link" to={generatePath(ROUTES.termsOfUse.path)}>Terms of Use</Link>
                    <Divider className="divider"/>

                    <Link className="footer-link" to={generatePath(ROUTES.termsOfSales.path)}>Terms of Sales</Link>
                    <Divider className="divider"/>

                    <Link className="footer-link" to={generatePath(ROUTES.legalNotices.path)}>Legal Notice</Link>
                    <Divider className="divider"/>

                    <Link className="footer-link" to={generatePath(ROUTES.privacyPolicy.path)}>Privacy Policy</Link>
                    <Divider className="divider"/>

                    <Link className="footer-link" to={generatePath(ROUTES.cookiePolicy.path)}>Cookie Policy</Link>
                </div>
            </Paper>
        </>
    )
}

export default Footer
