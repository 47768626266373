import {NOTIFICATION_CATEGORIES} from '../constants'
import './NotificationMessages.scss'
import {Notification} from '../../../models/Notification'
import React, {ReactNode} from 'react'
import {NotificationParameter} from '../../../models/NotificationAction'
import getUserServiceType from '../../../../service/model/UserServiceRequestType'

export const NotificationMessage = ({ notification }: { notification: Notification }) => {
    const { category, content, parameters } = notification
    const { guildCreationApproved, guildCreationDenied, guildUserInvitation, serviceRequestCompleted, serviceRequestCreated,serviceRequestQuotationUpdate } =
        NOTIFICATION_CATEGORIES
    const categoryMap = new Map([
        [guildCreationApproved, <GuildCreationAccepted guildName={content} />],
        [guildCreationDenied, <GuildCreationDenied guildName={content} />],
        [guildUserInvitation, <GuildInvitationRequest content={content} />],
        [serviceRequestCompleted, <ServiceRequestDelivered content={content} parameters={parameters} />],
        [serviceRequestCreated, <ServiceRequestCreated content={content} parameters={parameters} />],
        [serviceRequestQuotationUpdate, <ServiceRequestQuotationUpdate content={content} parameters={parameters} />],
    ])

    const notificationMessageComponent = categoryMap.get(category) ?? <DefaultRequest content={content} />
    return notificationMessageComponent
}

const ServiceRequestDelivered = ({ content, parameters }: { content: string, parameters: NotificationParameter[] }) => {
    return (
        <>
            {
                parameters?.map((param: NotificationParameter) => <span className="notification-parameter"><label className="bold-text">{param.label}:</label><p>{param.value}</p></span>) ?? null
            }
            <span className="notification-parameter"><label className="bold-text">Status:</label><p className="guild-creation-approved">Delivered</p></span>
            <p>The service you ordered has been delivered by the DATX team.</p>
        </>
    )
}

const ServiceRequestCreated = ({ content, parameters }: { content: string, parameters: NotificationParameter[] }) => {
    return (
        <>
            {
                parameters?.map((param: NotificationParameter) => <span className="notification-parameter"><label className="bold-text">{param.label}:</label><p>{param.value}</p></span>) ?? null
            }
            <span className="notification-parameter"><label className="bold-text">Status:</label><p className="service-request-creation">Requested</p></span>
        </>
    )
}

const ServiceRequestQuotationUpdate = ({ content, parameters }: { content: string, parameters: NotificationParameter[] }) => {
    return (
        <>
            {
                parameters?.map((param: NotificationParameter) => <span className="notification-parameter"><label className="bold-text">{param.label}:</label><p style={{color:getUserServiceType(param.value)?.color}}>
                    {getUserServiceType(param.value).label!=='N/A'? getUserServiceType(param.value).label:param.value}
                </p></span>) ?? null
            }
            <br />
            Your request for part matching service has received a quotation from the DATX team.
        </>
    )
}

const GuildCreationAccepted = ({ guildName }: { guildName: string }) => {
    return (
        <>
            Your request to <label className="bold-text">Create Guild: {guildName} </label> has been{' '}
            <span className="guild-creation-approved">approved</span>.
            <br />
            You can now access this guild by clicking on your profile and select
            the guild context.
        </>
    )
}

const GuildCreationDenied = ({ guildName }: { guildName: string }) => {
    return (
        <>
            Your request to <label className="bold-text">Create Guild: {guildName} </label> has been{' '}
            <span className="guild-creation-denied">rejected</span>.
        </>
    )
}



const GuildInvitationRequest = ({ content }: { content: ReactNode }) => {
    return <>{content}</>
}

const DefaultRequest = ({ content }: { content: ReactNode }) => {
    return <>{content}</>
}

