import {Grid} from '@mui/material'
import ClassificationCard from './ClassificationCard'
import React from 'react'
import './ClassificationComponentInPhraseSearchPage.css'
import LoadingSpinner from '../../../../genericComponents/LoadingSpinner'
import {SearchClassificationsResultDTO} from '../../../model/SearchClassificationsResultDTO'
import {NoData} from '../../../../genericComponents/NoData'
import {useGetDatxClassificationTreeQuery} from '../../../rtkSearchApi'

export const ClassificationComponentInPhraseSearchPage = ({searchResult, isFetching}: {
    searchResult: SearchClassificationsResultDTO[] | undefined,
    isFetching: boolean,
}) => {
    const {data: classificationTree, isFetching: isFetchingClassificationTree} = useGetDatxClassificationTreeQuery()

    if (isFetching || isFetchingClassificationTree) return <LoadingSpinner/>

    if (!searchResult || searchResult?.length === 0) return <NoData message="No classification found"/>

    return <Grid container spacing={2} padding={3} className="classification-filter-container">
        {
            searchResult.map(classificationNode => <ClassificationCard
                key={`classification-card-${classificationNode.classification.id}`}
                classification={classificationNode.classification}
                numberOfItems={classificationNode.numberOfItems}
                classificationTree={classificationTree}
                data-testid={`classification-card-${classificationNode.classification.id}`}
            />)
        }
    </Grid>


}