import {CustomCard} from '../../../genericComponents/customCard/CustomCard'
import {getItemType, ItemType, ItemTypeEnum} from '../../../item/model/ItemType'
import Highlighter from 'react-highlight-words'
import {SearchItem} from '../../model/SearchItem'
import {ROUTES} from '../../../constants/routing'
import {useDatxPathGenerator} from '../../../util/routing'
import {HeaderLinkContent} from '../../../item/itemDetails/itemDetailsHeader/ItemDetailsHeaderContent'
import ItemTypeChip from '../../../item/ItemTypeChip'
import {ItemNatureIcon} from '../../../item/itemNature/ItemNatureIcon'
import AddToListButton from '../../../genericComponents/AddToListButton'


export const SearchItemCard = ({
                                   item,
                                   onSelect,
                                   isSelected,
                                   handleAddSingleItemToList,
                                   highlightPhrase,
                                   displaySelectionCheckbox
                               }: {
    item: SearchItem,
    onSelect: (item: SearchItem, checked: boolean) => void,
    isSelected?: boolean,
    handleAddSingleItemToList: (item: SearchItem) => void,
    highlightPhrase?: string | null,
    displaySelectionCheckbox: boolean

}) => {
    const generatePath = useDatxPathGenerator()
    const itemType = getItemType(item)
    const partNumber = (
        <>
            <span className="label">Part Number</span>
            <span className="value" title={item.partNumber}>
                <Highlighter
                    searchWords={[highlightPhrase || '']}
                    autoEscape={true}
                    highlightClassName	={'match-element'}
                    textToHighlight={item.partNumber}
                />
            </span>
        </>
    )

    const definedBy = (
        <span className="value"
              title={item.definedByOrganization.label}>
                        <HeaderLinkContent className="defined-by" newTab={true}
                                           value={item.definedByOrganization.label}
                                           path={generatePath(ROUTES.organizationDetails.path, { organizationId: item.definedByOrganization.id })}
                                           label={itemType.label}/>
        </span>
    )

    const bodyContent =
        (
            <>
                <div className="tile-container">
                    <div className="item-info">
                        <div className="image-container">
                            <ItemNatureIcon nature={item.nature}/>
                        </div>
                        <div className="part-number-and-defined-by-container">
                            {partNumber}
                            {definedBy}
                        </div>
                    </div>
                </div>
                <div className="tile-container">
                    <div className="item-info centered">
                        <span className="label">Name</span>
                        <span className="value" title={item.label ?? '-'}>
                            <Highlighter
                                searchWords={[highlightPhrase || '']}
                                autoEscape={true}
                                textToHighlight={item.label ?? '-'}
                                highlightClassName={'match-element'}

                            />
                        </span>
                    </div>
                    <div className="item-info centered">
                        <span className="label">DATX ID</span>
                        <span className="value" title={item.id}>
                                 <Highlighter
                                     searchWords={[highlightPhrase || '']}
                                     autoEscape={true}
                                     textToHighlight={ item.id ?? '-'}
                                     highlightClassName	={'match-element'}
                                 />
                        </span>
                    </div>
                    <div className="item-info centered">
                        <HeaderLinkContent label="Technical Classification" className="technical-classification"
                                           value={item.technical?.label}
                                           path={generatePath(ROUTES.searchByClassificationNodeDescendants.path, { classificationId: item.technical?.id })}
                        />
                    </div>
                </div>
            </>
        )
      
    return (
        <CustomCard
            id={item.id}
            name={item.partNumber}
            checked={!!isSelected}
            handleChange={(id, checked) => onSelect(item, checked)}
            actionList={<AddToListButton onClick={() => handleAddSingleItemToList(item)}/>}
            cssClass={getItemTypeClass(itemType)}
            typeIcon={getItemTypeIcon(itemType)}
            bodyContent={bodyContent}
            displaySelectionCheckbox={displaySelectionCheckbox}
        />
    )
}

const getItemTypeClass = (type: ItemType) =>{
    switch(type.type){
        case ItemTypeEnum.MANUFACTURER: 
            return 'manufacturer'
        case ItemTypeEnum.COMPANY:
            return 'company'
        case ItemTypeEnum.ABSTRACT:
            return 'abstract'
        default: 
            return ''
    }
}

const getItemTypeIcon = (type : ItemType) =>{
    return (
    <div className="pre-header-information">
        <span className="pre-header-value">
            <ItemTypeChip itemTypeLabel={type.type} />
        </span>
    </div> )
}




