import React from 'react'
import GenericNotificationDisplay from './GenericNotificationDisplay'
import {Notification} from '../../../models/Notification'

const GuildCreationNotification = ({notification}: { notification: Notification }) => (
    <GenericNotificationDisplay
        notification={notification}
        title={'Guild creation'}
        canClose={true}
    />
)

export default GuildCreationNotification