import {GuildSharingSummary} from '../model/GuildSharingSummary'
import './ItemSharing.scss'
import {Divider} from '@mui/material'
import {LoadingButton} from '@mui/lab'
import React from 'react'
import {ROUTES} from '../../../constants/routing'
import {DateDisplay} from '../../../genericComponents/date/dateDisplay'
import {useDatxPathGenerator} from '../../../util/routing'
import {useNavigate} from 'react-router-dom'

const SharingRow = ({itemSharing, toMyGuild}: { itemSharing: GuildSharingSummary, toMyGuild: boolean }) => {
    const guildId = toMyGuild ? itemSharing.sourceGuildId : itemSharing.targetGuildId
    const guildName = toMyGuild ? itemSharing.sourceGuildName : itemSharing.targetGuildName

    const navigate = useNavigate()
    const generatePath = useDatxPathGenerator()

    return <>
        <div data-testid={`sharingRow-${guildId}`} className="item-sharing-details">
            <div className="item-sharing-title-column">
                <span className="item-sharing-title">{guildName?.toUpperCase()}</span>
                <span className="id-value">{guildId}</span>
            </div>
            <div className="item-sharing-last-updated">
                <span className="item-sharing-content">Last Updated:</span>
                <DateDisplay className="item-sharing-content" entryDate={itemSharing.updatedDate!} time={false}/>
            </div>
            {!toMyGuild && <div className="loadingButton-container">
                <LoadingButton
                    data-testid={`manage-sharing-${guildId}`}
                    variant="outlined"
                    color="primary"
                    type="button"
                    onClick={() => navigate(generatePath(ROUTES.itemsShareToOtherGuild.path ??
                        '', {sharingId: itemSharing.id}))}
                    className="manage-button"
                >
                    Manage
                </LoadingButton>
            </div>}
        </div>
        <Divider/>
    </>
}
export default SharingRow
