import {FormControl} from '@mui/material'
import React from 'react'
import {LoadingButton} from '@mui/lab'
import {
    generateDownloadableExcelFile,
    generateTemplateFileName,
    getCommonHeader,
    getCommonItemsForExcelForCreation
} from '../../model/PrivateAttributeExcelFileUtil'
import * as XLSX from 'xlsx'
import {useFormikContext} from 'formik'
import {AttributeStringCreationForm} from './PrivateAttributeStringCreation'
import {useGetItemListAllPagesQuery} from '../../../../../itemList/rtkItemListApi'
import {useCurrentGuildContext} from '../../../../../util/routing'
import LoadingSpinner from '../../../../../genericComponents/LoadingSpinner'
import {Label} from '../../../../../genericComponents/typographie/Label'
import {Description} from '../../../../../genericComponents/typographie/Description'
import {ServiceRequestStep} from '../../../genericComponents/formTemplate/steps/ServiceRequestStep'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'

export const DownloadTemplateStringCreation = () => {
    const formik = useFormikContext<AttributeStringCreationForm>()
    const [currentGuildContext] = useCurrentGuildContext()

    const attributeName = formik?.values?.attributeName
    const itemListLite = formik?.values?.itemList
    const fileName = generateTemplateFileName(itemListLite?.name, attributeName)

    const {data: itemListFull, isLoading: itemListFullIsLoading} = useGetItemListAllPagesQuery(
        {
            listId: itemListLite?.id
        },
        {skip: !currentGuildContext?.guildId || !itemListLite})

    if (!attributeName || !itemListLite)
        return <div className="service-request-form-step">
            <p className="service-request-step-desription">
                Attribute Name or ItemList are missing, please fill them to generate the template file
            </p>
        </div>

    const generateCreationTemplate = () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        const fileExtension = '.xlsx'

        let headers = getCommonHeader()
        const itemsForExcel = getCommonItemsForExcelForCreation(itemListFull?.elements ?? [])

        headers = [
            [...headers[0], 'PRIVATE_ATTRIBUTE'],
            [...headers[1], attributeName + ' (Label)']
        ]

        const ws = XLSX.utils.aoa_to_sheet([...headers, ...itemsForExcel])
        // Write to workbook
        generateDownloadableExcelFile(ws, fileType, fileExtension, fileName)
    }


    return <ServiceRequestStep>
        <Description className="service-request-step-description">
            The data template below has been generated based on your selection in Step 1.<br/>
            Fill the relevant values into the column named after the new private attribute you created and proceed
            Step 3
        </Description>

        {itemListFullIsLoading ?
            <div className="service-request-form-step">
                <LoadingSpinner></LoadingSpinner>
            </div>
            : <FormControl className="download-upload-template-container">
                <Label className="file-download-upload-label">Generated template based on your
                    selection:</Label>
                <LoadingButton sx={{marginTop: '16px'}} onClick={() => generateCreationTemplate()} variant="outlined"
                               className="download-template-button"
                               color="primary"
                               startIcon={<DownloadOutlinedIcon/>}
                               data-testid="download-template">{`${fileName}.xlsx`}</LoadingButton>
            </FormControl>
        }

    </ServiceRequestStep>
}