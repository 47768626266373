import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import './ShareWithGuildDialog.scss'
import CustomDialog from '../../genericComponents/customDialog/CustomDialog'
import {Button, Divider} from '@mui/material'
import {ItemListSelectable} from '../model/ItemListSelectable'
import {ShareItemListMessage, UnShareItemListMessage} from './ShareDialogMessages'
import { useCurrentGuildContext } from '../../util/routing'
import {useShareItemListMutation, useUnshareItemListMutation} from '../rtkItemListApi'

function ShareWithGuildDialog({ open, closeDialog ,selectedList}:
     {
         open: boolean,
         closeDialog: any,
         selectedList : ItemListSelectable
     }
){
    const [currentGuildContext] = useCurrentGuildContext()

    const [shareItemList, shareItemListResult] = useShareItemListMutation()
    const [unshareItemList, unshareItemListResult] = useUnshareItemListMutation()


    const isPending = shareItemListResult.isLoading || unshareItemListResult.isLoading

    const onSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!currentGuildContext) {
            return
        }

        if (!selectedList.list.isSharedWithGuild) {
            shareItemList(selectedList.list.id)
                .unwrap().then(() => {
                closeDialog()
            })
        } else {
            unshareItemList(selectedList.list.id)
                .unwrap().then(() => {
                closeDialog()
            })

        }
    }

            return (
        <CustomDialog
            className="share-item-list-dialog"
            open={open}
            title={selectedList.list.isSharedWithGuild ? 'Unshare with Guild' : 'Share with Guild'}
            onClose={closeDialog}
        >
            <div>
            {selectedList.list.isSharedWithGuild ? <UnShareItemListMessage itemListName={selectedList.list.name}/> : <ShareItemListMessage itemListName={selectedList.list.name}/> }

                <Divider sx={{ mt: 5, mb: 2 }} />

                <div className="share-items-dialog-buttons">
                    <Button
                        className="share-item-list-back-button"
                        onClick={closeDialog}
                    >
                        Cancel
                    </Button>

                    <LoadingButton
                        className="share-item-list-submit"
                        color="primary"
                        variant="contained"
                        onClick={onSubmit}
                        disabled={isPending}
                        loading={isPending}
                        data-testid = "share-confirmation-notification-modal"
                    >
                        Confirm
                    </LoadingButton>
                </div>


            </div>
        </CustomDialog>
    )
}

export default ShareWithGuildDialog
