import {Box, Button, Divider, Typography} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import {Link, useParams} from 'react-router-dom'
import {ROUTES} from '../../../constants/routing'
import React, {useMemo, useState} from 'react'
import {useDatxPathGenerator} from '../../../util/routing'
import {useGetSharingByIdQuery} from '../../rtkGuildApi'
import LoadingSpinner from '../../../genericComponents/LoadingSpinner'
import SharedOrganisationItemsTables from './SharedOrganisationItemsTables'
import {IdType} from '../../../util/models/IdType'
import {useGetOrganizationsQuery} from '../../../organization/rtkOrganizationApi'
import {skipToken} from '@reduxjs/toolkit/query'
import {OrganizationItemSharing} from '../model/OrganizationItemSharing'
import './ManageSharedOrganisationItems.scss'

const ManageSharedOrganisationItems = () => {
    const generatePath = useDatxPathGenerator()
    const {sharingId} = useParams()
    const [itemsSharing, setItemsSharing] = useState<OrganizationItemSharing[]>()
    const {
        data: sharingDetail,
        isFetching: isSharingLoading
    } = useGetSharingByIdQuery(sharingId!, {skip: !sharingId})

    const organizationIds: IdType[] = useMemo(() =>
            sharingDetail?.organizationsSharing?.map((itemSharing: OrganizationItemSharing) => itemSharing.targetOrganizationId) ?? [],
        [sharingDetail])

    const {
        data: organizations,
        isFetching: isOrganizationsLoading
    } = useGetOrganizationsQuery(organizationIds.length !== 0 ? organizationIds : skipToken)

    useMemo(() => {
        if (!organizations || organizations.length === 0 || !sharingDetail) return

        const organizationMapper = new Map<IdType, string>()

        organizations.forEach(({label, id}) => {
            organizationMapper.set(id, label)
        })

        const itemsSharing = sharingDetail.organizationsSharing.reduce((list: OrganizationItemSharing[], itemSharing: OrganizationItemSharing) => {
            if (itemSharing.targetOrganizationId) {
                list.push({
                    ...itemSharing,
                    targetOrganizationName: organizationMapper.get(itemSharing.targetOrganizationId)
                })
            }
            return list
        }, [])

        setItemsSharing(itemsSharing)
    }, [organizations, sharingDetail])

    const renderManageItemsSharingTable = () =>{
        if(isSharingLoading || isOrganizationsLoading) return <LoadingSpinner/>
        if(!itemsSharing?.length) return <div className="no-items">There are no items shared with this guild</div>
        return <SharedOrganisationItemsTables itemsSharing={itemsSharing}
                                              sharingId={sharingId as IdType}
                                              targetGuildName={sharingDetail?.targetGuildName ?? ''}/>
    }

    return <Box className="organisation-page-container">
        <div className="share-by-title-container">
            <Typography variant="h5" id="shareByOrgItemsTitle" className="page-title">
                Share by Organisation with <span>{sharingDetail?.targetGuildName}</span>
            </Typography>
            <Divider className="divider-title"/>
        </div>

        <div className="management-section">
            <div className="management-header">
                <h3 className="management-header-title">Items defined by Organisation</h3>
                <LoadingButton
                    data-testid="add-items"
                    className="add-organisation-button"
                    variant="contained"
                    color="primary"
                    size="small"
                    component={Link}
                    to={generatePath(ROUTES.addSharingItems.path, {sharingId: sharingId})}
                    state={{targetGuildName: sharingDetail?.targetGuildName}}
                >Add</LoadingButton>
            </div>
            <div className="management-description">
                <p>By selecting an organisation, the guild will receive view access for all items defined by the selected organisation.</p>
                <p>* When <b>new items</b> are defined by the selected organisation, sharing will be <b>updated automatically</b>.</p>
            </div>
        </div>

        <div className="shared-items-section">
            <div>
                {renderManageItemsSharingTable()}
            </div>
        </div>

        <div className="step-footer">
            <Divider className="divider"/>
            <div className="footer-contents">
                <Button className="back-button" component={Link} to={generatePath(ROUTES.itemsShareToOtherGuild.path, {sharingId: sharingId})}>Back to Manage Sharing</Button>
            </div>
        </div>
    </Box>
}

export default ManageSharedOrganisationItems
