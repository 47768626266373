import { createSlice } from '@reduxjs/toolkit'
export type CommonStateType = {
  snackbar: {
      message: string
  }
}

const initialState: CommonStateType = {
    snackbar: {
        message: ''
    }
}

const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setSnackbarMessage: (state: CommonStateType, action) => {
            state.snackbar.message = action.payload
        },
        clearSnackbarMessage: (state: CommonStateType) => {
            state.snackbar.message = initialState.snackbar.message
        }
    },
})

export const {setSnackbarMessage, clearSnackbarMessage} = commonSlice.actions

export default commonSlice.reducer
