import DisplaySetTitle from '../../displaySet/DisplaySetTitle'
import React, {useState} from 'react'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import {useSortable} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'
import {VisibilityOffOutlined, VisibilityOutlined} from '@mui/icons-material'
import {DisplaySetOrdered} from '../model/DisplaySetOrdered'


export const DisplaySetConfigurationComponent = ({displaySetOrdered}: {
    displaySetOrdered: DisplaySetOrdered
}) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        active,
    } = useSortable({id: displaySetOrdered.displaySet.id})
    const displaySetLabelClassName = displaySetOrdered.displaySet.label.replace(' ', '-').toLowerCase()

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    }

    const isActive = active?.id === displaySetOrdered.displaySet.id

    const [isDisabled, setIsDisabled] = useState(displaySetOrdered.disable)

    function handleDisplaySetVisibility() {
        setIsDisabled(!displaySetOrdered.disable)
        displaySetOrdered.disable = !displaySetOrdered.disable
    }

    return <div ref={setNodeRef} style={style}
                data-testid={'display-set-' + displaySetOrdered.displaySet.id}
                className={'display-set-configuration-component ' + displaySetLabelClassName + (isActive ? ' active' : '') + (isDisabled ? ' deactivate' : '')}>
        <div className="display-set-element" {...attributes} {...listeners}>
            <DragIndicatorIcon className="drag-indicator"/>
            <DisplaySetTitle title={displaySetOrdered.displaySet.label} iconName={displaySetOrdered.displaySet.iconName}/>
        </div>
        <div>
            {!isDisabled ?
                <VisibilityOutlined data-testid={'display-set-visibility-on-icon-' + displaySetOrdered.displaySet.label} onClick={handleDisplaySetVisibility} style={{ cursor: 'pointer' }}/> :
                <VisibilityOffOutlined data-testid={'display-set-visibility-off-icon-' + displaySetOrdered.displaySet.label} onClick={handleDisplaySetVisibility} style={{ cursor: 'pointer' }}/>
            }
        </div>
    </div>
}
