import React, {useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {ROUTES} from '../../../constants/routing'
import {useCurrentGuildContext, useDatxPathGenerator} from '../../../util/routing'
import {useGetServiceRequestFileLinkMutation} from '../rtkServiceRequestApi'


const DownloadServiceFileLink = () => {
    const [currentGuildContext] = useCurrentGuildContext()
    const {serviceRequestsId} = useParams()
    const navigate = useNavigate()
    const generatePath = useDatxPathGenerator()
    const [getServiceRequestFileLink] = useGetServiceRequestFileLinkMutation()

    const getFile = () => {
        if (serviceRequestsId != null) {
            getServiceRequestFileLink(serviceRequestsId)
            navigate(generatePath(ROUTES.welcome.path))
        }
    }

    useEffect(() => {
        if (currentGuildContext) {
            getFile()
        }
    }, [currentGuildContext])

    return (
        <>
        </>
    )
}
export default DownloadServiceFileLink