import {Icon, Paper} from '@mui/material'
import React, {ReactNode} from 'react'

export const ICON_TYPE = {
    ORGANISATION: 'organisation',
    ITEMS: 'items'
} as const
export type IconType = keyof typeof ICON_TYPE

const MenuBar = ({
                     cardTitle,
                     cardDescription,
                     children,
                     iconType,
                 }: {
    cardTitle: string,
    cardDescription: string,
    iconType: IconType,
    children: ReactNode
}) => {
    const OrganisationIcon = () => {
        return <Icon style={{fontSize: '48px', color: 'var(--primaryColor)'}}>apartment</Icon>
    }

    const ItemsIcon = () => {
        return <Icon style={{fontSize: '48px', color: 'var(--primaryColor)'}}>list</Icon>
    }

    const displayMenuIcon = () => {
        switch (iconType) {
            case 'ORGANISATION': {
                return OrganisationIcon()
            }
            case 'ITEMS': {
                return ItemsIcon()
            }
            default: {
                return <></>
            }
        }
    }

    return <Paper className="items-manage-card shared-by-organisation-card">
        <div className="card-container">
            <div className="card-icon">{displayMenuIcon()}</div>
            <div className="card-title-contents">
                <div className="card-title">{cardTitle}</div>
                <div className="card-description">{cardDescription}</div>
            </div>
            <div className="manage-button-container">
                {children}
            </div>
        </div>
    </Paper>
}

export default MenuBar