import {CookiesCategory} from '../CookiesTypes'
import {Button} from '@mui/material'

export const CookiesButtonsSection = ({
                                          inCategoryDialog,
                                          category,
                                          declineAllCookies,
                                          handleAcceptCustomCookies,
                                          rejectSelectedVendors,
                                          handleSelectedVendors
                                      }: {
    inCategoryDialog: boolean,
    category?: CookiesCategory,
    declineAllCookies?: () => void,
    handleAcceptCustomCookies?: () => void
    rejectSelectedVendors?: () => void,
    handleSelectedVendors?: () => void
}) => {

    return category !== CookiesCategory.RequiredCookies
        ? <div className="confirm-buttons">
            <Button variant="outlined"
                    type="button"
                    onClick={inCategoryDialog ? declineAllCookies : rejectSelectedVendors}
                    data-testid={inCategoryDialog ? 'reject-all-cookies-button' : 'reject-all-vendors-button'}>Reject
                All</Button>
            <Button variant="contained"
                    type={inCategoryDialog ? 'submit' : 'button'}
                    onClick={inCategoryDialog ? handleAcceptCustomCookies : handleSelectedVendors}
                    data-testid={inCategoryDialog ? 'cookies-confirmation-button' : 'vendors-confirmation-button'}>
                Confirm My Choices
            </Button>
        </div>
        : <></>
}

