import {FormControl, FormHelperText, MenuItem, Select, SelectChangeEvent, TextField} from '@mui/material'
import {ExportFormatEnum} from '../../../../../model/ExportFormatEnum'
import {KeyboardArrowDown} from '@mui/icons-material'
import React from 'react'
import {ExtractionFrequencyEnum} from '../../../../../model/ExtractionFrequencyEnum'
import {useFormikContext} from 'formik'
import {RecurringExtractionRequestForm} from './RecurringExtractionRequest'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import {Label} from '../../../../../../genericComponents/typographie/Label'
import {ServiceRequestStep} from '../../../../genericComponents/formTemplate/steps/ServiceRequestStep'

export const ExtractionPreference = () => {
    const formik = useFormikContext<RecurringExtractionRequestForm>()
    const handleSelectFormat = (e: SelectChangeEvent<ExportFormatEnum>) => {
        return formik.setFieldValue('format', e.target.value as ExportFormatEnum)
    }

    const handleSelectExtractionFrequency = (e: SelectChangeEvent<ExtractionFrequencyEnum>) => {
        return formik.setFieldValue('extractionFrequency', e.target.value as ExtractionFrequencyEnum)
    }

    const handleSelectExtractionPath = (e: React.ChangeEvent<HTMLInputElement>) => {
        return formik.setFieldValue('extractionPath', e.target.value as string)
    }

    return (
        <ServiceRequestStep>
            <FormControl className="form-select" required>
                <Label required={true} className="label-format">
                    Format
                </Label>
                <Select
                    size="small"
                    className="format-selector"
                    displayEmpty
                    labelId="format-list-label"
                    data-testid="format-dropdown-selector"
                    id="format-list"
                    required
                    onChange={handleSelectFormat}
                    renderValue={(fileFormat) =>
                        fileFormat in ExportFormatEnum ? fileFormat : <span className="placeholder">Select Format</span>
                    }
                    IconComponent={KeyboardArrowDown}
                >
                    <MenuItem data-testid="xml-dropdown-selector" value={ExportFormatEnum.XML} key={'xml'}>
                        XML
                    </MenuItem>
                    <MenuItem data-testid="json-dropdown-selector" value={ExportFormatEnum.JSON} key={'json'}>
                        JSON
                    </MenuItem>
                </Select>
            </FormControl>
            <FormControl className="form-select" required>
                <Label required={true} className="label-format">
                    Extraction Frequency
                </Label>
                <Select
                    size="small"
                    className="format-selector"
                    displayEmpty
                    data-testid="frequency-dropdown-selector"
                    labelId="frequency-list-label"
                    id="frequency-list"
                    required
                    onChange={handleSelectExtractionFrequency}
                    renderValue={(extractionFrequency) =>
                        extractionFrequency in ExtractionFrequencyEnum ? (
                            extractionFrequency
                        ) : (
                            <span className="placeholder">Select Extraction Schedule</span>
                        )
                    }
                    IconComponent={KeyboardArrowDown}
                >
                    <MenuItem data-testid="daily-dropdown-selector" value={ExtractionFrequencyEnum.DAILY}>
                        Daily
                    </MenuItem>
                    <MenuItem data-testid="weekly-dropdown-selector" value={ExtractionFrequencyEnum.WEEKLY}>
                        Weekly
                    </MenuItem>
                    <MenuItem data-testid="monthly-dropdown-selector" value={ExtractionFrequencyEnum.MONTHLY}>
                        Monthly
                    </MenuItem>
                </Select>
            </FormControl>
            <FormControl className="form-select">
                <Label className="label-format">
                    Extraction Path
                </Label>
                <TextField
                    className="custom-textfield"
                    id="inputPath"
                    size="small"
                    name="inputPath"
                    value={formik.values.extractionPath}
                    onChange={handleSelectExtractionPath}
                    variant="outlined"
                    data-testid="extraction-path"
                    placeholder="Input Path"
                    inputProps={{maxLength: 100}}
                    aria-invalid={Boolean(formik.errors.extractionPath)}
                    error={Boolean(formik.errors.extractionPath)}
                />
                {formik.errors.extractionPath && (
                    <FormHelperText className="form-helper-text" error>
                        <WarningAmberOutlinedIcon className="warning-icon" />
                        {formik.errors.extractionPath}
                    </FormHelperText>
                )}
            </FormControl>
        </ServiceRequestStep>
    )
}
