import React from 'react'
import {ArtifactAttribute} from '../attributeTemplates/ArtifactAttribute'
import {TemplateDefinitionForDisplaySet} from './TemplateDefinitionForDisplaySet'
import {AttributeDefinitionModel, AttributeModel} from '../model/AttributeModel'
import {sortByStartValidity} from '../utils/sortedDefinitions'

export const ArtifactDefaultDefinitionTemplate = ({definition}: { definition: AttributeDefinitionModel }) => {
    const filterAttributesToDisplay = () => {
        const groupedByLanguageAttributes = definition.attributes.reduce((acc, el) => {
            const languages = el.value.document?.languages ?? ['']
            const languageKey = [...languages].sort().join('')
            if (!acc.has(languageKey)) {
                acc.set(languageKey, [])
            }
            acc.get(languageKey)?.push(el)
            return acc
        }, new Map<string, AttributeModel[]>())

        const attributesToDisplay: AttributeModel[] = []

        groupedByLanguageAttributes.forEach((value, key) => {
            attributesToDisplay.push(sortByStartValidity(value)[0])
        })
        return sortByStartValidity(attributesToDisplay)
    }

    const sortedAttributes = filterAttributesToDisplay()

    return (
        <TemplateDefinitionForDisplaySet definition={definition}>
            {sortedAttributes.map(attribute => <ArtifactAttribute key={attribute.id} attribute={attribute}/>)}
        </TemplateDefinitionForDisplaySet>
    )
}
