import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import './DeleteItemListDialog.scss'
import CustomDialog from '../../genericComponents/customDialog/CustomDialog'
import {Button, Divider} from '@mui/material'
import {useCurrentGuildContext} from '../../util/routing'
import {ItemList} from '../model/ItemList'
import {useDeleteItemListsMutation} from '../rtkItemListApi'
import {IdType} from '../../util/models/IdType'

function DeleteItemListDialog({open, closeDialog, myLists}:
                                  {
                                      open: boolean,
                                      closeDialog: (deletedItemListIds? : IdType[]) => void,
                                      myLists: ItemList[]
                                  }
) {
    const [currentGuildContext] = useCurrentGuildContext()
    const [deleteItemLists, deleteItemListsResult] = useDeleteItemListsMutation()

    const onSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!currentGuildContext) {
            return
        }

        deleteItemLists(
            myLists.map(list => list.id)
        )
            .unwrap().then((deletedItemListIds) => {
            closeDialog(deletedItemListIds)
        })
        event.stopPropagation()

    }

    return (
        <CustomDialog
            className="delete-item-list-dialog"
            open={open}
            title="Confirmation"
            onClose={closeDialog}
        >
            <div>
                <p className="delete-item-list-dialog-text">
                    Delete the selected items {myLists.length > 1 ? 'lists' : 'list'} ?
                </p>
                <Divider sx={{mt: 5, mb: 2}}/>

                <div className="delete-items-dialog-buttons">
                    <Button
                        className="delete-item-list-back-button"
                        onClick={() => closeDialog()}
                    >
                        Cancel
                    </Button>

                    <LoadingButton
                        className="delete-item-list-submit"
                        color="primary"
                        variant="contained"
                        onClick={onSubmit}
                        disabled={deleteItemListsResult.isLoading}
                        loading={deleteItemListsResult.isLoading}

                        data-testid="delete-confirmation-notification-modal"
                    >
                        Delete selected {myLists.length > 1 ? 'lists' : 'list'}
                    </LoadingButton>
                </div>


            </div>
        </CustomDialog>
    )
}

export default DeleteItemListDialog
