import {searchAPI} from '../search/rtkSearchApi'
import {MonitoredBundle} from '../item/model/MonitoredBundle'

export const MONITORED_BUNDLES_TAG = 'MonitoredBundles'

export const monitoredBundleApi = searchAPI.enhanceEndpoints({
    addTagTypes: [MONITORED_BUNDLES_TAG]
}).injectEndpoints({
    endpoints :(builder) => ({
        getAllMonitoredBundles : builder.query<MonitoredBundle[], void>({
            query: () => ({
                url :'monitoredbundles',
                method: 'GET'
            }),
            transformErrorResponse: () => 'Something went wrong during monitored bundle retrieval',
            providesTags: [MONITORED_BUNDLES_TAG],
            keepUnusedDataFor: 300
        }),
    })
})

export const {useGetAllMonitoredBundlesQuery} = monitoredBundleApi


