import React from 'react'
import {Radio, TextField} from '@mui/material'
import {FormikContextType, useFormikContext} from 'formik'
import {WorkflowConfigurationForm} from '../WorkflowConfigurationTemplate'
import CustomSwitch from '../../../../genericComponents/customSwitch/CustomSwitch'
import {WorkflowConfigurationStage} from '../../model/WorkflowConfigurationStage'
import {WarningAmberOutlined} from '@mui/icons-material'

export const QuotationApprovalSelection = () => {
    const formik: FormikContextType<WorkflowConfigurationForm> = useFormikContext()

    const isAutoApprovalThresholdError = formik.values.quotationAutoApprovalThreshold !== null &&
        !formik.values.quotationAutoApprovalThreshold && formik.values.quotationAutoApproval

    const showErrorMessage = () => {
        if (!isAutoApprovalThresholdError) {
            return null
        }

        return <div className="threshold-error">
            <WarningAmberOutlined />
            <span> This field cannot be empty </span>
        </div>
    }

    return <div className="workflow-quotation-approval-container">
        <div className="edit-value">Quotation Approval</div>
        <div>
            <CustomSwitch
                disabled={false}
                value={WorkflowConfigurationStage.AUTO_APPROVE_QUOTATION}
                isChecked={formik.values.quotationAutoApproval}
                toggleChecked={(e) => {
                    formik.setFieldValue('quotationAutoApproval', e.target.checked)
                    formik.setFieldValue('quotationAutoApprovalThreshold', null)
                }}
                dataTestid="auto-quotation-approval-toggle"
            />
            <div>
                <div className="quotation-options">
                    <div className="radio-group-1">
                        <Radio
                            className="radio-button"
                            checked={formik.values.quotationAutoApprovalThreshold === null}
                            onChange={() => formik.setFieldValue('quotationAutoApprovalThreshold', null)}
                            disabled={!formik.values.quotationAutoApproval}
                        />
                        <span className="edit-title">Always</span>
                    </div>
                    <div className="radio-group-2">
                        <Radio
                            className="radio-button"
                            data-testid="quotation-input"
                            checked={formik.values.quotationAutoApprovalThreshold !== null}
                            onChange={() => formik.setFieldValue('quotationAutoApprovalThreshold', '')}
                            disabled={!formik.values.quotationAutoApproval}
                        />
                        <div className="credits-box">
                                                        <span
                                                            className="edit-title">When quotation is lower than:</span>
                            <TextField
                                data-testid="quotation-text-field"
                                size="small"
                                autoComplete="off"
                                className="quotation-threshold-input"
                                label={formik.values.quotationAutoApprovalThreshold ? '' : 'Number of Credits'}
                                InputProps={{
                                    inputProps: {min: 0}
                                }}
                                InputLabelProps={{
                                    className: 'custom-label',
                                    shrink: false
                                }}
                                type="number"
                                value={formik.values.quotationAutoApprovalThreshold || ''}
                                onChange={(e) =>
                                    formik.setFieldValue('quotationAutoApprovalThreshold', e.target.value)
                                }
                                error={isAutoApprovalThresholdError}
                                helperText={showErrorMessage()}
                                disabled={!formik.values.quotationAutoApproval ||
                                    formik.values.quotationAutoApprovalThreshold === null}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
