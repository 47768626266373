import './VariancePackagingWidget.scss'
import PackVariantCard from './PackVariantCard'
import {AttributeModel, LinkTypeEnum} from '../../model/AttributeModel'
import {PackVariant} from '../../model/AttributeValue'
import {getAttributeByDefinitionId} from '../../utils/common'
import {DisplaySet} from '../../model/DisplaySet'
import {SimpleItem} from '../../../item/model/SimpleItem'

const ITEM_VARIANCE_LINK_SPECIFICITIES_PACKAGING_DEF_ID = 'b28505ea-4200-50df-b742-dc53f83a66ae'
const CONTAINER_TYPE_DEFINITION_ID = 'e626f940-17b1-5c38-92ec-a27abdb89135'
const QUANTITY_DEFINITION_ID = '83c46187-f30f-543e-8bf1-e8e2794be59d'
const UNIT_DEFINITION_ID = 'afb38cfe-e510-5a0b-9039-2ea623d3e93f'

const getPackVariant = (attribute: AttributeModel | null | undefined): PackVariant | null => {
    if (!attribute) return null
    const containerType = getAttributeByDefinitionId(attribute, CONTAINER_TYPE_DEFINITION_ID)?.value.value
    const unit = getAttributeByDefinitionId(attribute, UNIT_DEFINITION_ID)?.value.value
    const quantityOfContent = getAttributeByDefinitionId(attribute, QUANTITY_DEFINITION_ID)?.value.value
    if (!containerType && !unit && !quantityOfContent) return null
    return {containerType, quantityOfContent, unit}
}


const VariancePackagingWidget = ({displaySet}: { displaySet: DisplaySet }) => {
    const linkedItemsAttributeModel = displaySet.attributes.filter((it) => it.attributeDefinitionType?.linkType === LinkTypeEnum.ITEM_VARIANCE_LINK_PACKAGING)

    const getPackVariantCards = linkedItemsAttributeModel.map(attribute => {
            const relatedItem = attribute.value.linkedItem
            const packVariant = getPackVariant(getAttributeByDefinitionId(attribute, ITEM_VARIANCE_LINK_SPECIFICITIES_PACKAGING_DEF_ID))
            if (!relatedItem) return <></>
            return <PackVariantCard linkedItem={relatedItem} packVariant={packVariant}/>
        }
    )

    return <div className="variance-packaging-widget">
        <div className="variance-packaging-widget-container">
            {getPackVariantCards}
        </div>
    </div>
}

export default VariancePackagingWidget