export const ClassificationNodeIconImageMap = new Map([
        ['Explosive', '/images/danger-classification-node-icons/SGH01.jpg'],
        ['Flammable', '/images/danger-classification-node-icons/SGH02.jpg'],
        ['Oxidizing', '/images/danger-classification-node-icons/SGH03.jpg'],
        ['GasUnderPressure', '/images/danger-classification-node-icons/SGH04.jpg'],
        ['Corrosive', '/images/danger-classification-node-icons/SGH05.jpg'],
        ['AcuteToxicity', '/images/danger-classification-node-icons/SGH06.jpg'],
        ['HealthOzoneHazard', '/images/danger-classification-node-icons/SGH07.jpg'],
        ['SeriousHealthHazard', '/images/danger-classification-node-icons/SGH08.jpg'],
        ['EnvironmentalHazard', '/images/danger-classification-node-icons/SGH09.jpg'],
])


