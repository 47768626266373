import React from 'react'

export const NewManufacturerItemDescription = () => {
    return (
        <div className="step-description">
            <p>
                The DATX team can assist you in the creation of Manufacturer items.
                <br />
                <br />
                These items are proposed in the catalog of Manufacturers and made available to the industrial market.
                They will be categorised as public items and visible to the DATX community when added to the platform.
                <br />
                <br />
                The creation of these items will be validated by the DATX team before being published.
                <br />
                <br />
                You may use this{' '}
                <a href="/files/services/New_Part_Creation_Template_for_Input_DATX.xlsx">
                    Manufacturer Item Data Template
                </a>{' '}
                for an example of how you can upload your parts.
            </p>
        </div>
    )
}
