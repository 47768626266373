import React from 'react'
import {ClassificationNodeIconImageMap} from '../../attributeTemplates/ClassificationNodeIconImageMap'
import {AttributeModel} from '../../model/AttributeModel'

//dedicated component only for DisplaySet dangerousness
export const DangerToDangerCategories = (
    {
        pictoAttribute,
        allDangerCategoriesAttributes
    }:
        { pictoAttribute: AttributeModel, allDangerCategoriesAttributes: AttributeModel[] }) => {

    const iconName = pictoAttribute.value.classificationNode?.iconName
    const matchingIconPath = iconName ? ClassificationNodeIconImageMap.get(iconName) : null
    if (!iconName || !matchingIconPath) return <></>

    const pictoLabel = pictoAttribute.value.classificationNode?.label ?? ''
    const dangerCategoriesOfPicto = allDangerCategoriesAttributes.filter(attr => attr.value?.classificationNode?.iconName === iconName)

    return (
        <div className="pair-attributes-container">
            {matchingIconPath && <img className="danger-icon" src={matchingIconPath} alt="Icon"/>}
            <div className="attribute-labels">
                {<div className="icon-name">{pictoLabel}</div>}
                {dangerCategoriesOfPicto.map(attr =>
                    <div key={attr.id} className="node-label">{attr.value?.classificationNode?.label}</div>
                )
                }
            </div>
        </div>
    )
}