import {AnyObject} from 'yup'
import {ServiceRequestStep} from '../../model/ServiceRequestStep'
import {ServiceRequestHeader} from '../structure/ServiceRequestHeader'
import {ServiceRequestFormTitle} from '../ServiceRequestFormTitle'
import {ServiceRequestFormTitleDescription} from '../ServiceRequestFormTitleDescription'
import React from 'react'
import {ServiceRequestStepsHeader} from './steps/ServiceRequestStepsHeader'

export const ServiceRequestFormHeader = <T extends AnyObject>({
                                                                  currentStep,
                                                                  currentStepIndex,
                                                                  steps,
                                                                  title,
                                                                  previousUrl,
                                                                  withStepper = false
                                                              }: {
    currentStepIndex: number,
    steps: ServiceRequestStep<T>[]
    title: string
    previousUrl: string
    currentStep?: ServiceRequestStep<T>,
    error?: boolean,
    withStepper?: boolean
}) => {
    return <ServiceRequestHeader>
        <ServiceRequestFormTitle title={title} previousUrl={previousUrl}/>
        {withStepper &&
            <ServiceRequestStepsHeader currentStepIndex={currentStepIndex} steps={steps}
                                       currentStep={currentStep}/>
        }
        {currentStep && currentStep?.description && (
            <ServiceRequestFormTitleDescription>
                {currentStep?.description}
            </ServiceRequestFormTitleDescription>
        )}
    </ServiceRequestHeader>
}