
import React from 'react'
import {getComponentForAttributeValueType} from './AttributeValueToComponentMapper'
import {AttributeDefinitionModel} from '../model/AttributeModel'
import {AttributeValueType} from '../model/AttributeValue'
import {sortAttributesByDisplayOrder, sortByStartValidity} from '../utils/sortedDefinitions'

export const NoDefinitionNameDisplayTemplate = ({definition}: { definition: AttributeDefinitionModel }) => {

    //Sort artifact attributes in case their definition has a null templateId
    const attributes = definition.attributes[0].value.type === AttributeValueType.ARTIFACT
        ? sortByStartValidity(definition.attributes)
        : sortAttributesByDisplayOrder(definition.attributes)

    return <>
        {attributes.map(attribute => getComponentForAttributeValueType(attribute))}
    </>
}
