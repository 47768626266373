import React, {useState} from 'react'
import {IconButton, Tooltip} from '@mui/material'
import CreateNewListDialog from './CreateNewListDialog'
import './CreateNewList.scss'
import {ItemList} from '../model/ItemList'
import PostAddIcon from '@mui/icons-material/PostAdd'

export default function CreateNewList({myLists}: { myLists: ItemList[] }) {
    const [isDialogOpen, setIsDialogOpen] = useState(false)

    return (
        <>
            <IconButton
                data-testid="create-itemlists"
                color="primary"
                onClick={() => setIsDialogOpen(true)}
                disabled={false}
            >
                <Tooltip title="Create new list">
                    <PostAddIcon/>
                </Tooltip>
            </IconButton>
            <CreateNewListDialog
                open={isDialogOpen}
                closeDialog={() => setIsDialogOpen(false)}
                myLists={myLists}
            />
        </>
    )
}
