import React from 'react'
import {useNavigate} from 'react-router-dom'

import {Button, Grid} from '@mui/material'

// styles
import './GuildCreationSuccess.scss'
import GuildFormHolder from '../form/GuildFormHolder'
import {ROUTES} from '../../../constants/routing'
import {useDatxPathGenerator} from '../../../util/routing'
import CardContainer from '../../../genericComponents/cardContainer/CardContainer'
import {HeaderContent} from '../../../item/itemDetails/itemDetailsHeader/ItemDetailsHeaderContent'

const GuildCreationConfirm = ({guildName}: {guildName: string}) => {
    const navigate = useNavigate()
    const generatePath = useDatxPathGenerator()

    return (
        <CardContainer>
            <div className="guild-creation-success">
                <header className="gcs-header">Guild Creation Request Submitted</header>
                <div className="gcs-guild-name">
                    Guild Name: <b>{guildName}</b>
                </div>
                <div className="gcs-description">
                    Your request has been been sent to DATX.
                    <br />
                    The Guild creation is a commercial process requiring interaction with the DATX commercial team.
                    <br />A DATX member will contact you soon on your guild application.
                </div>
                <Button
                    // use the same className as the default one in GuildFormHolder
                    className="guild-form-submission-button"
                    variant="contained"
                    color="primary"
                    onClick={() => navigate(generatePath(ROUTES.welcome.path))}
                >
                    Back to Home
                </Button>
            </div>
        </CardContainer>
    )
}

export default GuildCreationConfirm
