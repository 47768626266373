import DatxNoFile from '../styles/icons/DatxNoFile'

export const NoData = ({message = 'No Data Available'}: { message?: string }) => {
    return <div style={{
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '16px',
    }}
                data-testid="no-lists">
        <DatxNoFile/>
        <p>
            {message}
        </p>
    </div>

}