import MemberStatusEnum from './GuildMemberStatus'


export interface MemberStatus {
  color: string
  label: string
}

export const getGuildMemberType = (status: string) => {
  const guildMemberTypeMap = new Map<string, MemberStatus>([])
  guildMemberTypeMap.set(MemberStatusEnum.ACTIVE, { color: '#2DB453', label: 'Active' })
  guildMemberTypeMap.set(MemberStatusEnum.ACCEPTED, { color: '#2DB453', label: 'Accepted' })
  guildMemberTypeMap.set(MemberStatusEnum.INACTIVE, { color: 'rgba(0, 0, 0, 0.4)', label: 'Inactive' })
  guildMemberTypeMap.set(MemberStatusEnum.SENT, { color: '#FFC140', label: 'Invited' })
  guildMemberTypeMap.set(MemberStatusEnum.REJECTED, { color: '#E63A56', label: 'Invite Rejected' })

  return guildMemberTypeMap.get(status) ?? { color: 'black', label: 'N/A' }
}

export default getGuildMemberType

