import {GridRenderCellParams} from '@mui/x-data-grid'
import './table.scss'

export const getValue = (row: GridRenderCellParams) => {
    const value = row.row[row.field]
    if (!value) {
        return '-'
    }
    return <span title={value} className="label-format">{value}</span>
}

export const getSortedDate = (v1 : any, v2 : any) => {
    for (let i = 0; i < v1.length; i++) {
      if(v1[i] !== v2[i]){ return v1[i] - v2[i]}
    }
    return v1 - v2
}
