import React from 'react'
import ReactDOM from 'react-dom/client'
import {PublicClientApplication} from '@azure/msal-browser'
import {msalConfig} from './authentication/authConfig'
import {MsalProvider} from '@azure/msal-react'
import {Provider} from 'react-redux'
import store from './util/store'
import routes from './Routes'
import './index.scss'
import {CssBaseline, ThemeProvider} from '@mui/material'
import {thalesTheme} from './styles/theme'

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)

const msalInstance = new PublicClientApplication(msalConfig)

root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <CssBaseline>
                <ThemeProvider theme={thalesTheme}>
                    <Provider store={store}>{routes}</Provider>
                </ThemeProvider>
            </CssBaseline>
        </MsalProvider>
    </React.StrictMode>
)
