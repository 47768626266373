import {SearchResultDTO} from '../../../model/SearchResultDTO'
import {Search} from '../../../model/SearchFilter'
import React, {ChangeEvent} from 'react'
import {useSearchResultControl} from '../../useSearchResultControl'
import LoadingSpinner from '../../../../genericComponents/LoadingSpinner'
import {NoData} from '../../../../genericComponents/NoData'
import {
    SearchResultTableForClassificationAndPhrase
} from '../SearchResultTable/SearchResultTableForClassificationAndPhrase'

export const SearchResultsComponentForClassificationAndPhraseSearch = ({searchResult, isFetching, page, search, handlePageChange}: {
    searchResult: SearchResultDTO | undefined,
    isFetching: boolean,
    page: number,
    search: Search,
    handlePageChange: (e: ChangeEvent<unknown>, pageNumber: number) => void,
}) => {
    const {noFoundItems} = useSearchResultControl({searchResult, page})

    if (isFetching) return <LoadingSpinner/>
    if (noFoundItems) return <NoData message="No part found!"></NoData>
    return <SearchResultTableForClassificationAndPhrase searchResult={searchResult} page={page}
                                                        search={search} handlePageChange={handlePageChange}/>
}