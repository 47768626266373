import CheckIcon from '@mui/icons-material/Check'

const AutoApproveStatus = ({ autoApprovalStatus }: { autoApprovalStatus: string }) => {

    return (
        <div className="details-form-auto-quotation">
            <CheckIcon className="details-icon"/>
            <div className="details-value">{autoApprovalStatus}</div>
        </div>
    )
}
export default AutoApproveStatus
