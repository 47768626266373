import {DataGrid, GridColDef} from '@mui/x-data-grid'
import {DateDisplay} from '../../../genericComponents/date/dateDisplay'
import '../common/ItemSharing.scss'
import SharingActions from './SharingActions'
import {IdType} from '../../../util/models/IdType'
import {OrganizationItemSharing} from '../model/OrganizationItemSharing'

const SharedOrganisationItemsTables = ({itemsSharing, sharingId, targetGuildName}: {
    itemsSharing: OrganizationItemSharing[],
    sharingId: IdType,
    targetGuildName: string,
}) => {

    const getAction = ({row}: { row: OrganizationItemSharing }) => {
        return (
            <SharingActions itemSharing={row} sharingId={sharingId} targetGuildName={targetGuildName}/>
        )
    }

    const columns: GridColDef[] = [
        {
            field: 'targetOrganizationName',
            headerName: 'Sharing All Items Defined By',
            headerClassName: 'table-header',
            headerAlign: 'center',
            flex: 2,
            align: 'center',
            disableColumnMenu: true,
            valueGetter: ({row}: { row: OrganizationItemSharing }) => row.targetOrganizationName ?? '',
        },
        {
            field: 'updatedDate',
            headerName: 'Last Updated',
            headerClassName: 'table-header',
            headerAlign: 'center',
            flex: 1,
            align: 'center',
            sortable: true,
            disableColumnMenu: true,
            renderCell: ({row}: { row: OrganizationItemSharing }) => <DateDisplay entryDate={row.updatedDate || []} time={false}/>,
        },
        {
            field: 'action',
            headerName: 'Actions',
            headerClassName: 'table-header',
            headerAlign: 'center',
            flex: 1,
            align: 'center',
            disableColumnMenu: true,
            renderCell: getAction,
        },
    ]


    return <>
        <div className="shared-organizations" data-testid="manage-items-sharing-table">
            {itemsSharing?.length > 0 && (
                <>
                    <DataGrid
                        autoHeight={true}
                        getRowId={(itemSharing: OrganizationItemSharing) => itemSharing?.targetOrganizationId}
                        rows={itemsSharing}
                        columns={columns}
                        className="service-requests-table"
                        sortingOrder={['desc', 'asc']}
                        initialState={{
                            pagination: {paginationModel: {page: 0, pageSize: 20}},
                            sorting: {
                                sortModel: [
                                    {
                                        field: 'updatedDate',
                                        sort: 'desc',
                                    },
                                ],
                            },
                        }}/>
                </>
            )}
        </div>
    </>
}
export default SharedOrganisationItemsTables
