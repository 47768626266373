export const SLICE_NAME = 'guilds'

export interface GuildMenuItem {
    label: string
    linkTo: string
}

export const guildMenus: GuildMenuItem[] = [
    {
        label: 'Guild Details',
        linkTo: '/details'
    },
    {
        label: 'Guild Credit Management',
        linkTo: '/credit'
    },
    {
        label: 'Guild member management',
        linkTo: '/members'
    },
    {
        label: 'Guild Service Requests',
        linkTo: '/services-requests'
    },
    {
        label: 'Guild Service Subscriptions',
        linkTo: '/services-subscriptions'
    },
    {
        label: 'Sharing Configurations',
        linkTo: '/item-sharing/shared-to'
    },
    {
        label: 'Workflow Configuration',
        linkTo: '/workflow-configuration'
    },
    {
        label: 'SFTP Access',
        linkTo: '/sftp-access'
    },
]

export const initialMail = `Mail Subject: Invitation to register to the DATX-TCIS Portal

“Hello,

We are glad to announce you that someone invites you to register to the DATX-TCIS portal to take advantage of all the features provided by solution. 

The registration process is very quick and totally free.  Please follow the following link to register:  DATX Registration

Once your registration completed, you should receive an internal notification to join the GUILD NAME guild.

Best Regards”`
