import {LoadingButton} from '@mui/lab'
import {Button, Divider} from '@mui/material'
import CustomDialog from '../../genericComponents/customDialog/CustomDialog'
import {SearchListItemSelectable} from '../../search/model/SearchListItemSelectable'
import {ItemListWithItems} from '../model/ItemListWithItems'
import {SearchItem} from '../../search/model/SearchItem'
import {useCurrentGuildContext} from '../../util/routing'
import {useDeleteItemsFromItemListMutation} from '../rtkItemListApi'
import {UpdatedItemList} from '../model/UpdatedItemList'
import {IdType} from '../../util/models/IdType'

function DeleteItemFromListDialog({
    open,
    closeDialog,
    currentList,
    itemsToDelete
}: {
    open: boolean
    closeDialog: (deletedItems?: IdType[] | undefined) => void
    itemsToDelete: SearchListItemSelectable[]
    currentList: ItemListWithItems | null
}) {
    const [currentGuildContext] = useCurrentGuildContext()
    const [updateItemList, updateItemListResult] = useDeleteItemsFromItemListMutation()

    const onSubmit = () => {
        if (!currentGuildContext || !currentList) {
            return
        }
        const {id, name, elements} = currentList
        const itemsToDeleteIDs = itemsToDelete.map((item) => item.item.id)
        const currentItemIds = [...elements.map((item: SearchItem) => item.id)]

        const guildId = currentGuildContext.guildId
        const updatedItemList: UpdatedItemList = {id, name, guildId, items: itemsToDeleteIDs}

        updateItemList({listId: id, data: updatedItemList})
            .unwrap()
            .then(() => {
                closeDialog([...itemsToDeleteIDs])
            })
    }

    return (
        <CustomDialog className="delete-item-list-dialog" open={open} title="Confirmation" onClose={closeDialog}>
            <div>
                <p className="delete-item-list-dialog-text">Delete the selected items from the list ?</p>
                <Divider sx={{mt: 5, mb: 2}} />

                <div className="delete-items-dialog-buttons">
                    <Button className="delete-item-list-back-button" onClick={() => closeDialog()}>
                        Cancel
                    </Button>

                    <LoadingButton
                        className="delete-item-list-submit"
                        color="primary"
                        variant="contained"
                        onClick={onSubmit}
                        disabled={updateItemListResult.isLoading}
                        loading={updateItemListResult.isLoading}
                        data-testid="delete-button"
                    >
                        Delete selected items
                    </LoadingButton>
                </div>
            </div>
        </CustomDialog>
    )
}

export default DeleteItemFromListDialog
