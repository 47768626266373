
import React from 'react'
import './LinkedItemsGroupByType.scss'
import {RelatedItem} from '../../model/AttributeValue'
import {useDatxPathGenerator} from '../../../util/routing'
import {Link} from 'react-router-dom'
import {ROUTES} from '../../../constants/routing'
import ItemTypeChip from '../../../item/ItemTypeChip'

const ConformityLinkHeaderRow = () => (
    <div className="header">
        <p className="header-cell">Part Number</p>
        <p className="header-cell">Defined By</p>
        <p className="header-cell">Name</p>
        <p className="header-cell item-type-header">Item type</p>
    </div>
)

const ConformityLinkRow = ({relatedItem}: {relatedItem: RelatedItem}) => {
    const generatePath = useDatxPathGenerator()
    return <div className="row">
                <Link
                    to={generatePath(ROUTES.detailItem.path || '', {itemId: relatedItem.id})}
                    className="cell"
                    data-testid={`item-${relatedItem?.id}`}
                >
                    {relatedItem.partNumber}
                </Link>
                <p className="cell">{relatedItem.definedByOrganization.label}</p>
                <p className="cell">{relatedItem.label}</p>
                <p className="cell item-type-cell">
                    <ItemTypeChip itemTypeLabel={relatedItem.type.label} />
                </p>
            </div>
}

const LinkedItemsGroupByType = ({linkedItems, title}: {
    linkedItems: RelatedItem[]
    title?: string | undefined }) => {

    if(linkedItems.length === 0) return <></>

    return <div className="related-item-group">
        {title && <div className="title">{title}</div>}
        <div className="display-set-table">
            <ConformityLinkHeaderRow />
            <div className="body-container">
                {linkedItems.map((row, index) =>
                    <ConformityLinkRow key={row.id} relatedItem={row}/>
                )}
            </div>
        </div>
    </div>
}
export default LinkedItemsGroupByType
