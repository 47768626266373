import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import {TableSortLabel} from '@mui/material'
import * as React from 'react'
import {SearchResultDataTableColumnModel} from './SearchResultDataTable'
import {useSearchParams} from 'react-router-dom'
import {
    definitionIdUrlSearchParamsName,
    directionURLSearchParamsName,
    fieldURLSearchParamsName,
    SortingDirection,
    SortingField,
    toSortingDirection,
    toSortingField
} from '../../../model/Sorting'
import {validate as uuidValidate} from 'uuid'
import {pageURLSearchParamsName} from '../../../SearchParams'


export const SearchResultDataTableHeader = ({columns}:
                                                { columns: SearchResultDataTableColumnModel[] }) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const sortingDirection: SortingDirection = toSortingDirection(searchParams?.get(directionURLSearchParamsName))
    const sortingField: SortingField = toSortingField(searchParams?.get(fieldURLSearchParamsName))
    const currentSortingDefinitionId = searchParams?.get(definitionIdUrlSearchParamsName)

    const toggleDirection = () => {
        if (sortingDirection === 'asc') return 'desc'
        return 'asc'
    }

    const isSortingByDefinition = (newField: string) => newField && uuidValidate(newField)
    const handleSorting = (column: SearchResultDataTableColumnModel) => {
        const newField = column.dataKey
        let newDirection: SortingDirection = 'asc'

        // if we stay on the same filter, we toggle the newDirection
        if ((newField === sortingField) || (newField === currentSortingDefinitionId)) {
            newDirection = toggleDirection()
        }

        searchParams.set(directionURLSearchParamsName, newDirection)

        if (isSortingByDefinition(newField)) {
            searchParams.set(fieldURLSearchParamsName, SortingField.DEFINITION_VALUE)
            searchParams.set(definitionIdUrlSearchParamsName, newField)
        } else {
            searchParams.set(fieldURLSearchParamsName, newField)
            searchParams.delete(definitionIdUrlSearchParamsName)
        }

        searchParams.set(pageURLSearchParamsName, '1')
        setSearchParams(searchParams)
    }

    const isNotCurrentSortingColumn = (dataKey: string) =>{
        return (dataKey !== sortingField && !currentSortingDefinitionId) || (dataKey !== currentSortingDefinitionId && currentSortingDefinitionId)
    }

    const getDisplayDirection = (column: SearchResultDataTableColumnModel): SortingDirection => {
        // by default the arrow is going down when descending in mui
        // but we want it down when we are filtering ascending, so we inverse it
        if (isNotCurrentSortingColumn(column.dataKey)) return 'desc'
        return sortingDirection === 'asc' ? 'desc' : 'asc'
    }

    return <TableRow className="item-search-data-table-header">
        {columns.map((column) => (
            <TableCell
                key={column.dataKey}
                className="item-search-data-table-cell"
                style={{
                    width: column.width,
                }}>
                {
                    !column.sortable && column.label
                }
                {column.sortable && <TableSortLabel
                    active={column.dataKey === sortingField}
                    direction={getDisplayDirection(column)}
                    onClick={() => handleSorting(column)}
                    className={'sortable-header-column ' + (column.dataKey + '-sort-button')}
                >
                    <span className={'header-column-title'}>{column.label}</span>
                </TableSortLabel>}
            </TableCell>
        ))}
    </TableRow>
}

