import React from 'react'

import {CadModelAttribute} from '../attributeTemplates/CadModelAttribute/CadModelAttribute'
import {AttributeDefinitionModel} from '../model/AttributeModel'
import {sortAttributesByDisplayOrder} from '../utils/sortedDefinitions'


export const CadModelDefinitionTemplate = ({definition}: { definition: AttributeDefinitionModel }) => {
    const sortedAttributes = sortAttributesByDisplayOrder(definition.attributes)
    return <>
        {sortedAttributes.map(attribute => <CadModelAttribute attribute={attribute} key={attribute.id}/>)}
    </>
}
