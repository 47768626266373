import {FormControl, MenuItem, Select, SelectChangeEvent} from '@mui/material'
import React, {ReactNode} from 'react'
import {useFormikContext} from 'formik'
import {AttributeStringUpdateForm} from '../PrivateAttributeUpdate'
import {SelectItemList} from '../../../genericComponents/SelectItemList'
import {Label} from '../../../../../genericComponents/typographie/Label'
import {ServiceRequestStep} from '../../../genericComponents/formTemplate/steps/ServiceRequestStep'
import {KeyboardArrowDown} from '@mui/icons-material'
import LoadingSpinner from '../../../../../genericComponents/LoadingSpinner'
import {AttributeDefinition} from '../../../../../search/model/AttributeDefinition'
import {useGetPrivateDefinitionsManagedByGuildQuery} from '../../../../../search/rtkSearchApi'
import {useCurrentGuildContext} from '../../../../../util/routing'
import {ItemList} from '../../../../../itemList/model/ItemList'
import {PrivateAttributeDefinitionType} from '../../model/PrivateAttributeDefinitionType'

export const DefineUpdateStringUpdate = ({ setPrivateDefinition, setItemList }: {
    setPrivateDefinition: (updatedPrivateDefinition: PrivateAttributeDefinitionType | undefined) => void
    setItemList: (updatedItemList: ItemList | undefined) => void
}) => {
    const formik = useFormikContext<AttributeStringUpdateForm>()
    const [currentGuildContext] = useCurrentGuildContext()
    const {data: privateDefinitions = [], isLoading: privateDefinitionsIsLoading} =
        useGetPrivateDefinitionsManagedByGuildQuery(
            {guildId: currentGuildContext?.guildId},
            {skip: !currentGuildContext?.guildId}
        )

    const resetUploadedFile = () => {
        formik.setFieldValue('file', undefined)
    }

    const handleListChange = (event: SelectChangeEvent<ItemList>, _: ReactNode) => {
        resetUploadedFile()
        formik.handleChange(event)
        setItemList(event.target.value as ItemList)
    }

    return <ServiceRequestStep>
        <FormControl>
            <Label id="select-attribute-label" className="select-attribute-label" htmlFor="itemList" required>
                Select an existing private attribute
            </Label>
            <Select
                size="small"
                displayEmpty
                variant="outlined"
                data-testid="private-definition-dropdown-selector"
                required
                id="privateDefinition"
                name="privateDefinition"
                value={formik.values.privateDefinition}
                onChange={(event: SelectChangeEvent<PrivateAttributeDefinitionType>) => {
                    formik.handleChange(event)
                    setPrivateDefinition(event.target.value as PrivateAttributeDefinitionType)
                }}
                className="select-private-definition"
                placeholder="Select existing private attribute"
                renderValue={(def: AttributeDefinition) =>
                    def?.name ?? <span className="placeholder">Select existing private attribute</span>
                }
                aria-invalid={formik.touched.privateDefinition && Boolean(formik.errors.privateDefinition)}
                IconComponent={KeyboardArrowDown}
            >
                {privateDefinitionsIsLoading ? <LoadingSpinner />
                    : privateDefinitions?.length > 0 &&
                    privateDefinitions.map((definition) => (
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        <MenuItem
                            value={definition}
                            data-testid="menuitem-dropdown-selector"
                            key={definition.id}
                            autoFocus={false}
                            data-cy={`select-option-${definition.name}`}
                            sx={{fontSize: '14px'}}
                        >
                            {definition.name}
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>

        <SelectItemList
            selectedList={formik.values.itemList}
            handleChange={handleListChange}
            touched={formik.touched.itemList}
            label="Update to be made for all items in this list"
            errors={formik.errors.itemList}
            required
        />
    </ServiceRequestStep>
}