import React from 'react'
import {ItemPriceRule} from '../QuotationRule'
import {useTypedDispatch} from '../../../../util/store'
import {CopyButton} from '../../../../genericComponents/CopyButton'
import {setSnackbarMessage} from '../../../../genericComponents/commonSlice'

export const ItemPriceRuleComponent = ({rule}: {rule: ItemPriceRule}) => {
    const dispatch = useTypedDispatch()

    return <div className="quotation-detail-rule-structure">
        <div className="quotation-detail-description quotation-detail-item-name">
            <p className="quotation-detail-item-name-text">{rule.itemName}</p>
            <CopyButton toCopy={rule.itemName}
                        onCopySuccess={() => dispatch(setSnackbarMessage('Item name was successfully copied to your clipboard'))} />
        </div>
        <p className="quotation-detail-unit-price">{rule.price}</p>
        <p className="quotation-detail-total">{rule.calculate(0)}</p>
    </div>
}