import {guildAPI} from './../guild/rtkGuildApi'
import {Banner} from './model/Banner'

export const BANNER_TAG = 'Banners'

export const bannerApi = guildAPI.enhanceEndpoints({
    addTagTypes : [BANNER_TAG]
}).injectEndpoints({
    endpoints: (builder) => ({
        getEnabledBanners : builder.query<Banner[], void>({
            query : () => ({
                url : 'banners/enabled',
                method: 'GET'
            }),
            transformResponse: (response:any) => response.data,
            transformErrorResponse: () => 'Error getting enabled banners',
            providesTags: [BANNER_TAG]
            })
        })
    })

export const {useGetEnabledBannersQuery} = bannerApi
