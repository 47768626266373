import React from 'react'
import '../../../styles/Forms.scss'
import {Button} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import {InformationPackage} from '../../../../../item/model/InformationPackage'

export const ValidationFormInformationPackageAccessForItem = (
    {
        informationPackage,
        itemId,
        isLoading,
        closeDialog,
        handleConfirmItemInformationPackageRequest
    }: {
        informationPackage: InformationPackage,
        itemId: string,
        isLoading: boolean,
        closeDialog: () => void,
        handleConfirmItemInformationPackageRequest: (itemId: string, informationPackage: InformationPackage) => void
    }) => {
    return (
        <div>
            <p>
                Confirm request access to the following information package ?
            </p>
            <li className="information-package-line">
                {informationPackage.label}
            </li>
            <div className="dialog-actions">
                <Button color="secondary" onClick={() => closeDialog()}>Back</Button>
                <LoadingButton loading={isLoading} variant="contained"
                               onClick={() => handleConfirmItemInformationPackageRequest(itemId, informationPackage)}>Confirm</LoadingButton>
            </div>
        </div>
    )
}