import React, {useEffect, useState} from 'react'
import {ServiceRequest} from '../../../service/model/ServiceRequest'
import {UpdateDialogMessages, UpdateDialogContent} from '../modals/model/UpdateDialogContent'
import ApproveOptionsMenu from './ApproveOptionsMenu'
import { useAcceptQuotationMutation, useRejectQuotationMutation } from '../../../service/serviceRequests/rtkServiceRequestApi'


const approvedContent : UpdateDialogMessages = {
        confirmationMsg: 'Click Approve to accept the quotation and send the service request to Datx production team',
        titleMsg: 'Approve quotation',
        approveButtonLabel: 'Approve',
        approveMenu: 'Approve Quotation'
}

const rejectedContent : UpdateDialogMessages = {
        confirmationMsg: 'Click Reject to reject the quotation and terminate the service request',
        titleMsg: 'Reject quotation',
        approveButtonLabel: 'Reject',
        approveMenu: 'Reject Quotation'
}

const updateDialogContent : UpdateDialogContent= {
    approvedContent: approvedContent,
    rejectedContent: rejectedContent
}

const ApproveQuotation = ({row}: { row: ServiceRequest }) => {
    const [acceptQuotation, acceptQuotationResult] = useAcceptQuotationMutation()
    const [rejectQuotation, rejectQuotationResult] = useRejectQuotationMutation()

   const approvedRequest = () => {
       if(row.id)
            acceptQuotation(row.id)

   }

   const rejectedRequest = () => {
        if( row.id) {
            rejectQuotation(row.id)
        }
    }

    return <>
        <ApproveOptionsMenu row={row}
                            isLoading = {acceptQuotationResult.isLoading || rejectQuotationResult.isLoading}
                            updateDialogContent={updateDialogContent}
                            approvedRequest={approvedRequest}
                            rejectedRequest={rejectedRequest}/>
    </>
}

export default ApproveQuotation
