import {IconDisplay} from '../genericComponents/IconDisplay'
import {ItemTypeEnum} from './model/ItemType'

const itemTypeMap = new Map([
    [ItemTypeEnum.ABSTRACT.toUpperCase(), '/images/item-type/abstract_item.svg'],
    [ItemTypeEnum.COMPANY.toUpperCase(), '/images/item-type/company_item.svg'],
    [ItemTypeEnum.MANUFACTURER.toUpperCase(), '/images/item-type/manufacturer_item.svg'],
    ['default', '/images/item-type/company_item.svg']
])

const ItemTypeChip = ({itemTypeLabel}: {itemTypeLabel: string}) => (
    <IconDisplay
        className="item-card-image"
        iconName={itemTypeLabel}
        iconSourceMap={itemTypeMap}
        iconLabel={`item nature ${itemTypeLabel}`}
    />
)

export default ItemTypeChip
