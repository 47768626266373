import React, { useContext } from 'react'
import {Organization} from '../../model/Organization'
import {DisplaySetTreeComponent} from '../../../displaySet/DisplaySetTreeComponent'
import {OrganizationAddressFakeDisplaySet} from '../fakeDisplaySets/OrganizationAddressFakeDisplaySet'
import {OrganizationContactFakeDisplaySet} from '../fakeDisplaySets/OrganizationContactFakeDisplaySet'
import { DisplaySetContext } from '../../../displaySet/DisplaySetContext'

export const OrganizationDetailsContent = ({organization}: {
    organization: Organization,
}) => {
    const {selectedDisplaySet} = useContext(DisplaySetContext)

    const isFirstDisplaySet = (): boolean => {
        if(!selectedDisplaySet) return true

        if(!organization?.displaySets || organization?.displaySets?.length <= 1)
            return true

        const firstDisplaySetPerOrder = organization.displaySets.reduce((prev, curr) => prev.displayOrder < curr.displayOrder ? prev : curr)
        return firstDisplaySetPerOrder.id === selectedDisplaySet.id
    }

    return <div className="organization-details-content details-content details-page-section">
        <div className="display-sets">
            {/*Hardcode in the first display set the address and contact because they are not display sets*/}
            {isFirstDisplaySet() && <>
                    <OrganizationAddressFakeDisplaySet organization={organization}></OrganizationAddressFakeDisplaySet>
                    <OrganizationContactFakeDisplaySet organization={organization}></OrganizationContactFakeDisplaySet>
                </>
            }

            {selectedDisplaySet &&
                <DisplaySetTreeComponent
                    key={selectedDisplaySet?.displayOrder ?? Math.random()}
                    displaySet={selectedDisplaySet}
                />
            }
        </div>
    </div>
}
