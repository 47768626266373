import {SearchResultDTO} from '../../../model/SearchResultDTO'
import {Search} from '../../../model/SearchFilter'
import React, {ChangeEvent} from 'react'
import {useSearchResultControl} from '../../useSearchResultControl'
import SearchResultCardGrid from '../SearchResultCardGrid'
import ItemSearchModal from '../AddItemToListModal/AddItemToListModal'
import {NoData} from '../../../../genericComponents/NoData'
import LoadingSpinner from '../../../../genericComponents/LoadingSpinner'
import {SearchPagination} from '../../../searchPagination/SearchPagination'

export const ExactSearchResult = ({searchResult, page, search, isFetching, handlePageChange}: {
    searchResult: SearchResultDTO | undefined,
    page: number,
    search: Search,
    isFetching: boolean
    handlePageChange: (e: ChangeEvent<unknown>, pageNumber: number) => void,
}) => {

    const {
        handleAddSingleItemToList,
        pageSize,
        addItemsToListModalVisible,
        setAddItemsToListModalVisible,
        finalItemsToBeAddedToList
    } = useSearchResultControl({searchResult, page})

    const nbPages = Math.ceil((searchResult?.totalElements || 0) / pageSize)
    if (isFetching) return <LoadingSpinner/>
    if (!searchResult || searchResult?.elements.length === 0) return <NoData message="No exact match found"/>


    return <div>
        <div className="search-results-table">
            <SearchResultCardGrid
                searchItems={searchResult?.elements ?? []}
                handleAddSingleItemToList={handleAddSingleItemToList}
                isMultiSelectionEnabled={false}
                highlightPhrase={search.phrase?.phraseDetail}
            />
        </div>
        {nbPages > 1 && <SearchPagination totalElements={searchResult?.totalElements || 0}
                                          pageSize={pageSize}
                                          page={page}
                                          handlePageChange={handlePageChange}/>
        }
        {addItemsToListModalVisible &&
            <ItemSearchModal closeModal={() => setAddItemsToListModalVisible(false)}
                             itemList={finalItemsToBeAddedToList}/>}
    </div>
}