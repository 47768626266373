import {Search} from '../../../model/SearchFilter'
import {IdType} from '../../../../util/models/IdType'
import {selectClassificationTree, useSearchItemsQuery} from '../../../rtkSearchApi'
import {useDatxPathGenerator} from '../../../../util/routing'
import {useSelector} from 'react-redux'
import React, {ChangeEvent, useEffect, useState} from 'react'
import {BreadCrumbs, BreadcrumbType} from '../../../../genericComponents/breadcrumbs/BreadCrumbs'
import {getPathToClassification} from '../../../../util/classifications/Classification'
import {ROUTES} from '../../../../constants/routing'
import {Box} from '@mui/material'
import SearchFilters from '../../../searchFilter/SearchFilters'
import {
    SearchResultsComponentForClassificationAndPhraseSearch
} from '../SearchResultsComponent/SearchResultsComponentForClassificationAndPhraseSearch'

export const SearchResultPageForClassificationNode = ({search, page, classificationId, pageSize, handlePageChange}: {
    search: Search,
    page: number,
    classificationId: IdType,
    pageSize: number,
    handlePageChange: (e: ChangeEvent<any>, pageNumber: number) => void
}) => {
    const {data: searchResult, isFetching} = useSearchItemsQuery({
        search: search,
        pageNumber: page - 1,
        pageSize: pageSize
    })
    const generatePath = useDatxPathGenerator()
    const classificationTree = useSelector(selectClassificationTree)
    const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbType[]>([])

    const getBreadcrumbs = () => {
        if (!classificationId) return
        const classificationPath = getPathToClassification(classificationId, classificationTree)

        setBreadcrumbs(classificationPath.map(classification => {
            return {
                link: {
                    pathname: generatePath(ROUTES.searchByClassificationNodeDescendants.path, {classificationId: classification.id}),
                    search: 'page=1'
                },
                label: classification?.label,
                id: classification.id
            }
        }))
    }

    useEffect(() => {
        getBreadcrumbs()
    }, [classificationId, classificationTree])


    return <Box className="item-list-page">
        {searchResult &&
            <BreadCrumbs breadcrumbList={breadcrumbs} totalResults={searchResult.totalElements}
                         isFetching={isFetching}/>}
        <SearchFilters search={search}/>
        <SearchResultsComponentForClassificationAndPhraseSearch
            searchResult={searchResult}
            isFetching={isFetching}
            page={page}
            search={search}
            handlePageChange={handlePageChange}
            // Added key : If one of these attribute changes, the searchResults Component is reredended
            key={`${search.guildId}_${search.phrase}_${search.technicalClassificationNodeId}`}
        />
    </Box>
}