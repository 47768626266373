import {AttributeModel} from './AttributeModel'
import {IdType} from '../../util/models/IdType'
import {ItemOrganization} from '../../item/model/ItemOrganization'



export interface AttributeValue {
    value?: string
    type?: AttributeValueType
    classificationNode?: ClassificationNode
    linkedItem?: RelatedItem
    composedByAttributes: AttributeModel[]
    document?: Document
}

export interface RelatedItem {
    definedByOrganization: ItemOrganization
    id: string
    label: string
    partNumber: string
    type: ClassificationNode
    technical: ClassificationNode
    nature?: ClassificationNode
    linkCreatedDate?: number[] | null
}

export interface ClassificationNode {
    id: IdType
    code: string
    label: string
    parentId: IdType
    children: ClassificationNode[] // Assuming children can also be of type ClassificationNode
    iconName: string | null
    classificationId: IdType
}

export interface PackVariant {
    containerType?: string
    quantityOfContent?: string
    unit?: string
}

export interface Document {
    id: string
    externalUrl: string | null
    languages: string[]
    startOfValidity: number[]
    file: FileDTO | null,
    name?: string | null,
    attributes: AttributeModel[] | null
}

export interface FileDTO {
    name: string
    status: string
    uri: string | null
}

export enum FileStatus {
    VALID = 'Valid'
}

export enum AttributeValueType {
    CLASSIFICATION_LINK = 'CLASSIFICATION_LINK',
    SIMPLE = 'SIMPLE',
    COMPLEX = 'COMPLEX',
    ITEM_LINK = 'ITEM_LINK',
    ARTIFACT = 'ARTIFACT',
    REFERENCE_LIST = 'REFERENCE_LIST'
}
