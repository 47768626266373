import React, {useMemo, useState} from 'react'
import {InputAdornment, TextField} from '@mui/material'
import './SearchFilterCard.scss'
import SearchIcon from '@mui/icons-material/Search'
import {DisplayFilterDTO, ValueOption} from '../model/DisplayFiltersDTO'

const TextFilterInput = ({filter, setSelectableValues}: {
    filter: DisplayFilterDTO,
    setSelectableValues: React.Dispatch<React.SetStateAction<ValueOption[]>>,
}) => {
    const [search, setSearch] = useState('')

    const sortValues = (a: ValueOption, b: ValueOption): number => {
        return a.value.localeCompare(b.value)
    }
    const filterSelectableValues = (values: ValueOption[]) => {
        const sortedValues = [...values]
        sortedValues.sort(sortValues)
        if (!search) return sortedValues
        return sortedValues?.filter(option => option?.value?.toLowerCase()?.includes(search))
    }

    useMemo(() =>
            setSelectableValues(filterSelectableValues(filter?.values ?? []))
        , [search, filter.values])

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value?.toLowerCase())
    }

    return (
        <TextField
            className="search-filter-input"
            label={`Search ${filter.name}`}
            value={search}
            onChange={handleSearch}
            variant="outlined"
            margin="normal"
            InputLabelProps={{
                classes: {
                    root: 'cssLabel',
                },
            }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <SearchIcon className="search-icon"/>
                    </InputAdornment>
                ),
            }}
        />
    )

}

export default TextFilterInput