import {CookiesCategory, CookieVendor} from '../../CookiesTypes'
import {Switch} from '@mui/material'
import {ChangeEvent, MouseEvent} from 'react'

export const AccordionSwitch = ({category, type, handleSwitch, checked, inCategoryDialog}: {
    type: CookieVendor,
    category: CookiesCategory,
    handleSwitch: (event: ChangeEvent<HTMLInputElement>) => void,
    checked: boolean,
    inCategoryDialog: boolean,

}) => {

    return category === CookiesCategory.RequiredCookies
        ? <div className="accordion-switch always-active">Always Active</div>
        : <Switch className={`accordion-switch ${inCategoryDialog? 'category': 'vendor'}`}
                  value={inCategoryDialog ? category : type}
                  size="small"
                  name={inCategoryDialog ? `cookiesConsent.cookieCategories.${category}.userConsent` : `categoryConsent.cookies.${type}.userConsent`}
                  checked={checked}
                  onClick={(e: MouseEvent) => e.stopPropagation()}
                  onChange={handleSwitch}
                  inputProps={{'aria-label': 'accordion-switch'}}
                  data-testid={inCategoryDialog ? `switch-${category}` : `switch-${type}`}
        ></Switch>
}