import {excelColumnHeader, excelColumnHeaderForPartMatching} from '../serviceRequests/readImportedFile'

export interface NewItemInput {
    PART_NUMBER: string
    ORG_NAME?: string
    MANUAL_DESCR_EN: string
    LINKED_MANUFACTURER_ITEM_ID: string
    ITEM_NAME: string
}

export class NewItemInputDTO {
    partNumber: string
    organizationName?: string
    description: string
    linkedManufacturerItemId: string
    itemName: string

    constructor(item: any) {
        this.partNumber = item[excelColumnHeader.partNumber]
        this.organizationName = item[excelColumnHeader.orgName]
        this.description = item[excelColumnHeader.itemDescription]
        this.linkedManufacturerItemId = item[excelColumnHeader.manufacturerId]
        this.itemName = item[excelColumnHeader.itemName]
    }
}

export interface ItemMatchingInput {
    PARTNUMBER_IN: string
    MANUFACTURER_IN: string
}
export class ItemMatchingInputDTO {
    partNumberIn: string
    manufacturerIn: string

    constructor(item: any) {
        this.partNumberIn = item[excelColumnHeaderForPartMatching.partNumber]
        this.manufacturerIn = item[excelColumnHeaderForPartMatching.orgName]
    }
}
