import React, {SyntheticEvent} from 'react'
import {Link} from 'react-router-dom'
import './ServiceRequestChoiceCard.scss'
import {CardTitle} from '../../../genericComponents/typographie/CardTitle'
import {Description} from '../../../genericComponents/typographie/Description'
import {CustomTooltip} from '../../../genericComponents/customTooltip/CustomTooltip'
import InfoIcon from '@mui/icons-material/Info'

export interface Disabled {
    isDisabled: boolean
    reason: string
}

export const ServiceRequestChoiceCard = ({
    icon,
    title,
    description,
    id,
    to,
    disabled
}: {
    icon?: React.ReactNode
    title?: string
    description?: string
    to: string
    id: string
    disabled?: Disabled
}) => {
    const onClick = (event: SyntheticEvent) => {
        if (disabled?.isDisabled) event.preventDefault()
    }

    return (
        <Link
            to={to}
            className={`service-request-choice-card ${disabled?.isDisabled ? 'isDisabled' : ''}`}
            data-testid={id}
            id={id}
            onClick={onClick}
        >
            <div className="icon-container">
                {icon && <div className="card-icon">{icon}</div>}
                {disabled?.isDisabled && (
                    <CustomTooltip title={disabled?.reason} placement="right-end">
                        <InfoIcon className="card-info-icon" />
                    </CustomTooltip>
                )}
            </div>
            {title && <CardTitle title={title}>{title}</CardTitle>}
            {description && <Description className="card-description">{description}</Description>}
        </Link>
    )
}
