import React from 'react'
import {Chip} from '@mui/material'
import './CustomChip.scss'

export const SuccessChip = ({ label, className, icon}: { label: string, className?: string, icon?: React.ReactElement}) => {
    return (
        <Chip className={'custom-chip success-chip ' + (className ? className : '')}
              icon={icon}
              size="small"
              label= {label}/>
    )
}