import React, {useState} from 'react'
import {Checkbox, ListItemText, MenuItem, Select, SelectChangeEvent} from '@mui/material'
import TextFilterInputInSelect from './TextFilterInputInSelect'
import {User} from '../../../../user/model/User'
import {IdType} from '../../../../util/models/IdType'
import {FormikContextType, useFormikContext} from 'formik'
import {WorkflowConfigurationForm} from '../WorkflowConfigurationTemplate'

export const UserSelectionDropdown = ({name, defaultValue, users}: {
    name: string,
    defaultValue: string,
    users: User[]
}) => {
    const formik: FormikContextType<WorkflowConfigurationForm> = useFormikContext()
    const fieldValue = formik.getFieldProps(name).value

    const [usersSearchText, setUsersSearchText] = useState('')

    const areAllUsersSelected = (selectedIds: IdType[]): boolean => {
        return selectedIds.length === users.length
    }

    const handleUsersChange = (event: SelectChangeEvent<IdType[]>) => {
        const value = event.target.value

        if (users.length && value[value.length - 1] === 'All') {
            handleSelectAllUsers()
            return
        }
        formik.setFieldValue(name, value)
    }

    const handleSelectAllUsers = () => {
        if (fieldValue.length >= 0 && fieldValue.length < users.length) {
            formik.setFieldValue(name, users.map(u => u.id))
            return
        }

        formik.setFieldValue(name, [])
    }

    return <Select
        id={name}
        {...formik.getFieldProps(name)}
        multiple
        onChange={handleUsersChange}
        renderValue={(selected) => {
            return selected?.length ? `${selected?.length} selected` : defaultValue
        }}
        MenuProps={{autoFocus: false}}
        displayEmpty
    >
        <div className="filter-input" role="group">
            <TextFilterInputInSelect
                searchText={usersSearchText}
                setSearchText={setUsersSearchText}
                inputClassName="search-text-input"
                label="Search User"
            ></TextFilterInputInSelect>
        </div>
        <MenuItem value="All" className="user-items">
            <Checkbox size="small" checked={areAllUsersSelected(fieldValue)} />
            <ListItemText className="menu-item-label" primary="(Select All)" />
        </MenuItem>
        {users.filter(user => user.email.includes(usersSearchText)).map((user) => (
            <MenuItem key={user.id} value={user.id} className="user-items"
                      data-testid={`${name}-${user.email}`}>
                <Checkbox
                    checked={fieldValue.includes(user.id)} />
                <ListItemText className="menu-item-label" primary={user.email} />
            </MenuItem>
        ))}
    </Select>
}
