import {Organization} from '../../model/Organization'
import React from 'react'
import {OrganizationIcon} from './OrganizationDetailsHeader'
import {SecondaryTitle} from '../../../genericComponents/typographie/SecondaryTitle'

export const OrganizationDetailsHeaderContent = ({organization}: { organization: Organization }) => {
    return <div className="organization-header">
        <div className="organization-header-image ellipse">
            <OrganizationIcon/>
        </div>
        <div className="organization-header-content">
            <SecondaryTitle title={organization?.acronym + ' - ' + organization?.label}>
                {organization?.acronym} - {organization?.label}
            </SecondaryTitle>
        </div>
    </div>
}