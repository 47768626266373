import {IconButton, Menu, MenuItem} from '@mui/material'
import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useCurrentGuildContext, useDatxPathGenerator} from '../../../util/routing'
import {ROUTES} from '../../../constants/routing'
import {IdType} from '../../../util/models/IdType'
import {setSnackbarMessage} from '../../../genericComponents/commonSlice'
import {useTypedDispatch} from '../../../util/store'

export const MoreOptionsMenu = ({itemId}: { itemId: IdType }) => {
    const [currentGuildContext] = useCurrentGuildContext()
    const navigate = useNavigate()
    const dispatch = useTypedDispatch()
    const generatePath = useDatxPathGenerator()
    const [anchorEl, setAnchorEl] = useState<Element | null>(null)

    const open = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const openItemUpdateForm = () => {
        if (!currentGuildContext) {
            dispatch(setSnackbarMessage('You have to select a guild in order to subscribe to a service.'))
        } else {
            navigate(generatePath(ROUTES.itemUpdate.path, {itemId: itemId}))
        }
    }

    const closeMenu = () => setAnchorEl(null)

    return <>
        <IconButton className="datxIcon" data-testid="item-more-action-button" onClick={handleClick}>
            <img alt="more action" src="/images/custom-icons/MoreVertical.svg"/>
        </IconButton>
        <Menu open={open} onClose={closeMenu} anchorEl={anchorEl}
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
              }}
              transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
              }}
        >
            <MenuItem onClick={openItemUpdateForm} data-testid="item-update-request">Request Item Update</MenuItem>
        </Menu>
    </>
}