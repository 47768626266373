import {Snackbar as MuiSnackbar} from '@mui/material'
import {useTypedDispatch, useTypedSelector} from '../util/store'
import {clearSnackbarMessage} from './commonSlice'

export const Snackbar = () => {
    const {
        snackbar: {message}
    } = useTypedSelector((state) => state.common)
    const dispatch = useTypedDispatch()

    const handleRequestClose = () => {
        dispatch(clearSnackbarMessage())
    }

    return (
        <MuiSnackbar
            id="snackbar-message"
            data-testid="snackbar-message"
            autoHideDuration={4000}
            open={message !== ''}
            message={message}
            onClose={handleRequestClose}
        />
    )
}
