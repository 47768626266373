import React from 'react'
import {ClassificationType} from '../../../../../item/model/ClassificationType'
import {ItemNatureIcon} from '../../../../../item/itemNature/ItemNatureIcon'


const ItemNature = ({nature}: { nature: ClassificationType | undefined }) => {
    return (
        <span className="item-nature-icon">
            <ItemNatureIcon nature={nature}/>
        </span>
    )
}
export default ItemNature