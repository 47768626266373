import {ReactElement, useState} from 'react'
import './CustomCard.scss'
import Checkbox from '@mui/material/Checkbox'
import {IdType} from '../../util/models/IdType'
import {Button, Typography} from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import Popover from '@mui/material/Popover'


export const CustomCard = ({
                               id,
                               name,
                               bodyContent,
                               handleChange,
                               actionList,
                               cssClass = '',
                               typeIcon,
                               checked,
                               moreOptionMenu = false,
                               displaySelectionCheckbox = true
                           }: {
    id: IdType,
    name: string,
    bodyContent?: ReactElement,
    handleChange: ((id: IdType, checked: boolean) => void),
    actionList?: ReactElement,
    cssClass?: string,
    moreOptionMenu?: boolean,
    checked: boolean,
    typeIcon: ReactElement | null,
    displaySelectionCheckbox?: boolean
}) => {
    const [optionsOpen, setOptionOpen] = useState<boolean>(false)

    const handleCheckbox = () => {
        handleChange(id, !checked)
    }

    const handleOptions = (open: boolean) => {
        setOptionOpen(open)
    }

    const selected = optionsOpen || checked

    return (
        <div className={[selected ? 'selected' : '', 'card'].join(' ')} key={id} data-testid={id} aria-label={name}>
            <div className={`card-header ${cssClass}`}>
                {displaySelectionCheckbox && <Checkbox
                    className="select-item"
                    size="small"
                    checked={checked}
                    onChange={handleCheckbox}
                    onClick={(event) => {
                        handleCheckbox()
                        event?.preventDefault()
                        event?.stopPropagation()
                    }
                    }
                    sx={{
                        padding: '9px 0px 9px 9px'
                    }}
                    title="Select card"
                    name="custom-card-checkbox"
                />}
                {typeIcon && moreOptionMenu && <div className={'item-list-card-type'}>{typeIcon}</div>}
                <div className={'card-action'} data-testid="action-icon" onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                }}>
                    {actionList && moreOptionMenu && <MoreOptions actionList={actionList} openChange={handleOptions}/>}
                    {actionList && !moreOptionMenu && actionList}
                </div>
                {typeIcon && !moreOptionMenu && <div className={'card-type'}>{typeIcon}</div>}
            </div>
            <div className={`card-body ${cssClass}`}>
                {bodyContent}
            </div>
        </div>
    )
}


export const MoreOptions = ({
                                actionList,
                                openChange
                            }: { actionList: ReactElement, openChange: (open: boolean) => void }) => {

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined


    const handleOnClickIcon = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
        openChange(Boolean(event.currentTarget))
        event.stopPropagation()
        event.preventDefault()
    }


    const handleOnCloseIcon = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(null)
        openChange(false)
        event.stopPropagation()
        event.preventDefault()
    }


    return (
        <>
            <Button onClick={handleOnClickIcon} className="more-actions-button" data-testid="3-dot-button-on-list">
                <MoreHorizIcon/>
            </Button>

            <Popover
                id={id}
                open={open}
                onClose={handleOnCloseIcon}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Typography sx={{p: 1}}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '3px'
                            }}
                >
                    {actionList}
                </Typography>
            </Popover>
        </>
    )
}
