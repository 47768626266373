import React, {ChangeEvent, SyntheticEvent} from 'react'
import '../../styles/Forms.scss'
import {FormControl} from '@mui/material'
import {setSnackbarMessage} from '../../../../genericComponents/commonSlice'
import {ItemMatchingInputDTO, NewItemInputDTO} from '../../../model/NewItemInput'
import {Label} from '../../../../genericComponents/typographie/Label'
import UploadCommonLoadingButton from '../../genericComponents/UploadCommonLoadingButton'
import {useTypedDispatch} from '../../../../util/store'
import {useFormikContext} from 'formik'
import {ManufacturerItemCreationForm} from '../newManufacturerItems/NewManufacturerItemsForm'

export const UploadTemplateItemCreation = ({
    description: Description,
    handleFileUpload
}: {
    description: React.ComponentType
    handleFileUpload: (tempFile: File) => Promise<NewItemInputDTO[] | ItemMatchingInputDTO[]>
}) => {
    const formik = useFormikContext<ManufacturerItemCreationForm>()

    const setFile = (file: File | undefined) => {
        formik.setFieldValue('file', file)
    }

    const setItems = (items: NewItemInputDTO[] | ItemMatchingInputDTO[]) => {
        formik.setFieldValue('items', items)
    }

    const unselectFile = (event: SyntheticEvent) => {
        setFile(undefined)
        setItems([])
        event.preventDefault()
    }

    const dispatch = useTypedDispatch()

    async function parseFile(event: ChangeEvent<HTMLInputElement>) {
        if (event.target?.files?.length) {
            const tempFile = event.target.files[0]
            if (tempFile.size > 10 * 1000 * 1000) {
                dispatch(setSnackbarMessage('Your file is too big. Max size is 10 Mo'))
                return
            }
            setFile(tempFile)
            const items = await handleFileUpload(tempFile)
            if (items.length === 0) {
                dispatch(setSnackbarMessage('You have to upload a spreadsheet containing at least one part number'))
                return
            }
            setItems(items)
        }
    }
    return (
        <div className="service-request-form-step">
            <div className="step-upload-title">
                <Description />
            </div>
            <FormControl className="download-upload-template-container">
                <Label className="file-download-upload-label" htmlFor="upload-file">
                    Upload the completed template <span className="red-asterisk">*</span>
                </Label>
                <UploadCommonLoadingButton
                    className="upload-template-button"
                    selectedFileName={formik.values.file?.name ?? ''}
                    unselectFile={unselectFile}
                    parseFile={parseFile}
                />
            </FormControl>
        </div>
    )
}
