import '../genericComponents/structure/ServiceRequest.scss'

import {setSnackbarMessage} from '../../../genericComponents/commonSlice'
import {useTypedDispatch} from '../../../util/store'
import {ItemList} from '../../../itemList/model/ItemList'
import {InformationPackage} from '../../../item/model/InformationPackage'
import {useCurrentGuildContext, useDatxPathGenerator} from '../../../util/routing'
import {useCreateAccessToInformationPackageServiceRequestForListMutation} from '../rtkServiceRequestApi'
import {ROUTES} from '../../../constants/routing'
import {getServiceName, ServiceEnum} from '../../model/ServiceEnum'
import {mixed, object} from 'yup'
import {ServiceRequestFormTemplate} from '../genericComponents/formTemplate/ServiceRequestFormTemplate'
import {ServiceRequestStep} from '../model/ServiceRequestStep'
import {UploadInformationPackageAccessForm} from './Forms/UploadForm/UploadInformationPackageAccessForm'
import ReviewInformationPackageAccess from './Forms/ValidationForm/ReviewInformationPackageAccess'


export interface AccessToInformationPackageInput {
    itemList?: ItemList,
    informationPackage?: InformationPackage
}

const validationSchema = object<AccessToInformationPackageInput>({
    itemList: mixed().required(),
    informationPackage: mixed().required()
})

const AccessToInformationPackage = () => {
    const dispatch = useTypedDispatch()

    const [currentGuildContext] = useCurrentGuildContext()
    const [createRequest] =
        useCreateAccessToInformationPackageServiceRequestForListMutation()

    const generatePath = useDatxPathGenerator()

    const steps: ServiceRequestStep<AccessToInformationPackageInput>[] = [
        {
            name: getServiceName(ServiceEnum.SUBSCRIBE_INFORMATION_PACKAGE),
            component: <UploadInformationPackageAccessForm />,
            pageValidation: validationSchema,
            description: 'By filling this form, you will be requesting access to an information package for your chosen list of components.'
        },
        {
            name: 'Review and Submit',
            component: <ReviewInformationPackageAccess />,
            pageValidation: validationSchema
        }
    ]

    const handleCompleteUploadForm = ({
                                          itemList,
                                          informationPackage
                                      }: AccessToInformationPackageInput): Promise<any> | undefined => {
        if (!informationPackage) {
            dispatch(setSnackbarMessage('You have to select an information package in order to request access to information package.'))
            return
        }

        return createRequest({
            guildId: currentGuildContext?.guildId ?? '',
            itemListIds: [itemList?.id ?? ''],
            informationPackageId: informationPackage.id,
            informationPackageName: informationPackage.label
        })
    }

    return (
        <ServiceRequestFormTemplate
            steps={steps}
            handleFormSubmit={handleCompleteUploadForm}
            initialValues={{
                itemList: undefined,
                informationPackage: undefined
            }}
            previousUrl={generatePath(ROUTES.servicesCatalogue.path)}
            title={getServiceName(ServiceEnum.SUBSCRIBE_INFORMATION_PACKAGE)}
        />
    )
}

export default AccessToInformationPackage
