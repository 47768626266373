import React from 'react'
import './ResetRecurringExtractionDialog.scss'

export const ResetRecurringSubscriptionDescription = () => {
    return (
        <>
            <p>
                After the first full extraction, only items with updates made will be extracted in recurring
                extractions.
            </p>
            <p>
                Upon confirmation of reset incremental extraction, full extraction will be <b>generated immediately.</b>
            </p>
            <p>Recurring extraction dates will remain unaffected.</p>
        </>
    )
}
