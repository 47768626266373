import {Grid, IconButton} from '@mui/material'
import {NotificationMessage} from '../NotificationMessages/NotificationMessages'
import React, {ReactNode} from 'react'
import {Close} from '@mui/icons-material'
import {Notification} from '../../../models/Notification'
import {useDeleteNotificationMutation} from '../rtkNotificationApi'
import {
    formatDate,
    formatMilliseconds,
    formatMonth,
    formatTime,
    fromNow
} from '../../../../genericComponents/date/DateService'

const GenericNotificationDisplay = ({notification, title, canClose, children, body, actions}:
                                        { notification: Notification, title: string, canClose: boolean, children?: ReactNode, body?: ReactNode, actions?: ReactNode }) => {
    const [deleteNotification, deleteNotificationResult] = useDeleteNotificationMutation()

    const dateToDisplay = formatMilliseconds([...notification.createdDate].map(Number))
    formatMonth(dateToDisplay)

    const date = () => {
        const notificationDate = formatDate(dateToDisplay)
        const notificationTime = formatTime(dateToDisplay)
        const timeFromNow = fromNow(dateToDisplay)

        return (
            <div className="notification-time">
                {notificationDate &&
                    `${notificationDate} | ${notificationTime} - ${timeFromNow}`}
            </div>
        )
    }

    return (
        <Grid className="datx-notification-item" container>
            <div className="notification-container">
                <div className="notification-col notification-body">
                    <div className="notification-title">{title}</div>
                    <div className="notification-content">
                        <NotificationMessage notification={notification}/>
                    </div>
                    {body}
                    {date()}
                    {actions}
                    {children}
                </div>
                <div
                    className={'notification-col notification-control ' + (!canClose ? 'hidden' : '')}
                >
                    <IconButton
                        size="small"
                        onClick={() => deleteNotification(notification.id)}
                        disabled={deleteNotificationResult.isLoading}
                    >
                        <Close/>
                    </IconButton>
                </div>
            </div>
        </Grid>
    )
}

export default GenericNotificationDisplay
