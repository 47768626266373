export const COOKIES_CONSENT_NAME = 'CookiesConsentV1'

export const COOKIE_EXPIRE_TIME = 180


export enum CookiesCategory {
    RequiredCookies = 'Required Cookies',
    FunctionalCookies = 'Functional Cookies',
    PerformanceCookies = 'Performance Cookies',
}

export enum CookieVendor {
    MicrosoftAzureB2C = 'Microsoft Azure AD B2C',
    MicrosoftApplicationInsights = 'Microsoft Application Insights',
    AzureFrontDoor = 'Azure Front Door',
    ApplicationGateway = 'Azure Application Gateway',
    Datx = 'Datx',
    Hotjar = 'Hotjar',
}

export type CategoryConsentType = {
    userConsent: boolean,
    cookies: {
        [key in CookieVendor]?: {
            userConsent: boolean
        }
    }
}


export type CookieConsentType = {
    timestamp: string;
    cookieCategories: {
        [key in CookiesCategory]: CategoryConsentType
    }
}

export const getCookiesListFromConsentObject = (cookieConsent: CookieConsentType): string[] => {
    const categories = (cookieConsent as CookieConsentType)?.cookieCategories
    const categoriesValues = Object.values(categories)
    return categoriesValues.flatMap((value) => Object.keys(value.cookies))
}

export type Vendor = {
    name: string,
    description: string
}

export type VendorType = {
    type: CookieVendor,
    moreInfoUrl: string | undefined,
    vendorList: Vendor[]
}

export type CookieDetail = {
    category: CookiesCategory,
    description: string,
    vendorDetails: VendorType[]
}

export enum CookiesDialogType {
    CategoriesDialog,
    VendorsDialog
}

export type AccordionType = CookiesCategory | CookieVendor