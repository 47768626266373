import React from 'react'
import {FastField} from 'formik'
import {FormControlLabel} from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import {convertItemTypeLabel, ItemType} from '../model/ItemType'

const ItemTypeFilter = ({itemType, className}: {
        itemType: ItemType,
        className: string
}) => {
        return <FastField
            type="checkbox"
            name="itemTypes"
            value={itemType}
            as={FormControlLabel}
            className={className}
            control={<Checkbox/>}
            label={convertItemTypeLabel(itemType)}
            title={itemType}
            data-testid={itemType + '-filter-value'}
            id={itemType}
        />
}
export default ItemTypeFilter