import React, {useEffect, useState} from 'react'

import {ServiceRequest} from '../../../service/model/ServiceRequest'
import {UpdateDialogMessages, UpdateDialogContent} from '../modals/model/UpdateDialogContent'
import ApproveOptionsMenu from './ApproveOptionsMenu'
import { useAcceptServiceRequestMutation, useRejectServiceRequestMutation } from '../../../service/serviceRequests/rtkServiceRequestApi'

const approvedContent : UpdateDialogMessages = {
        confirmationMsg: 'Click Approve to accept and send the service request for quotation',
        titleMsg: 'Approve request',
        approveButtonLabel: 'Approve',
        approveMenu: 'Approve request'
}

const rejectedContent : UpdateDialogMessages = {
        confirmationMsg: 'Click Reject to reject and terminate the service request',
        titleMsg: 'Reject request',
        approveButtonLabel: 'Reject',
        approveMenu: 'Reject request'
}

const updateDialogContent : UpdateDialogContent= {
    approvedContent: approvedContent,
    rejectedContent: rejectedContent
}

const ApproveRequest = ({row}: { row: ServiceRequest }) => {

   const [acceptServiceRequest, acceptServiceRequestResult] = useAcceptServiceRequestMutation()
   const [rejectServiceRequest, rejectServiceRequestResult] = useRejectServiceRequestMutation()

   const approvedRequest = () => {
        if(row.id) {
           acceptServiceRequest(row.id)
        }
    }

   const rejectedRequest = () => {
        if( row.id) {
            rejectServiceRequest(row.id)
        }
    }

    return <>
        <ApproveOptionsMenu row={row}
                            isLoading={acceptServiceRequestResult.isLoading || rejectServiceRequestResult.isLoading}
                            updateDialogContent={updateDialogContent}
                            approvedRequest={approvedRequest}
                            rejectedRequest={rejectedRequest}
                            />
    </>
}

export default ApproveRequest
