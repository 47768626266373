import {FileDTO, ServiceRequest} from '../model/ServiceRequest'
import {ContainerClient} from '@azure/storage-blob'
import FileSaver from 'file-saver'

export const uploadServiceRequestFileToBlobStorage = async (fileDTO: FileDTO | null, file: File) => {
    // if no file, don't try to upload it
    if (!fileDTO) return

    // upload file on storage account
    const {saskey, blobName, containerURL} = fileDTO
    const containerClient = new ContainerClient(`${containerURL}?${saskey}`)
    const arrayBufferFile = await file.arrayBuffer()
    if (blobName) {
        await containerClient.getBlockBlobClient(blobName).uploadData(arrayBufferFile)
    } else {
        throw new Error()
    }
}

export const saveFileFromBlobStorage = async (fileDTO: FileDTO) => {
    const {name, blobName, containerURL, saskey} = fileDTO
    if (!name || !blobName || !containerURL || !saskey) {
        throw new Error('Missing fields')
    }
    const containerClient = new ContainerClient(`${containerURL}?${saskey}`)
    const downloadBlockBlobResponse = await containerClient.getBlockBlobClient(blobName).download()
    const data = await downloadBlockBlobResponse.blobBody
    if (!data) {
        throw new Error('No file')
    }

    FileSaver.saveAs(data, name)
}
