import React from 'react'
import Header from '../header/Header'
import {useMatchedRoute} from '../util/routing'
import '../App.scss'
import {Outlet} from 'react-router-dom'
import Footer from '../footer/Footer'
import './HeaderApp.scss'
import DrawerClassificationTree from '../header/classificationTreeDrawer/DrawerClassificationTree'

const HeaderApp = () => {
    const routes = useMatchedRoute()
    const matchedRoute = routes?.[0].route
    const hideSearch = matchedRoute?.options?.hideSearch
    const fullSize = matchedRoute?.options?.fullSize


    return (<div className="app-with-header-container">
        <Header hideSearch={hideSearch}/>
        <DrawerClassificationTree/>
        <div className={'content-main-page ' + (fullSize ? 'full-size' : '')}>
            <Outlet/>
        </div>
        <Footer />
    </div>)
}

export default HeaderApp
