import {getTypedQuotationRule, QuotationRule} from './QuotationRules/QuotationRule'
import {Quotation, ServiceRequest} from './ServiceRequest'



export const isQuotationAvailable = (totalQuotation:number, status :string | undefined) =>{
    return  totalQuotation &&
        (status == 'AWAITING_QUOTATION_APPROVAL' || status == 'DELIVERED' || status == 'REJECTED')

}

export const getTotalFromRequest = (service: ServiceRequest | undefined) => {
    const totalQuotation = getTotalFromQuotation(service?.quotation)
    if(!isQuotationAvailable(totalQuotation,service?.status)) return 0
    return totalQuotation
}



export const getTotalFromQuotation = (quotation: Quotation | undefined) => {
  if(!quotation) return 0
  return getTotalQuotation(quotation.numberOfObjects, quotation.quotationRules)
}

export const getTotalQuotation = (numberOfObject: number, rules: QuotationRule[]) => {
  let cost = 0
  rules.forEach((rule) => {
        cost = getTypedQuotationRule(rule).calculate(cost, numberOfObject)
      }
  )

  return cost
}
