import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {ROUTES} from '../../../../constants/routing'
import {useCurrentGuildContext, useDatxPathGenerator} from '../../../../util/routing'
import LoadingButton from '@mui/lab/LoadingButton'
import React from 'react'
import {IdType} from '../../../../util/models/IdType'
import {OrganizationItemSharing} from '../../model/OrganizationItemSharing'
import {
    isPublicSearchParamsName,
    managedByGuildIdUrlSearchParamsName,
    notDefinedByOrganizationsUrlParamsName,
    visibleByGuildIdUrlSearchParamsName
} from '../../../../search/SearchParams'

export const NavigateToSpecificItemsPageButton = ({
                                                      manageButtonTestId,
                                                      label,
                                                      size = 'small',
                                                      classes,
                                                      targetGuildId,
                                                      organizationsSharing
                                                  }: {
    manageButtonTestId?: string,
    label: string,
    size?: 'small' | 'medium' | 'large',
    classes?: string,
    targetGuildId: IdType,
    organizationsSharing: OrganizationItemSharing[]
}) => {
    const generatePath = useDatxPathGenerator()
    const navigate = useNavigate()
    const {sharingId} = useParams()

    const [searchParams] = useSearchParams()
    const [currentGuildContext] = useCurrentGuildContext()
    const navigateToSpecificItemsPage = () => {
        if (!currentGuildContext) return
        searchParams.set(managedByGuildIdUrlSearchParamsName, currentGuildContext.guildId)
        searchParams.set(visibleByGuildIdUrlSearchParamsName, targetGuildId)
        searchParams.set(isPublicSearchParamsName, 'false')
        if (organizationsSharing.length !== 0) {
            const organizationsSharingId = organizationsSharing.map(organization => organization.targetOrganizationId)
            searchParams.set(notDefinedByOrganizationsUrlParamsName, JSON.stringify(organizationsSharingId))
        }
        navigate({
            pathname: generatePath(ROUTES.manageSpecificSharedItems.path, {sharingId: sharingId}),
            search: `?${searchParams}`,
        })

    }

    return <LoadingButton
        className={classes}
        data-testid={manageButtonTestId}
        variant="contained"
        color="primary"
        size={size}
        onClick={navigateToSpecificItemsPage}
    >{label}</LoadingButton>

}