import React from 'react'
import './DangerousnessWidget.scss'
import {DefinitionForDisplaySet} from '../../definitionForDisplaySet/DefinitionForDisplaySet'
import { DangerToDangerCategories } from './DangerToDangerCategories'
import {DisplaySet} from '../../model/DisplaySet'
import {groupByDefinitionId} from '../../utils/sortedDefinitions'

const CLP_SIGNAL_WORD = '83160ccf-60c9-5e4c-8118-a16b8498e037'
const CLP_DANGER_PICTO = 'f319cf51-10b6-5972-957b-69ce1d2c2841'
const CLP_DANGER_CATEGORY = '04d47b79-30c6-5db9-b88c-10fe170c3f9a'

export const DangerousnessWidget = ({displaySet}: { displaySet: DisplaySet }) => {
    const attributes = displaySet.attributes

    const signalWordAttributes = attributes.filter(attr => attr.attributeDefinitionId === CLP_SIGNAL_WORD).sort((a, b) => a.value.value?.localeCompare(b.value.value ?? '') ?? 0)
    const dangerPictoAttributes = attributes.filter(attr => attr.attributeDefinitionId === CLP_DANGER_PICTO).sort((a, b) => a.value.value?.localeCompare(b.value.value ?? '') ?? 0)
    const dangerCategoryAttributes = attributes.filter(attr => attr.attributeDefinitionId === CLP_DANGER_CATEGORY)

    const otherAttributes = attributes.filter(attr => ![CLP_SIGNAL_WORD, CLP_DANGER_PICTO, CLP_DANGER_CATEGORY].includes(attr.attributeDefinitionId))
    const otherDefinitions = [...groupByDefinitionId(otherAttributes).values()]

    return (
        <div className="dangerousness-widget">
            <div className="danger-attributes-container">
                {signalWordAttributes?.map(signalWordAttribute => (
                    <div className="danger-title"
                         key={signalWordAttribute.id}>{signalWordAttribute.value.classificationNode?.label?.toUpperCase()}</div>
                ))}

                <div className="attribute-pairs">
                    {dangerPictoAttributes?.map((pictoAttribute) => (
                        <DangerToDangerCategories
                            key={pictoAttribute.id}
                            pictoAttribute={pictoAttribute}
                            allDangerCategoriesAttributes={dangerCategoryAttributes}
                        />
                    ))}
                </div>
            </div>
            <div>
                {otherDefinitions?.map(definition => <DefinitionForDisplaySet definition={definition}
                                                                              key={definition.attributeDefinitionId}></DefinitionForDisplaySet>)}
            </div>
        </div>
    )
}

