import {Button} from '@mui/material'
import {useNavigate} from 'react-router-dom'
import { ROUTES } from '../constants/routing'
import './NotFoundPage.scss'
import { useDatxPathGenerator } from '../util/routing'
import React from 'react'

const NotFoundPage = () => {
    const generatePath = useDatxPathGenerator()
    const navigate = useNavigate()

    const navigateToHomePage= () =>{
        navigate(generatePath(ROUTES.welcome.path))
    }

    return (
        <div className="not-found-panel" data-testid="not-found-panel">
            <img
                src="/images/DATX_Logo_Black.svg"
                alt="logo-title"
                className="logo-title"
                data-testid="logo-title"
                style={{height: '80px', paddingTop: '8px', margin: 'auto'}}
            />
            <p data-testid="description-not-found-page" className="description" >Sorry you do not have access to this page on the DATX platform.</p>
            <Button
                data-testid="button-home-page"
                variant="contained"
                className="homepage-button"
                onClick={() => navigateToHomePage()}
                title="back to homepage"
            >
                Back to Homepage
            </Button>
        </div>
    )
}

export default NotFoundPage
