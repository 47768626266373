import React, {ReactNode} from 'react'
import {Dialog, IconButton} from '@mui/material'
import {Close} from '@mui/icons-material'

const CustomModal = ({handleClose, children}: {
    handleClose: () => void, children: ReactNode
}) => {
    return (
        <Dialog onClose={handleClose} open={true} data-testid="modal-component">
        <IconButton
            disableRipple={true}
            style={{
                position: 'absolute',
                right: '0',
                zIndex: 10,
                padding: ' 24px'
            }}
            onClick={handleClose}

            
        >
            <Close/>
        </IconButton>
        <div style={{
            padding: ' 24px'
        }}>{children}</div>
    </Dialog>
    )

}


export default CustomModal
