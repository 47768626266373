import React, {useMemo} from 'react'


import {Link} from 'react-router-dom'
import {Tooltip} from '@mui/material'

import './ProcurementWidget.scss'
import {DisplaySet} from '../../model/DisplaySet'
import {useDatxPathGenerator} from '../../../util/routing'
import {IdType} from '../../../util/models/IdType'
import {AttributeModel} from '../../model/AttributeModel'
import {ItemOrganization} from '../../../item/model/ItemOrganization'
import {useGetOrganizationsQuery} from '../../../organization/rtkOrganizationApi'
import {ROUTES} from '../../../constants/routing'
import {Price, PriceAttribute} from '../../model/ProcurementWidgetPriceAttribute'

const PRICE_DEF_ID = '98b37c60-8bb0-506f-8a25-cb6d348eaf50'
const CURRENCY_DEF_ID = '4874c7ae-f52c-5135-8ce2-f98653422601'
const PRICE_RANGE_DEF_ID = '7e78b37c-5ce6-54ce-9c9f-8b47b688bd3e'
const LINKED_STOCK_DEF_ID = '1f7e8cd5-d4ab-4f58-9d86-2bb39f859a40'
const MIN_QTY_DEF_ID = '771d8631-c981-56ac-91e3-290e75f6cbed'
const PRICE_DATE_DEF_ID = '4b3446d0-8926-5af5-9efd-09ec91e4e5e6'
const LEAD_TIME_DEF_ID = 'f6d6b6c3-7c31-4a6a-a701-7c4c9e0d76db'
const STOCK_VALUE_DEF_ID = 'a0422787-12a8-51e1-aed5-152329b78209'
const DISTRIBUTOR_NAME_DEF_ID = 'c098d518-6724-4a89-b53e-1e0457ba95c6'

export const ProcurementWidget = ({displaySet}: { displaySet: DisplaySet }) => {

    const generatePath = useDatxPathGenerator()

    const getAttributeValue = (composingAttributesByDefinitionId: Map<IdType, AttributeModel>, definitionId: string): string => {
        return composingAttributesByDefinitionId.get(definitionId)?.value.value ?? ''
    }

    const getPrice = (composingAttributesByDefinitionId: Map<IdType, AttributeModel>): Price | null => {
        const value = Number(composingAttributesByDefinitionId.get(PRICE_DEF_ID)?.value.value)
        if (isNaN(value)) return null
        const currency = getAttributeValue(composingAttributesByDefinitionId, CURRENCY_DEF_ID)
        return {
            value, currency
        }
    }

    function mapComposingAttributesOfComplex(attr: AttributeModel) {
        const composingAttributesByDefinitionId: Map<IdType, AttributeModel> = attr.value.composedByAttributes?.reduce((acc, attr) => {
            if (attr.attributeDefinitionId) {
                acc.set(attr.attributeDefinitionId, attr)
            }
            return acc
        }, new Map<IdType, AttributeModel>()) ?? new Map<IdType, AttributeModel>()
        return composingAttributesByDefinitionId
    }

    const buildPriceAttribute = (attr: AttributeModel, priceAttributesById: Map<IdType, AttributeModel>): PriceAttribute | null => {
        const composingAttributesByDefinitionId = mapComposingAttributesOfComplex(attr)

        const stockId: IdType = '' + composingAttributesByDefinitionId.get(LINKED_STOCK_DEF_ID)?.value.value
        const stock = priceAttributesById.get(stockId)
        if (!stock) return null

        const stockComposingAttributesByDefinitionId = mapComposingAttributesOfComplex(stock)
        const price = getPrice(composingAttributesByDefinitionId)
        if (!price) return null

        return {
            id: attr.id,
            price,
            minimumQuantity: getAttributeValue(composingAttributesByDefinitionId, MIN_QTY_DEF_ID),
            leadTime: getAttributeValue(stockComposingAttributesByDefinitionId, LEAD_TIME_DEF_ID),
            stock: getAttributeValue(stockComposingAttributesByDefinitionId, STOCK_VALUE_DEF_ID),
            supplierId: getAttributeValue(stockComposingAttributesByDefinitionId, DISTRIBUTOR_NAME_DEF_ID),
            updateDate: getAttributeValue(composingAttributesByDefinitionId, PRICE_DATE_DEF_ID)

        }
    }

    function getOnlyBestSupplierPrices(priceAttributesBySupplierId: Map<IdType, PriceAttribute[]>) {
        return Array.from(priceAttributesBySupplierId.values()).map((pricesOfSupplier: PriceAttribute[]) => {
            return pricesOfSupplier.reduce((a: PriceAttribute, b: PriceAttribute) => a.price.value < b.price.value ? a : b)
        })
    }

    function groupPriceAttributesBySupplierId(prices: PriceAttribute[]) {
        return prices.reduce((acc, price) => {
            if (!acc.has(price.supplierId)) {
                acc.set(price.supplierId, [])
            }
            acc.get(price.supplierId)?.push(price)
            return acc
        }, new Map<IdType, PriceAttribute[]>())
    }

    function buildPriceAttributes(displaySet: DisplaySet) {
        const priceAttributesById: Map<IdType, AttributeModel> = displaySet.attributes.reduce((acc, attr) => {
            acc.set(attr.id, attr)
            return acc
        }, new Map<IdType, AttributeModel>())

        const prices: PriceAttribute[] = []
        displaySet.attributes.filter(attr => attr.attributeDefinitionId === PRICE_RANGE_DEF_ID).forEach(attr => {
            const priceAttribute = buildPriceAttribute(attr, priceAttributesById)
            if (priceAttribute) prices.push(priceAttribute)
        })
        return prices
    }

    function getMinimumSupplierPrices(prices: PriceAttribute[]) {
        const priceAttributesBySupplierId: Map<IdType, PriceAttribute[]> = groupPriceAttributesBySupplierId(prices)
        return getOnlyBestSupplierPrices(priceAttributesBySupplierId)
    }

    const getPriceAttributes = (displaySet: DisplaySet): PriceAttribute[] => {
        const prices = buildPriceAttributes(displaySet)
        const minimumSupplierPrices = getMinimumSupplierPrices(prices)
        return minimumSupplierPrices.sort((attrA, attrB) => attrA.price.value - attrB.price.value)
    }

    const getPriceDisplay = (priceAttribute: PriceAttribute): string => `${priceAttribute.price.value} ${priceAttribute.price.currency}`

    const mapOrganizationsById = (organizations: ItemOrganization[] | undefined): Map<IdType, ItemOrganization> => {
        if (!organizations) return new Map<IdType, ItemOrganization>()
        return organizations.reduce((acc, org) => {
            acc.set(org.id, org)
            return acc
        }, new Map<IdType, ItemOrganization>())
    }

    const prices: PriceAttribute[] = useMemo(() => {
        return getPriceAttributes(displaySet)
    }, [displaySet])

    const organizationIds: IdType[] = useMemo(() => prices?.map(p => p.supplierId) ?? [], [prices])
    const {data: organizations} = useGetOrganizationsQuery(organizationIds, {skip: !organizationIds?.length})

    const organizationsById = useMemo(() => mapOrganizationsById(organizations), [organizations])
    const bestPrice = prices[0]
    const otherPrices = prices.slice(1)

    const OtherPricesDisplay = ({
                                    prices, organizations
                                }: {
        prices: PriceAttribute[],
        organizations: Map<IdType, ItemOrganization> | undefined
    }) => {
        return <div className="item-attribute other-prices-container" data-testid="test-details-simple-attribute-value">
            <span>Other suppliers</span>
            <div className="complex-child other-prices">
                {prices.map((price: PriceAttribute) => <React.Fragment key={price.id}>
                <span className="item-attribute-value" data-testid={`test-other-price-supplier-${price.id}`}>
                    {(organizations?.get(price.supplierId)?.label ?? 'Unknown supplier') + ':'}
                </span>
                        <span className="item-attribute-value" data-testid={`test-other-price-${price.id}`}>
                    {`${getPriceDisplay(price)} (Min Qty: ${price.minimumQuantity})`}
                </span>
                    </React.Fragment>
                )}
            </div>
        </div>
    }

    return <>
        {bestPrice &&
            <div className="item-attribute best-price-container" data-testid="test-details-simple-attribute-value">
                <span>Best price</span>
                <div className="complex-child">
                    <div className="best-price-table" data-testid="test-best-price-table">
                        <span className="price-header price-cell">Price</span>
                        <span className="price-header price-cell">Min Qty</span>
                        <span className="price-header price-cell">Stock</span>
                        <span className="price-header price-cell">Lead time (wks)</span>
                        <span className="price-header price-cell">Supplier</span>
                        <span className="price-cell" data-testid="test-best-price">{getPriceDisplay(bestPrice)}</span>
                        <span className="price-cell"
                              data-testid="test-best-price-minimum-quantity">{bestPrice.minimumQuantity}</span>
                        <span className="price-cell" data-testid="test-best-price-stock">{bestPrice.stock}</span>
                        <span className="price-cell" data-testid="test-best-price-lead-time">{bestPrice.leadTime}</span>
                        <Tooltip title={organizationsById?.get(bestPrice.supplierId)?.label ?? ''}>
                            <Link
                                to={generatePath(ROUTES.organizationDetails.path, {organizationId: bestPrice.supplierId})}
                                className="organization-link price-cell"
                                data-testid="test-best-price-supplier"
                                target="_blank">
                                {organizationsById?.get(bestPrice.supplierId)?.label ?? ''}
                            </Link>
                        </Tooltip>
                    </div>
                    <span className="item-attribute-value">{`Last updated: ${bestPrice.updateDate}`}</span>
                </div>
            </div>}

        {otherPrices.length > 0 && <OtherPricesDisplay prices={otherPrices} organizations={organizationsById}/>}
    </>
}
