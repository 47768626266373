import {FormControl} from '@mui/material'
import React, {ChangeEvent, SyntheticEvent} from 'react'
import UploadCommonLoadingButton from '../../../genericComponents/UploadCommonLoadingButton'
import {useDispatch} from 'react-redux'
import {setSnackbarMessage} from '../../../../../genericComponents/commonSlice'
import * as XLSX from 'xlsx'
import {useFormikContext} from 'formik'
import {AttributeStringUpdateForm} from '../PrivateAttributeUpdate'
import {NewValueByItemDTO} from '../../../../model/private/NewValueByItemDTO'
import {ServiceRequestStep} from '../../../genericComponents/formTemplate/steps/ServiceRequestStep'
import {Description} from '../../../../../genericComponents/typographie/Description'
import {Label} from '../../../../../genericComponents/typographie/Label'
import {UpdateAttributeExcelFileModel} from '../../../../model/private/UpdateAttributeExcelFileModel'

export const UploadTemplateStringUpdate = () => {
    const formik = useFormikContext<AttributeStringUpdateForm>()
    const dispatch = useDispatch()

    const setFile = (file: File | undefined) => {
        formik.setFieldValue('file', file)
    }

    const setPrivateAttributes = (privateAttributes: NewValueByItemDTO[] | undefined) => {
        if (!privateAttributes || privateAttributes.length <= 0) return

        // We don't put in the "newItems" items which didn't change value
        const newAttributes = privateAttributes.filter(attribute => attribute.value !== null
            && attribute.value !== attribute.oldValue
            && attribute.value !== ''
            && attribute.itemId)
        const deleteAttributesFromItems = privateAttributes.filter(attribute => attribute.value !== null
            && attribute.value === ''
            && attribute.value !== attribute.oldValue
            && attribute.itemId)
            .map(attribute => attribute.itemId)

        formik.setFieldValue('privateAttributesToDelete', deleteAttributesFromItems)
        formik.setFieldValue('privateAttributesToUpdate', newAttributes)
    }

    const unselectFile = (event: SyntheticEvent) => {
        setPrivateAttributes(undefined)
        setFile(undefined)
        event.preventDefault()
    }


    const parseFile = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target?.files?.length) return

        const reader = new FileReader()
        reader.onerror = () => dispatch(setSnackbarMessage('Error on the file format. We are not able to parse it.'))
        reader.onabort = () => dispatch(setSnackbarMessage('Error downloading the file. Please try again'))
        reader.onload = (event) => {
            try {
                const buffer = event?.target?.result
                const workbook = XLSX.read(buffer, {type: 'array'})
                const sheetName = workbook.SheetNames[0]
                const sheet = workbook.Sheets[sheetName]

                const data = XLSX.utils.sheet_to_json<UpdateAttributeExcelFileModel>(sheet)
                const dataForService = data.slice(1).map((record, i) => {
                    if (!record.DATX_ID) {
                        dispatch(setSnackbarMessage(`A DATX Id is missing on line ${i + 3}. The line will be be ignored.`))
                    }
                    return {
                        itemId: record.DATX_ID,
                        //Used to know after the submit if an entry has been changed or not
                        oldValue: record.PRIVATE_ATTRIBUTE ?? '',
                        value: record.PRIVATE_ATTRIBUTE_1 ?? '',

                    }
                })

                setPrivateAttributes(dataForService)
            } catch (e: any) {
                dispatch(setSnackbarMessage('Error on the file format. We are not able to parse it.'))
            }
        }

        const tempFile = event.target.files[0]
        reader.readAsArrayBuffer(tempFile)
        setFile(tempFile)
    }

    return <ServiceRequestStep>
        <Description className="service-request-step-description">
            By submitting the completed template, the values you have provided will be updated for the relevant items.
        </Description>

        <FormControl className="download-upload-template-container">
            <Label className="file-download-upload-label"
                   htmlFor="upload-file">Upload the completed template</Label>
            <UploadCommonLoadingButton className="upload-template-button"
                                       selectedFileName={formik?.values?.file?.name ?? ''}
                                       unselectFile={unselectFile}
                                       parseFile={parseFile}/>
        </FormControl>


    </ServiceRequestStep>
}