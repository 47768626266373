import WorkflowConfigurationTemplate from '../formTemplate/WorkflowConfigurationTemplate'
import {useCreateWorkflowConfigurationMutation, useGetGuildQuery} from '../../rtkGuildApi'
import {WorkflowConfiguration} from '../model/WorkflowConfiguration'
import {setSnackbarMessage} from '../../../genericComponents/commonSlice'
import {ROUTES} from '../../../constants/routing'
import {useTypedDispatch} from '../../../util/store'
import {useNavigate} from 'react-router-dom'
import {useCurrentGuildContext, useDatxPathGenerator} from '../../../util/routing'

const CreateWorkflowConfiguration = () => {
    const generatePath = useDatxPathGenerator()
    const navigate = useNavigate()
    const dispatch = useTypedDispatch()
    const [currentGuildContext] = useCurrentGuildContext()
    const [createWorkflowConfiguration, createWorkflowResult] = useCreateWorkflowConfigurationMutation()
    const {data: guild} = useGetGuildQuery(currentGuildContext?.guildId, { skip: !currentGuildContext?.guildId })

    const getPageTitle = 'Add New Workflow'

    const handleCreateWorkflowConfiguration = (values: WorkflowConfiguration) => {
        createWorkflowConfiguration({newWorkflowConfiguration: values})
            .unwrap()
            .then(() => {
                dispatch(setSnackbarMessage('New Workflow has been added'))
                navigate(generatePath(ROUTES.workflowConfiguration.path))
            })
    }

    return <WorkflowConfigurationTemplate pageTitle={getPageTitle} guild={guild}
                                          onSubmit={handleCreateWorkflowConfiguration}
                                          isLoading={createWorkflowResult.isLoading}
                                          submitButtonLabel="Add Workflow"
    />
}

export default CreateWorkflowConfiguration