import {Dialog, DialogContent} from '@mui/material'
import './CookiesSettingsDialog.scss'
import {CategoryConsentType, CookieConsentType, CookiesCategory, CookieVendor,} from '../CookiesTypes'
import {useState} from 'react'
import {useFormik} from 'formik'
import {InitialCookiesUserConsent} from '../CookiesData'
import {CookiesCategoriesDialog} from './CookiesCategoriesDialog'
import {CookiesVendorsDialog} from './CookiesVendorsDialog'

export const CookiesSettingsDialog = ({
                                          openDialog,
                                          handleClose,
                                          allowAllCookies,
                                          declineAllCookies,
                                          acceptCustomCookies
                                      }: {
    openDialog: boolean,
    handleClose: () => void
    allowAllCookies: () => void
    declineAllCookies: () => void
    acceptCustomCookies: (cookieConsentObject: CookieConsentType) => void
}) => {

    const [categoryVisited, setCategoryVisited] = useState<CookiesCategory | undefined>()

    const formik = useFormik<{
        cookiesConsent: CookieConsentType
    }>({
        initialValues: {
            cookiesConsent: InitialCookiesUserConsent(false)
        },
        onSubmit: values => {
            formik.setFieldValue(values.cookiesConsent.timestamp, new Date().toString())
            acceptCustomCookies(values.cookiesConsent)
        }
    })

    const openDialogType = (category: CookiesCategory | undefined) => setCategoryVisited(category)


    const updateVendorConsents = (category: CookiesCategory, isSelectedCategory: boolean) => {
        //selecting a category will lead to the selection of all its vendors
        //conversely, deselecting a category will lead to the deselection of all its vendors
        const cookiesConsent = formik.values.cookiesConsent.cookieCategories[category].cookies
        Object.keys(cookiesConsent).forEach(cookie => cookiesConsent[cookie as CookieVendor] = {userConsent: isSelectedCategory})
        formik.setFieldValue(`cookiesConsent.cookieCategories.${category}.cookies`, cookiesConsent)
    }

    const handleSelectedVendors = (categoryConsent: CategoryConsentType) => {
        if (!categoryVisited) return
        formik.setFieldValue(`cookiesConsent.cookieCategories.${categoryVisited}`, categoryConsent)
        setCategoryVisited(undefined)
    }


    return <Dialog onClose={handleClose} open={openDialog} fullWidth data-testid="cookies-settings-dialog"
                   PaperProps={{classes: {root: 'cookies-settings-dialog'}}}>
        <div className="cookies-settings-dialog-logo">
            <img src="/images/DATX_Logo.svg" alt="DATX"/>
        </div>
        <DialogContent>
            <form onSubmit={formik.handleSubmit}>
                <CookiesCategoriesDialog classes={categoryVisited ? 'hidden' : 'display'}
                                         handleClose={handleClose}
                                         allowAllCookies={allowAllCookies}
                                         declineAllCookies={declineAllCookies}
                                         handleSwitch={(e, category) => {
                                             formik.handleChange(e)
                                             updateVendorConsents(category, e.target.checked)
                                         }}
                                         checkedSwitch={(category) => formik.values.cookiesConsent.cookieCategories[category].userConsent}
                                         openDialogType={openDialogType}/>

                {categoryVisited && <CookiesVendorsDialog categoryVisited={categoryVisited}
                                                          openDialogType={(openDialogType)}
                                                          initialCookieCategory={formik.values.cookiesConsent.cookieCategories[categoryVisited]}
                                                          handleSelectedVendors={handleSelectedVendors}/>}
            </form>
        </DialogContent>
    </Dialog>
}