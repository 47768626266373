import UserRequestStatusEnum from './UserRequestStatusEnum'


export interface UserServiceRequestStatus {
    color: string
    label: string
}

const getUserServiceStatus = (status: string) => {
    const userServiceTypeMap = new Map<string, UserServiceRequestStatus>([])
    userServiceTypeMap.set(UserRequestStatusEnum.AWAITING_APPROVAL, { color: '#FFC140', label: 'Awaiting Approval' })
    userServiceTypeMap.set(UserRequestStatusEnum.CREATION_PENDING, { color: '#FFC140', label: 'Creation Pending' })
    userServiceTypeMap.set(UserRequestStatusEnum.CREATION_ERROR, { color: '#E63A56', label: 'Creation Error' })
    userServiceTypeMap.set(UserRequestStatusEnum.AWAITING_QUOTATION, { color: '#FFC140', label: 'Awaiting Quotation' })
    userServiceTypeMap.set(UserRequestStatusEnum.QUOTATION_APPROVED, { color: '#2DB453', label: 'Quotation Approved' })
    userServiceTypeMap.set(UserRequestStatusEnum.REQUEST_REJECTED, { color: '#E63A56', label: 'Request Rejected' })
    userServiceTypeMap.set(UserRequestStatusEnum.DELIVERED, { color: '#2DB453', label: 'Delivered' })
    userServiceTypeMap.set(UserRequestStatusEnum.AWAITING_QUOTATION_APPROVAL, { color: '#FFC140', label: 'Awaiting Quotation Approval' })
    userServiceTypeMap.set(UserRequestStatusEnum.QUOTATION_REJECTED, { color: '#E63A56', label: 'Quotation Rejected' })

    return userServiceTypeMap.get(status) ?? { color: 'black', label: 'N/A' }
}

export default getUserServiceStatus

