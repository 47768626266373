import {Menu, MenuItem} from '@mui/material'
import React, {useState} from 'react'
import {PAGE_SIZES} from '../../../../constants/search'
import PageSizeSelection from './PageSizeSelection'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {pageSizeURLSearchParamsName, pageURLSearchParamsName} from '../../../SearchParams'


export const SelectPageSizeWithMenu = ({
        startIndex, endIndex, totalResultCount
                               }: {
        startIndex: number, endIndex: number, totalResultCount: number

}) => {
    const [anchorEl, setAnchorEl] = useState<Element|null>(null)

    const open = Boolean(anchorEl)
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    const pageSize = +(searchParams.get(pageSizeURLSearchParamsName) ?? 25)
    const handleClick = (e: React.MouseEvent) => {
        setAnchorEl(e.currentTarget)
        return
    }

    const handleClose = () => setAnchorEl(null)

    const handlePageSizeChange = (pageSizeNumber: number) => {
        if (pageSizeNumber === pageSize) {
            return
        }
        searchParams.set(pageURLSearchParamsName, '1')
        searchParams.set(pageSizeURLSearchParamsName, `${pageSizeNumber}`)
        navigate({
            search: `?${searchParams}`,
        })
    }

    return (
        <>
            <div className="results-count">
                Showing: <span data-testid="startIndex">{startIndex}</span>-<span data-testid="endIndex">{endIndex}</span><PageSizeSelection
                values={PAGE_SIZES}
                onClick={handleClick}/>of {totalResultCount} results
            </div>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {Array.isArray(PAGE_SIZES) &&
                    PAGE_SIZES.map((pageSize) => {
                        return (
                            <MenuItem
                                key={pageSize}
                                data-testid={pageSize}
                                onClick={() => {
                                    handlePageSizeChange(pageSize)
                                    handleClose()
                                }}
                            >
                                {pageSize}
                            </MenuItem>
                        )
                    })}
            </Menu>
        </>

    )
}


