import React, {ReactElement} from 'react'
import {SimpleAttribute} from '../attributeTemplates/SimpleAttribute'
import {ComplexAttribute} from '../attributeTemplates/ComplexAttribute'
import {ArtifactAttribute} from '../attributeTemplates/ArtifactAttribute'
import {ClassificationLinkAttribute} from '../attributeTemplates/ClassificationLinkAttribute'
import {LinkAttribute} from '../attributeTemplates/LinkAttribute'
import {AttributeValueType} from '../model/AttributeValue'
import {AttributeModel} from '../model/AttributeModel'

const mapAttributeTypeToComponent = new Map<AttributeValueType, (attribute: AttributeModel) => ReactElement>([
    [AttributeValueType.SIMPLE, (attribute) => <SimpleAttribute key={attribute.id} attribute={attribute}/>],
    [AttributeValueType.ITEM_LINK, (attribute) => <LinkAttribute key={attribute.id} attribute={attribute}/>],
    [AttributeValueType.COMPLEX, (attribute) => <ComplexAttribute key={attribute.id} attribute={attribute}/>],
    [AttributeValueType.ARTIFACT, (attribute) => <ArtifactAttribute key={attribute.id} attribute={attribute}/>],
    [AttributeValueType.CLASSIFICATION_LINK, (attribute) => <ClassificationLinkAttribute key={attribute.id} attribute={attribute}/>],
])

export const getComponentForAttributeValueType = (attribute: AttributeModel) => {
    const type = attribute?.value?.type ?? AttributeValueType.SIMPLE
    const componentFunction = mapAttributeTypeToComponent.get(type)
    if (!componentFunction) return <SimpleAttribute attribute={attribute}></SimpleAttribute>
    return componentFunction(attribute)
}