import {ServiceRequest} from '../../model/ServiceRequest'
import React from 'react'
import './DownloadServiceFile.scss'
import LoadingButton from '@mui/lab/LoadingButton'
import {useGetServiceRequestFileMutation} from '../rtkServiceRequestApi'


export const DownloadServiceFile = ({userServiceRequest}: { userServiceRequest: ServiceRequest }) => {
    const [getServiceRequestFile, {isLoading}] = useGetServiceRequestFileMutation()

    const getFile = () => {
        getServiceRequestFile(userServiceRequest.id)
    }

    if (!userServiceRequest?.file?.name) {
        return <></>
    }

    if (userServiceRequest?.file?.status !== 'Valid') {
        return <div className="text-error"
                    title={userServiceRequest?.file?.status}>{userServiceRequest?.file?.status}</div>
    }

    return <LoadingButton className="service-request-table-file-name" type="button"
                          loading={isLoading}
                          onClick={() => getFile()} title={userServiceRequest?.file?.name}>
        <span className="button-label">{userServiceRequest?.file?.name}</span>
    </LoadingButton>
}