import {createSlice} from '@reduxjs/toolkit'
import {LocalStorage} from '../util/localStorage'
import {GuildContext} from './models/GuildContext'
import {SearchScope, searchScopesMock} from './models/SearchScope'
import {IdType} from '../util/models/IdType'
import {SidebarClassificationNode} from './models/SidebarClassificationNode'
import { guildAPI } from '../guild/rtkGuildApi'
import {userAPI} from '../user/rtkUserApi'

export type HeaderStateType = {
    userIsAuthenticated: boolean,
    searchScopes: SearchScope[],
    drawerOpen: boolean

}

const initialState: HeaderStateType = {
    userIsAuthenticated: false,
    searchScopes: searchScopesMock,
    drawerOpen: false
}

const headerSlice = createSlice({
    name: 'header',
    initialState,
    reducers: {
        setDrawerOpen(state: HeaderStateType, action) {
            state.drawerOpen = action.payload
        },
        setUserIsAuthenticated(state: HeaderStateType, action) {
            state.userIsAuthenticated = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            //React to a call to getUser
            .addMatcher(userAPI.endpoints.getUser.matchFulfilled, (state:HeaderStateType, action) => {
                LocalStorage.setUserEmail(action.payload?.email ?? '')
            })
    },
})

export const { setUserIsAuthenticated, setDrawerOpen} = headerSlice.actions

export default headerSlice.reducer
