import React from 'react'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import InputBase from '@mui/material/InputBase'
import {Tooltip} from '@mui/material'
import './SearchPhraseForm.scss'

export const SearchPhraseForm = ({
                                     displayTooltip = false,
                                     tooltipMessage = '',
                                     isSearchDisabled = false,
                                     placeholder = 'Search',
                                     onSearch,
                                     inputHandler,
                                     inputText,
                                     inputTestId = '',
                                     searchButtonTestId = ''
                                 }: {
    displayTooltip?: boolean,
    tooltipMessage?: string,
    isSearchDisabled?: boolean,
    onSearch: (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement>) => void
    inputHandler: (event: React.ChangeEvent<HTMLInputElement>) => void,
    inputText: string,
    placeholder?: string,
    inputTestId?: string,
    searchButtonTestId?: string
}) => {
    return (
        <form className="searchbar" onSubmit={event => onSearch(event)}>
            <Tooltip open={displayTooltip && isSearchDisabled}
                     title={tooltipMessage} arrow>
                <InputBase
                    sx={{ml: 1, flex: 1}}
                    placeholder={placeholder}
                    onChange={inputHandler}
                    value={inputText}
                    inputProps={{'data-testid': inputTestId}}
                    className={`input-container ${isSearchDisabled ? 'inactive' : ''}`}
                />
            </Tooltip>
            <IconButton aria-label="header-search-icon" className={`search-icon ${isSearchDisabled ? 'disabled' : ''}`}
                        disableRipple={true} type="submit" onClick={event => onSearch(event)}
                        data-testid={searchButtonTestId}>
                <SearchIcon/>
            </IconButton>
        </form>
    )
}