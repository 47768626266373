import React from 'react'
import {GridCellParams} from '@mui/x-data-grid/models/params/gridCellParams'
import {
    getCreatedDate,
    getFileNameDetail,
    getLastModifiedDate,
    getService,
    getStatus
} from './ServiceRequestFormatter'
import {isRecurringExtraction} from '../../service/model/ServiceEnum'

function UserServiceRequestContent({
                                      selectedServiceRequest,
                                  }: {
    selectedServiceRequest: GridCellParams,
}) {

    const isRecurringExtractionType = isRecurringExtraction(selectedServiceRequest.row.service)
    const extractionPath = selectedServiceRequest.row.serviceTypeAttributes?.extractionPath

    return (
        <div className="body">
            <p data-testid="Service">
                <span className="title" title="Service">Service: </span>
                <span className="body-value">{getService(selectedServiceRequest)}</span>
            </p>

            <p data-testid="Details">
                <span className="title" title="Details">Details: </span>
                <span className="body-value">{getFileNameDetail(selectedServiceRequest)}</span>
            </p>

            <p data-testid="Status">
                <span className="title" title="Status">Status: </span>
                <span className="body-value">{getStatus(selectedServiceRequest)}</span>
            </p>

            {isRecurringExtractionType && (
                <p data-testid="ExtractionPath">
                    <span className="title" title="Extraction Path">Extraction Path:</span>
                    <span className="body-value">{extractionPath != undefined ? extractionPath : '/'}</span>
                </p>
            )}

            <p data-testid="Creation Date">
                <span className="title" title="Creation Date">Creation Date: </span>
                <span className="body-value">{getCreatedDate(selectedServiceRequest)}</span>
            </p>
            <p data-testid="Last Modified">
                <span className="title" title="Last Modified">Last Modified: </span>
                <span className="body-value">{getLastModifiedDate(selectedServiceRequest)}</span>
            </p>
        </div>
    )}

export default UserServiceRequestContent