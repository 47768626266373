import React from 'react'
import '../../styles/Forms.scss'
import {ServiceEnum} from '../../../model/ServiceEnum'
import {NewManufacturerItemDescription} from '../description/NewManufacturerItemDescription'
import {useCurrentGuildContext, useDatxPathGenerator} from '../../../../util/routing'
import {getItemsFromFile} from '../../readImportedFile'
import {useCreateDefaultServiceRequestMutation} from '../../rtkServiceRequestApi'
import {ROUTES} from '../../../../constants/routing'
import {ItemMatchingInputDTO, NewItemInputDTO} from '../../../model/NewItemInput'
import {array, mixed, object, string} from 'yup'
import {ServiceRequestFormTemplate} from '../../genericComponents/formTemplate/ServiceRequestFormTemplate'
import {ServiceRequestStep} from '../../model/ServiceRequestStep'
import {UploadTemplateItemCreation} from '../common/UploadTemplateItemCreation'
import {ReviewNewItemsForm} from './ReviewStep/ReviewNewItemsForm'
import {ServiceRequestWorkflowInput} from '../../model/ServiceRequestWorkflowInput'

export interface ManufacturerItemCreationForm extends ServiceRequestWorkflowInput {
    file: File | undefined
    items: NewItemInputDTO[] | ItemMatchingInputDTO[]
}

const selectManufacturer = {
    file: mixed().required(),
    items: array().required().min(1)
}

const validationSchema = {
    ...selectManufacturer,
    selectedWorkflowId: string().required()
}

export const NewManufacturerItemsForm = () => {
    const generatePath = useDatxPathGenerator()
    const [currentGuildContext] = useCurrentGuildContext()
    const [createRequest, {isLoading}] = useCreateDefaultServiceRequestMutation()

    function handleCompleteUploadForm(itemCreation: ManufacturerItemCreationForm): Promise<any> | undefined {
        if (!itemCreation.file) return
        if (!itemCreation.items) return
        if (!itemCreation.selectedWorkflowId) return

        return createRequest({
            service: ServiceEnum.NEW_MANUFACTURER_ITEMS,
            guildId: currentGuildContext?.guildId ?? '',
            file: itemCreation.file,
            numberOfItems: itemCreation.items.length,
            workflowConfigurationId: itemCreation.selectedWorkflowId,
        })
    }

    async function handleFileUpload(tempFile: File) {
        return await getItemsFromFile(tempFile)
    }

    const steps: ServiceRequestStep<ManufacturerItemCreationForm>[] = [
        {
            name: 'Create Manufacturer Items',
            component: <UploadTemplateItemCreation description={NewManufacturerItemDescription}
                                                   handleFileUpload={handleFileUpload}/>,
            pageValidation: object<ManufacturerItemCreationForm>(selectManufacturer)
        }, {
            name: 'Create Manufacturer Items',
            component: <ReviewNewItemsForm service={ServiceEnum.NEW_MANUFACTURER_ITEMS}/>,
            pageValidation: object<ManufacturerItemCreationForm>(validationSchema)
        }
    ]

    return <ServiceRequestFormTemplate
        steps={steps}
        handleFormSubmit={handleCompleteUploadForm}
        initialValues={{
            file: undefined,
            items: [],
            selectedWorkflowId: '',
        }}
        title="Create Manufacturer Items"
        previousUrl={generatePath(ROUTES.newPartsServiceForm.path)}
    ></ServiceRequestFormTemplate>
}
