import {getGuildId, Notification} from '../../../models/Notification'
import {Button} from '@mui/material'
import {ROUTES} from '../../../../constants/routing'
import {generatePath, useNavigate} from 'react-router-dom'
import NotificationDisplay from './GenericNotificationDisplay'
import { useDatxPathGenerator } from '../../../../util/routing'

const ServiceRequestQuotationDeliveredNotification = ({notification}: { notification: Notification }) => {
    const navigate = useNavigate()
    const guildId = getGuildId(notification)
    return (
        <NotificationDisplay
            notification={notification}
            title="Quotation Received"
            canClose={true}
        >
            <Button
                id="notification-action-button-accept"
                type="button"
                variant="contained"
                onClick={() => navigate(generatePath(ROUTES.serviceRequests.path || '', {currentGuildId: guildId}))}
            >
                View
            </Button>
        </NotificationDisplay>

    )

}



export default ServiceRequestQuotationDeliveredNotification