import {searchAPI} from '../search/rtkSearchApi'
import {BillingTypeEnum, InformationPackage} from '../item/model/InformationPackage'

export const INFORMATION_PACKAGES_TAG = 'InformationPackages'

export const informationPackageApi = searchAPI.enhanceEndpoints({
    addTagTypes: [INFORMATION_PACKAGES_TAG]
}).injectEndpoints({
    endpoints :(builder) => ({
        getAllInformationPackages : builder.query<InformationPackage[], BillingTypeEnum>({
            query: (billingType) => ({
                url :`informationpackages/type/${billingType}`,
                method: 'GET'
            }),
            transformErrorResponse: () => 'Something went wrong during information package retrieval',
            providesTags: [INFORMATION_PACKAGES_TAG],
            keepUnusedDataFor: 300
        }),
    })
})

export const {useGetAllInformationPackagesQuery} = informationPackageApi


