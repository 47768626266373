import {AttributeValue} from './AttributeValue'

export interface AttributeModel {
    attributeDefinitionId: string
    templateId?: DefinitionTemplateEnum | string
    id: string
    value: AttributeValue
    displayOrder: number
    attributePosition: number
    name: string
    unit: string | null
    attributeDefinitionType: AttributeDefinitionType | null
    createdDate?: number[] | null
}

export interface AttributeDefinitionType {
    attributeDefinitionType: AttributeDefinitionTypeEnum
    classificationTreeId?: string | null
    constraintLite?: ConstraintLite
    linkType?: LinkTypeEnum | null | undefined
    composedByAttributesDefinition?: string[]
}

export interface ConstraintLite {
    id: string
    label: string
    managedBy: string
    definedBy: string
    isPublic: boolean
    type: string
}

export interface AttributeDefinitionModel {
    attributeDefinitionId: string
    templateId?: DefinitionTemplateEnum | string
    displayOrder: number
    name: string
    unit: string | null
    attributes: AttributeModel[]
}

export enum AttributeDefinitionTypeEnum {
    BOOLEAN = 'BOOLEAN',
    STRING = 'STRING',
    DOUBLE = 'DOUBLE',
    INTEGER = 'INTEGER',
    DATE = 'DATE',
    CLASSIFICATION = 'CLASSIFICATION',
    COMPLEX = 'COMPLEX',
    LINK = 'LINK',
    REFERENCE_LIST = 'REFERENCE_LIST',
    CONSTRAINT = 'CONSTRAINT',
    ARTIFACT = 'ARTIFACT'
}

export enum DefinitionTemplateEnum {
    DEFAULT = 'DEFAULT',
    FREE_TEXT = 'FREE_TEXT',
    NO_DEFINITION_DISPLAY = 'NO_DEFINITION_DISPLAY',
    ARTIFACT_DEFAULT = 'ARTIFACT_DEFAULT',
    ARTIFACT_DATA_SHEET = 'ARTIFACT_DATA_SHEET',
    REGULATION_STATUS = 'REGULATION_STATUS',
    REGULATION_DETAILS = 'REGULATION_DETAILS',
    REGULATION_PROOFS = 'REGULATION_PROOFS',
    CAD_MODEL = 'CAD_MODEL'
}

export enum LinkTypeEnum {
    ITEM_CHEMICAL_COMPOSITION = 'ITEM_CHEMICAL_COMPOSITION',
    ITEM_CONFORMITY_LINK = 'ITEM_CONFORMITY_LINK',
    ITEM_EQUIVALENCE_LINK = 'ITEM_EQUIVALENCE_LINK',
    ORG_CODIFICATION = 'ORG_CODIFICATION',

    ITEM_PRICE_ORIGIN_ORGANIZATION = 'ITEM_PRICE_ORIGIN_ORGANIZATION',
    ITEM_PRICE_DISTRIBUTOR_ORIGIN_ORGANIZATION = 'ITEM_PRICE_DISTRIBUTOR_ORIGIN_ORGANIZATION',

    ITEM_EQUIVALENCE_LINK_ID_CHANGE = 'ITEM_EQUIVALENCE_LINK_ID_CHANGE', //TODO remove when migration will be done
    ITEM_VARIANCE_LINK_PACKAGING = 'ITEM_VARIANCE_LINK_PACKAGING',
    ITEM_CAMS_SOLUTION = 'ITEM_CAMS_SOLUTION'
}

export enum AttributeStatusColorEnum {
    GREEN = 'GREEN',
    GREY = 'GREY',
    ORANGE = 'ORANGE',
    RED = 'RED'
}
