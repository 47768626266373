import {createApi} from '@reduxjs/toolkit/dist/query/react'
import {EXTRACT_API_ENDPOINT} from '../constants/backendUrls'
import { FTPCredentials } from '../guild/guildSFTPAccess/models/FTPCredentials'
import {axiosBaseQuery} from '../rtkQueryUtils'
import { IdType } from '../util/models/IdType'


export const extractAPI = createApi({
    reducerPath: 'extractAPI',
    keepUnusedDataFor: 0,
    baseQuery : axiosBaseQuery({baseUrl: `${EXTRACT_API_ENDPOINT}/api/v1/datx/`}),
    endpoints :(builder) => ({
        getGuildFTPCredentials: builder.query<FTPCredentials, IdType | undefined>({
            query : (guildId) => ({
                url : `guilds/${guildId}/ftp-account`,
                method : 'GET'
            }),
        }),
        resetFTPPassword: builder.mutation<FTPCredentials, IdType | undefined>({
            query: (guildId) => ({
                url: `guilds/${guildId}/ftp-account/password`,
                method: 'POST'
            }),
            transformErrorResponse : () => 'Something went wrong while generating your password'
        })

    })
})


export const {
    useGetGuildFTPCredentialsQuery,
    useResetFTPPasswordMutation } = extractAPI
