import {Link} from 'react-router-dom'
import './DoubleIdWidget.scss'
import {useDatxPathGenerator} from '../../../util/routing'
import {ROUTES} from '../../../constants/routing'
import {DisplaySet} from '../../model/DisplaySet'
import {SimpleItem} from '../../../item/model/SimpleItem'
import {getLinkedItems} from '../../utils/sortedDefinitions'
import {AttributeDefinitionTypeEnum} from '../../model/AttributeModel'

const DoubleIdHeaderRow = () => (
    <div className="header">
        <p className="header-cell">Part Number</p>
        <p className="header-cell">Defined By</p>
        <p className="header-cell">Codification System</p>
    </div>
)

const DoubleIdLinkRow = ({id, partNumber, definedBy, codificationSystem}: {
    id: string,
    partNumber: string,
    definedBy: string,
    codificationSystem: string}) => {
    const generatePath = useDatxPathGenerator()

    return (
        <div className="row">
            <Link
            to={generatePath(ROUTES.detailItem.path, {itemId: id})}
            className="cell"
            data-testid={`item-${id}`}
        >
            {partNumber}
        </Link>
            <p className="cell">{definedBy}</p>
            <p className="cell">{codificationSystem}</p>
        </div>
    )
}

const DoubleIdWidget = ({displaySet}: { displaySet: DisplaySet }) => {
    const linkedItemsAttributeModel = displaySet.attributes.filter((it) => it.attributeDefinitionType?.attributeDefinitionType === AttributeDefinitionTypeEnum.LINK)

    const linkedItems = getLinkedItems(linkedItemsAttributeModel)

    if (linkedItems.length === 0) return <></>

    return (
        <div className="double-id-widget">
            <div data-testid="double-id-table" className="related-item-display-set-table">
                <DoubleIdHeaderRow/>
                <div data-testid="double-id-body-table" className="body-container">
                    {linkedItems.map((row, index) => (
                    <DoubleIdLinkRow key={row.id} id={row.id} partNumber={row.partNumber}
                                     definedBy={row.definedByOrganization.label}
                                     codificationSystem={row.definedByOrganization.codificationSystem}
                    />))
                    }
                </div>
            </div>
        </div>
    )
}

export default DoubleIdWidget
