import React, {useState} from 'react'
import {Checkbox, FormControlLabel} from '@mui/material'
import './SearchFilterCard.scss'
import {DisplayFilterComponent, DisplayFilterDTO, ValueOption} from '../model/DisplayFiltersDTO'
import {FastField, useFormikContext} from 'formik'

const SearchFilterCard = ({filter, name, shouldGetId = false}: {
    filter: DisplayFilterDTO,
    name: string,
    shouldGetId?: boolean
}) => {

    const formik = useFormikContext()
    const sortValues = (a: ValueOption, b: ValueOption): number => {
        // if values could be numbers, compare them as number
        if (+(a.value) && +(b.value)) {
            return +(a.value) - +(b.value)
        }
        return a.value.localeCompare(b.value)
    }
    const filterSelectableValues = (values: ValueOption[]) => {
        const sortedValues = [...values]
        sortedValues.sort(sortValues)
        return sortedValues
    }
    const [selectableValues, setSelectableValues] = useState(filterSelectableValues(filter?.values ?? []))
    const [selectAllFilterChecked, setSelectAllFilterChecked] = useState<boolean>(false)

    /**
     * DATX-494 : Workaround to fix formik issue
     */
    const getValue = (option: ValueOption) => {
        return (option.value === 'false' || option.value === 'true') ? JSON.stringify(option.value) : option.value
    }

    const handleSelectAll = () => {
        const allSelected = !selectAllFilterChecked
        if(allSelected) {
            formik.setFieldValue(name, selectableValues.map(option => (shouldGetId ? option.targetId : getValue(option))))
        } else {
            formik.setFieldValue(name, [])
        }
        setSelectAllFilterChecked(allSelected)
    }

    return <>
        <div className="search-filter" data-testid={filter.id + '-filter-card'}>
            <span className="filter-card-title">{filter.name}</span>
            <div className="search-filter-content">

                <DisplayFilterComponent filter={filter} setSelectableValues={setSelectableValues}/>

                <div className="search-filter-content-list" role="group">
                    <FormControlLabel
                        control={
                            <Checkbox
                                data-testid="select-all-filter"
                                name="selectAllFilter"
                                checked={selectableValues?.every((value) => formik.getFieldProps(name)?.value?.includes(value.targetId || value.value)) && selectableValues.length !== 0}
                                onChange={handleSelectAll}
                            />
                        }
                        label="(Select All)"
                    />
                    {selectableValues.map((option) => (
                        <FastField
                            type="checkbox"
                            name={name}
                            value={shouldGetId ? option.targetId : getValue(option)}
                            key={option.value}
                            as={FormControlLabel}
                            control={<Checkbox/>}
                            label={`${option.value} ${filter?.suffix ?? ''}`}
                            title={`${option.value} ${filter?.suffix ?? ''}`}
                            data-testid={option.targetId + '-filter-value'}
                            id={option.targetId + '-filter-value'}
                            sx={{fontSize: 12}}
                        />
                    ))}
                </div>
            </div>
        </div>
    </>

}

export default SearchFilterCard