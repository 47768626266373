import {CookiesCategory} from '../CookiesTypes'
import {Switch} from '@mui/material'
import {ChangeEvent} from 'react'

export const AllowAllVendorsSwitch = ({category, isChecked, handeSwitch}: {
    category: CookiesCategory,
    isChecked: boolean,
    handeSwitch: (e: ChangeEvent<HTMLInputElement>) => void
}) => {

    return category !== CookiesCategory.RequiredCookies ? <div className="allow-all-consent">
        <span>Allow All Consent </span>
        <Switch size="small" checked={isChecked} name="categoryConsent.userConsent"
                onChange={(e) => handeSwitch(e)}/>
    </div> : <></>

}