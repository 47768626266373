import {ROUTES} from '../../constants/routing'
import {ReactNode} from 'react'
import {Link} from 'react-router-dom'
import {useDatxPathGenerator} from '../../util/routing'
import {Headline} from '../../genericComponents/typographie/Headline'
import './Shortcuts.scss'
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined'
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined'
import InventoryRoundedIcon from '@mui/icons-material/InventoryRounded'


const Shortcut = ({title, icon, link, color}: {
    title: string,
    icon: ReactNode,
    link: string | undefined,
    color: string
}) => {
    const generatePath = useDatxPathGenerator()

    return <Link to={generatePath(link)} className="shortcut">
        <div className={'shortcut-icon ' + color}>{icon}</div>
        <div className="shortcut-title">{title}</div>
    </Link>
}

export const Shortcuts = () => {
    return <div className="home-shortcuts-container">
        <Headline title="SHORTCUTS">SHORTCUTS</Headline>
        <div className="shortcut-list">
            <Shortcut title="Service Catalogue" icon={<StorefrontOutlinedIcon/>} link={ROUTES.servicesCatalogue.path}
                      color="catalogue"></Shortcut>
            <Shortcut title="My Service Requests" icon={<InventoryRoundedIcon/>} link={ROUTES.userServiceRequests.path}
                      color="requests"></Shortcut>
        </div>
    </div>
}