import SharingRow from './SharingRow'
import {GuildSharingSummary} from '../model/GuildSharingSummary'
import './ItemSharing.scss'

const SharingList = ({itemShares, toMyGuild}: {
    itemShares: GuildSharingSummary[] | undefined,
    toMyGuild: boolean
}) => {
    return <div className="sharing-list">
        {itemShares?.length
            ? itemShares?.map((itemSharing: GuildSharingSummary) => <SharingRow
                key={itemSharing.id}
                itemSharing={itemSharing} toMyGuild={toMyGuild}/>)
            : <p>No sharing was found</p>}
    </div>
}

export default SharingList
