import {
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    InputAdornment,
    MenuItem,
    Select,
    TextField,
    TextFieldProps,
    Typography
} from '@mui/material'
import {GridCellParams, GridColDef, GridFilterOperator, GridSearchIcon, GridFilterItem} from '@mui/x-data-grid'
import React, {useMemo, useState} from 'react'
import {DesktopDatePicker} from '@mui/x-date-pickers'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'

const DateRangeInputComponent = ({
    colDef,
    getPossibleValues,
    filters,
    setFilter,
    operator
}: {
    colDef: GridColDef
    getPossibleValues: (col: GridColDef) => any[]
    filters: any[]
    setFilter: (filter: GridFilterItem) => void
    operator: string
}) => {
    const [startDate, setStartDate] = useState<Date | null>(null)
    const [endDate, setEndDate] = useState<Date | null>(null)
    const [error, setError] = useState<string>('')

    const updateFilter = (start: Date | null, end: Date | null) => {
        setFilter({
            id: colDef.field,
            field: colDef.field,
            operator: operator,
            value: [start, end]
        })
    }

    const handleStartDateChange = (newStartDate: Date | null) => {
        if (endDate && newStartDate && newStartDate > endDate) {
            setError('Start date cannot be after end date')
            return
        }
        setStartDate(newStartDate)
        updateFilter(newStartDate, endDate)
        setError('')
    }

    const handleEndDateChange = (newEndDate: Date | null) => {
        if (startDate && newEndDate && newEndDate < startDate) {
            setError('End date cannot be before start date')
            return
        }
        setEndDate(newEndDate)
        updateFilter(startDate, newEndDate)
        setError('')
    }

    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className="date-range-picker">
                    <div className="date-picker-container">
                        <DesktopDatePicker
                            label="Start Date"
                            value={filters[0] ?? null}
                            onChange={handleStartDateChange}
                            renderInput={(params: TextFieldProps) => (
                                <TextField {...params} className="date-text-field" variant="outlined" />
                            )}
                            maxDate={new Date()}
                        />
                        <div className="date-separator"></div>
                        <DesktopDatePicker
                            label="End Date"
                            value={filters[1] ?? null}
                            onChange={handleEndDateChange}
                            renderInput={(params: TextFieldProps) => (
                                <TextField {...params} className="date-text-field" variant="outlined" />
                            )}
                            maxDate={new Date()}
                        />
                    </div>
                    {error && <Typography color="error">{error}</Typography>}
                </div>
            </LocalizationProvider>
        </div>
    )
}

const EqualsOperatorInput = ({
    colDef,
    getPossibleValues,
    filters,
    setFilter,
    operator
}: {
    colDef: GridColDef
    getPossibleValues: (col: GridColDef) => any[]
    filters: any[]
    setFilter: (filter: GridFilterItem) => void
    operator: string
}) => {
    const [sub, setSub] = useState<string>('')

    const existingValues = useMemo(
        () =>
            getPossibleValues(colDef).filter((value) => {
                return value.toString().toLowerCase().includes(sub.toLowerCase())
            }),
        [colDef, getPossibleValues, sub]
    )

    return (
        <div className="datx-list-filter">
            <TextField
                value={sub}
                onChange={(e) => setSub(e.target.value)}
                variant="outlined"
                margin="normal"
                InputLabelProps={{
                    classes: {
                        root: 'subFilterInput'
                    }
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <GridSearchIcon className="search-icon" />
                        </InputAdornment>
                    )
                }}
                placeholder={colDef.headerName ?? colDef.field}
            />
            <div className="filter-checkboxes">
                {existingValues.map((fil) => {
                    return (
                        <FormControlLabel
                            key={fil}
                            control={
                                <Checkbox
                                    value={fil}
                                    checked={filters.includes(fil)}
                                    onChange={(e, checked) => {
                                        setFilter({
                                            id: colDef.field,
                                            field: colDef.field,
                                            operator: operator,
                                            value: checked
                                                ? [...filters, fil]
                                                : filters.filter((filter: string) => filter !== fil)
                                        })
                                    }}
                                />
                            }
                            label={fil}
                        />
                    )
                })}
            </div>
        </div>
    )
}

const SelectOperatorInput = ({
    colDef,
    getPossibleValues,
    filters,
    setFilter,
    operator
}: {
    colDef: GridColDef
    getPossibleValues: (col: GridColDef) => any[]
    filters: any[]
    setFilter: (filter: GridFilterItem) => void
    operator: string
}) => {
    const existingValues = useMemo(() => getPossibleValues(colDef), [colDef, getPossibleValues])

    return (
        <Select
            onChange={(e) => {
                setFilter({
                    id: colDef.field,
                    field: colDef.field,
                    operator: operator,
                    value: [e.target.value]
                })
            }}
            value={filters.length ? filters[0] : ''}
            displayEmpty
        >
            <MenuItem value="">No Filter</MenuItem>
            {existingValues.map((value) => {
                return (
                    <MenuItem key={value} value={value}>
                        {value}
                    </MenuItem>
                )
            })}
        </Select>
    )
}

const MatchStringOperatorInput = ({
    colDef,
    getPossibleValues,
    filters,
    setFilter,
    operator
}: {
    colDef: GridColDef
    getPossibleValues: (col: GridColDef) => any[]
    filters: any[]
    setFilter: (filter: GridFilterItem) => void
    operator: string
}) => {
    return (
        <TextField
            value={filters[0] || ''}
            onChange={(e) =>
                setFilter({
                    id: colDef.field,
                    field: colDef.field,
                    operator: operator,
                    value: [e.target.value]
                })
            }
            variant="outlined"
            margin="normal"
            InputLabelProps={{
                classes: {
                    root: 'subFilterInput'
                }
            }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <GridSearchIcon className="search-icon" />
                    </InputAdornment>
                )
            }}
            placeholder={colDef.headerName ?? colDef.field}
        />
    )
}

export const equalsOperator: GridFilterOperator = {
    label: 'equals',
    value: 'equals',
    getApplyFilterFn: (filterItem, column) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator || !filterItem.value.length) {
            return null
        }

        return (params: GridCellParams) => {
            return filterItem.value.some((filterValue: string) => String(params.value) === filterValue)
        }
    },
    InputComponent: EqualsOperatorInput
}

export const comboBoxSelect: GridFilterOperator = {
    label: 'comboSelect',
    value: 'comboSelect',
    getApplyFilterFn: (filterItem, column) => {
        if (
            !filterItem.field ||
            !filterItem.value ||
            !filterItem.operator ||
            !filterItem.value.length ||
            !filterItem.value[0]
        ) {
            return null
        }
        return (params: GridCellParams) => {
            return filterItem.value === String(params.value)
        }
    },
    InputComponent: SelectOperatorInput
}

export const matchString: GridFilterOperator = {
    label: 'matchString',
    value: 'matchString',
    getApplyFilterFn: (filterItem, column) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator || !filterItem.value.length) {
            return null
        }

        return (params: GridCellParams) => {
            return String(params.value).toLowerCase().includes(filterItem.value[0].toLowerCase())
        }
    },
    InputComponent: MatchStringOperatorInput
}

export const dateRangeMatch: GridFilterOperator = {
    label: 'dateRangeMatch',
    value: 'dateRangeMatch',
    getApplyFilterFn: (filterItem, column) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator || !filterItem.value.length) {
            return null
        }

        const [startDate, endDate] = filterItem.value

        return (params: GridCellParams) => {
            if (!params.value || !Array.isArray(params.value)) return false
            const [year, month, day, hour, minute, second, millisecond] = params.value
            const fixedMillisecond = millisecond % 1000
            const transactionDate = new Date(year, month - 1, day, hour, minute, second, fixedMillisecond)

            const start = startDate ? new Date(startDate) : null
            const end = endDate ? new Date(endDate) : null

            return (!start || transactionDate >= start) && (!end || transactionDate <= end)
        }
    },
    InputComponent: DateRangeInputComponent
}

export const operatorMap: {[key: string]: any} = {
    equals: equalsOperator,
    comboSelect: comboBoxSelect,
    matchString: matchString,
    dateRangeMatch: dateRangeMatch
}
