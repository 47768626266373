import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import {SearchRounded} from '@mui/icons-material'
import {Formik} from 'formik'
import React from 'react'
import {object, string} from 'yup'
import {FormHelperText} from '@mui/material'

export interface SearchMembersForm {
    email: string | undefined
}

export const SearchMembers = ({handleSearchMember}: { handleSearchMember: (email: string | undefined) => void }) => {

    const validation = object({
        email: string().min(0).max(200)
    })

    const handleFormSubmit = (formResult: SearchMembersForm) => {
        handleNewEmail(formResult?.email)
    }
    const handleEmailChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        handleNewEmail(event?.target?.value)
    }

    const handleNewEmail = (email: string | undefined) => {
        if (!email) {
            handleSearchMember(undefined)
            return
        }
        if (email.length < 0) return
        if (email.length > 200) return

        handleSearchMember(email)
    }

    return <Formik enableReinitialize
                   initialValues={{email: undefined}}
                   validationSchema={validation}
                   onSubmit={handleFormSubmit}
                   validateOnBlur={true}
                   validateOnChange={true}
    >{({
           values,
           errors,
           handleSubmit,
           handleChange,
           handleBlur,

       }) => (
        <form onSubmit={handleSubmit} className="search-member-container">
            <div className="search-member-bar">
                <InputBase
                    name="email"
                    inputProps={{'data-testid': 'search-members'}}
                    className="search-members"
                    id="email"
                    type="input"
                    value={values.email}
                    onChange={(event) => {
                        handleChange(event)
                        handleEmailChange(event)
                    }}
                    onBlur={(event) => {
                        handleBlur(event)
                        handleEmailChange(event)
                    }}
                    placeholder="Search guild members by e-mail"
                />
                <IconButton type="submit"
                            disableRipple={true} data-testid={'SearchIcon'}>
                    <SearchRounded className="homePageSearchIcon" id="homePageSearchIcon"/>
                </IconButton>
            </div>
            <div className="search-member-error">
                {errors.email && (
                    <FormHelperText className="form-helper-text" error>
                        {errors.email}
                    </FormHelperText>
                )}
            </div>

        </form>
    )}
    </Formik>
}