import React, {ReactElement} from 'react'
import {WidgetEnum, WidgetId} from './model/Widget'
import {DisplaySet} from './model/DisplaySet'
import {SimpleItem} from '../item/model/SimpleItem'
import {DefaultDisplaySetDataWidget} from './widgets/DefaultDisplaySetDataWidget'
import {ComingSoonWidget} from './widgets/ComingSoon/ComingSoonWidget'
import {ProcurementWidget} from './widgets/Procurement/ProcurementWidget'
import {ChemicalCompositionWidget} from './widgets/ChemicalComposition/ChemicalCompositionWidget'
import {DangerousnessWidget} from './widgets/DangerousnessWidget/DangerousnessWidget'
import ConformityLinkWidget from './widgets/ConformityLinkWidget/ConformityLinkWidget'
import CodificationLinkWidget from './widgets/CodificationLinkWidget/CodificationLinkWidget'
import VariancePackagingWidget from './widgets/VariancePackagingWidget/VariancePackagingWidget'
import DisplaySetTitle from './DisplaySetTitle'
import DoubleIdWidget from './widgets/DoubleIdWidget/DoubleIdWidget'
import {DisplaySetOrdered} from '../user/model/DisplaySetOrdered'
import {isValidityExpiredFromCurrentDate} from '../genericComponents/date/DateService'

const mapWidgetToComponent = new Map<WidgetId, (displaySet: DisplaySet, parentItem?: SimpleItem) => ReactElement>([
    [WidgetEnum.DEFAULT, (displaySet, _) => <DefaultDisplaySetDataWidget displaySet={displaySet} />],
    [WidgetEnum.COMING_SOON, (displaySet, _) => <ComingSoonWidget />],
    [WidgetEnum.PROCUREMENT, (displaySet, _) => <ProcurementWidget displaySet={displaySet} />],
    [WidgetEnum.CHEMICAL_COMPOSITION, (displaySet, parentItem) => <ChemicalCompositionWidget displaySet={displaySet} parentItem={parentItem} />],
    [WidgetEnum.DANGEROUSNESS, (displaySet, _) => <DangerousnessWidget displaySet={displaySet} />],
    [WidgetEnum.CONFORMITY_LINK, (displaySet, parentItem) => <ConformityLinkWidget displaySet={displaySet} parentItem={parentItem} />],
    [WidgetEnum.CODIFICATION_LINK, (displaySet, parentItem) => <CodificationLinkWidget displaySet={displaySet} parentItem={parentItem} />],
    [WidgetEnum.ITEM_VARIANCE_LINK_PACKAGING, (displaySet, _) => <VariancePackagingWidget displaySet={displaySet}/>],
    [WidgetEnum.DOUBLE_ID_LINK, (displaySet, _) => <DoubleIdWidget displaySet={displaySet}/>]
])

//Attribute Definition Id for Item Link End Effective date
const ITEM_LINK_EXPIRY_DEFINITION = 'ae4793c1-dc33-5286-949b-ca8dd308a50e'

export const getWidget = (displaySet: DisplaySet, parentItem?: SimpleItem) => {
    const filteredDisplaySet = getFilteredDisplaySetRemoveExpiredLinks(displaySet)
    const componentFunction = mapWidgetToComponent.get(filteredDisplaySet?.widgetId ?? WidgetEnum.DEFAULT)
    if (!componentFunction) return <DefaultDisplaySetDataWidget displaySet={filteredDisplaySet}></DefaultDisplaySetDataWidget>
    return componentFunction(filteredDisplaySet, parentItem)
}

const getFilteredDisplaySetRemoveExpiredLinks = (displaySet: DisplaySet): DisplaySet => {
    const filteredAttributes = displaySet.attributes.filter(attr => {
            const validityExpiryDate = attr.value.composedByAttributes?.find(attr => attr.attributeDefinitionId === ITEM_LINK_EXPIRY_DEFINITION)?.value.value
            return !(validityExpiryDate && isValidityExpiredFromCurrentDate(validityExpiryDate))
        }
    )
    return {
        ...displaySet,
        attributes: filteredAttributes
    }
}

export const DisplaySetComponent = ({displaySet, parentItem}: { displaySet: DisplaySetOrdered, parentItem?: SimpleItem }) => {
    const displaySetLabelClassName = displaySet.displaySet.label.replace(' ', '-').toLowerCase()

    return <div className={'display-set ' + displaySetLabelClassName} data-testid="test-first-level-display-set">
        <DisplaySetTitle title={displaySet.displaySet.label} iconName={displaySet.displaySet.iconName}/>
        <div className="display-set-content">
            {getWidget(displaySet.displaySet, parentItem)}
        </div>
    </div>
}

