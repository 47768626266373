import React, {ReactElement} from 'react'
import {SimpleItem} from './SimpleItem'
import {SearchItem} from '../../search/model/SearchItem'
import {IconDisplay} from '../../genericComponents/IconDisplay'

export enum ItemLabelEnum {
    COMPANY = 'Company Item',
    MANUFACTURER = 'Manufacturer Item',
    ABSTRACT = 'Abstract Item'
}

export enum ItemTypeEnum {
    COMPANY = 'Company Item',
    MANUFACTURER = 'Manufacturer Item',
    ABSTRACT = 'Abstract Item'
}

const ItemTypeIconSourceMap = new Map<string, string>([
    [ItemTypeEnum.COMPANY.toUpperCase(), '/images/item-type/company_item.svg'],
    [ItemTypeEnum.MANUFACTURER.toUpperCase(), '/images/item-type/manufacturer_item.svg'],
    [ItemTypeEnum.ABSTRACT.toUpperCase(), '/images/item-type/abstract_item.svg'],
    ['default', '/images/item-type/abstract_item.svg']
])

export interface ItemType {
    type: string
    label: string
    icon: ReactElement | null
}

export const getItemType = (item: SimpleItem | SearchItem) => {
    const defaultItemType: ItemType = {
        type: ItemTypeEnum.ABSTRACT,
        label: 'Defined by',
        icon: (
            <IconDisplay
                className="type-item-icon"
                iconName={ItemTypeEnum.ABSTRACT}
                iconSourceMap={ItemTypeIconSourceMap}
                iconLabel={ItemLabelEnum.ABSTRACT}
            />
        )
    }

    const itemTypeMap = new Map<string, ItemType>([
        [
            ItemTypeEnum.COMPANY,
            {
                type: ItemTypeEnum.COMPANY,
                label: 'Defined by',
                icon: (
                    <IconDisplay
                        className="type-item-icon"
                        iconName={ItemTypeEnum.COMPANY}
                        iconSourceMap={ItemTypeIconSourceMap}
                        iconLabel={ItemLabelEnum.COMPANY}
                    />
                )
            }
        ],
        [
            ItemTypeEnum.MANUFACTURER,
            {
                type: ItemTypeEnum.MANUFACTURER,
                label: 'Defined by',
                icon: (
                    <IconDisplay
                        className="type-item-icon"
                        iconName={ItemTypeEnum.MANUFACTURER}
                        iconSourceMap={ItemTypeIconSourceMap}
                        iconLabel={ItemLabelEnum.MANUFACTURER}
                    />
                )
            }
        ],
        [
            ItemTypeEnum.ABSTRACT,
            {
                type: ItemTypeEnum.ABSTRACT,
                label: 'Defined by',
                icon: (
                    <IconDisplay
                        className="type-item-icon"
                        iconName={ItemTypeEnum.ABSTRACT}
                        iconSourceMap={ItemTypeIconSourceMap}
                        iconLabel={ItemLabelEnum.ABSTRACT}
                    />
                )
            }
        ]
    ])
    return itemTypeMap.get(item?.type?.label) ?? defaultItemType
}
