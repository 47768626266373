import {
    COOKIE_EXPIRE_TIME,
    CookieConsentType,
    COOKIES_CONSENT_NAME,
    CookiesCategory,
    CookieVendor
} from './CookiesTypes'
import Cookies from 'universal-cookie'

const cookies = new Cookies(null, { path: '/' })
export const getCookie = (name: string): object | undefined => {
    return cookies.get(name)
}

export const setCookie = (name: string, value: string, daysExpire: number = COOKIE_EXPIRE_TIME) => {
    const expire = new Date()
    expire.setDate(expire.getDate() + daysExpire)
    cookies.set(name, value, {expires: expire})
}

export const removeCookie = (name: string) => {
    cookies.remove(name)
}

export const getCookieConsentFor = (category: CookiesCategory, cookieName: CookieVendor): boolean => {
    const cookieConsentValue = getCookie(COOKIES_CONSENT_NAME)
    if (cookieConsentValue === undefined) return false
    return (cookieConsentValue as CookieConsentType).cookieCategories[category].cookies[cookieName]?.userConsent || false
}
