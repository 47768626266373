import React, {ReactElement, useEffect, useState} from 'react'
import {CustomCard} from '../../genericComponents/customCard/CustomCard'
import {IdType} from '../../util/models/IdType'
import {SearchItemSelectable} from '../../search/model/SearchItemSelectable'
import {getItemType, ItemType, ItemTypeEnum} from '../model/ItemType'
import Highlighter from 'react-highlight-words'
import {HeaderLinkContent} from '../itemDetails/itemDetailsHeader/ItemDetailsHeaderContent'
import {ROUTES} from '../../constants/routing'
import {useDatxPathGenerator} from '../../util/routing'
import ItemTypeChip from '../ItemTypeChip'
import {ItemNatureIcon} from '../itemNature/ItemNatureIcon'

export const ItemCard = ({
    content,
    id,
    handleChildChecked,
    actionIcon,
    highlightPhrase,
}: {
    content: SearchItemSelectable
    id: IdType
    handleChildChecked: any
    actionIcon: ReactElement
    highlightPhrase: string
}) => {
    const generatePath = useDatxPathGenerator()
    const [checked, setChecked] = useState(false)
    const itemType = getItemType(content.item)

    const partNumber = (
        <>
            <span className="label">Part Number</span>
            <span className="value" title={content.item.partNumber}>
                <Highlighter
                    searchWords={[highlightPhrase || '']}
                    autoEscape={true}
                    highlightClassName	={'match-element'}
                    textToHighlight={content.item.partNumber}
                />
            </span>
        </>
    )

    const definedBy = (
        <span className="value"
              title={content.item.definedByOrganization.label}>
                        <HeaderLinkContent className="defined-by" newTab={true}
                                           value={content.item.definedByOrganization.label}
                                           path={generatePath(ROUTES.organizationDetails.path, { organizationId: content.item.definedByOrganization.id })}
                                           label={itemType.label}/>
        </span>
    )

    const bodyContent =
        (
            <>
                <div className="tile-container">
                    <div className="item-info">
                        <div className="image-container">
                            <ItemNatureIcon nature={content.item.nature}/>
                        </div>
                        <div className="part-number-and-defined-by-container">
                            {partNumber}
                            {definedBy}
                        </div>
                    </div>
                </div>
                <div className="tile-container">
                    <div className="item-info centered">
                        <span className="label">Name</span>
                        <span className="value" title={content.item.label ?? '-'}>
                            <Highlighter
                                searchWords={[highlightPhrase || '']}
                                autoEscape={true}
                                textToHighlight={content.item.label ?? '-'}
                            />
                        </span>
                    </div>
                    <div className="item-info centered">
                        <span className="label">DATX ID</span>
                        <span className="value" title={content.item.id}>{content.item.id}</span>
                    </div>
                    <div className="item-info centered">
                        <HeaderLinkContent label="Technical Classification" className="technical-classification"
                                           value={content.item.technical?.label}
                                           path={generatePath(ROUTES.searchByClassificationNodeDescendants.path, { classificationId: content.item.technical?.id })}
                        />
                    </div>
                </div>
            </>
        )

    useEffect(() => {
        setChecked(content.selected)
    }, [content.selected])

    const handleChange = () => {
        const newState = !content.selected
        setChecked(newState)
        content.selected = newState
        handleChildChecked(content)
    }

    return (
        <CustomCard
            id={id}
            name={content.item.partNumber}
            checked={checked}
            handleChange={handleChange}
            actionList={actionIcon}
            cssClass={getItemTypeClass(itemType)}
            typeIcon={getItemTypeIcon(itemType)}
            bodyContent={bodyContent}
        />
    )
}

const getItemTypeClass = (type: ItemType) =>{
    switch(type.type){
        case ItemTypeEnum.MANUFACTURER: 
            return 'manufacturer'
        case ItemTypeEnum.COMPANY:
            return 'company'
        case ItemTypeEnum.ABSTRACT:
            return 'abstract'
        default: 
            return ''
    }
}

const getItemTypeIcon = (type : ItemType) =>{
    return (
        <div className="pre-header-information">
        <span className="pre-header-value">
            <ItemTypeChip itemTypeLabel={type.type} />
        </span>
        </div> )
}

