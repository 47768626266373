
import {guildAPI} from '../../guild/rtkGuildApi'
import {News} from './model/News'

export const NEWS_TAG = 'News'

export const newsApi = guildAPI.enhanceEndpoints({
    addTagTypes : [NEWS_TAG]
}).injectEndpoints({
    endpoints: (builder) => ({
        getEnabledNews : builder.query<News[], void>({
            query : () => ({
                url : 'news/enabled',
                method: 'GET'
            }),
            transformResponse: (response:any) => response.data,
            transformErrorResponse: () => 'Error getting enabled news',
            providesTags: [NEWS_TAG]
        })
    })
})

export const {useGetEnabledNewsQuery} = newsApi