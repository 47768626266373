import {useDatxPathGenerator} from '../../../../../util/routing'
import {Link} from 'react-router-dom'
import {Description} from '../../../../../genericComponents/typographie/Description'
import {Button} from '@mui/material'
import {ROUTES} from '../../../../../constants/routing'
import React from 'react'
import {ServiceRequestBody} from '../../../genericComponents/structure/ServiceRequestBody'
import {ServiceRequestFooter} from '../../../genericComponents/structure/ServiceRequestFooter'

export const ConfirmationStepSelfService = () => {
    const generatePath = useDatxPathGenerator()

    return <>
        <ServiceRequestBody>
            <Description>
                Your Item Matching output has been generated. Please check that your output has been downloaded on
                your browser.
            </Description>
            <Description>
                If you require matching of a large number of items, it is recommended to{' '}
                <a href={generatePath(generatePath(ROUTES.partMatchingServicePaidForm.path))}>
                    Request Item Matching (Paid Service).
                </a>{' '}
                This will save you the time that you would otherwise spend in checking for each part manually.
            </Description>
        </ServiceRequestBody>
        <ServiceRequestFooter>
            <Button
                component={Link}
                color="primary"
                variant="contained"
                type="button"
                to={generatePath(ROUTES.userServiceRequests.path)}
                data-testid="to-my-requests"
                className="view-my-service-requests-button"
            >
                View My Service Requests
            </Button>
        </ServiceRequestFooter>
    </>
}
