import React, {useState} from 'react'
import './guildSFTPAccess.scss'
import {Paper, Typography} from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import {SFTPPasswordModal} from './sftpPasswordModal/SFTPPasswordModal'
import {LoadingButton} from '@mui/lab'
import { useGetGuildFTPCredentialsQuery, useResetFTPPasswordMutation } from '../../extract/rtkExtractApi'
import { useCurrentGuildContext } from '../../util/routing'

const GuildSFTPAccess = () => {
    const [isModalOpen, setModalOpen] = useState(false)

    const [currentGuildContext] = useCurrentGuildContext()

    const {data: ftpCredentials, isFetching: getFTPIsLoading } = useGetGuildFTPCredentialsQuery(currentGuildContext?.guildId, {skip: !currentGuildContext?.guildId})
    const [resetFTPPassword, resetFTPPasswordResult] = useResetFTPPasswordMutation()

    const sendToClipboard = async (textToCopy?: string) => {
        if(textToCopy){
            await navigator.clipboard.writeText(textToCopy)
        }
    }

    const generatePassword = () => {
        resetFTPPassword(currentGuildContext?.guildId)
        .then((response) => {
            if(!('error' in response)){
                setModalOpen(true)
            }
        })
    }

    return (
        <>
            <div className="guildServiceOrdersMain">
                <Typography variant="h5" id="serviceOrdersTitle" className="sftpAccessTitle">
                    SFTP Access
                </Typography>
                <p>Some services such as part extraction will upload files in a dedicated FTP server, this page gives you the credentials in order to access this server and download the documents.</p>
                <div className="ftp-setup-container">
                    <div className="ftp-setup-column">
                        <p className="ftp-setup-column-row-title">SFTP Account Credentials</p>
                        <Paper className="ftp-setup-card">
                            <div className="ftp-setup-card-step">
                                <p className="ftp-setup-card-title">Host</p>
                                <LoadingButton loading={getFTPIsLoading} onClick={()=> sendToClipboard(ftpCredentials?.host)} variant= "text" color="primary" endIcon={<ContentCopyIcon/>}>{ftpCredentials?.host}</LoadingButton>
                            </div>
                            <div className="ftp-setup-card-step">
                                <p className="ftp-setup-card-title">Username</p>
                                <LoadingButton loading={getFTPIsLoading} onClick={()=> sendToClipboard(ftpCredentials?.username)} variant= "text" color="primary" endIcon={<ContentCopyIcon/>}>{ftpCredentials?.username}</LoadingButton>
                            </div>
                            <div className="ftp-setup-card-step">
                                <p className="ftp-setup-card-title">Password</p>
                                <LoadingButton  variant= "contained" color="primary" loading={resetFTPPasswordResult.isLoading} onClick={()=> generatePassword()}>Generate New Password</LoadingButton>
                                <div className="ftp-notice"><p><span>* </span>If you have previously generated a password for your SFTP account, it will be overwritten.</p></div>
                            </div>
                        </Paper>
                    </div>
                </div>
                {isModalOpen && (<SFTPPasswordModal setModalOpen={setModalOpen} password={resetFTPPasswordResult.data?.password}/>)}
            </div>
        </>
    )
}

export default GuildSFTPAccess
