import {FunctionComponent, SVGProps} from 'react'

export const IconDisplaySVG = ({
                                   className,
                                   iconName,
                                   iconSourceMap,
                                   defaultIcon
                               }: {
    iconName: string | undefined,
    className: string | undefined,
    iconSourceMap: Map<string | undefined, FunctionComponent<SVGProps<SVGSVGElement>>>
    defaultIcon: FunctionComponent<SVGProps<SVGSVGElement>>
}) => {
    const Icon = iconSourceMap.get(iconName?.toUpperCase()) ?? defaultIcon
    return <span title={iconName}><Icon className={className} data-testid={iconName}/></span>
}
