import {useGetDatxClassificationTreeQuery} from '../../../search/rtkSearchApi'
import {useEffect, useState} from 'react'
import {BreadCrumbs, BreadcrumbType} from '../../../genericComponents/breadcrumbs/BreadCrumbs'
import {IdType} from '../../../util/models/IdType'
import {getPathToClassification} from '../../../util/classifications/Classification'
import {ROUTES} from '../../../constants/routing'
import {useDatxPathGenerator} from '../../../util/routing'

const BreadcrumbsSection = ({classificationId, partNumber}: {classificationId: IdType; partNumber: string}) => {
    const {data: classificationTree, isFetching: isFetchingClassificationTree} = useGetDatxClassificationTreeQuery()

    const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbType[]>([])
    const generatePath = useDatxPathGenerator()

    const getBreadcrumbs = () => {
        if (!classificationTree) return
        const classificationPath = getPathToClassification(classificationId, classificationTree)
        const itemDetailsPage = {link: '', label: partNumber, id: ''}
        setBreadcrumbs([
            ...classificationPath.map((classification) => {
                return {
                    link: {
                        pathname: generatePath(ROUTES.searchByClassificationNodeDescendants.path, {
                            classificationId: classification.id,
                        }),
                        search: 'page=1',
                    },
                    label: classification?.label,
                    id: classification.id,
                }
            }),
            itemDetailsPage,
        ])
    }

    useEffect(() => {
        if (!isFetchingClassificationTree && classificationTree) getBreadcrumbs()
    }, [isFetchingClassificationTree, classificationTree])

    return <BreadCrumbs breadcrumbList={breadcrumbs} />
}

export default BreadcrumbsSection
