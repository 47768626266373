import {createApi} from '@reduxjs/toolkit/dist/query/react'
import {SEARCH_API_ENDPOINT} from '../constants/backendUrls'
import {axiosBaseQuery} from '../rtkQueryUtils'
import {IdType} from '../util/models/IdType'
import {Organization} from './model/Organization'
import {ItemOrganization} from '../item/model/ItemOrganization'

export const CURRENT_ORGANIZATION_TAG = 'CurrentOrganization'
export const CURRENT_ORGANIZATIONS_TAG = 'CurrentOrganizations'

export const organizationAPI = createApi({
    reducerPath: 'organizationAPI',
    keepUnusedDataFor: 0,
    tagTypes: [CURRENT_ORGANIZATION_TAG, CURRENT_ORGANIZATIONS_TAG],
    baseQuery: axiosBaseQuery({baseUrl: `${SEARCH_API_ENDPOINT}/api/v1/datx/`}),
    endpoints: (builder) => ({
        getOrganization: builder.query<Organization, IdType | undefined>({
            query: (organizationId) => ({
                url: `organizations/${organizationId}`, method: 'GET'
            }), transformResponse: (response: any) => response, providesTags: [CURRENT_ORGANIZATION_TAG]
        }),
        getOrganizations: builder.query<ItemOrganization[], IdType[]>({
            query: (organizationIds) => ({
                url: 'organizations', method: 'POST', data: organizationIds
            }), transformResponse: (response: any) => response, providesTags: [CURRENT_ORGANIZATIONS_TAG]
        }),
        getOrganizationsByGuildId: builder.query<ItemOrganization[], IdType>({
            query: (guildId) => ({
                url: `organizations/managed-by/${guildId}`, method: 'GET'
            }),
            transformResponse: (response: any) => response,
            transformErrorResponse: (response: any) => response?.data?.errorMessage || 'Something went wrong during organisations retrieval',
        })
    })
})

export const {useGetOrganizationsByGuildIdQuery, useGetOrganizationQuery, useGetOrganizationsQuery} = organizationAPI
