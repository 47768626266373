import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Menu, MenuItem, Typography} from '@mui/material'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import CustomHeaderButton from './CustomHeaderButton'
import {ItemNavigation} from './ItemNavigation'

const ItemNavigationMenu = ({itemList, label, subRoute, url}:
                                {
                                    itemList?: ItemNavigation[],
                                    label: string,
                                    subRoute: string,
                                    url?: string
                                }) => {
    const {pathname} = useLocation()
    const [anchorEl, setAnchorEl] = useState<Element | null>(null)
    const handleClick = (e: React.MouseEvent) => {
        if (itemList) {
            setAnchorEl(e.currentTarget)
            return
        }
        if (url) {
            navigate(url)
            return
        }

    }
    const handleClose = () => setAnchorEl(null)

    const open = Boolean(anchorEl)
    const navigate = useNavigate()
    const [activeLink, setActiveLink] = useState(false)
    const params = useParams()

    useEffect(() => {
        setActiveLink(pathname.startsWith('/guild/'+ params?.currentGuildId + subRoute))
    }, [pathname, subRoute])


    return (
        <>
            <div className="header-menu">
                <CustomHeaderButton
                    onClick={handleClick}
                    endIcon={null}
                    activeNavLink={activeLink}
                >
                    <Typography className="header-menu-text">{label}</Typography>
                </CustomHeaderButton>
            </div>
            {itemList && (
                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    {Array.isArray(itemList) &&
                        itemList.map((item) => {
                            return (
                                <MenuItem
                                    key={item.objId}
                                    data-testid={item.dataTestId}
                                    disabled={item.disabled}
                                    onClick={() => {
                                        navigate(item.url)
                                        handleClose()
                                    }}
                                >
                                    {item.label}
                                </MenuItem>
                            )
                        })}
                </Menu>
            )}
        </>
    )
}

ItemNavigationMenu.propTypes = {
    itemList: PropTypes.array,
    label: PropTypes.string,
    icon: PropTypes.element,
}

export default ItemNavigationMenu
