import React, {ReactNode} from 'react'
import {Dialog as MuiDialog, IconButton,} from '@mui/material'
import './ServiceRequestDialog.scss'
import {Close} from '@mui/icons-material'
import { CopyButton } from '../CopyButton'

function ServiceRequestDialog({
                          children,
                          open,
                          onClose,
                          serviceRequestId,
                      }: {
    children: ReactNode,
    open: boolean,
    onClose: () => void,
    serviceRequestId: string ,
}) {
    return (

        <MuiDialog
            open={open}
            onClose={onClose}
            classes={{
                root: 'service-request-dialog-root',
                paper: 'service-request-dialog-container',
            }}
            className="common-service-request-dialog"
            onClick = {(event)=>
                event.stopPropagation()
            }
        >
            <div className="service-request-dialog" data-testid="common-service-request-dialog-id">
                <div className="dialog-header">
                    <div className="dialog-title">Request Details</div>
                    <IconButton className="dialog-close" onClick={onClose}>
                        <Close/>
                    </IconButton>
                </div>
                <div className="id-container" data-testid="id-container">
                    <span className="id-value" title="ID">ID: </span>
                    <span className="id-value">{serviceRequestId}</span>
                    <CopyButton toCopy={serviceRequestId}/>
                </div>
                <div className="dialog-body">{children}</div>
            </div>
        </MuiDialog>
    )
}

export default ServiceRequestDialog
