import React, {ReactNode, useEffect, useRef, useState} from 'react'
import {Formik, FormikProps} from 'formik'
import {Button} from '@mui/material'
import {ServiceRequestStep} from '../../model/ServiceRequestStep'
import {AnyObject} from 'yup'
import './ServiceRequestFormTemplate.scss'
import {ServiceRequestTemplate} from '../structure/ServiceRequestTemplate'
import {ServiceRequestBody} from '../structure/ServiceRequestBody'
import {ServiceRequestFooter} from '../structure/ServiceRequestFooter'
import {setSnackbarMessage} from '../../../../genericComponents/commonSlice'
import {useTypedDispatch} from '../../../../util/store'
import {useCurrentGuildContext} from '../../../../util/routing'
import {ServiceRequestStepNotFound} from './steps/ServiceRequestStepNotFound'
import {ServiceRequestFormHeader} from './ServiceRequestFormHeader'
import {ServiceRequestConfirmationSuccess} from './serviceRequestConfirmation/ServiceRequestConfirmationSuccess'

export const ServiceRequestFormTemplate = <T extends AnyObject>({
                                                                    children,
                                                                    steps,
                                                                    handleFormSubmit,
                                                                    initialValues,
                                                                    title,
                                                                    previousUrl,
                                                                    optionalConfirmationStep,
                                                                    withStepper = false
                                                                }: {
    children?: ReactNode
    steps: ServiceRequestStep<T>[]
    handleFormSubmit: (formValues: T) => Promise<any> | undefined
    initialValues: T
    title: string
    previousUrl: string
    withStepper?: boolean
    optionalConfirmationStep?: ReactNode
}) => {
    const [currentStepIndex, setCurrentStepIndex] = useState<number>(0)
    const [finalRequestId, setFinalRequestId] = useState<string | undefined>(undefined)
    const currentStep = steps[currentStepIndex]
    const formikRef = useRef<FormikProps<any>>(null)
    const dispatch = useTypedDispatch()
    const [currentGuildContext] = useCurrentGuildContext()

    useEffect(() => {
        if (formikRef.current) formikRef.current.validateForm()
    }, [currentStepIndex, formikRef])

    const handleForm = (formResult: T) => {
        if (!currentGuildContext) {
            dispatch(setSnackbarMessage('You have to select a guild in order to fill this request form.'))
            return
        }

        handleFormSubmit(formResult)?.then((result: { data?: any, isSuccess?: boolean, isError?: boolean }) => {
            if (result.isError) {
                currentStep.error = true
                return
            }
            if (result.isSuccess) {
                // some service doesn't care of returning a service request id and will have a custom confirmation page,
                // so by default we set the final request id to not needed to still trigger the confirmation page
                setCurrentStepIndex(currentStepIndex + 1)
                setFinalRequestId('not needed')
                return
            }

            if (result?.data?.id) {
                setFinalRequestId(result?.data?.id)
                setCurrentStepIndex(currentStepIndex + 1)
            }
        }).catch(_ => {
            currentStep.error = true
        })
    }

    const next = () => {
        setCurrentStepIndex(currentStepIndex + 1)
        setFinalRequestId(undefined)
    }
    const previous = () => {
        setCurrentStepIndex(currentStepIndex - 1)
        setFinalRequestId(undefined)
    }


    if (finalRequestId) return <ServiceRequestTemplate className="service-request-confirmation">
        <ServiceRequestFormHeader currentStepIndex={currentStepIndex} title={title}
                                  withStepper={withStepper}
                                  previousUrl={previousUrl} steps={steps}></ServiceRequestFormHeader>
        {optionalConfirmationStep ? optionalConfirmationStep :
            <ServiceRequestConfirmationSuccess requestId={finalRequestId}></ServiceRequestConfirmationSuccess>}
    </ServiceRequestTemplate>

    if (!currentStep) return <ServiceRequestStepNotFound previousUrl={previousUrl}/>

    return <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={currentStep.pageValidation}
        onSubmit={handleForm}
        innerRef={formikRef}
    >
        {({values, errors, touched, handleSubmit, dirty, isValid}) => (
            <form onSubmit={handleSubmit} className="service-request-form-steps-template">
                <ServiceRequestTemplate>
                    <ServiceRequestFormHeader currentStep={currentStep} currentStepIndex={currentStepIndex}
                                              steps={steps}
                                              withStepper={withStepper}
                                              title={title} previousUrl={previousUrl}></ServiceRequestFormHeader>
                    <ServiceRequestBody>
                        {currentStep.component}
                        {children}
                    </ServiceRequestBody>

                    <ServiceRequestFooter>
                        {currentStepIndex > 0 && <Button variant="text" type="button" onClick={previous}
                                                         className="service-request-form-previous">
                            Previous
                        </Button>
                        }
                        {currentStepIndex + 1 < steps.length &&
                            <Button color="primary" variant="contained" type="button" onClick={next}
                                    data-testid="stepper-next" disabled={!dirty || !isValid}
                                    className="service-request-form-next">
                                Next
                            </Button>
                        }
                        {currentStepIndex + 1 >= steps.length &&
                            <Button color="primary" variant="contained" type="submit" disabled={!dirty || !isValid}
                                    data-testid="stepper-submit" className="service-request-form-submit">
                                Submit
                            </Button>
                        }
                    </ServiceRequestFooter>
                </ServiceRequestTemplate>
            </form>
        )}
    </Formik>
}
