import {AttributeStatusColorEnum} from '../model/AttributeModel'
import {IconDisplay} from '../../genericComponents/IconDisplay'


export const RegulationStatusColorAttribute = ({ statusColor }: { statusColor: string | undefined }) => {

    const statusColorIconSourceMap = new Map<AttributeStatusColorEnum | string, string>([
        [AttributeStatusColorEnum.GREEN, '/images/custom-icons/StatusCompliant.svg'],
        [AttributeStatusColorEnum.ORANGE, '/images/custom-icons/StatusCompliantException.svg'],
        [AttributeStatusColorEnum.GREY, '/images/custom-icons/StatusUnknown.svg'],
        [AttributeStatusColorEnum.RED, '/images/custom-icons/StatusNonCompliant.svg'],
    ])

    if (!statusColor) return <></>
    return <IconDisplay data-testid="test-details-attribute-status-color-icon" className="attribute-status-color-icon"
                        iconName={statusColor} iconSourceMap={statusColorIconSourceMap} iconLabel={statusColor} />
}