import {SearchItem} from '../../../model/SearchItem'
import {AppBar, Button, Checkbox, Divider, IconButton, Toolbar} from '@mui/material'
import {SelectionDownload} from '../../../../util/component/SelectionDownload'
import {DeleteForever} from '@mui/icons-material'
import {SelectPageSizeWithMenu} from '../PageSelection/SelectPageSizeWithMenu'
import React, {useState} from 'react'
import {useGetSharingByIdQuery, useRemoveSpecificItemsToShareMutation} from '../../../../guild/rtkGuildApi'
import {useParams} from 'react-router-dom'
import {LoadingButton} from '@mui/lab'
import CustomDialog from '../../../../genericComponents/customDialog/CustomDialog'

export const SearchToolbarForSpecificItemsSharing = ({
                                                         handleSelectAll,
                                                         handleUnselectAll,
                                                         isAnItemOnPageSelected,
                                                         allItemsSelected,
                                                         selectedItemsList,
                                                         startIndex,
                                                         endIndex,
                                                         totalResultCount
                                                     }: {
    handleSelectAll: () => void,
    handleUnselectAll: () => void,
    isAnItemOnPageSelected: boolean,
    allItemsSelected: boolean,
    selectedItemsList: SearchItem[],
    startIndex: number,
    endIndex: number,
    totalResultCount: number
}) => {

    const {sharingId} = useParams()
    const {data: sharingDetail} = useGetSharingByIdQuery(sharingId!, {skip: !sharingId})
    const [isDeleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false)
    const [removeSpecificItemsToShare, updateShareResult] = useRemoveSpecificItemsToShareMutation()

    const closeConfirmationDeleteDialog = () => {
        setDeleteConfirmationDialogOpen(false)
    }

    const displayConfirmationDeleteDialog = () => {
        setDeleteConfirmationDialogOpen(true)
    }

    const stopSharingSpecificItems = (sharingId: string | undefined) => {
        if (sharingId) {
            removeSpecificItemsToShare({
                sharingId: sharingId,
                itemIdsOfItemList: selectedItemsList.map(searchItem => searchItem.id)
            }).then(() => closeConfirmationDeleteDialog())
        }
    }

    const ConfirmationDeleteDialog = () => {
        return <CustomDialog
            key="remove-specific-shared-item-dialog"
            className="remove-specific-shared-item-dialog"
            headerIcon={false}
            open={isDeleteConfirmationDialogOpen}
            title="Remove Items?"
            onClose={closeConfirmationDeleteDialog}
        >
            <div className="remove-specific-shared-item-dialog-body">
                <span>By removing the selected items, Guild {sharingDetail?.targetGuildName ? sharingDetail?.targetGuildName : ''} will no longer receive view access to the selected items.</span>
                <p>Are you sure you want to proceed?</p>
                <div className="remove-specific-shared-item-dialog-buttons">
                    <Button
                        className="cancel-button"
                        variant="outlined"
                        onClick={closeConfirmationDeleteDialog}
                    >
                        Cancel
                    </Button>

                    <LoadingButton
                        className="remove-button"
                        color="primary"
                        variant="contained"
                        loading={updateShareResult.isLoading}
                        onClick={() => stopSharingSpecificItems(sharingId)}
                        data-testid="remove-specific-shared-item-dialog-remove-button"
                    >
                        Remove
                    </LoadingButton>
                </div>
            </div>
        </CustomDialog>
    }

    return (<AppBar position="static" className="item-search-toolbar-container">
        <Toolbar variant="dense" className="item-search-toolbar">
            <Checkbox
                data-testid="check-all-items"
                checked={allItemsSelected}
                indeterminate={isAnItemOnPageSelected && !allItemsSelected}
                onClick={() => {
                    if (!allItemsSelected) {
                        handleSelectAll()
                    } else {
                        handleUnselectAll()
                    }
                }}
            />

            <Divider orientation="vertical" variant="middle" flexItem/>

            <SelectionDownload selectedItems={selectedItemsList} isDisabled={true}/>

            <IconButton
                className="delete-button"
                aria-label="delete"
                data-testid="delete-shared-item"
                onClick={() => displayConfirmationDeleteDialog()}
                disabled={!isAnItemOnPageSelected}
                color="primary"
            >
                <DeleteForever/>
            </IconButton>

            <ConfirmationDeleteDialog />
            <SelectPageSizeWithMenu startIndex={startIndex} endIndex={endIndex} totalResultCount={totalResultCount}/>
        </Toolbar>
    </AppBar>)
}