import {AccordionTemplate} from './AccordionTemplate'
import {CookiesCategory, CookieVendor, Vendor, VendorType} from '../../CookiesTypes'
import {ChangeEvent} from 'react'
import {AccordionSwitch} from './AccordionSwitch'

export const VendorCookieAccordion = ({
                                          category,
                                          type,
                                          vendorDetails,
                                          handleSwitch,
                                          checkedSwitch,
                                          inCategoryDialog,
                                      }: {
    category: CookiesCategory
    type: CookieVendor
    vendorDetails: VendorType[] | Vendor[],
    handleSwitch: (event: ChangeEvent<HTMLInputElement>) => void,
    checkedSwitch: boolean,
    inCategoryDialog: boolean,
}) => {

    return <AccordionTemplate category={type}
                              title={type}
                              toggleSwitch={<AccordionSwitch inCategoryDialog={inCategoryDialog} type={type}
                                                             category={category} handleSwitch={handleSwitch}
                                                             checked={checkedSwitch}/>}
                              accordionDetailsClasses="vendors-list"
    >
        {vendorDetails.map(vendor => <div className="vendor-details">
            {Object.entries(vendor as Vendor).map(([name, detail], index) =>
                <div className="vendor-detail" key={`${name}-${index}`}>
                    <span className="vendor-name">{name}</span>
                    <span>{detail}</span>
                </div>
            )}
        </div>)}
    </AccordionTemplate>
}