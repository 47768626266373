import { useFormikContext } from 'formik'
import { AccessToInformationPackageInput } from '../../AccessToInformationPackage'
import { Description } from '../../../../../genericComponents/typographie/Description'
import { Label } from '../../../../../genericComponents/typographie/Label'
import { ServiceRequestStep } from '../../../genericComponents/formTemplate/steps/ServiceRequestStep'

const ReviewInformationPackageAccess = () => {
    const formik = useFormikContext<AccessToInformationPackageInput>()

    return (
        <ServiceRequestStep>
            <Description>Review the perimeters of your selection and submit.</Description>

            <div data-testid="information-package-summary" className="service-request-form-summary">
                <Label className="form-summary-label">Item List</Label>
                <span className="form-summary-value">{formik?.values?.itemList?.name ?? '-'}</span>

                <Label className="form-summary-label">Information Package</Label>
                <span className="form-summary-value">{formik?.values?.informationPackage?.label ?? '-'}</span>
            </div>
        </ServiceRequestStep>
    )
}

export default ReviewInformationPackageAccess