import './ItemSharing.scss'
import CustomDialog from '../../../genericComponents/customDialog/CustomDialog'
import {LoadingButton} from '@mui/lab'
import {useStopSharingMutation} from '../../rtkGuildApi'
import {useState} from 'react'
import {IdType} from '../../../util/models/IdType'
import {OrganizationItemSharing} from '../model/OrganizationItemSharing'

export const DialogStopSharing = ({open, title, className, onClose, itemSharing, sharingId, targetGuildName}: {
    open: boolean,
    title: string,
    className: string,
    onClose: () => void,
    itemSharing: OrganizationItemSharing,
    sharingId: IdType,
    targetGuildName: string
}) => {

    const [openDialog, setOpenDialog] = useState(false)
    const [stopSharing, stopSharingResult] = useStopSharingMutation()

    const handleStopSharing = async() => {
       await stopSharing({sharingId: sharingId, organizationId: itemSharing.targetOrganizationId})
       setOpenDialog(false)
    }

    return (
        <CustomDialog
            open={open || openDialog}
            title={title}
            className={className}
            onClose={onClose}
        >
            <div>
                <div className="stop-sharing-dialog-body" data-testid="stop-sharing-message">
                    Guild members from <span className="target-guild-name">{targetGuildName}</span> will no longer be able to view items defined
                        by {itemSharing?.targetOrganizationName ?? ''}
                </div>

                <div className="stop-sharing-dialog-footer">
                    <LoadingButton
                        variant="text"
                        onClick={onClose}
                        data-testid="cancel-stop-sharing"
                        className="cancel-stop-sharing"
                    >
                        Cancel
                    </LoadingButton>

                    <LoadingButton
                        color="primary"
                        variant="contained"
                        data-testid="confirmation-stop-sharing"
                        onClick={handleStopSharing}
                        loading={stopSharingResult.isLoading}
                    >
                        Stop Sharing
                    </LoadingButton>
                </div>
            </div>
        </CustomDialog>
    )
}
