import {IdType} from '../../util/models/IdType'
import {convertToUnix} from '../../genericComponents/date/DateService'
import {AttributeDefinitionModel, AttributeModel} from '../model/AttributeModel'
import {RelatedItem} from '../../displaySet/model/AttributeValue'
import {ItemTypeEnum} from '../../item/model/ItemType'

export const sortByDisplayOrder = (definitions: AttributeDefinitionModel[]): AttributeDefinitionModel[] =>
    definitions.sort((a, b) => a.displayOrder - b.displayOrder)

export const sortAttributesByDisplayOrder = (definitions: AttributeModel[]): AttributeModel[] =>
    definitions.sort((a, b) => a.displayOrder - b.displayOrder)

export const groupByDefinitionId = (attributes: AttributeModel[]) =>
    attributes.reduce((acc, attribute) => {
        const key = attribute?.attributeDefinitionId
        if (!key) return acc
        const definition = acc.get(key)
        if (!definition) acc.set(key, {...attribute, attributes: [attribute]})
        else definition.attributes.push(attribute)
        return acc
    }, new Map<IdType, AttributeDefinitionModel>())

export const sortSubItemsByPartNumber = (items: {partNumber: string}[]) =>
    items.sort((a, b) => a.partNumber.localeCompare(b.partNumber))

type AttributeDateAccessor<T> = (attribute: T) => number[] | null | undefined;

export const sortByStartValidity = (attributes: AttributeModel[]) =>
    sortByDate(attributes, (attribute) => attribute.value.document?.startOfValidity)

export const sortByDate = <T extends AttributeModel>(attributes: T[], getDate: AttributeDateAccessor<T>): T[] =>
    attributes.sort((a, b) => {
        const dateA = getDate(a)
        const dateB = getDate(b)
        if (!dateA) return 1
        if (!dateB) return -1
        const aDate = convertToUnix(dateA)
        const bDate = convertToUnix(dateB)
        return bDate - aDate
    })


export const getLinkedItemsByType = (itemAttributes: AttributeModel[], type: ItemTypeEnum) =>
    sortSubItemsByPartNumber(
        itemAttributes.reduce((acc, att) => {
            const linkedItem = att.value.linkedItem
            if (linkedItem && linkedItem.type.label === type) acc.push(linkedItem)
            return acc
        }, [] as RelatedItem[])
    ) as RelatedItem[]

export const getLinkedItems = (itemAttributes: AttributeModel[]) =>
    itemAttributes.reduce((acc, att) => {
        if (att.value.linkedItem) {
            acc.push({...att.value.linkedItem, linkCreatedDate: att.createdDate})
        }
        return acc
    }, [] as RelatedItem[])

